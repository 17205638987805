/* eslint-disable no-prototype-builtins,no-unneeded-ternary,no-undef,no-loop-func */
/* eslint-disable no-param-reassign,no-unused-vars */
import {html, PolymerElement} from "@polymer/polymer/polymer-element";

import "ag-grid-polymer";
import "@vaadin/vaadin-icons";
import "@hpacs/healthhub-icons/healthhub-icons";
import "@hpacs/spinner/hpacs-spinner";

// import * as Sentry from "@sentry/browser";

import moment from "moment-timezone";
import {timeOut} from "@polymer/polymer/lib/utils/async";
import {Debouncer} from "@polymer/polymer/lib/utils/debounce";
import download from "../public/resource/js/download";
import GridUtils from "./utils/grid-utils";
import DateFilterUtils from "../public/resource/js/utils/date-filter-utils";

import {CustomDateComponent} from "../public/resource/js/custom-date-component";
import {CustomDateFloatingComponent} from "../public/resource/js/custom-date-floating-component";
import {CustomTooltip} from "../public/resource/js/customTooltip";

import CommonUtils from "../public/resource/js/utils/common";

// import "./components/hh-dropdown-menu-dialog";
// import "./components/hh-referral-form-dialog";
import mixinCommons from "./common-mixin";
import store from "./redux/store";
import { ReportActionType } from "./redux/reducers/report.ts";
import {RedrawRowsType, WorklistActionType} from "./redux/reducers/worklist.ts";
import {RelatedWorklistActionType} from "./redux/reducers/related-worklist";
import TechnicianUtils from "./utils/technician-utils";
import {CommonActionType, CustomContextMenuType, DialogActionType, DialogType} from "./redux/reducers/common";
import RadiologyUtils from "./utils/radiology-utils";
import FilmboxUtils from "./utils/filmbox-utils";
import {TechlistActionType} from "./redux/reducers/techlist";
import {FilmboxActionType} from "./redux/reducers/filmbox";
import i18n from "./utils/i18n-utils";
import {QueryKey} from "./redux/reducers/query";

class GridNewfilm extends mixinCommons(PolymerElement) {
   static get is() {
      return "grid-newfilm";
   }

   static get template() {
      return html`
         <link rel="stylesheet" href="/vendor/ag-grid-enterprise/dist/styles/ag-grid.min.css">
         <link rel="stylesheet" href="/vendor/ag-grid-enterprise/dist/styles/ag-theme-balham-dark.min.css">
         <link rel="stylesheet" href="/resource/style/ag-grid-hpacs.css">

         <style>
            :host {
               display: block;
               height: 100%;
               width: 100%;
            }

            .prefetch {
               background-color: #2f2f2f !important;
               color: #c3c3c3 !important;
            }

            input {
               border: 1px solid #4c5667 !important;
            }

            paper-progress {
               --paper-progress-height: 12px;
               --paper-progress-active-color: #0087cb;
               --paper-progress-container-color: #ccc;
               width: 100%;
               top: 7px;
            }

            .contextMenuIcon {
               --iron-icon-width: 16px;
               --iron-icon-height: 16px;
            }

            ::-webkit-scrollbar {
               width: 15px;
               height: 15px;
            }

            ::-webkit-scrollbar-thumb {
               background-color: #6b6b6b;
               border-radius: 10px;
               background-clip: content-box;
               border: 3px solid rgba(255,255,255,0);
            }

            paper-input {
               --paper-input-container-input-color: #aaaaaa;
               --paper-input-container-focus-color: #0087cb;
               --paper-input-container-label: {
                  text-align: left;
                  font-size: 13px;
               };
            }

            .relative-date-filter-input {
               text-align: center;
            }

            paper-dropdown-menu, paper-listbox {
               --paper-input-container-focus-color: #0087cb;
               --paper-input-container-label: {
                  text-align: center;
                  color: #aaaaaa;
                  font-size: 13px;
               };
               --paper-input-container-input: {
                  text-align: center;
                  color: #aaaaaa;
                  font-size: 13px;
               };
               color: #aaaaaa;
               background: var(--ag-background-color,#2d3436);
            }

            paper-item {
               --paper-item-min-height: 30px;
               --paper-item-focused: {
                  color: #0087cb;
               };
               font-size: 13px;
            }

            .relative-date-filter-body {
               display: flex;
               justify-content: space-evenly;
               align-items: flex-end;
               width: 100%;
            }

            #duplicationDownloadToast {
               display: flex;
               position: fixed;
               bottom: 85px;
               width: 420px;
               left: 245px;
               padding: 15px 20px;
               transform: translate(-50%, 10px);
               border-radius: 20px;
               overflow: hidden;
               font-size: .8rem;
               opacity: 0;
               visibility: hidden;
               transition: opacity .5s, visibility .5s, transform .5s;
               background: rgba(0, 0, 0, .8);
               color: #aaa;
               z-index: 10000;
            }

            #duplicationDownloadToast.reveal {
               opacity: 1;
               visibility: visible;
               transform: translate(-50%, 0)
            }

            #fileDownloadToast {
               display: flex;
               position: fixed;
               bottom: 12px;
               width: 530px;
               left: 300px;
               padding: 15px 20px;
               transform: translate(-50%, 10px);
               border-radius: 20px;
               overflow: hidden;
               font-size: .8rem;
               opacity: 0;
               visibility: hidden;
               transition: opacity .5s, visibility .5s, transform .5s;
               background: rgba(0, 0, 0, .8);
               color: #aaa;
               z-index: 10000;
            }

            #fileDownloadToast.reveal {
               opacity: 1;
               visibility: visible;
               transform: translate(-50%, 0)
            }

            #fileDownloadToast_title {
               display: flex;
               align-items: center;
               width: 84%;
            }

            .yellow-button {
               text-transform: none;
               color: #eeff41;
            }
         </style>

         <ag-grid-polymer id="girdNewfilm"
               class="ag-theme-balham-dark"
               gridOptions="{{gridOptions}}"
               columnDefs="{{columnDefs}}"
         ></ag-grid-polymer>
         <hh-dropdown-menu-dialog id="teleServiceDialog"></hh-dropdown-menu-dialog>
         <hh-referral-form-dialog id="referralFormDialog"></hh-referral-form-dialog>
         <hh-confirm-dialog id="tempCaseDialog"></hh-confirm-dialog>
         <hh-confirm-dialog id="editCaseDialog"></hh-confirm-dialog>
         <paper-toast id="toast1" duration="0" text="" class="btnToast">
            <paper-button class="yellow-button">&nbsp&nbsp&nbsp Close</paper-button>
         </paper-toast>

         <div id="duplicationDownloadToast">
            <div id="duplicationDownloadToast_title"></div>
            <paper-button id="closedDuplicationToast" class="yellow-button">Close now!</paper-button>
         </div>
         <div id="fileDownloadToast">
            <div id="fileDownloadToast_title"></div>
            <paper-button id="closedDownloadToast" class="yellow-button">Close now!</paper-button>
         </div>
      `;
   }

   static get properties() {
      return {
         activeTabCode: {
            type: Number,
            observer: "onActiveTabChange"
         },
         category: {
            type: Number,
            observer: "onSelectCategory"
         },
         _utcOffset: {
            type: Number
         },
         gridApi: {
            type: Object,
            value: {}
         },
         columnDefs: {
            type: Array,
            value: []
         },
         popup: {
            type: Object
         },
         popupName: {
            type: String,
            value: "popup"
         },
         popupOpts: {
            type: String,
            value: "resizable=yes,toolbar=no,status=0,location=no,menubar=no,scrollbars=0,dependent=yes"
         },
         _selectedRow: {
            type: Object,
            value: {}
         },
         _selectedRows: {
            type: Array,
            value: [],
            observer: "_selectedRowsChanged"
         },
         _purchaseAiList: {
            type: Array,
            value: [],
         },
         // g_objId: {
         //    type: String
         // },
         // g_reader: {
         //    type: Object,
         //    value: {}
         // },
         message: {
            type: Object,
            value: {
               msg: "",
               isErr: false
            }
         },
         // _filmboxStatus: {
         //    type: Object,
         //    value: {}
         // },
         g_quickFilter: {
            type: Boolean,
            value: false
         },
         _filters: {
            type: Object,
            value: {}
         },
         _startRow: {
            type: Number,
            value: 0
         },
         rowNodes: {
            type: Array,
            value: []
         },
         _flagFilter: {
            type: Boolean,
            value: false
         },
         _filterParams: {
            type: Object,
            value: {}
         },
         g_dateFilter: {
            type: String
         },
         isEmptyFilter: {
            type: Boolean,
            value: false
         },
         isShift: {
            type: Boolean,
            value: false
         },
         filmboxHash: {
            type: Object,
            value: {}
         },
         appliedDateFilters: {
            type: Object,
            value: {}
         },
         createdRelativeDateFilter: {
            type: Object,
            value: {
               studyDtime: false,
               requestDtime: false
            }
         },
         activeDateToggle: {
            type: String,
            value: null
         },
         selectedTab: {
            type: Number,
            value: null
         },
         initDefaultFilter: {
            type: Boolean,
            value: false
         },
         dblClickedId: {
            type: String
         },
         filterTimeStamp: {
            type: Number
         },
         fetchTimeStamp: {
            type: Number
         },
         abortController: {
            type: Object
         },
         isUpdatingReading: {
            type: Boolean,
            value: false
         },
         isAwaitUpdatingReading: {
            type: Boolean,
            value: false
         },
         readingId: {
            type: String,
            value: ""
         },
         eventSource: {
            type: EventSource,
            value: null
         },
         dcmImgCnt: {
            type: Number,
            value: 1
         },
         worklistFilterState: {
            type: Object,
            value: {},
            observer: "setWorklistFilter"
         },
         customContextMenuState: {
            type: Number
         },
         prefetchState: {
            type: Boolean,
            observer: "changePrefetchState"
         },
         filmboxExpand: { // hangingProtocol 확장여부
            type: String,
            value: "T" // T or F or A
         },
         filmboxState: {
            type: Object,
            observer: "changeFilmboxState"
         },
         reportRow: {
            type: Object
         },
         redrawRows: {
            type: Object,
            observer: "changeRedrawRows"
         },
         popupReport: {
            type: Boolean,
            value: false,
         },
         isPopupPin: { // #17744 report popup pin 여부
            type: Boolean,
            value: true
         },
         refreshFlag: {
            type: Boolean,
            value: false
         },
         worklistSortModel: {
            type: Object,
         }
      };
   }

   constructor() {
      super();

      this.gridOptions = {
         defaultColDef: {
            suppressMenu: true,
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filterParams: {
               newRowsAction: "keep"
            },
            suppressKeyboardEvent: params => GridUtils.disableRowDeselectionBySpace(params),
         },
         rowModelType: "serverSide",
         serverSideStoreType: "partial",
         animateRows: true,
         components: {
            // eslint-disable-next-line no-undef
            customTooltip: CustomTooltip,
            agDateInput: CustomDateComponent,
            customDateFloatingFilter: CustomDateFloatingComponent
         },
         // floatingFilter: true,
         rowSelection: "multiple",
         sideBar: {
            toolPanels: [
               {
                  id: "columns",
                  labelDefault: this.t("label.showHideColumns"),
                  labelKey: "columns",
                  iconKey: "columns",
                  toolPanel: "agColumnsToolPanel",
                  toolPanelParams: {
                     suppressRowGroups: true,
                     suppressValues: true,
                     suppressPivots: true,
                     suppressPivotMode: true,
                     suppressSideButtons: true,
                     suppressColumnFilter: true,
                     suppressColumnSelectAll: true,
                     suppressColumnExpandAll: true,
                  }
               }
            ]
         },
         postProcessPopup: params => this.postProcessPopup(params),
         overlayNoRowsTemplate: `<span style='font-size: 13px'>${this.t("label.noRecordsFound")}</span>`,
         cacheBlockSize: 50,
         navigateToNextCell: params => this.navigateToNextCell(params),
         tooltipShowDelay: 0,
         // serverSideDatasource: this.createDatasource.bind(this)
         serverSideSortingAlwaysResets: false,
         localeText: GridUtils.localeText()
      };

      this.gridOptions.onGridReady = (params) => {
         this.gridApi = params.api;

         this.initGridColumns().then(() => {
            params.api.setServerSideDatasource(this.createDatasource());
         });
      };

      this.gridOptions.onModelUpdated = (e) => {
         // RefreshFlag 관련 처리
         if (this.refreshFlag) {
            const selectedRows = this.gridOptions.api.getSelectedRows();
            let first; let last;
            const nodes = [];
            this.gridOptions.api.forEachNode(({rowIndex, data}) => {
               if (first === undefined) first = rowIndex;
               last = rowIndex;
               nodes.push(data); // data -> undefined 일 수 있음
            });

            // grid nodes 가 전부 그려지지 않았다면 return
            if (nodes.filter(node => node?.id).length !== nodes.length) return;

            // console.log(`-> [onModelUpdated] selectedRowLength: ${selectedRows?.length}, first-last: ${first}-${last}, gridNodes:`, [...nodes])
            if (selectedRows && selectedRows.length > 0) {
               selectedRows.forEach(selectedRow => {
                  const selectedNode = this.gridOptions.api.getRowNode(selectedRow.id);
                  if (selectedNode) {
                     // console.log("-> selectedNode", selectedNode.rowIndex, selectedNode.isSelected(), selectedNode)
                     this.refreshFlag = false;
                     this.gridOptions.api.ensureIndexVisible(selectedNode.rowIndex);
                  } else {
                     // console.log("-> selectedNode 없음")
                     this.refreshFlag = false;
                     // 선택된 node 없다면 deselect 처리
                     this.clearSelectedRow();
                  }
               });
            } else {
               this.refreshFlag = false;
            }
         }
      };

      // data의 id를 RowNodeId로 초기화
      this.gridOptions.getRowNodeId = (data) => {
         return data.id;
      };
      this.gridOptions.getContextMenuItems = params => this.getContextMenuItems(params);
      this.gridOptions.onCellContextMenu = params => this.onCellContextMenu(params);

      this.gridOptions.onRowSelected = evt => this.onRowSelected(evt);
      this.gridOptions.onRowClicked = evt => this.onRowClicked(evt);
      this.gridOptions.onRowDoubleClicked = evt => this.onRowDoubleClicked(evt);

      this.gridOptions.onFilterChanged = v => this.onFilterChanged(v);
      this.gridOptions.onFilterModified = evt => this.onFilterModified(evt);

      // this.gridOptions.onColumnMoved = evt => this.onColumnMoved(evt);
      this.gridOptions.onDragStopped = evt => this.onDragStopped(evt);
      this.gridOptions.onColumnResized = evt => this.onColumnResized(evt);
      this.gridOptions.onColumnVisible = evt => this.onColumnVisible(evt);
      this.gridOptions.onSortChanged = evt => this.onSortChanged(evt);

      this.gridOptions.onCellKeyDown = param => this.onCellKeyDown(param);
   }

   ready() {
      super.ready();

      store.subscribe(() => {
         this.activeTabCode = store.getState().relatedWorklist.activeTabCode;
         this.category = store.getState().common.category;
         this.worklistFilterState = store.getState().worklist.filter;
         this.customContextMenuState = store.getState().common.customContextMenu;
         this.prefetchState = store.getState().common.prefetch;
         if (this.category === 0) this.filmboxState = store.getState().filmbox.filmbox;
         this.appliedWorklistFilter = store.getState().worklist.appliedFilter;
         this.reportRow = store.getState().report.reportRow;
         this.redrawRows = store.getState().worklist.redrawRows;
         const { userConfig, popupReport } = store.getState().common;
         this.popupReport = popupReport?.open;
         if (userConfig?.layout?.isPopupPin !== undefined) this.isPopupPin = userConfig.layout.isPopupPin;
         const countResult = store.getState().query[QueryKey.GET_COUNT];
         if(countResult?.result) this.count = countResult?.result;
         this.worklistSortModel = store.getState().worklist.appliedSortModel;
      });

      this.utcCheck().then((result) => {
         this._utcOffset = result;
      });

      document.addEventListener("click", () => {
         if (this.customContextMenuState !== undefined) store.dispatch({ type: CommonActionType.HIDE_CONTEXT_MENU });
      });

      window.addEventListener("message", (event) => {
         switch ((event.data || {}).event) {
         case "READING_ON": {
            this.readingOn();
            break;
         }
         case "READING_OFF" : {
            this.readingOff(event.data.id);
            break;
         }
         case "FILMBOX_CLOSED": {
            // TODO :: 통합처리
            // window.document.filmbox = undefined;
            window.filmbox.reset(); // fleader에서 사용
            store.dispatch({ type: FilmboxActionType.CLOSE_FILMBOX });
            break;
         }
         case "POPUP_DISPLAY_PREV": {
            this.displayPrev();
            break;
         }
         case "POPUP_DISPLAY_NEXT": {
            this.displayNext();
            break;
         }
         case "POPUP_MARK_CVR": {
            this.refreshCellByCVR(event.data.id);
            break;
         }
         default:
         }
      });

      this.$.tempCaseDialog.addEventListener("confirm", (e) => {
         this.reloadFilter();
      });

      this.$.editCaseDialog.addEventListener("confirm", (e) => {
         if(this.popupReport && this.isPopupPin) window.open("", "reportWindow");
      });

      window.getAvailableAiList = (allAiList) => {
         return this.getAvailableAiList(allAiList);
      };

      window.aiStudyRequest = (caseID, applicationId, seriesImage, seriesImages, aiType, addParams) => {
         this.aiStudyRequest(caseID, applicationId, seriesImage, seriesImages, aiType, addParams);
      };

      this.$.toast1.addEventListener("click", () => {
         this.$.toast1.close();
      });

      this.$.teleServiceDialog.addEventListener("dialogSendEvent", (e) => {
         this.postFetchRequestTele(e.detail.id, e.detail.serviceId, e.detail.isEmergency);
      });

      this.$.closedDownloadToast.addEventListener("click", () => {
         this.closeDownloadToast();
      });

      this.$.closedDuplicationToast.addEventListener("click", () => {
         this.closeDuplicationDownloadToast();
      });

      window.addEventListener("prevEvent", () => {
         this.displayPrev();
      });

      window.addEventListener("nextEvent", () => {
         this.displayNext();
      });

      window.addEventListener("markCVREvent", (param) => {
         const {detail} = param;
         if(detail) this.refreshCellByCVR(detail);
      });

      window.addEventListener("sink-add-contents", (e) => {
         const { caseId, contentId, count } = e.detail||{};
         this.gridOptions.api.forEachNode((rowNode) => {
            const { data } = rowNode;
            console.log("sink-add-contents", data, caseId, (data||{}).id);
            //    const { data } = row;
            //    console.log("sink-add-contents", caseId, data, (data||{}).id);
            //    if(data && data.id === caseId) {
            //       row.setDataValue("imageCount", count);
            //       this.gridOptions.api.redrawRows({row});
            //    }
         });
      });
   }

   setWorklistFilter(e = {}) {
      if (e.detail) {
         const { filterModel = {}, sortModel = [] } = e.detail;

         if(e.detail.isQuickFilter) {
            this.g_quickFilter = true;
         }
         // this.gridOptions.api.setFilterModel(filterModel);

         this.setFilter(filterModel);
         // sortModel을 filter 설정전에 Clear 시 조회가 이루어지는 이슈로 인한 수정
         // sortModel의 조회여부는 getWorklist function에서 분기처리로 이루어짐
         this.setFilterSortClear();
         // if (!CommonUtils.isEmptyArr(sortModel)) this.gridOptions.columnApi.applyColumnState({ state: sortModel });

         if (!CommonUtils.isEmptyArr(sortModel)) this.gridOptions.columnApi.applyColumnState({ state: sortModel });
      } else {
         const studyDtime = DateFilterUtils.getDateFilterModel(2, "months");
         this.setFilter({ studyDtime });
      }
   }

   onActiveTabChange(tab) {
      if (this.category === 0) {
         this.relatedTabChanged(tab);
      }
   }

   onSelectCategory(category) {
      this.onFilterChanged();
   }

   getPurchaseAiList() {
      return fetch(`/api/market/list`, {
         method: "GET",
         headers: this._headers(),
      }).then((response) => {
         if (response.ok && response.status === 200) return response.json();
         throw new Error(`Error : ${response.status}`);
      });
   }

   getPurchaseAiListById(id) {
      return fetch(`/api/market/list/state?caseId=${id}`, {
         method: "GET",
         headers: this._headers(),
      }).then((response) => {
         if (response.ok && response.status === 200) return response.json();
         throw new Error(`Error : ${response.status}`);
      });
   }

   aiStudyRequest(caseID, applicationId, seriesImage = "", seriesImages = [], aiType = "", addParams = "") {
      const body = {
         caseID,
         applicationId,
         seriesImage,
         seriesImages,
         aiType,
         addParams
      };

      // toast message
      const isErr = false;
      const msg = this.t("label.aiRequestSuccess");

      document.dispatchEvent(new CustomEvent("toastEvent", {
         bubbles: true, composed: true, detail:{msg, isErr}
      }));

      // row redraw
      const rowData = this.gridApi.getSelectedNodes()[0].data;
      rowData.aiRequestState = "IDLE";
      this.gridOptions.api.redrawRows({row: rowData});

      // api 호출
      return new Promise((resolve, reject) => {
         fetch(`/api/apphost/launch`, {
            method: "POST",
            headers: this._headers(),
            body: JSON.stringify(body),
         }).then((response) => {
            if (response.ok && response.status === 200) {
               const selectedRowId = this.gridApi.getSelectedNodes()[0].data.id;
            }
            else {
               const isErr = true;
               const msg = "AI request is fail!";

               document.dispatchEvent(new CustomEvent("toastEvent", {
                  bubbles: true, composed: true, detail:{msg, isErr}
               }));

               reject(new Error(`Error : ${response.status}`));
            }
         });
      });
   }

   _headers() {
      return {
         "Authorization": localStorage.getItem("jwt"),
         "Content-Type": "application/json",
      };
   }

   aiRequestCancel(applicationId, caseID) {
      const body = {
         applicationId,
         caseID,
      };

      const isErr = false;
      const msg = this.t("label.aiRequestCancel");

      document.dispatchEvent(new CustomEvent("toastEvent", {
         bubbles: true, composed: true, detail:{msg, isErr}
      }));

      return new Promise((resolve, reject) => {
         fetch(`/api/apphost/cancel`, {
            method: "POST",
            headers: this._headers(),
            body: JSON.stringify(body),
         }).then((response) => {
            if (response.ok && response.status === 200) {
               // this.refreshCellByTele(selectedRowId);
            }
            else {
               const isErr = true;
               const msg = this.t("label.aiRequestCancelFail");

               document.dispatchEvent(new CustomEvent("toastEvent", {
                  bubbles: true, composed: true, detail:{msg, isErr}
               }));
               reject(new Error(`Error : ${response.status}`));
            }
         });
      });
   }

   setRightSelectedRows(row) {
      // #16041 마우스 우클릭으로 row가 선택되게끔 추가
      row.node.setSelected(true, true);

      // #16192
      // onRowSelected 이벤트 보다 먼저 타기 때문에 우클릭으로 row 선택시 this._selectedRows 값 세팅
      this._selectedRow = row.node.data;
      this._selectedRows = [ row.node.data ];
   }

   _selectedRowsChanged(newValue, oldValue) {
      // 선택된 로우 변경시 onRowSelected event를 두번 타는데 (select, deselect)
      // 두번 값이 할당되어도 같은 값이 할당되기 때문에 observer를 타지 않을거라 생각했지만 같은 값이여도 observer가 호출되어 아래 배열 비교를 추가함
      if (JSON.stringify(newValue) === JSON.stringify(oldValue)) return;
      // row 선택시 이전 선택한 판독문과 현재 선택한 판독문을 같이 보냄
      if (!newValue || newValue.length === 0) return;

      const newRow = newValue.find(row => row.id === this._selectedRow.id)||newValue[0];
      // const [oldRow] = oldValue;

      if (newRow.id !== this._selectedRow?.id) { // multi 선택 후 deselect 시 처리를 위함
         this._selectedRow = newRow;
         store.dispatch({ type: ReportActionType.SELECT_THUMBNAIL_ROW, payload: newRow.id });
      }

      const param = {
         detail: newRow,
         rows: newValue
      };
      store.dispatch({ type: WorklistActionType.SELECT_ROW, payload: { ...param } });
      // store.dispatch({ type: ReportActionType.SELECT_REPORT_ROW, payload: { ...param, oldRow, oldRows: oldValue } });
      store.dispatch({ type: ReportActionType.SELECT_REPORT_ROW, payload: { ...param, oldRow: this.reportRow?.detail, oldRows: this.reportRow?.rows } });

      if (window.selected_rows_changed) window.selected_rows_changed(); // FLeader 쪽에 신호를 보내 리포트영역으로 커서를 옮기는 기능을 초기화 한다

      this.readingOff();
   }

   relatedTabChanged(relatedTabCode) {
      const key = this.dblClickedId;
      const studies = {};
      const stopRelatedSyncOpen = JSON.parse(localStorage.getItem("stopRelatedSyncOpen"));
      if(key && (window.filmbox && window.filmbox.get() && window.filmbox.get().name === "popup") && !stopRelatedSyncOpen) {
         const url = `/filmbox#${key}&group=new&type=click&related=${FilmboxUtils.convertRelatedTabCode(relatedTabCode)}`;

         this.popup = window.open(url, this.popupName, this.popupOpts);
         // window.document.filmbox = this.popup;
         this.popup.focus();
      }
   }

   downloadToast(requestInfoList, title) {
      this.openDownloadToast(title);
   }

   openDuplicationDownloadToast(string) {
      const toast = this.$.duplicationDownloadToast;
      const toastTitle = this.$.duplicationDownloadToast_title;

      if (!toast.classList.contains("reveal")) {
         toast.classList.add("reveal");
      }
      toastTitle.innerText = string;
   }

   closeDuplicationDownloadToast() {
      this.$.duplicationDownloadToast.classList.remove("reveal");
   }

   openDownloadToast(string) {
      const toast = this.$.fileDownloadToast;
      const toastTitle = this.$.fileDownloadToast_title;

      if (!toast.classList.contains("reveal")) {
         toast.classList.add("reveal");
      }
      toastTitle.innerText = string;
   }

   closeDownloadToast() {
      this.$.fileDownloadToast.classList.remove("reveal");
   }

   downloadStart(requestInfoList, type) {
      if (this.eventSource) {
         const msg = "진행 중인 다운로드 완료 후 진행해 주세요.\n만약 다운로드가 완료된 경우 페이지 새로고침 후 진행해 주세요.";
         this.openDuplicationDownloadToast(msg);
         return;
      }

      const key = CommonUtils.uuidv4();
      this.closeDuplicationDownloadToast();
      this.openDownloadToast("Download Start !!");
      this.dcmImgCnt = 1;

      // eslint-disable-next-line no-undef
      this.eventSource = new EventSource(`${__API_URL__}/events?key=${key}`);
      this.eventSource.onmessage = (result) => {
         const { data } = result;
         // console.log(result, data);
         const { eventId, msg, totalCount, completeCount, totalImgCount, completeImgCount, patientId, patientName, modality, studyDtime, type } = JSON.parse(data);
         // console.log(eventId, msg, totalCount, completeCount, totalImgCount, completeImgCount, patientId, patientName, modality, studyDtime, type);
         switch(eventId) {
         case "DOWNLOAD": {
            let message = "Downloading File...";
            if (completeCount && totalCount) message += ` (${completeCount} / ${totalCount})`;
            if (patientId && patientName && modality && studyDtime) message += `\n ${patientId} / ${patientName} / ${modality} / ${moment(studyDtime, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")} `;
            if (totalImgCount && completeImgCount) {
               if (type === "dcm") {
                  message += ` (${this.dcmImgCnt} / ${totalImgCount}) `;
                  this.dcmImgCnt = this.dcmImgCnt !== totalImgCount ? this.dcmImgCnt + 1 : 1;
               }
               else message += ` (${completeImgCount} / ${totalImgCount}) `;
            }

            this.openDownloadToast(message);
            break;
         }
         case "COMPRESS": {
            this.openDownloadToast("Compressing File...");
            break;
         }
         case "COMPLETE": {
            this.stopServerSentEvent();
            break;
         }
         case "ERROR": {
            this.stopServerSentEvent();
            this.openDownloadToast(type === "dcm" ? `DICOM download error! - ${msg}` : `JPEG download error! - ${msg}`);
            break;
         }
         default:
         }
      };

      this.eventSource.onopen = () => {
         console.log("EventSource open");
      };

      this.eventSource.onerror = (err) => {
         console.log("EventSource error: ", err);
         this.stopServerSentEvent();
      };

      if (requestInfoList && type) {
         this.downloadStudy(requestInfoList, type, key);
      }
   }

   stopServerSentEvent() {
      if (this.eventSource) {
         this.eventSource.close();
         console.log("close EventSource! ");
         this.eventSource = null;
      }
   }

   downloadStudy(requestInfoList, type, key) {
      let fileName = "";
      // eslint-disable-next-line no-undef
      fetch(`${__API_URL__}/dicom/download/study/list/${type}/key/${key}`, {
         method: "POST",
         headers: {
            "Authorization": localStorage.getItem("jwt"),
            "Content-Type": "application/json"
         },
         body: JSON.stringify(requestInfoList)
      }).then((response) => {
         if (response.ok && response.status === 200) {
            const header = response.headers.get("content-disposition");
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(header);
            if (matches != null && matches[1]) {
               fileName = decodeURIComponent(matches[1].replace(/['"]/g, ""));
            }
            return response.blob();
         }
         throw new Error(`[${response.status}] ${response.statusText}`);
      }).then((blob) => {
         download(blob, `${fileName}`, "application/octet-stream");
         this.openDownloadToast("Download Completed !!");
         setTimeout(() => this.closeDownloadToast(), 1000);
      }).catch((e) => {
         console.error(e.message, e);
         this.openDownloadToast([(type === "dcm" ? `DICOM download error!` : `JPEG download error!`), e.message].filter(v => v && v.trim() !== "").join("\n"));
      }).finally(() => this.stopServerSentEvent());
   }

   // #16581 updateGridHeader로 통합
   // updateGridHeader(headerArr) {
   //    this.getUserStyle().then((result) => {
   //       this.updateGridNewfilm(result.id, headerArr);
   //    }).catch((err) => {
   //       console.info(err);
   //       this.gridApi.setColumnDefs(headerArr);
   //    });
   // }

   // retrieveOpinion(param) {
   //    this.getOpinion(param);
   // }

   /**
    * Approve Opinions Result
    * @param result {id, ids}
    */
   approveOpinionisResult(result) {

      // NOTE: 2020/09/04 By Jacob - 판독문 approve 완료되면 approve count를 증가시킨다.
      // this.dispatchEvent(new CustomEvent("addApproveCountEvent", {bubbles: true, composed: true, detail: result.filter(v => v.readingStatus === "3A").length}));
      // NOTE: 2020/09/04 By Jacob - 판독문 save 완료되면 temp count를 증가시킨다.
      // this.dispatchEvent(new CustomEvent("addTempCountEvent", {bubbles: true, composed: true, detail: result.filter(v => v.readingStatus === "2T").length}));
      // this.dispatchEvent(new CustomEvent("addTranscribeCountEvent", {bubbles: true, composed: true, detail: result.filter(v => v.readingStatus === "2DT").length}));

      const calcCount = {"1W": 0, "2T": 0, "2DT": 0, "3A": 0}; // #18754

      this.gridOptions.api.forEachNode((rowNode) => {
         const {data} = rowNode;
         const selectedRow = result.find(row => row.id === data?.id);
         if(selectedRow) {
            if (data.readingStatus !== selectedRow.readingStatus) {
               calcCount[data.readingStatus] -= 1;
               calcCount[selectedRow.readingStatus] += 1;
               data.readingStatus = selectedRow.readingStatus;
            }
            data.studyStatus = selectedRow.studyStatus;
            data.readingDoctor = selectedRow.opinionUpdateUser;
            data.readingDoctorId = selectedRow.opinionUpdateUserId;
            data.teleStatus = selectedRow.teleStatus;
            data.verify = null;
            if(selectedRow.opinionUpdateDtime) data.confirm = CommonUtils.convertTimestampToDate(selectedRow.opinionUpdateDtime);
            if (selectedRow.verifyInfo && selectedRow.verifyInfo.length > 0) {
               const verify = selectedRow.verifyInfo.find(v => (v.isActive));
               if(verify) data.verify = verify.username;
            }
            this.gridOptions.api.redrawRows({row: data});
         }
      });

      // this.dispatchEvent(new CustomEvent("recalculateCountEvent", {bubbles: true, composed: true, detail: calcCount}));
      // count 조회값 + calcCount 기준
      if(this.count.length) {
         this.count.forEach(c => {
            calcCount[c.readingStatus] += Number(c.totalCnt);
         });

         const count = [];
         for (const [key, value] of Object.entries(calcCount)) {
            const obj = { readingStatus: key, totalCnt : String(value) };
            count.push(obj);
         }
         store.dispatch({ type: QueryKey.GET_COUNT, payload: {result: count} });
      }
   }

   refreshCellByTele(id) {
      this.getCaseInfo(id).then((result) => {
         this.gridOptions.api.forEachNode((rowNode) => {
            const {data} = rowNode;
            if (data.id === result.id) {
               data.readingStatus = result.readingStatus;
               data.readingDoctor = result.opinionUpdateUser;
               data.readingDoctorId = result.opinionUpdateUserId;
               data.teleStatus = result.teleStatus;
               data.verify = null;
               if(result.opinionUpdateDtime) data.confirm = CommonUtils.convertTimestampToDate(result.opinionUpdateDtime);
               if (result.verifyInfo && result.verifyInfo.length > 0) {
                  const verify = result.verifyInfo.find(v => (v.isActive));
                  if(verify) data.verify = verify.username;
               }
               this.gridOptions.api.redrawRows({row: data});
            }
         });
      });
   }

   refreshCellByCVR(id) {
      this.getCaseInfo(id).then((result) => {
         this.gridOptions.api.forEachNode((rowNode) => {
            const {data} = rowNode;
            if (data.id === result.id) {
               let emergencyStatus = "";
               if(result.isEmergency && result.isCVR) emergencyStatus = "cvr";
               if(result.isEmergency && !result.isCVR) emergencyStatus = "em";
               if(!result.isEmergency && result.isCVR) emergencyStatus = "cvr";
               if(!result.isEmergency && !result.isCVR) emergencyStatus = "normal";
               data.isEmergency = emergencyStatus;
               this.gridOptions.api.redrawRows({row: data});
            }
         });
      });
   }

   clearSelectedRow() {
      if (this.reportRow?.detail) {
         store.dispatch({ type: ReportActionType.SELECT_REPORT_ROW, payload: { oldRow: this.reportRow.detail, oldRows: this.reportRow.rows } });
      }

      store.dispatch({ type: WorklistActionType.CLEAR_ROW_SELECTION });
      store.dispatch({ type: ReportActionType.CLEAR_THUMBNAIL_ROW });
      store.dispatch({ type: RelatedWorklistActionType.CLEAR_RELATED_ROW });

      this.gridOptions.api.deselectAll();
   }

   getFirstFilter() {
      return fetch(`/api/user/option/filter`, {
         method: "GET",
         headers: this._headers(),
      }).then((response) => {
         if(response.ok && response.status === 200) return response.json();
         return null;
      }).then((result) => {
         if (!result) return result;

         const target = result.find(v => (v.userFilterModel||{}).pin);
         if(!target) return null;

         this._flagFilter = true;
         this._filterParams = target;
         return this.fetchGetUserFilter(target.id);
      });
   }

   fetchGetUserFilter(id) {
      return fetch(`/api/user/option/filter/${id}`, {
         method: "GET",
         headers: this._headers(),
      }).then((response) => {
         if(response.ok && response.status === 200) return response.json();
         throw new Error(`[${response.status}] Get user filter error`);
      });
   }

   validTeleService(facilityConfig) {
      const activeTeleService = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const tele of facilityConfig.tele) {
         if(tele.isActive) {
            activeTeleService.push(tele);
         }
      }
      if(activeTeleService.length < 1) {
         throw new Error("No active teleradiology service was found.");
      }
      return activeTeleService;
   }

   getFetchFacilityConfig() {
      return new Promise((resolve, reject) => {
         const {facilityId} = JSON.parse(localStorage.getItem("user"));
         fetch(`/api/admin/facility/${facilityId}/config`, {
            method: "GET",
            headers: this._headers(),
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json()
                  .then(result => resolve(result));
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }

   cancelRequestTele() {
      // eslint-disable-next-line no-restricted-syntax
      // for (const {data} of this.gridApi.getSelectedNodes()) {
      this.gridApi.getSelectedNodes().forEach((node) => {
         const { data } = node;
         const item = {
            id: data.id,
            readingStatus: data.readingStatus,
            teleStatus: data.teleStatus
         };

         // 비동기 작업으로 인한 teleStatus === "sending" 조건 제거
         if (item.readingStatus === "1W" && (item.teleStatus === "sent" || item.teleStatus === "wait")) {
            Promise.resolve()
               .then(() => this.deleteFetchRequestTele(item.id))
               .then(() => {
                  this.refreshCellByTele(item.id);
                  this.message.msg = "Teleradiology on the study has been successfully canceled.";
                  this.message.isErr = false;
                  this.openToast(this.message);
               })
               .catch((e) => {
                  this.message.msg = e;
                  this.message.isErr = true;
                  this.openToast(this.message);
               });
         } else {
            if(item.teleStatus === "none") {
               this.message.msg = "Teleradiology request history does not exist.";
            } else {
               this.message.msg = "Teleradiology request cannot be canceled.";
            }
            this.message.isErr = true;
            this.openToast(this.message);
         }
      });
   }

   requestTele(isEmergency = false) {
      // eslint-disable-next-line no-restricted-syntax
      // for (const {data} of this.gridApi.getSelectedNodes()) {
      this.gridApi.getSelectedNodes().forEach((node) => {
         const { data } = node;

         const item = {
            id: data.id,
            readingStatus: data.readingStatus,
            teleStatus: data.teleStatus
         };

         if (item.readingStatus === "1W" && (item.teleStatus === "none" || item.teleStatus === "cancelled" || item.teleStatus === "fail")) {
            Promise.resolve()
               .then(this.getFetchFacilityConfig.bind(this))                // 기관 설정정보 조회
               .then(this.validTeleService.bind(this))                      // 활성화된 원격판독 서비스 조회 및 유효성 검사
               .then((r) => {
                  // 서비스가 1개면 즉시요청, 1개 이상이면 사용자에게 서비스 선택 요청
                  return (r.length === 1) ? this.postFetchRequestTele(item.id, r[0].serviceId, isEmergency) : this.openDialog(item.id, r, isEmergency);
               })
               .catch((e) => {
                  this.message.msg = e;
                  this.message.isErr = true;
                  this.openToast(this.message);
               });
         } else {
            this.message.msg = this.requestTeleErrorHandler(item.readingStatus, item.teleStatus);
            this.message.isErr = true;
            this.openToast(this.message);
         }
      });
   }

   deleteFetchRequestTele(id) {
      return new Promise((resolve, reject) => {
         fetch(`/api/tele/${id}`, {
            method: "DELETE",
            headers: this._headers(),
         }).then((response) => {
            if (response.ok && response.status === 200) {
               resolve({});
            }
            else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }

   postFetchRequestTele(id, serviceId, isEmergency = false) {
      return new Promise((resolve, reject) => {
         const {facilityId} = JSON.parse(localStorage.getItem("user"));
         const param = {};
         param.serviceId = serviceId;
         param.emergency = isEmergency;

         fetch(`/api/tele?caseId=${id}&facilityId=${facilityId}`, {
            method: "POST",
            headers: this._headers(),
            body: JSON.stringify(param)
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((result) => {
                  resolve(result);
               });
            }
            else {
               // reject(new Error(`${response.status} ${response.statusText}`));
               reject(new Error(`Teleradiology on the study has been request failed.`));
            }
         });
      });
   }

   openDialog(id, teleServices = [], isEmergency = false) {
      return new Promise( () => {
         const message = {
            content: teleServices,
            ok: "SEND",
            cancel: "CANCEL",
            caseId : id,
            isEmergency
         };

         store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.TELE_REQUEST_DAILOG, message, open: true } });
         // this.$.teleServiceDialog.doCustomSize(null, 287);
         // this.$.teleServiceDialog.doOpen(message);
      });
   }

   // eslint-disable-next-line consistent-return
   requestTeleErrorHandler(rs, ts) {
      if( rs !== "1W" ) return "Teleradiology is only available for studies with the reading status of W(wait).";
      if( ts !== "none" && ts !== "cancelled") return "Teleradiology on this study has already been requested.";
   }

   initWorklist() {
      return new Promise((resolve) => {
         if(this.selectedTab !== 0) {
            resolve();
            return;
         }

         this.initDefaultFilter = true;

         const referralPId = localStorage.getItem("patientId");
         const queryParams = JSON.parse(localStorage.getItem("queryParams"));
         // console.log("referralPId", referralPId, "queryParams", queryParams);
         if(referralPId) {
            this.g_quickFilter = true;

            const patientID = { filter: referralPId, filterType: "text", type: "contains", };
            const patientName = { filter: referralPId, filterType: "text", type: "contains" };
            const filterModel = { patientID, patientName };

            // ag-grid-enterprise.min.noStyle.js:8 Uncaught TypeError: Cannot read property 'length' of undefined 오류 수정(2021. 3. 11 - dave.oh)
            try {
               this.gridOptions.api.setFilterModel(filterModel);
            } catch(e) {}

            resolve();
         }
         // EzCareTech 연동
         else if(queryParams && Object.keys(queryParams).length > 0) {
            const filterModel = {};
            if(queryParams.patientId) {
               const patientID = { filter: queryParams.patientId, filterType: "text", type: "contains" };
               filterModel.patientID = patientID;
            }

            // console.log("accessionNumber", queryParams.accessionNumber);
            if(queryParams.accessionNumber) {
               const accessionNumber = { filter: queryParams.accessionNumber, filterType: "text", type: "contains" };
               filterModel.accessionNumber = accessionNumber;
            }

            // ag-grid-enterprise.min.noStyle.js:8 Uncaught TypeError: Cannot read property 'length' of undefined 오류 수정(2021. 3. 11 - dave.oh)
            try {
               this.gridOptions.api.setFilterModel(filterModel);
            } catch(e) {}
            resolve();
         }
         else {
            const params = CommonUtils.getQueryParams();
            const keys = Object.keys(params);
            // console.log("params", params, keys);
            // query parameters 로 넘어 오는 경우
            if(keys.length > 0 && (keys.includes("patientId") || keys.includes("accessionNumber"))) {
               // console.log("patientId >> ", params.patientId, params.patientId[0], "accessionNumber >> ", params.accessionNumber, params.accessionNumber[0]);
               const filterModel = {};
               if(keys.includes("patientId") && params.patientId[0] !== "") {
                  filterModel.patientID = { filter: params.patientId[0], filterType: "text", type: "contains" };
               }

               if(keys.includes("accessionNumber") && params.accessionNumber[0] !== "") {
                  filterModel.accessionNumber = { filter: params.accessionNumber[0], filterType: "text", type: "contains" };
               }

               // ag-grid-enterprise.min.noStyle.js:8 Uncaught TypeError: Cannot read property 'length' of undefined 오류 수정(2021. 3. 11 - dave.oh)
               try {
                  this.gridOptions.api.setFilterModel(filterModel);
               } catch(e) {}

               resolve();
            }
            else {
               this.getFirstFilter().then((result) => {
                  if (!result) {
                     const studyDtime = {
                        dateFrom: this.lastThreeDays(),
                        dateTo: this.getToday(),
                        type: "inRelativeRange",
                        filterType: "date",
                        isRelative: true,
                        amount: 3,
                        unit: "days"
                     };
                     // console.log("studyDtime", studyDtime);
                     this.setFilter({ studyDtime });
                  } else if ((result.userFilterModel || {}).isQuickFilter) {
                     const param = {
                        pId: result.userFilterModel.filterModel.patientID.filter,
                        pName: result.userFilterModel.filterModel.patientName.filter
                     };

                     this.pIdpNameSearchNewFilm(param);
                     this.dispatchEvent(new CustomEvent("selectUserFilterButtonEvent", { detail: { id: result.id } }));
                  } else {
                     const { filterModel } = (this._filterParams || {}).userFilterModel || {};
                     if (!filterModel || !Object.keys(filterModel).length) {
                        // filterModel이 없을 경우 로직이 그냥 끝나기 때문에 재호출
                        this.gridApi.onFilterChanged();
                     }

                     // #18582 [HPACS > 홍새롬 선생님] 소팅 된 컬럼 필터 저장 -> 저장 필터 선택하면 소팅이 풀리는 오류
                     // Init 시 sortModel이 FilterModel 보다 나중에 적용 되면, 조회를 2번 하는 이슈로 인한 수정
                     const { sortModel } = (this._filterParams || {}).userFilterModel || {};
                     if(!CommonUtils.isEmptyArr(sortModel)) this.gridOptions.columnApi.applyColumnState({ state: sortModel });

                     if (filterModel) this.setFilter(filterModel);
                     this.dispatchEvent(new CustomEvent("selectUserFilterButtonEvent", { detail: { id: result.id } }));
                  }

                  resolve();
               }).catch((err) => {
                  console.log(err);
               }).finally(() => {
                  // #18582 [HPACS > 홍새롬 선생님] 소팅 된 컬럼 필터 저장 -> 저장 필터 선택하면 소팅이 풀리는 오류
                  // const { sortModel } = (this._filterParams || {}).userFilterModel || {};
                  // if(!CommonUtils.isEmptyArr(sortModel)) this.gridOptions.columnApi.applyColumnState({ state: sortModel });
               });
            }
         }
      });
      // .then(() => console.log("done!"));
   }

   getWorklist(params, index) {
      // #17774 [HPACS] 페이지 첫 진입시 다른탭에 있는 grid가 조회되는 문제
      if(this.selectedTab !== 0) {
         return;
      }

      // console.trace("getWorklist", params.request);
      const { filterModel, sortModel } = params.request;
      params.request.filterModel = RadiologyUtils.convertParmas(filterModel);

      // SortModel이 적용된 상태에서 AG-GRID에서 필터 변경 이벤트 마다 바인드 해서 sort model이 있을때, 모든 파라미터 전달을 위해 return
      if (this.worklistFilterState) {
         // 적용된 sort Model이 있는 경우
         if (!CommonUtils.isEmptyArr(this.worklistFilterState.detail.sortModel)) {
            // 적용된 sort Model이 있지만, request에서 sortModel이 없는 경우 => filterModel 부터 Set 하기 때문
            if (CommonUtils.isEmptyArr(sortModel)) {
               // Worklist SortModel을 통해서 이미 적용된 Sorting에 대해 분기 처리
               if (!this.worklistSortModel) {
                  return;
               } else if(CommonUtils.isEmptyArr(this.worklistSortModel.appliedSortModel)) {
                  return;
               }
            }
         // 적용된 Sort Model이 없을때(Clear, Month 변경) 파라미터에 sortModel이 없으면 조회 X
         } else if(!CommonUtils.isEmptyArr(this.worklistSortModel) && !CommonUtils.isEmptyArr(sortModel)) {
            if (!CommonUtils.isEmptyArr(this.worklistSortModel.appliedSortModel)) {
               const appliedSort = this.worklistSortModel.appliedSortModel[0];
               const filterModelKeys = Object.keys(filterModel).sort();
               const filterStateKeys = Object.keys(this.worklistFilterState.detail.filterModel).sort();
               // sort Id, sort direction과 filter의 정보를 통해 조회
               if (appliedSort.colId === sortModel[0].colId && appliedSort.sort === sortModel[0].sort
                  && CommonUtils.arrayEquals(filterModelKeys, filterStateKeys)
                  && this._startRow === params.request.startRow) return;
            }
         }
      }

      if(this.g_quickFilter) {
         params.request.filterModel.isQuickFilter = true;
         this.g_quickFilter = false;
      }
      // TODO: #17446 퀵필터 여부 체크, 추후 체크 방법 변경 필요
      else if(params.request.filterModel.patientID && params.request.filterModel.patientName
         && params.request.filterModel.patientID.filter === params.request.filterModel.patientName.filter
         && params.request.filterModel.patientID.filterType === params.request.filterModel.patientName.filterType) {
         params.request.filterModel.isQuickFilter = true;
      }

      params.request.filterModel.isEmptyFilter = this.isEmptyFilter;

      // #17773 [HPACS > 전수령 원장님] 워크리스트 하단 W 값이 - 로 표기 되는 오류
      if(this.abortController && this.fetchTimeStamp !== this.filterTimeStamp) {
         this.abortController.abort();
      }

      this.fetchTimeStamp = this.filterTimeStamp;
      this.abortController = new AbortController();
      const { signal } = this.abortController;
      fetch(`/api/exchange/worklist`, {
         signal,
         method: "POST",
         headers: this._headers(),
         body: JSON.stringify(params.request)
      }).then((response) => {
         if (response.ok) {
            response.json().then((httpResponse) => {
               if(httpResponse.rows.length > 0) {
                  let refreshFlag = false;
                  for(let i=0; i<httpResponse.rows.length; i++) {
                     const row = httpResponse.rows[i];
                     const duplicateRow = this.gridOptions.api.getRowNode(row.id);

                     if(!refreshFlag && duplicateRow) {
                        // 강제 refresh 일때 먼저 멈추었다가 init 시 다시 실행 ==> 확인 필요
                        this.dispatchEvent(new CustomEvent("stopWorkerEvent", {bubbles: true, composed: true }));
                        refreshFlag = true;
                        this.refreshFlag = refreshFlag;
                     }

                     if(row.requestDtime) {
                        // let localTime = moment(row.requestDtime).add(this._utcOffset, "h").toDate();
                        // localTime = moment(localTime).format("YYYY-MM-DD HH:mm:ss");
                        // // eslint-disable-next-line no-param-reassign
                        // row.requestDtime = localTime;
                        row.requestDtime = CommonUtils.convertTimestampToDate(row.requestDtime);
                     }

                     if(row.confirm) {
                        // let localConfirmTime = moment(row.confirm).add(this._utcOffset, "h").toDate();
                        // localConfirmTime = moment(localConfirmTime).format("YYYY-MM-DD HH:mm:ss");
                        // // eslint-disable-next-line no-param-reassign
                        // row.confirm = localConfirmTime;
                        row.confirm = CommonUtils.convertTimestampToDate(row.confirm);
                     }
                  }

                  this._startRow = params.request.startRow; // 프리패치, 두번째 페이지 확인용
                  let cnt = this._startRow;

                  // eslint-disable-next-line no-restricted-syntax
                  for (const row of httpResponse.rows) {
                     cnt++;
                     row.no = cnt;
                     if (!row.aiRequestState) {
                        row.aiRequestState = "-";
                     }
                     if (!row.aiResult) row.aiResult = "-";
                     if (!row.aiScore) row.aiScore = "-";
                     if (!row.aiSeverity) row.aiSeverity = "-";
                  }

                  if(refreshFlag) {
                     this.purgeEnterpriseCache();
                     this.onFilterChanged();
                  } else {
                     params.success({
                        rowData: httpResponse.rows,
                        rowCount: httpResponse.lastRow
                     });
                     this.initStruct(httpResponse.rows, index);
                     this.gridOptions.api.hideOverlay();
                  }
               } else {
                  params.success({
                     rowData: httpResponse.rows,
                     rowCount: httpResponse.lastRow
                  });
                  this.initStruct(httpResponse.rows, index);
                  this.gridOptions.api.showNoRowsOverlay();
               }

               if(params.request.startRow > 0 && httpResponse.lastRow !== null) {
                  this.dispatchEvent(new CustomEvent("syncCountEvent", {detail: httpResponse.lastRow}));
               }
            });
         } else if(response.status === 401 || response.status === 403) {
            const detail = {msg: "Access denined!.", isErr: true};
            this.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail}));
            setTimeout(() => {window.location.href = "/home";}, 100);
         } else {
            // console.log("worklist loading error.");
            const detail = {msg: "Worklist loading error.", isErr: true};
            this.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail}));
         }
      }).catch((err) => {
         console.error(err);
      });
   }

   textFormatter(s) {
      if (s === null) return null;
      return this.textFormatterCore(s);
   }

   gridTextFormmater() {
      return [{
         textFormatter: (s) => {
            if (s === null) return null;
            return this.textFormatterCore(s);
         }
      }];
   };

   textFormatterCore(s) {
      let r = s.toLowerCase();
      r = r.replace(new RegExp("[àáâãäå]", "g"), "a");
      r = r.replace(new RegExp("æ", "g"), "ae");
      r = r.replace(new RegExp("ç", "g"), "c");
      r = r.replace(new RegExp("[èéêë]", "g"), "e");
      r = r.replace(new RegExp("[ìíîï]", "g"), "i");
      r = r.replace(new RegExp("ñ", "g"), "n");
      r = r.replace(new RegExp("[òóôõøö]", "g"), "o");
      r = r.replace(new RegExp("œ", "g"), "oe");
      r = r.replace(new RegExp("[ùúûü]", "g"), "u");
      r = r.replace(new RegExp("[ýÿ]", "g"), "y");
      return r;
   }

   dateComparator(filterLocalDateAtMidnight, cellValue) {
      const dateAsString = cellValue;
      if (dateAsString == null) return 0;
      const dateParts = dateAsString.split("/");
      const day = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[0]);
      const cellDate = new Date(day, month, year);
      if (cellDate < filterLocalDateAtMidnight) return -1;
      if (cellDate > filterLocalDateAtMidnight) return 1;
      return 0;
   }

   pfCellRenderer(params) {
      switch(params.value) {
      case "begin":
         return `<hpacs-spinner class="begin"></hpacs-spinner>`;
      case "success":
         return `<iron-icon class="prefetchIcon success" icon="healthhub:prefetchDone"></iron-icon>`;
      case "fail":
      case "empty":
         return `<iron-icon class="prefetchIcon fail" icon="healthhub:prefetchErr"></iron-icon>`;
      default:
         return `<iron-icon class='prefetchIcon none' icon='vaadin:thin-square'></iron-icon>`;
      }
   }

   emCellRenderer(params) {
      if(params.value === "normal") {
         return "<div class='emStatus'>N</div>";
      }
      if(params.value === "em") {
         return "<div class='emStatus em'>E</div>";
      }
      if(params.value === "cvr") {
         return "<div class='emStatus cvr'><iron-icon icon='healthhub:cvr'></iron-icon></div>";
      }
   }

   rsCellRenderer(params) {
      if (params.value === "3A") {
         return "A";
      }
      if (params.value === "1W") {
         return "W";
      }
      if (params.value === "2DT") {
         return "T";
      }
      if(localStorage.user) {
         const user = JSON.parse(localStorage.user);
         if (params.value === "2T" && (user.id === params.data.readingDoctorId)) {
            return "H";
         }
      }
      return "O";
   }

   psCellRenderer(params) {
      if (params.value === "F") {
         return "F";
      }
      if (params.value === "M") {
         return "M";
      }
      return "O";
   }

   tsCellRenderer(params) {
      if (params.data.teleType === "emergency"){
         return `${params.value}(E)`;
      }

      return params.value;
   }

   cellRenderer(headerArr) {
      return headerArr.reduce((acc, it) => {
         const header = it;
         const {field, filterParams, floatingFilterComponentParams} = it;
         if(field === "isEmergency")   header.cellRenderer = v => this.emCellRenderer(v);
         if(field === "readingStatus") header.cellRenderer = v => this.rsCellRenderer(v);
         if(field === "patientSex")    header.cellRenderer = v => this.psCellRenderer(v);
         if(field === "prefetch")      header.cellRenderer = v => this.pfCellRenderer(v);
         if(field === "verify")        header.cellRenderer = v => GridUtils.verifyCellRenderer(v);
         if(field === "teleStatus")    header.cellRenderer = v => this.tsCellRenderer(v);

         if(filterParams) {
            header.filterParams.textFormatter = this.gridTextFormmater()[0].textFormatter;
         }

         if (!floatingFilterComponentParams) {
            const filterKey = Object.keys(this._filters);
            const isFilter = filterKey.includes(field);

            if(isFilter && filterParams) {
               filterParams.values = this._filters[field];
               header.filterParams = filterParams;
            }
         }

         acc.push(header);
         return acc;
      }, []);
   }

   createColumnDefs() {
      const columns = [
         {headerName: this.t("label.gridHeader.name.pf"),          field: "prefetch",          width: 33,  headerTooltip: this.t("label.gridHeader.tooltip.pf"),          sortable: false, resizable: false, pinned: "left",         cellStyle: {"text-align": "center"}, cellRenderer: params => this.pfCellRenderer(params) },
         {headerName: this.t("label.gridHeader.name.no"),          field: "no",                width: 33,  headerTooltip: this.t("label.gridHeader.tooltip.no"),          sortable: false, resizable: false,                         cellStyle: {"text-align": "right"} },
         {headerName: this.t("label.gridHeader.name.count"),       field: "imageCount",        width: 45,  headerTooltip: this.t("label.gridHeader.tooltip.count"),       sortable: false, resizable: false,                         cellStyle: {"text-align": "right"} },
         {headerName: this.t("label.gridHeader.name.seriesCount"),       field: "seriesCount",        width: 45,  headerTooltip: this.t("label.gridHeader.tooltip.seriesCount"),       sortable: false, resizable: false,                         cellStyle: {"text-align": "right"} },
         {headerName: this.t("label.gridHeader.name.em"),          field: "isEmergency",       width: 50,  headerTooltip: this.t("label.gridHeader.tooltip.em"),          cellStyle: {"text-align": "right"},  cellClassRules: {"emergency": params => params.value === "true", "normal": params => params.value === "false"}, cellRenderer: params => this.emCellRenderer(params) },
         {headerName: this.t("label.gridHeader.name.rs"),          field: "readingStatus",     width: 50,  headerTooltip: this.t("label.gridHeader.tooltip.rs"),          cellStyle: {"text-align": "center"}, filter: "agSetColumnFilter",  filterParams: { applyButton: true, clearButton: true, values: this._filters.readingStatus}, cellRenderer: params => this.rsCellRenderer(params) },
         {headerName: this.t("label.gridHeader.name.accessionNo"), field: "accessionNumber",   width: 100, headerTooltip: this.t("label.gridHeader.tooltip.accessionNo"), filter: "agTextColumnFilter", filterParams: { filterOptions: ["contains", "notContains"], caseSensitive: true, /* true 시 대소문자 구분 */ suppressAndOrCondition: true, applyButton: true, /* 엔터키를 처야 필터 시작 */ newRowsAction: "keep" /* defaultColDef에 적용한 사항 적용안되는 오류 있음. */ }, floatingFilterComponentParams: { suppressFilterButton: true /* 깔때기 모양의 메뉴를 없애줌 */ } },
         {headerName: this.t("label.gridHeader.name.ss"),          field: "studyStatus",       width: 70,  headerTooltip: this.t("label.gridHeader.tooltip.ss"),          filter: "agSetColumnFilter",  filterParams: { applyButton: true, clearButton: true, values: this._filters.studyStatus} },
         {headerName: this.t("label.gridHeader.name.ts"),          field: "teleStatus",        width: 70,  headerTooltip: this.t("label.gridHeader.tooltip.ts"),          cellRenderer: params => this.tsCellRenderer(params)},
         {headerName: this.t("label.gridHeader.name.as"),          field: "aiRequestState",    width: 70,  headerTooltip: this.t("label.gridHeader.tooltip.as"),          sortable: false, tooltipField: "aiMessage", tooltipValueGetter: params => { value: params.value } },
         {headerName: this.t("label.gridHeader.name.id"),          field: "patientID",         width: 80,  headerTooltip: this.t("label.gridHeader.tooltip.id"),          filter: "agTextColumnFilter", filterParams: { applyButton: true, textFormatter: r => this.textFormatter(r), caseSensitive:true}, floatingFilterComponentParams: { suppressFilterButton: true } },
         {headerName: this.t("label.gridHeader.name.name"),        field: "patientName",       width: 85,  headerTooltip: this.t("label.gridHeader.tooltip.name"),        filter: "agTextColumnFilter", filterParams: { filterOptions:["contains", "notContains"], textFormatter: r => this.textFormatter(r), caseSensitive:true, suppressAndOrCondition: true, applyButton: true}, floatingFilterComponentParams: { suppressFilterButton: true } },
         {headerName: this.t("label.gridHeader.name.age"),         field: "patientAge",        width: 45,  headerTooltip: this.t("label.gridHeader.tooltip.age"),         sortable: false,                                           cellStyle: {"text-align": "center"} },
         {headerName: this.t("label.gridHeader.name.birthDate"),   field: "patientBirthDate",  width: 80,  headerTooltip: this.t("label.gridHeader.tooltip.birthDate"),   cellStyle: {"text-align": "center"}, filter: "agTextColumnFilter", filterParams: { applyButton: true, textFormatter: r => this.textFormatter(r), caseSensitive:true}, floatingFilterComponentParams: { suppressFilterButton: true } },
         {headerName: this.t("label.gridHeader.name.sex"),         field: "patientSex",        width: 45,  headerTooltip: this.t("label.gridHeader.tooltip.sex"),         cellStyle: {"text-align": "center"}, filter: "agSetColumnFilter",  filterParams: { applyButton: true, clearButton: true, values: this._filters.patientSex}, cellRenderer: params => this.psCellRenderer(params) },
         {headerName: this.t("label.gridHeader.name.modality"),    field: "modality",          width: 60,  headerTooltip: this.t("label.gridHeader.tooltip.modality"),    filter: "agSetColumnFilter",  filterParams: {applyButton: true, clearButton: true, values: this._filters.modality}},
         {headerName: this.t("label.gridHeader.name.bodyPart"),    field: "bodypart",          width: 80,  headerTooltip: this.t("label.gridHeader.tooltip.bodyPart"),    filter: "agSetColumnFilter",  filterParams: {applyButton: true, clearButton: true, values: this._filters.bodypart}},
         {headerName: this.t("label.gridHeader.name.studyDesc"),   field: "studyDescription",  width: 200, headerTooltip: this.t("label.gridHeader.tooltip.studyDesc"),   filter: "agTextColumnFilter", filterParams: {applyButton: true, textFormatter: r => this.textFormatter(r), caseSensitive:true}, floatingFilterComponentParams: {suppressFilterButton: true} },
         {headerName: this.t("label.gridHeader.name.studyDate"),   field: "studyDtime",        width: 140, headerTooltip: this.t("label.gridHeader.tooltip.studyDate"),   cellStyle: {"text-align": "center"}, filter: "agDateColumnFilter", floatingFilterComponent: "customDateFloatingFilter", filterParams: {applyButton: true, clearButton: true, suppressAndOrCondition: true, filterOptions: ["equals", "lessThanOrEqual", "greaterThanOrEqual", "inRange", DateFilterUtils.relativeDateFilterOption]}},
         {headerName: this.t("label.gridHeader.name.verifyTo"),    field: "verify",            width: 110, headerTooltip: this.t("label.gridHeader.tooltip.verifyTo"),    sortable: false,                                                     filter: "agTextColumnFilter", filterParams: {applyButton: true, textFormatter: true, caseSensitive:true}, floatingFilterComponentParams: {suppressFilterButton: true}, cellRenderer: params => GridUtils.verifyCellRenderer(params) },
         {headerName: this.t("label.gridHeader.name.repDoc"),      field: "readingDoctor",     width: 110, headerTooltip: this.t("label.gridHeader.tooltip.repDoc"),      sortable: false,  tooltipField: "readingDoctor",                           filter: "agTextColumnFilter", filterParams: {applyButton: true, clearButton: true, textFormatter: r => this.textFormatter(r), caseSensitive:true}, floatingFilterComponentParams: {suppressFilterButton: true} },
         {headerName: this.t("label.gridHeader.name.confirm"),     field: "confirm",           width: 140, headerTooltip: this.t("label.gridHeader.tooltip.confirm"),     cellStyle: {"text-align": "center"} },
         {headerName: this.t("label.gridHeader.name.reporting"),   field: "reader.userName",   width: 80,  headerTooltip: this.t("label.gridHeader.tooltip.reporting"),   tooltipField: "reader.userName" },
         {headerName: this.t("label.gridHeader.name.service"),     field: "serviceName",       width: 150, headerTooltip: this.t("label.gridHeader.tooltip.service"),     sortable: false,                                                          filter: "agSetColumnFilter",  filterParams: {applyButton: true, clearButton: true, values: this._filters.serviceName }},
         {headerName: this.t("label.gridHeader.name.reqHosp"),     field: "requestHospital",   width: 150, headerTooltip: this.t("label.gridHeader.tooltip.reqHosp"),     filter: "agSetColumnFilter",  filterParams: {applyButton: true, clearButton: true, values: this._filters.requestHospital} },
         {headerName: this.t("label.gridHeader.name.reqDate"),     field: "requestDtime",      width: 140, headerTooltip: this.t("label.gridHeader.tooltip.reqDate"),     cellStyle: {"text-align": "center"}, filter: "agDateColumnFilter", floatingFilterComponent: "customDateFloatingFilter", filterParams: {applyButton: true, clearButton: true, suppressAndOrCondition: true, browserDatePicker: true, comparator: this.dateComparator, filterOptions: ["equals", "lessThanOrEqual", "greaterThanOrEqual", "inRange", DateFilterUtils.relativeDateFilterOption]}},
         {headerName: this.t("label.gridHeader.name.acqHosp"),     field: "checkupHospital",   width: 150, headerTooltip: this.t("label.gridHeader.tooltip.acqHosp"),     filter: "agSetColumnFilter",  filterParams: {applyButton: true, clearButton: true, values: this._filters.checkupHospital} },
         {headerName: this.t("label.gridHeader.name.reqDept"),     field: "checkupDepartment", width: 80,  headerTooltip: this.t("label.gridHeader.tooltip.reqDept"),     filter: "agSetColumnFilter",  filterParams: {applyButton: true, clearButton: true, values: this._filters.checkupDepartment} },
         {headerName: this.t("label.gridHeader.name.reqDoc"),      field: "checkupDoctor",     width: 80,  headerTooltip: this.t("label.gridHeader.tooltip.reqDoc"),      floatingFilterComponentParams: {suppressFilterButton: true} },
         {headerName: this.t("label.gridHeader.name.tech"),        field: "checkupTechnician", width: 80,  headerTooltip: this.t("label.gridHeader.tooltip.tech"),        filter: "agTextColumnFilter", filterParams: {applyButton: true, textFormatter: r => this.textFormatter(r), caseSensitive:true}, floatingFilterComponentParams: {suppressFilterButton: true} },
         {headerName: this.t("label.gridHeader.name.aiResult"),    field: "aiResult",          width: 150, headerTooltip: this.t("label.gridHeader.tooltip.aiResult"),    sortable: false},
         {headerName: this.t("label.gridHeader.name.aiScore"),     field: "aiScore",           width: 80,  headerTooltip: this.t("label.gridHeader.tooltip.aiScore"),     sortable: false},
         {headerName: this.t("label.gridHeader.name.aiSeverity"),  field: "aiSeverity",        width: 80,  headerTooltip: this.t("label.gridHeader.tooltip.aiSeverity"),  sortable: false},
      ];

      return GridUtils.changeFilterParams(columns);
   }

   purgeEnterpriseCache() {
      this.gridApi.refreshServerSideStore({ route: [], purge: true });
   }

   /**
    * 선택한 탭의 date toggle 설정(탭 변경시 기억)
    * @param dateFilter
    */
   setDateFilter(dateFilter) {
      this.g_dateFilter = dateFilter;
   }

   /**
    * 선택한 탭의 date toggle 정보
    * @returns {*}
    */
   getDateFilter() {
      return this.g_dateFilter;
   }

   /**
    * Date Filter Toggle 을 위한 이름 조회
    * @param filterModel
    * @returns {null}
    */
   getDateFilterName(filterModel) {
      if(!filterModel) return null;

      let dateFilter = null;
      const {studyDtime : model} = filterModel;
      if(model && model.isRelative) {
         if(model.type === "equals")
            dateFilter = "today";
         else if(Number(model.amount) === 3 && model.unit === "days")
            dateFilter = "threeDays";
         else if(Number(model.amount) === 1 && model.unit === "weeks")
            dateFilter = "week";
         else if(Number(model.amount) === 1 && model.unit === "months")
            dateFilter = "oneMonths";
         else if(Number(model.amount) === 2 && model.unit === "months")
            dateFilter = "twoMonths";
      }
      return dateFilter;
   }

   getFilter() {
      return this.getFilterModelIncludeDateFilter();
   }

   setFilter(filterModel) {
      this.refreshFlag = false;

      Object.entries(filterModel).forEach(([key, value]) => {
         const {filterType, type, amount, unit, isRelative} = value;
         if(filterType === "date" && type === "inRelativeRange") { // relative range
            filterModel[key].dateFrom = DateFilterUtils.getRelativeDate(amount, unit);
            filterModel[key].dateTo = DateFilterUtils.getToday();
            this.appliedDateFilters[key] = value;
         } else if(filterType === "date" && type === "equals" && isRelative) { // today
            filterModel[key].dateFrom = DateFilterUtils.getToday();
            filterModel[key].dateTo = DateFilterUtils.getToday();
            this.appliedDateFilters[key] = value;
         }
      });

      // set toggle button
      if(!CommonUtils.isEmptyObject(this.appliedDateFilters)) {
         // active date toggle 조회
         this.dispatchEvent(new CustomEvent("getActiveDateToggleEvent"));
         // filterModel 의 date filter name 조회
         const dateFilter = this.getDateFilterName(filterModel);
         // active date toggle 과 filterModel 의 date filter name 이 다르면 set toggle button
         if(this.activeDateToggle !== dateFilter) {
            this.dispatchEvent(new CustomEvent("setDateToggleEvent", { detail: dateFilter }));
         }
      }
      // ag-grid-enterprise.min.noStyle.js:8 Uncaught TypeError: Cannot read property 'length' of undefined 오류 수정(2021. 3. 11 - dave.oh)
      try {
         this.gridOptions.api.setFilterModel(filterModel);
      } catch(e){}
   }

   clearFilter() {
      this.gridOptions.api.getFilterInstance("patientID").eFilterTextField.value = "정주혁";
   }

   reloadFilter() {
      const filterModel = this.getFilterModelIncludeDateFilter();
      this.setFilter(filterModel);
   }

   initStruct(rows, index) {
      // const requestObjectIdList = (rows||[]).map(row => row.id);
      const requestObjectIdList = (rows||[]).map(row => {
         const returnObj = {}
         returnObj.id = row.id;
         returnObj.no = row.no;
         return returnObj;
      });
      // eslint-disable-next-line no-restricted-syntax
      // for (const row of rows) {
      //    requestObjectIdList.push(row.id);
      //    // worklist.push({id: row.id, total: row.imageCount});
      // }

      const obj = {
         requestObjectIdList,
         // worklist,
      };

      const param = {
         jwt: localStorage.getItem("jwt"),
         data: obj,
         // targetPrefetch: "newFilm",
      };

      if (this._startRow === 0) {   // 첫번째 검색 결과
         // if (index === 1) {
         //    param.type = "first";
         // } else {
         param.type = "filter";
         this.rowNodes = [];
         // }
      }
      else param.type = "later"; // 첫번째 이후 검색 결과
      // console.log("params", param);

      // const request = { detail: param };
      this.dispatchEvent(new CustomEvent("initStructEvent", {bubbles: true, composed: true, detail: param }));   // 의뢰 및 이미지 구조체 생성 worker 실행
   }

   completedPrefetchMark(result) {
      if (Array.isArray(result)) {
         const arr = result.filter((item, pos, self) => self.indexOf(item) === pos);
         this.gridOptions.api.forEachNode((node) => {
            if(node.data && node.data.id && arr.includes(node.data.id)) {
               node.setDataValue("prefetch", "success");
            }
         });
      } else {
         this.gridOptions.api.forEachNode((node) => {
            console.log("Not Array", node, node.data);
            if (node.data.id === result) {
               node.setDataValue("prefetch", "success");
            }
         });
      }

      // eslint-disable-next-line consistent-return
      this.gridOptions.getRowClass = (params) => {
      //    // eslint-disable-next-line no-restricted-syntax
      //    for (const i in this.rowNodes) {
      //       if (params.node === this.rowNodes[i]) {
      //          return "prefetch";
      //       }
      //    }
         if((this.rowNodes||[]).filter(node => node === params.node).length > 0) return "prefetch";
      };

      // this.gridOptions.api.hideOverlay();
      // this.gridOptions.api.redrawRows({rowNodes: this.rowNodes});
   }

   prefetcStatushMark(message) {
      this.gridOptions.api.forEachNode((rowNode) => {
         if (rowNode.data) {
            if (rowNode.data.id === message.data) {
               if(message.msgType === "startPrefetch") {
                  rowNode.setDataValue("prefetch", "begin");
               }
               else if(message.msgType === "failPrefetch") {
                  rowNode.setDataValue("prefetch", "fail");
               }
               else if(message.msgType === "emptyPrefetch") {
                  rowNode.setDataValue("prefetch", "empty");
               }
               else if(message.msgType === "success") {
                  rowNode.setDataValue("prefetch", "success");
               }
               else if(message.msgType === "none"){
                  rowNode.setDataValue("prefetch", "none");
               }
            }
         }
      });
   }

   setPreformSetting(preformSetting) {
      const row = [];
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const i in preformSetting) {
         const preform = {};
         preform.seqNo = preformSetting[i].seqNo;
         preform.title = preformSetting[i].title;
         preform.modality = preformSetting[i].modality;
         preform.hotkey = preformSetting[i].hotKey2;
         preform.finding = preformSetting[i].finding;
         preform.conclusion = preformSetting[i].conclusion;
         preform.recommendation = preformSetting[i].recommendation;
         row.push(preform);
      }
      return row;
   }

   hideOverlay() {
      this.gridOptions.api.hideOverlay();
   }

   updateGridHeader(headerArr) {
      return new Promise((resolve) => {
         const type = "newFilm";
         fetch(`/api/user/option/gridheader/${type}`, {
            method: "PATCH",
            headers: this._headers(),
            body: JSON.stringify(headerArr)
         }).then((response) => {
            if (response.ok) {
               response.json().then((res) => {
                  resolve(res);
               });
            } else {
               console.debug(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }

   /** Verify/Unverify row redraw */
   updateVerifyByObjectId() {
      const studies = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const row of this.gridOptions.api.getSelectedRows()) {
         studies.push(row.id);
      }

      fetch(`/api/tech/studylist/studies`, {
         method: "POST",
         headers: this._headers(),
         body: JSON.stringify(studies)
      }).then((response) => {
         if (response.ok) {
            response.json().then((study) => {
               this.redrawVerifiedRows(study);
            });
         }
      });
   }

   redrawVerifiedRows(requestList) {
      if(!requestList) return;
      this.gridOptions.api.forEachNode((rowNode) => {
         const {data} = rowNode;
         requestList.forEach((item) => {
            if (data && data.id === item.id) {
               data.verify = item.verify;
               data.verifyEmail = item.verifyEmail;
               data.verifyInfo = item.verifyInfo;
               data.studyStatus = item.studyStatus;
               this.gridOptions.api.redrawRows({row: data});
            }
         });
      });
   }

   redrawRequestAiRows(aiAppProgress) {
      if(!aiAppProgress) return;
      this.gridOptions.api.forEachNode((rowNode) => {
         const {data} = rowNode;
         if (data && data.id === aiAppProgress.caseID) {
            data.aiRequestState = aiAppProgress.state;
            if (aiAppProgress.imageCount) data.imageCount = aiAppProgress.imageCount;
            this.gridOptions.api.redrawRows({row: data});
         }
      });
   }

   getCaseInfo(id) {
      return new Promise((resolve, reject) => {
         fetch(`/api/case/${id}`, {
            method: "GET",
            headers: this._headers(),
         }).then((response) => {
            if (response.ok) {
               if (response.status === 200) {
                  response.json().then((httpResponse) => {
                     resolve(httpResponse);
                  });
               } else if(response.status === 204) {
                  resolve();
               }
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }

   dateFilterSearch(date) {
      const filterModel = this.getFilterModelIncludeDateFilter();
      if(date) filterModel.studyDtime = date;
      else delete filterModel.studyDtime;
      this.setFilter(filterModel);
   }

   pIdpNameSearchNewFilm(param){
      const patientID = {};
      const patientName = {};
      const filterModel = {};
      this.g_quickFilter = true;
      if(param.pId && param.pName){
         patientID.filter = param.pId;
         patientID.filterType = "text";
         patientID.type = "contains";
         patientName.filter = param.pName;
         patientName.filterType = "text";
         patientName.type = "contains";
         filterModel.patientID = patientID;
         filterModel.patientName = patientName;
         // filterModel.studyStatus = {
         //    values: ["verified", "inProgress", "completed"],
         //    filterType: "set"
         // };
         // ag-grid-enterprise.min.noStyle.js:8 Uncaught TypeError: Cannot read property 'length' of undefined 오류 수정(2021. 3. 11 - dave.oh)
         try {
            this.gridOptions.api.setFilterModel(filterModel);
            this.dispatchEvent(new CustomEvent("clearWindowReportEvent"));
         } catch(e) {}
      }
   }

   getToday() {
      return moment().format("YYYY-MM-DD");
   }

   lastThreeDays() {
      return moment().subtract(2, "days").format("YYYY-MM-DD");
   }

   displayPrev() {
      if (this.gridOptions.api.getSelectedNodes()[0]) {
         let {rowIndex} = this.gridOptions.api.getSelectedNodes()[0];
         if (rowIndex > 0) {
            rowIndex--;
         }
         this.gridOptions.api.forEachNode((node) => {
            if (node.rowIndex === rowIndex) {
               node.setSelected(true);
               this.displayFilmbox(node.data.id);
               this.gridOptions.api.ensureIndexVisible(rowIndex);
            } else {
               node.setSelected(false);
            }
         });
      } else {
         this.gridOptions.api.forEachNode((node) => {
            if (node.rowIndex === 0) {
               node.selected(false, false);
               node.setSelected(true);
               this.displayFilmbox(node.data.id);
               this.gridOptions.api.ensureIndexVisible(node.rowIndex);
            } else {
               node.setSelected(false);
            }
         });
      }
      window.fulfilled();
   }

   displayNext() {
      if (this.gridOptions.api.getSelectedNodes().length > 0) {
         let maxIndex = Math.max(...this.gridOptions.api.getSelectedNodes().map(o => o.rowIndex), 0);
         maxIndex++;
         this.gridOptions.api.forEachNode((node) => {
            if (node.rowIndex === maxIndex) {
               node.setSelected(true);
               this.displayFilmbox(node.data.id);
               this.gridOptions.api.ensureIndexVisible(maxIndex);
            } else {
               node.setSelected(false);
            }
         });
      } else {
         this.gridOptions.api.forEachNode((node) => {
            if (node.rowIndex === 0) {
               node.setSelected(true);
               this.displayFilmbox(node.data.id);
               this.gridOptions.api.ensureIndexVisible(node.rowIndex);
            } else {
               node.setSelected(false);
            }
         });
      }
      window.fulfilled();
   }

   displayFilmbox(id) {
      this.dblClickedId = id;
      this.gridNewDblClickEvent();
      // this.dispatchEvent(new CustomEvent("gridNewDblClickEvent", { bubbles: true, composed: true, detail: id}));
   }

   displayPageUp() {
      // console.log("grid new page up");
   }

   displayPageDown() {
      // console.log("gird new page down");
   }

   utcCheck() {
      return new Promise((resolve) => {
         const now = new Date();
         const _zone = moment.tz.guess();
         const timeOffset = moment(now.getTime()).tz(_zone);
         resolve(timeOffset._offset / 60);
      });
   }

   relatedOpen(related) {
      related.rel1 = !related.rel1 ? this._selectedRow?.id : related.rel1;

      let rel = related.rel1;
      if(related.group === "old") rel = related.rel2;

      // #18030 [HPACS > Dev2] 필름박스 리팩토링 : Related / New Exam 나오지 않는 이슈
      if(related.group === "old" && related.type === "click") related.rel1 = this._selectedRow?.id;

      const prevHash = !this.popup ? "" : this.popup.location.hash;
      const type = `&group=${related.group}&type=${related.type}&expand=${related.expand}`;
      let hash = `#${rel.split(`,`).map(r => r.concat(type)).toString()}`;

      // #18030 [HPACS > Dev2] 필름박스 리팩토링 : Related / New Exam 나오지 않는 이슈
      if(related.group === "old" && related.type === "click") hash += `&newId=${related.rel1}`;
      // const hash = `#${rel}&group=${related.group}&type=${related.type}`;
      const url = `/filmbox${hash}`;

      this.popup = window.open(url, this.popupName, this.popupOpts);
      this.popup.focus();

      if(prevHash === hash) {
         this.popup.dispatchEvent(new CustomEvent("hashchange"));
      }
   }

   // setSelectedSeriesInfo(param) {
   //    // const resolve = true;
   //    const url = `/filmbox#${param.caseId}&series=${encodeURIComponent(param.seriesId)}&index=${param.imageIndex}&expand=F`;
   //    // if ((this.popup || this.popup.closed) && resolve) {
   //    //    this.popup = window.open(url, this.popupName, this.popupOpts);
   //    // } else if (resolve) {
   //    this.popup = window.open(url, this.popupName, this.popupOpts);
   //    // } else if (!resolve) {
   //    //    if (typeof (this.popup) !== "undefined") {
   //    //       this.popup.struct = null;
   //    //       this.popup.oldFlag = false;
   //    //       this.popup.seriesFlag = false;
   //    //       this.popup.reloadFilmbox();
   //    //    }
   //    // }
   // }

   navigateToNextCell(params) {
      let previousCell = params.previousCellPosition;
      const suggestedNextCell = params.nextCellPosition;

      const KEY_UP = 38;
      const KEY_DOWN = 40;
      const KEY_LEFT = 37;
      const KEY_RIGHT = 39;

      switch (params.key) {
      case KEY_DOWN:
         previousCell = params.previousCellPosition;
         this.gridOptions.api.forEachNode((node) => {
            node.setSelected(false);
            if (previousCell.rowIndex + 1 === node.rowIndex) {
               node.setSelected(true);
            }
         });
         return suggestedNextCell;
      case KEY_UP:
         previousCell = params.previousCellPosition;
         this.gridOptions.api.forEachNode((node) => {
            node.setSelected(false);
            if (previousCell.rowIndex - 1 === node.rowIndex) {
               node.setSelected(true);
            }
         });
         return suggestedNextCell;
      case KEY_LEFT:
      case KEY_RIGHT:
         return suggestedNextCell;
      default :
         throw new Error("this will never happen, navigation is always one of the 4 keys above");
      }
   }

   convertDateToString(date) {
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate().toString();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();
      month = month >= 10 ? month : `0${month}`;
      day = day >= 10 ? day : `0${day}`;
      hours = hours >= 10 ? hours : `0${hours}`;
      minutes = minutes >= 10 ? minutes : `0${minutes}`;
      seconds = seconds >= 10 ? seconds : `0${seconds}`;
      return `${year}${month}${day}${hours}${minutes}${seconds}`;
   }

   convertDateToTimestamp(date) {
      const timestamp = new Date(date);
      return (timestamp.getTime() - (timestamp.getTimezoneOffset() * 60 * 1000)).toString();
   }

   updateFilmBoxWindow(id, filmboxWindow) {
      fetch(`/api/user/option/filmboxWindow/${id}`, {
         method: "PATCH",
         headers: this._headers(),
         body: JSON.stringify(filmboxWindow)
      }).then((response) => {
         if (!response.ok) {
            console.debug(`${response.status} : Filmbox Window Update Failed`);
         }
      });
   }

   // getUserStyle() {
   //    return fetch(`/api/user/option/style`, {
   //       method: "GET",
   //       headers: this._headers(),
   //    }).then((response) => {
   //       if (response.ok && response.status === 200) return response.json();
   //       return new Error(`[${response.status}] Get user style error!`);
   //    }).catch((err) => {
   //       console.error("Get user style error!", err);
   //       return err;
   //    });
   // }

   getUserStyle() {
      return new Promise((resolve, reject) => {
         fetch(`/api/user/option/style`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")
            }
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((rows) => {
                  resolve(rows);
               });
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }

   getFilters() {
      return fetch("/api/exchange/worklist/filters", {
         method: "GET",
         headers: this._headers(),
      }).then((response) => {
         if(response.ok && response.status === 200) return response.json();
         return new Error(`[${response.status}] Get filters error!`);
      }).catch((err) => {
         console.error("Get filters error!", err);
         return err;
      });
   }

   getInitSortModelWithColumnDefs(result) {
      return new Promise((resolve) => {
         if (result.grid && result.grid.newFilm && result.grid.newFilm.length > 0) {
            const { newFilm } = result.grid;
            this.columnDefs = GridUtils.mergeFilterParams(newFilm, this.createColumnDefs());

            const sortModel = result.grid.newFilm.reduce((acc, {field, sortDirection}) => {
               if(sortDirection) {
                  acc.push({ colId: field, sort: sortDirection });
               }
               return acc;
            }, []).reverse();

            resolve(sortModel);
         } else {
            this.columnDefs = this.createColumnDefs();
            this.updateGridHeader(this.columnDefs).then((r) => {
               // #16581 sort를 해주지 않으면 worklist 조회가 안되서 추가
               const sortModel = r.grid.newFilm.reduce((acc, {field, sortDirection}) => {
                  if(sortDirection) {
                     acc.push({ colId: field, sort: sortDirection });
                  }
                  return acc;
               }, []).reverse();

               resolve(sortModel);
            });
         }
      });
   }

   openToast(detail) {
      // console.log("'fireEvent'", {bubbles: true, composed: true, detail});
      this.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail}));
   }

   /**
    * 적용된 DateFilter 정보를 반영한 Grid FilterModel
    * @returns {{[p: string]: any} | void}
    */
   getFilterModelIncludeDateFilter() {
      const filterModel = this.gridApi.getFilterModel();
      Object.keys(this.appliedDateFilters).forEach((key) => {
         if(filterModel[key])
            filterModel[key] = this.appliedDateFilters[key];
      });
      return filterModel;
   }

   getSortModel() {
      const columnState = this.gridOptions.columnApi.getColumnState();
      return columnState.filter(item => item.sort !== null);
   }

   setSortModel(sortModel = []) {
      const sortIndexArr = sortModel.map((item, idx) => {
         const { sortIndex } = item;
         item.sortIndex = sortIndex === undefined ? idx : sortIndex;
         return item;
      });

      this.gridOptions.columnApi.applyColumnState({
         state: sortIndexArr,
         defaultState: {
            sort: null,
            sortIndex: null
         }
      });
   }

   /**
    * ContextMenu 활성화 여부, Replace
    * @return {boolean}
    */
   isDisabledForReplaceContextMenu(rows) {
      if(rows.length > 1) return true;
      return false;
   }

   /**
    * Properties에는 선택한 값을 가지고 있지만 화면상에 selection된 row가 없을경우를 체크
    * @return {number}
    */
   getSelectRowLength() {
      if (CommonUtils.isEmptyObject(this.gridApi.getSelectedNodes())) return 0;
      return this.gridApi.getSelectedNodes().length;
   }

   /**
    * ContextMenu 활성화 여부, Verify
    * @return {boolean}
    */
   isDisabledForVerifyContextMenu() {

   }

   /**
    * ContextMenu 활성화 여부, Unverify
    * @returns {boolean}
    */
   isDisabledForUnverifyContextMenu() {
      // 전부 verify 상태여야만 unverify 할 수 있음
      const verifyRows = this._selectedRows.filter(row => row.studyStatus === "verified");
      if(verifyRows.length === this._selectedRows.length)
         return false;
      return true;
   }

   /**
    * Viewing 컬럼의 데이터를 수정한다.
    * 필름박스에서 backend 처리 후 ag-grid 수정을 위해 호출 된다.
    * @param {string} studyId
    * @param {Object} data
    */
   updateViewing(studyId, data) {
      // console.log("updateViewing", studyId, data);
      const reader = {};
      if(data) {
         const { userName, userId } = data;
         reader.userName = userName;
         reader.userId = userId;
      }

      this.gridOptions.api.forEachNode((rowNode) => {
         const {data} = rowNode;
         // console.log(data.id, studyId, reader);
         if (data && data.id === studyId) {
            data.reader = reader;
            this.gridOptions.api.redrawRows({row: data});
         }
      });
   }

   /**
    * DICOM Send 창을 표시한다(main-app 에서 표시한다.).
    */
   showDicomSend() {
      const rows = this.gridApi.getSelectedRows()||[];
      if(rows.length === 0) return;

      const message = { detail: { caseIds: rows.map(m => m.id) } };
      store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.DICOM_SEND_DIALOG, actionType: DialogActionType.DICOM_SEND, message, open: true } });
   }

   updateFilmboxOFFStatusByObjectId(requestObjectId, userId) {
      let url = `/api/exchange/worklist/filmboxStatus/close/${requestObjectId}`;
      const user = JSON.parse(localStorage.getItem("user"));

      if(userId) {
         // 로그아웃 했을경우
         url += `/user?userId=${userId}`;
      } else if(user) {
         // #17034 토큰이 만료된 후 웹페이지를 닫았을 경우 viewing 정보 update
         const nowDate = new Date().getTime();
         const expTime = JSON.parse(localStorage.getItem("jwtExpTime"));
         if((!expTime) || (expTime && nowDate >= expTime)) {
            url += `/user?userId=${user.id}`;
         }
      }

      fetch(url, {
         method: "PATCH",
         headers: this._headers(),
         keepalive: true
      }).then((response) => {
         if (!response.ok) {
            console.debug(`${response.status} : Fimbox Window Update Failed`);
         }
      });
   }

   updateFilmboxStatusByObjectId(requestObjectId) {
      return new Promise((resolve) => {
         fetch(`/api/exchange/worklist/filmboxStatus/${requestObjectId}`, {
            method: "PATCH",
            headers: this._headers()
            // body: JSON.stringify(filmboxStatus)
         }).then((response) => {
            if (!response.ok) {
               console.log("ERR FILMBOX STATUS");
               resolve(requestObjectId);
            } else {
               resolve(requestObjectId);
            }
         });
      });
   }

   getFilmboxReader(requestObjectId) {
      return new Promise((resolve, reject) => {
         fetch(`/api/exchange/worklist/filmboxStatus/${requestObjectId}`, {
            method: "GET",
            headers: this._headers()
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((rows) => {
                  resolve(rows);
               });
            } else if (response.status === 204) {
               resolve(null);
            } else {
               reject(new Error(`Error : ${response.status}`));
            }
         });
      });
   }

   postProcessPopup(params) {
      if (params.type !== "columnMenu") return;

      const columnId = params.column.getId();
      const {ePopup} = params;

      if(columnId === "studyDtime" || columnId === "requestDtime") {
         let count = 0;
         const interval = setInterval(() => {
            if(!this.createdRelativeDateFilter[columnId]) {
               const filterBody = ePopup.querySelector(".ag-filter-body");
               if (filterBody) {
                  clearInterval(interval);

                  DateFilterUtils.renderRelativeDateFilter(ePopup, () => {
                     const floatingFilter = this.$.girdNewfilm.querySelector(".ag-popup"); // 팝업이 한번 닫힌 후 ePopup 에는 하위 element 값이 없어서 다시 불러줌
                     const filterModel = this.getFilterModelIncludeDateFilter();
                     DateFilterUtils.applyRelativeDateFilter(floatingFilter, columnId, filterModel, (filterModel) => {
                        this.setFilter(filterModel);
                     });
                  }, () => {
                     const floatingFilter = this.$.girdNewfilm.querySelector(".ag-popup"); // 팝업이 한번 닫힌 후 ePopup 에는 하위 element 값이 없어서 다시 불러줌
                     const filterModel = this.getFilterModelIncludeDateFilter();
                     DateFilterUtils.clearRelativeDateFilter(floatingFilter, columnId, filterModel, (filterModel) => {
                        this.setFilter(filterModel);
                     });
                     // const filterInstance = this.gridApi.getFilterInstance(columnId);
                     // filterInstance.setModel(null);
                     // filterInstance.applyModel();
                     // this.gridApi.onFilterChanged();
                  },() => {
                     const filterInstance = this.gridApi.getFilterInstance(columnId);
                     DateFilterUtils.setDateFilterComponent(ePopup, filterInstance, this.appliedDateFilters[columnId]);
                  });

                  this.createdRelativeDateFilter[columnId] = true;
               }
            } else {
               const customFilter = ePopup.querySelector(".relative-date-filter-body");
               const customButton = ePopup.querySelector(".relative-date-filter-button");
               const applyButton = ePopup.querySelector(".ag-filter-apply-panel-button");
               if(customFilter && customButton && applyButton) {
                  clearInterval(interval);

                  const filterInstance = this.gridApi.getFilterInstance(columnId);
                  DateFilterUtils.setDateFilterComponent(ePopup, filterInstance, this.appliedDateFilters[columnId]);
               }
            }
            if(this.createdRelativeDateFilter[columnId] || count > 100) {
               clearInterval(interval);
            }
            count++;
         }, 100);
      }
   }

   setFirstSelectedRows(id) {
      this.clearSelectedRow();
      this.gridOptions.api.getRowNode(id).setSelected(true);
   }

   getContextMenuItems(params) {
      if(!params.node) return;

      const {studyId, old} = this.filmboxHash;
      const rows = params.api.getSelectedRows();

      if(rows.length > 1) {
         // 다중 선택시 index sort
         rows.sort((a, b) => a.no - b.no);
         this.dblClickedId = rows[0].id;

         // #16192 우클릭으로 선택되어있지 않는 row를 선택했을시 선택값을 초기화 한다.
         const row = rows.find( row => row.id === params.node.data.id);
         if (!row) this.setRightSelectedRows(params);
      } else {
         this.setRightSelectedRows(params);
      }

      if (this.customContextMenuState !== undefined) {
         store.dispatch({ type: CommonActionType.SHOW_CONTEXT_MENU, payload: CustomContextMenuType.SYSTEM });
      }

      const result = [
         {
            name: this.t("label.newReplaceTab"),
            disabled: this.isDisabledForReplaceContextMenu(rows),
            action: () => {
               this.getUserStyle().then((s) => {
                  if (s && s.filmbox && s.filmbox.expand) {
                     const {expand} = s.filmbox;
                     this.filmboxExpand = expand;
                  }

                  const related = {rel1: params.node.id, rel2: old, type: "replace", group: "new", expand: this.filmboxExpand};
                  // console.log("Open on New", related);
                  this.relatedOpen(related);
               });
            }
         },
         {
            name: this.t("label.newAddTab"),
            action: () => {
               this.getUserStyle().then((s) => {
                  if (s && s.filmbox && s.filmbox.expand) {
                     const {expand} = s.filmbox;
                     this.filmboxExpand = expand;
                  }

                  const related = {rel1: params.node.id, rel2: old, type: "tab", group: "new", expand: this.filmboxExpand};

                  // 다중 선택시 선택된 모든 검사 추가
                  if (rows.length > 1) {
                     const ids = [];
                     rows.forEach(r => ids.push(r.id));
                     related.rel1 = ids.toString();

                     // #18066 [HPACS > 탭뷰 사용성 개선] 멀티로 new tab을 오픈 시 첫번째 검사가 focus되도록 변경 필요
                     this.setFirstSelectedRows(rows[0].id);
                  }

                  // console.log("Open on New", related);
                  this.relatedOpen(related);
               });
            }
         },
         {
            name: this.t("label.relReplaceTab"),
            disabled: this.isDisabledForReplaceContextMenu(rows),
            action: () => {
               this.getUserStyle().then((s) => {
                  if (s && s.filmbox && s.filmbox.expand) {
                     const {expand} = s.filmbox;
                     this.filmboxExpand = expand;
                  }

                  const related = {rel1: studyId, rel2: params.node.id, type: "replace", group: "old", expand: this.filmboxExpand};
                  // console.log("Open on Related", related, rows.length, studyId, params.node.id);
                  this.relatedOpen(related);
               });
            }
         },
         {
            name: this.t("label.relAddTab"),
            action: () => {
               this.getUserStyle().then((s) => {
                  if (s && s.filmbox && s.filmbox.expand) {
                     const {expand} = s.filmbox;
                     this.filmboxExpand = expand;
                  }

                  const related = {rel1: studyId, rel2: params.node.id, type: "tab", group: "old", expand: this.filmboxExpand};

                  // 다중 선택시 선택된 모든 검사 추가
                  if (rows.length > 1) {
                     const ids = [];
                     rows.forEach(r => ids.push(r.id));
                     related.rel2 = ids.toString();

                     // #18066 [HPACS > 탭뷰 사용성 개선] 멀티로 new tab을 오픈 시 첫번째 검사가 focus되도록 변경 필요
                     this.setFirstSelectedRows(rows[0].id);
                  }

                  // console.log("Open on Related", related, rows.length, studyId, params.node.id);
                  this.relatedOpen(related);
               });
            }
         },
         "separator",
         {
            name: this.t("label.verify"),
            disabled: this.isDisabledForVerifyContextMenu(),
            action: () => {
               this.verify();
            }
         },
         {
            name: this.t("label.unverify"),
            disabled: this.isDisabledForUnverifyContextMenu(),
            action: () => {
               this.unVerify();
            }
         },
         "separator",
         {
            name: this.t("label.teleradiology"),
            subMenu: [
               {
                  name: this.t("label.generalRequest"),
                  action: () => this.requestTele(false),
                  icon: "<iron-icon class='contextMenuIcon' icon='' slot='prefix'></iron-icon>"
               },
               {
                  name: this.t("label.emergencyRequest"),
                  action: () => this.requestTele(true),
                  icon: "<iron-icon class='contextMenuIcon' icon='' slot='prefix'></iron-icon>"
               },
               "separator",
               {
                  name: this.t("label.cancelRequest"),
                  action: () => this.cancelRequestTele()
               }
            ],
            icon: "<iron-icon class='contextMenuIcon' icon='' slot='prefix'></iron-icon>"
         },
         "separator",
         {
            id: "aiRequest",
            name: this.t("label.aiRequest"),
            subMenu: this._purchaseAiList || [],
            disabled: (this.gridApi.getSelectedRows()||[]).length === 0 || (this.gridApi.getSelectedRows()||[]).length > 1 || this._purchaseAiList.length === 0,
            icon: "<iron-icon class='contextMenuIcon' icon='' slot='prefix'></iron-icon>"
         },
         "separator",
         {
            id: "referralForm",
            name: "Referral form",
            // action: () => this.$.referralFormDialog.doOpen(params.node.data),
            action: () => store.dispatch({type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.REFERRAL_DAILOG, message: params.node.data } }),
            disabled: (this.gridApi.getSelectedRows()||[]).length === 0 || (this.gridApi.getSelectedRows()||[]).length > 1,
            icon: "<iron-icon class='contextMenuIcon' icon='' slot='prefix'></iron-icon>"
         },
         "separator",
         {
            name: this.t("label.export"),
            subMenu: [
               {
                  id: "share-to-patient",
                  name: this.t("label.shareToPatient"),
                  action: (p) => {
                     // console.log("params", params, p);
                     const rows = params.api.getSelectedRows()||[];
                     if(rows.length === 0) return;
                     store.dispatch({type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.SHARE_TO_PATIENT_DIALOG, message:{ detail: { caseIds: rows.map(m => m.id) }}} });
                     // this.dispatchEvent(new CustomEvent("OPEN_SHARE_TO_PATIENT", { bubbles: true, composed: true, detail: { caseIds: rows.map(m => m.id) } }));
                  },
                  disabled: (params.api.getSelectedNodes()||[]).length === 0,
                  icon: "<iron-icon class='contextMenuIcon' icon='' slot='prefix'></iron-icon>"
               },
               {
                  name: this.t("label.dicomSend"),
                  action: () => this.showDicomSend(),
                  disabled: (this.gridApi.getSelectedRows()||[]).length === 0
               },
            ]
         },
          "separator",
          {
              name: this.t("label.download"),
              subMenu: [
                  {
                      name: this.t("label.dicom"),
                      action: () => {
                          const requestInfoList = [];
                          // eslint-disable-next-line no-restricted-syntax
                          for (const obj of params.api.getSelectedNodes()) {
                              const requestInfo = {};
                              requestInfo.id = obj.data.id;
                              requestInfo.patientId = obj.data.patientID;
                              requestInfo.patientName = obj.data.patientName;
                              requestInfo.studyDtime = this.convertDateToString(new Date(obj.data.studyDtime));
                              requestInfo.modality = obj.data.modality;
                              requestInfoList.push(requestInfo);
                          }

                          this.downloadStart(requestInfoList, "dcm");
                      }
                  },
                  {
                      name: this.t("label.jpeg"),
                      action: () => {
                          const requestInfoList = [];
                          // eslint-disable-next-line no-restricted-syntax
                          for (const obj of params.api.getSelectedNodes()) {
                              const requestInfo = {};
                              requestInfo.id = obj.data.id;
                              requestInfo.patientId = obj.data.patientID;
                              requestInfo.patientName = obj.data.patientName;
                              requestInfo.studyDtime = this.convertDateToString(new Date(obj.data.studyDtime));
                              requestInfo.modality = obj.data.modality;
                              requestInfoList.push(requestInfo);
                          }

                          this.downloadStart(requestInfoList, "jpeg");
                      }
                  },
                  "excelExport",
                  "csvExport",
              ]
          },
         "separator",
         {
            name: this.t("label.copy"),
            subMenu: [
               "copy",
               "copyWithHeaders"
             ]
         },
      ];

      const userInfo = JSON.parse(localStorage.getItem("user"));

      if (!userInfo.activeAi) {
         for(let i = 0; i < result.length; i++) {
            if (result[i].id === "aiRequest") {
               result.splice(i, 2);
            }
         }
      }

      if(!userInfo.activeHscan) {
         for(let i = 0; i < result.length; i++) {
            (result[i].subMenu || []).forEach((r,j) => {
               if(r.id === "share-to-patient") {
                   result[i].subMenu.splice(j, 1);
                }
            });
         }
      }

      if(!userInfo.activeHrefer) {
         for(let i = 0; i < result.length; i++) {
            if (result[i].id === "referralForm") {
               result.splice(i, 2);
            }
         }
      }

      return result;
   }


   onCellContextMenu(params) {
      const userInfo = JSON.parse(localStorage.getItem("user"));

      // #16814 ai 구매 목록 리스트 조회에서 처음 오른쪽 클릭해서 띄울 시 빈 리스트가 보이는 오류
      const factory = params.api.contextMenuFactory;

      if(!factory.activeMenu) return;
      if(!userInfo.activeAi) return;


      const { compId } = factory.activeMenu;
      const { id } = this.gridApi.getSelectedNodes()[0].data;

      // ai request list
      this.getPurchaseAiListById(id).then((httpResponse)=>{
         const purchasedAiList = [];
         const requestableAiList = this.getAvailableAiList(httpResponse);

         for (let i = 0; i < requestableAiList.length; i++) {
            const { lastState, aiName, applicationId, useForm, aiType } = requestableAiList[i];
            let purchasedAiSubMenu = {};

            if(lastState === "IDLE") {
               purchasedAiSubMenu = {
                  name: `${aiName || ""}(Cancel)`,
                  action: () => {
                     this.aiRequestCancel(applicationId, id);
                  },
               };
            } else {
               purchasedAiSubMenu = {
                  name: aiName,
                  action: () => {
                     if(useForm === "Y") {
                        const message = {
                           detail: {
                              row: this.gridApi.getSelectedNodes()[0].data,
                              aiList: requestableAiList[i]
                           },
                           open: true
                        };
                        store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.BONEAGE_REPORT_DIALOG, actionType: DialogActionType.BONEAGE_REPORT, message} });
                     } else {
                        this.aiStudyRequest(id, applicationId, "", [], aiType);
                     }
                  },
               };
            }

            purchasedAiList.push(purchasedAiSubMenu);
         }

         const { activeMenu } = factory;
         if(activeMenu && compId === activeMenu.compId) {
            this._purchaseAiList = purchasedAiList;

            factory.hideActiveMenu();
            factory.showMenu(params.node, params.column, params.value, params.event);
         }
      }).catch((err) => {
         console.log(err);
      });
   }

   getAvailableAiList(allAiList) {
      if(CommonUtils.isEmptyObject(this._selectedRow)) return;

      const requestableAiList = [];
      const bodypart = this._selectedRow.bodypart.toUpperCase().replace(/ /g,"");
      const modality = this._selectedRow.modality.toUpperCase();

      if (this._selectedRow.bodypart !== "-") {
         for (let j = 0; j < allAiList.length; j++) {
            let isModality = false;
            let isBodyPart = false;
            const aiBodyparts = [];
            const aiModalitys = [];

            // 선택한 row값의 바디파트가 구매한 ai 리스트들의 바디파트 중에 포함이 되는 것이 있는지 확인
            allAiList[j].bodypart.forEach((element) => { aiBodyparts.push(element.toUpperCase().replace().replace(/ /g,"")); });
            isBodyPart = aiBodyparts.includes(bodypart);

            // 선택한 row값의 모달리티가 구매한 ai 리스트들의 모달리티 중에 포함이 되는 것이 있는지 확인
            allAiList[j].modality.forEach((element) => { aiModalitys.push(element.toUpperCase().replace().replace(/ /g,"")); });
            isModality = aiModalitys.includes(modality);

            // 선택한 row값의 모달리티 및 바디파트 모두 포함된다면 요청 가능한 ai 리스트로 판정하여 리스트 추가
            if (isModality && isBodyPart) {
               requestableAiList.push(allAiList[j]);
            }
            // #18494 AI 중 Bodypart, Modality가 ALL인 경우 모두 표시
            else if(allAiList[j].bodypart.includes("ALL") && allAiList[j].modality.includes("ALL")) {
               requestableAiList.push(allAiList[j]);
            }
         }
      }

      return requestableAiList;
   }

   onRowSelected(evt) {
      // console.log("onRowSelected", (evt.node.selected), evt.data?.id);

      // click + selection(keyboard)
      if (evt.node.selected) {
         this._selectedRow = evt.data;
         store.dispatch({ type: ReportActionType.SELECT_THUMBNAIL_ROW, payload: evt.data.id });
         store.dispatch({ type: ReportActionType.SELECT_CLINICALINFO_ROW, payload: evt.data.id });
      }

      this._selectedRows = evt.api.getSelectedRows();
   }

   onRowClicked(evt) {
      // console.log("onRowClicked", evt.node.selected);
      this.isShift = evt.event.shiftKey;

      if(evt.node.selected && evt.data) {
         // click 한 row 가 선택된 row 에 포함되어 있다면
         // _selectedRows 는 row clicked event 이후 row selected event 에서 값을 할당하기 때문에 이미 선택된 row 를 재선택시에만 아래 로직을 탐
         const {id} = evt.data;
         const included = this._selectedRows.map(row => row.id).includes(id);
         if(included) {
            // doubleClick 이슈 : click 이벤트가 2번 호출되어 1번만 호출되게끔 debounce 추가
            this._debouncer = Debouncer.debounce(this._debouncer, timeOut.after(0), () => {

               if(id === this._selectedRow.id) {
                  store.dispatch({ type: ReportActionType.SELECT_THUMBNAIL_ROW, payload: id });
                  store.dispatch({ type: ReportActionType.SELECT_CLINICALINFO_ROW, payload: evt.data.id });
                  const reportRow = {
                     detail: evt.data,
                     rows: evt.api.getSelectedRows(),
                     oldRow: this.reportRow?.detail,
                     oldRows: this.reportRow?.rows
                  };
                  store.dispatch({ type: ReportActionType.SELECT_REPORT_ROW, payload: reportRow });
               } else {
                  this._selectedRow = evt.data;
               }
            });
         }
      }
   }

   onRowDoubleClicked(evt) {
      // console.log("onRowDoubleClicked", event);

      this.dblClickedId = evt.data.id;
      this.gridNewDblClickEvent();
      if(this.popupReport && this.isPopupPin) window.open("", "reportWindow");
      // this.dispatchEvent(new CustomEvent("gridNewDblClickEvent", { bubbles: true, composed: true, detail: evt.data.id}));
   }

   onFilterChanged(v) {
      // console.log("-> [onFilterChanged] refreshFlag", this.refreshFlag, [...this.gridApi.getSelectedNodes()])
      this.dispatchEvent(new CustomEvent("stopWorkerEvent", {bubbles: true, composed: true }));
      if (this.refreshFlag) return;

      this.filterTimeStamp = new Date().getTime();
      const filterModel = this.gridApi.getFilterModel();

      // 필터가 존재하지 않을 경우, 서버에서 aggregation pipline 을 다르게 query 해야 하기 때문에 구분값을 지정해준다.
      this.isEmptyFilter = (!filterModel || CommonUtils.isEmptyObject(filterModel)) ? true : false;

      if(this.g_quickFilter) {
         filterModel.isQuickFilter = true;
      }
      // TODO: #17446 퀵필터 여부 체크, 추후 체크 방법 변경 필요
      else if(filterModel.patientID && filterModel.patientName
         && filterModel.patientID.filter === filterModel.patientName.filter
         && filterModel.patientID.filterType === filterModel.patientName.filterType) {
         filterModel.isQuickFilter = true;
      }


      // global applied date filter 정리
      Object.entries(this.appliedDateFilters).forEach(([columnId, {type: globalType, dateFrom: globalDateFrom}]) => {
         // - global date filter 에는 있는데 현재 적용된 filter model 에 없거나
         // - 적용된 filter model 에 있는데 global filter 와 타입이 맞지 않거나
         // - global filter 에 today 인데 적용된 filter 와 date 가 다른 경우
         if(!filterModel[columnId]
            || (filterModel[columnId] && filterModel[columnId].type !== globalType)
            || (filterModel[columnId] && filterModel[columnId].type === "equals" && !moment(filterModel[columnId].dateFrom).isSame(moment(globalDateFrom)))) {
            delete this.appliedDateFilters[columnId];
         }
      });

      store.dispatch({ type: WorklistActionType.SET_APPLIED_FILTER, payload: { filterModel, appliedDateFilters: this.appliedDateFilters } });

      // const request = {};
      // request.detail = RadiologyUtils.convertParmas(filterModel);
      // request.detail.category = 0;
      // this.dispatchEvent(new CustomEvent("checkDateFilterEvent", { detail: filterModel }));
      // this.dispatchEvent(new CustomEvent("checkUserFilterButtonEvent", { detail: filterModel }));
      // this.dispatchEvent(new CustomEvent("initCountEvent", request));
      // this.displayFilter(filterModel);
      // apply button 클릭 후 filter List modal hidden
      // const floatingFilter = this.$.girdNewfilm.querySelector(".ag-popup");
      // if(floatingFilter && !v.afterFloatingFilter) {
      //    floatingFilter.hidden = true;
      // }

      // console.log("change filter");
      // this.gridApi.setRowData([]);
      // this.gridApi.hideOverlay();
   }

   onFilterModified(evt) {
      const columnId = evt.column.getId();
      if(columnId === "studyDtime" || columnId === "requestDtime") {
         const floatingFilter = this.$.girdNewfilm.querySelector(".ag-popup");
         const filterInstance = this.gridApi.getFilterInstance(evt.column.colId);
         DateFilterUtils.setDateFilterComponent(floatingFilter, filterInstance);
      }
   }

   onColumnMoved(evt) {

   }

   onDragStopped(evt) {
      const tempArr = evt.columnApi.columnController.gridColumns;
      const headerArr = tempArr.reduce((acc, {colDef}) => {
         acc.push(colDef);
         return acc;
      }, []);

      this.updateGridHeader(headerArr);
   }

   onColumnResized(evt) {
      if(evt.finished) {
         const tempArr = evt.columnApi.columnController.gridColumns;
         const headerArr = tempArr.reduce((acc, {colDef, actualWidth}) => {
            const newColDef = colDef;
            newColDef.width = actualWidth;
            acc.push(newColDef);
            return acc;
         }, []);

         this.updateGridHeader(headerArr);
      }
   }

   onColumnVisible(evt) {
      const tempArr = evt.columnApi.columnController.gridColumns;
      const headerArr = tempArr.reduce((acc, {colDef, visible}) => {
         const isHide = visible ? Boolean(false) : Boolean(true);
         const newColDef = colDef;
         newColDef.hide = isHide;
         acc.push(newColDef);
         return acc;
      }, []);
      this.updateGridHeader(headerArr);
   }

   onSortChanged(evt) {
      this.dispatchEvent(new CustomEvent("stopWorkerEvent", {bubbles: true, composed: true }));
      const columns = evt.columnApi.getAllColumns();
      const headerArr = columns.reduce((acc, {colDef, sort}) => {
         const direction = sort;
         const newColDef = colDef;
         if(direction) newColDef.sortDirection = direction;
         else newColDef.sortDirection = null;
         acc.push(newColDef);
         return acc;
      }, []);
      this.updateGridHeader(headerArr).then(
         (res) => {
            if(!CommonUtils.isEmptyObject(res)) store.dispatch({ type: WorklistActionType.SET_APPLIED_SORT_MODEL, payload: { appliedSortModel: this.getSortModel() } });
         }
      );
   }

   onCellKeyDown(param) {
      const { code, ctrlKey, metaKey } = param.event;
      const { userAgent } = window.navigator;
      const [ isWindow, isMac ] = [ /Windows/.test(userAgent), /Mac OS/.test(userAgent)];

      // #16447 [HWP-UT-W-001] Worklist 단축키 관련
      if(isWindow && ctrlKey || isMac && metaKey) {
         const centerViewPort = this.gridApi.gridPanel.eCenterViewport;

         if(code === "ArrowLeft") centerViewPort.scrollLeft = 0;
         if(code === "ArrowRight") centerViewPort.scrollLeft = centerViewPort.scrollWidth;
      }
   }

   getDefaultFilters() {
      return {
         bodypart: [],
         checkupDepartment: [],
         checkupHospital: [],
         isEmergency: ["E", "N", "C"],
         modality: [],
         patientSex: ["F", "M", "O"],
         readingStatus: ["W", "A", "H/O", "T"],
         requestHospital: [],
         serviceName: [],
         studyStatus: [
            this.t("label.studyStatus.inProgress"),
            this.t("label.studyStatus.verified"),
            this.t("label.studyStatus.completed")
         ],
         teleStatus: [
            this.t("label.studyStatus.none"),
            this.t("label.studyStatus.wait"),
            this.t("label.studyStatus.sending"),
            this.t("label.studyStatus.sent"),
            this.t("label.studyStatus.inReading"),
            this.t("label.studyStatus.fail"),
            this.t("label.studyStatus.cancelled"),
            this.t("label.studyStatus.completed")
         ],
      };
   }

   initGridColumns() {
      return new Promise((resolve) => {
         Promise.all([this.getFilters(), this.getUserStyle()]).then((values) => {
            // console.log("initGridColumns", values);
            if((values[0] instanceof Error)) {
               this._filters = this.getDefaultFilters();
            } else {
               // eslint-disable-next-line prefer-destructuring
               this._filters = values[0];
            }

            if(values[1] instanceof Error) {
               this.columnDefs = this.createColumnDefs();
               this.initWorklist().then(resolve);
            } else {
               this.getInitSortModelWithColumnDefs(values[1]).then((sortModel) => {
                  // #17755 [HPACS] Worklist Grid - 페이지 첫 진입시 sortModel 적용하지 않도록 변경
                  // sortModel.forEach(({ colId, sort }, idx) => {
                  //    const cols = this.gridOptions.columnApi.getColumn(colId);
                  //    cols.setSort(sort);
                  //    cols.setSortIndex(idx);
                  // });
                  // this.gridOptions.api.refreshHeader();
                  this.initWorklist().then(resolve);
               }).catch(resolve);
            }

            this.initWorklist().then(resolve);
         }).catch((err) => {
            console.error(`init grid columns error[${err}]`);
            this.columnDefs = this.createColumnDefs();
            this.initWorklist().then(resolve);
         });

      });
   }

   createDatasource() {
      return {
         getRows: this.getWorklist.bind(this)
      };
   }

   setFilterSortClear() {
      const colDefs = this.gridOptions.api.getColumnDefs();
      colDefs.forEach((col) => {
         if(col.sort) {
            col.sort = null;
         }
      });

      this.gridOptions.api.setColumnDefs(colDefs);
      this.gridOptions.api.refreshHeader();
   }

   sinkViewing(request) {
      if(!request) return;
      this.gridOptions.api.forEachNode((rowNode) => {
         const {data} = rowNode;
         if (data && data.id === request.id) {
            data.reader = request.reader;
            this.gridOptions.api.redrawRows({row: data});
         }
      });
   }

   readingOn() {
      const { id } = this.reportRow.detail;

      // update return 조건
      // 1. viewingUpdate가 진행중, 2. 다른사람이 reading중일때 (toast open), 3. 내가 reading중일때
      if (this.isUpdatingReading || this.isReadingToastOpen || this.readingId === id) return;

      this.isUpdatingReading = true;
      this.updateFilmboxStatusByObjectId(id).then(() => {
         this.getFilmboxReader(id).then((result) => {
            const user = JSON.parse(localStorage.user);
            if (result && result.userId) {
               if (result.userId !== user.id) {
                  this.openCloseBtnToast(`${result.userName} started reading at ${CommonUtils.convertTimestampToDate(result.readingDtime)}`);
                  this.isReadingToastOpen = true;
               } else {
                  this.updateViewing(id, result);
                  this.readingId = id;
               }
            }
            this.isUpdatingReading = false;
         });
      });
   }

   readingOff(userId) {
      this.isReadingToastOpen = false;
      if(this.isUpdatingReading && !this.isAwaitUpdatingReading) {
         // update가 현재 진행중일때 closed 처리가 오는경우 ( macro )
         // update가 끝날때까지 기다리고 off 처리
         this.isAwaitUpdatingReading = true;
         const awaitReadingOn = setInterval(()=>{
            if(!this.isUpdatingReading) {
               this.isAwaitUpdatingReading = false;
               this.readingOff();
               clearInterval(awaitReadingOn);
            }
         }, 300);
      } else if(this.readingId.length !== 0) {
         this.updateFilmboxOFFStatusByObjectId(this.readingId, userId);
         this.updateViewing(this.readingId, null);
         this.readingId = "";
         this.isReadingToastOpen = false;
      }
   }

   openCloseBtnToast(message) {
      this.$.toast1.style.backgroundColor = "#E46159";
      this.$.toast1.text = message;
      this.$.toast1.open();
   }

   // tele 요청 비동기 작업으로 인한 sse 통신으로 telestatus update
   redrawVerifiedTeleStatusRow(request) {
      if(!request) return;
      this.gridOptions.api.forEachNode((rowNode) => {
         const {data} = rowNode;
         if (data && data.id === request.id) {
            data.teleStatus = request.teleStatus;
            data.teleType = request.teleType;
            this.gridOptions.api.redrawRows({row: data});
         }
      });
   }

   // tele 요청 비동기 작업으로 인한 sse 통신으로 상태 업데이트
   redrawVerfiedRequestRow(params) {
      if (params.success) {
         this.refreshCellByTele(params.request.id);
         this.message.msg = "Teleradiology on the study has been successfully requested.";
         this.message.isErr = false;
      } else {
         this.message.msg = "Teleradiology on the study has been request failed.";
         this.message.isErr = true;
      }
      this.openToast(this.message);
   }

   verify() {
      const selectedRows = this.gridOptions.api.getSelectedRows();
      if (CommonUtils.isEmptyObject(selectedRows)) {
         document.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail: {msg : this.t("msg.verify.selected"), isErr : true}}));
         return;
      }

      const message = {
         selectedRows,
         callback: (result) => {
            if (result) {
               this.updateVerifyByObjectId();
            } else {
               document.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail: {msg: this.t("msg.verify.fail"), isErr: true}}));
            }
         }
      };
      store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.VERIFY_DIALOG, actionType: DialogActionType.VERIFY, message, open: true } });
   }

   unVerify() {
      const selectedRows = this.gridOptions.api.getSelectedRows();
      if (CommonUtils.isEmptyObject(selectedRows)) {
         document.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail: {msg : this.t("msg.verify.selected"), isErr : true}}));
         return;
      }

      const unVerify = () => {
         TechnicianUtils.unVerify(selectedRows).then(result => {
            if (result) {
               this.updateVerifyByObjectId();
               document.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail: {msg: "Exam Unverified.", isErr: false}}));
            } else {
               document.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail: {msg: "Unverify Failed.", isErr: true}}));
            }
         });
      };
      const message = {
         contents: i18n("msg.unverify", {returnObjects: true}),
         title: i18n("label.unverify"),
         ok: i18n("button.accept"),
         cancel: i18n("button.cancel"),
         onOk: unVerify,
      };
      store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, actionType: DialogActionType.UN_VERIFY, message, open: true } });
   }

   changePrefetchState(prefetchState) {
      if (!prefetchState) {
         this.gridApi.forEachNode((rowNode) => {
            if(rowNode.data) {
               rowNode.setDataValue("prefetch", "success");
            }
         });
      }
   }

   gridNewDblClickEvent() {
      if (!this.dblClickedId) return;

      this.getUserStyle().then((s) => {
         if (s && s.filmbox && s.filmbox.expand) {
            const {expand} = s.filmbox;
            this.filmboxExpand = expand;
         }

         // gridNewDblClickRespEvent
         const key = this.dblClickedId;
         const {activeTabCode} = this;
         const expand = this.filmboxExpand;

         this.getCaseInfo(key).then((result) => {
            if (!result || (result && result.processingStatus === "temp")) {
               this.$.tempCaseDialog.doCustomSize(200, 380);
               this.$.tempCaseDialog.doOpen({
                  title: "Cannot open filmbox",
                  contents: ["The study has been edited.", "Please refresh the page."],
                  ok: this.t("button.refresh"),
                  cancel: this.t("button.close")
               });
               return;
            }
            if (result) {
               if (result.edit === true) {
                  this.$.editCaseDialog.doCustomSize(200, 380);
                  this.$.editCaseDialog.doOpen({
                     title: "Filmbox Open",
                     contents: ["Technician is editing a study now.", "Please enter a reading after editing."],
                     ok: this.t("button.ok")
                  });
               }

               const prevHash = !this.popup ? "" : this.popup.location.hash;
               // const hash = `#${key}&related=${activeTabCode}&expand=${expand}`;
               const hash = `#${key}&group=new&type=click&related=${FilmboxUtils.convertRelatedTabCode(activeTabCode)}&expand=${expand}`;
               const url = `/filmbox${hash}`;

               this.popup = window.open(url, this.popupName, this.popupOpts);
               // window.document.filmbox = this.popup;
               this.popup.focus();
               if (prevHash === hash) {
                  this.popup.dispatchEvent(new CustomEvent("hashchange"));
               }
            }
         });
      });
   }

   changeFilmboxState(filmboxState) {
      if (filmboxState) {
         const { caseId, seriesId, imageIndex, related } = filmboxState;
         // TODO: 로직 체크 필요

         // ThumbnailDoubleClickEvent
         if (seriesId && imageIndex) {
            const url = `/filmbox#${caseId}&series=${encodeURIComponent(seriesId)}&index=${imageIndex}&expand=F`;
            this.popup = window.open(url, this.popupName, this.popupOpts);
         } else if (related) {
            this.relatedOpen(related);
         }
      }
   }

   changeRedrawRows(redrawRows) {
      const { ids, type } = redrawRows;

      this.getSelectedRowsCaseInfo(ids).then((res) => {
         switch (type) {
         case RedrawRowsType.REPORTED: {
            this.approveOpinionisResult(res);
            break;
         }
         default:
         }
      });
   }

   getSelectedRowsCaseInfo(ids) {
      return new Promise((resolve, reject) => {
         fetch(`/api/case/list?ids=${ids.join(",")}`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")
            }
         }).then((response) => {
            if (response.ok) {
               response.json().then((httpResponse) => {
                  resolve(httpResponse);
               });
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   };
}

window.customElements.define(GridNewfilm.is, GridNewfilm);
