/* eslint-disable no-param-reassign,no-undef,no-lonely-if */
/* eslint-disable no-prototype-builtins */
import {html, PolymerElement} from "@polymer/polymer/polymer-element";

import "ag-grid-polymer";

import moment from "moment-timezone";
import {Debouncer} from "@polymer/polymer/lib/utils/debounce";
import {timeOut} from "@polymer/polymer/lib/utils/async";
import GridUtils from "../utils/grid-utils";

import {CustomTooltip} from "../../public/resource/js/customTooltip";
import CommonUtils from "../../public/resource/js/utils/common";
import mixinCommons from "../common-mixin";
import store from "../redux/store";
import { RelatedWorklistActionType } from "../redux/reducers/related-worklist.ts";
import { ReportActionType } from "../redux/reducers/report.ts";
import {TechlistReportActionType} from "../redux/reducers/techlist-report.ts";
import {RelatedTechlistActionType} from "../redux/reducers/related-techlist";
import {CommonActionType, CustomContextMenuType} from "../redux/reducers/common";
import {FilmboxActionType} from "../redux/reducers/filmbox";

class GridOldfilm extends mixinCommons(PolymerElement) {
   static get is() {
      return "grid-oldfilm";
   }

   static get template() {
      return html`
         <link rel="stylesheet" href="/vendor/ag-grid-enterprise/dist/styles/ag-grid.css">
         <link rel="stylesheet" href="/vendor/ag-grid-enterprise/dist/styles/ag-theme-balham-dark.min.css">
         <link rel="stylesheet" href="/resource/style/ag-grid-hpacs.css">
         <style>
            :host {
               display: block;
               height: 100%;
               width: 100%;
            }

            ::-webkit-scrollbar {
               width: 15px;
               height: 15px;
            }

            ::-webkit-scrollbar-thumb {
               background-color: #6b6b6b;
               border-radius: 10px;
               background-clip: content-box;
               border: 3px solid rgba(255,255,255,0);
            }
         </style>

         <ag-grid-polymer class="ag-theme-balham-dark"
                        gridOptions="{{gridOptions}}"
                        rowData="{{rowData}}"
                        ></ag-grid-polymer>
      `;
   }

   static get properties() {
      return {
         selectedRow: {
            type: Object,
            observer: "onSelectRow"
         },
         selectedRelatedRow: {
            type: Object
         },
         _utcOffset: {
            type: Number
         },
         gridApi: {
            type: Object,
            value: {}
         },
         _filters: {
            type: Object,
            value: {}
         },
         rowNodes: {
            type: Array,
            value: []
         },
         rowData: {
            type: Array,
            value: []
         },
         // popup: {
         //    type: Object
         // },
         isShift: {
            type: Boolean,
            value: false
         },
         filmboxHash: {
            type: Object,
            value: {}
         },
         _selectedTechTab: {
            type: Number,
            value: 0
         },
         tabType: {
            type: String,
            value: ""
         },
         customContextMenuState: {
            type: Number
         },
         reportRow: {
            type: Object,
            observer: "changeReportRow"
         },
         filmboxExpand: { // hangingProtocol 확장여부
            type: String,
            value: "T" // T or F or A
         },
         redrawId: {
            type: String,
            observer: "redrawRelatedExam"
         }
      };
   }

   ready() {
      super.ready();

      store.subscribe(() => {
         if (this._selectedTechTab === 0) {
            this.selectedRow = store.getState().worklist.row;
            this.reportRow = store.getState().report.reportRow;
         }
         else if (this._selectedTechTab === 1) {
            this.selectedRow = store.getState().techlist.row;
         }
         this._selectedTechTab = store.getState().common.category;
         this.customContextMenuState = store.getState().common.customContextMenu;
         this.redrawId = store.getState().relatedWorklist.redrawId;
      });

      this.utcCheck().then((result) => {
         this._utcOffset = result;
      });

      window.addEventListener("setFilterSortClearEvent", () => {
         this.setFilterSortClear();
      });
   }

   constructor() {
      super();

      this.gridOptions = {
         defaultColDef: {
            suppressMenu: true,
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filterParams: {
               newRowsAction: "keep"
            },
            suppressKeyboardEvent: params => GridUtils.disableRowDeselectionBySpace(params),
         },
         // floatingFilter: true,
         // rowSelection: "single",
         rowSelection: "multiple",
         sideBar: {
            toolPanels: [
               {
                  id: "columns",
                  labelDefault: this.t("label.showHideColumns"),
                  labelKey: "columns",
                  iconKey: "columns",
                  toolPanel: "agColumnsToolPanel",
                  toolPanelParams: {
                     suppressRowGroups: true,
                     suppressValues: true,
                     suppressPivots: true,
                     suppressPivotMode: true,
                     suppressSideButtons: true,
                     suppressColumnFilter: true,
                     suppressColumnSelectAll: true,
                     suppressColumnExpandAll: true,
                  }
               }
            ]
         },
         components: {
            // eslint-disable-next-line no-undef
            customTooltip: CustomTooltip
         },
         tooltipShowDelay: 0,
         overlayLoadingTemplate: `<span style='font-size: 13px'>${this.t("label.noRecordsFound")}</span>`,
         overlayNoRowsTemplate: `<span style='font-size: 13px'>${this.t("label.noRecordsFound")}</span>`,
         navigateToNextCell: params => this.navigateToNextCell(params)
      };

      this.gridOptions.onGridReady = (params) => {
         this.gridApi = params.api;
         this.oldGridHeaderCreate();
      };

      this.gridOptions.onRowClicked = (evt) => {
         // doubleClick 이슈 : click 이벤트가 2번 호출되어 1번만 호출되게끔 debounce 추가
         this._debouncer = Debouncer.debounce(this._debouncer, timeOut.after(300), () => {
            this.selectedRelatedRow = evt.data;

            const param = {};
            param.detail = evt.data;
            if (this._selectedTechTab === 0) {
               store.dispatch({ type: RelatedWorklistActionType.SELECT_RELATED_ROW, payload: param });
               // related row click시 reportWindow 영역에서 thumbnail만 변경됨
               store.dispatch({ type: ReportActionType.SELECT_THUMBNAIL_ROW, payload: param?.detail?.id });
            } else if (this._selectedTechTab === 1) {
               store.dispatch({ type: RelatedTechlistActionType.SELECT_RELATED_ROW, payload: param });
               store.dispatch({ type: TechlistReportActionType.SELECT_REPORT_ROW, payload: param }); // thumbnail, clinicalInfo, opinion
            }

            this.isShift = evt.event.shiftKey;
         });
      };

      // TODO: grid에서 우클릭시 row 선택을 해주기 위해 click 이벤트에서 selected 이벤트로 변경
      this.gridOptions.onRowSelected = (evt) => {
         const selectedRows = evt.api.getSelectedRows();
         if(evt.node.selected) {
            // console.log("onRowSelected", evt);
            const param = {};
            param.detail = evt.data;

            this.selectedRelatedRow = evt.data;

            if (this._selectedTechTab === 0) {
               store.dispatch({ type: RelatedWorklistActionType.SELECT_RELATED_ROW, payload: param });
               store.dispatch({ type: ReportActionType.SELECT_THUMBNAIL_ROW, payload: param?.detail?.id });
            } else if (this._selectedTechTab === 1) {
               store.dispatch({ type: RelatedTechlistActionType.SELECT_RELATED_ROW, payload: param });
               store.dispatch({ type: TechlistReportActionType.SELECT_REPORT_ROW, payload: param }); // thumbnail, clinicalInfo, opinion
            }

            // #18800 [HPACS > M19] 필름박스 tab에 띄워져 있는 related 표시
            // filmbox tab에 띄워진 related는 눈모양으로 표시한다
            // 눈모양 클릭시 붉은 눈모양으로 표시한다
            // 그외 related row 클릭시 빨간점으로 표시한다
            // 우클릭으로도 가능하게끔 onRowSelected로 이동
            this.gridOptions.api.forEachNode((rowNode) => {
               const {data} = rowNode;
               if(data.currentViewing === "click") rowNode.setDataValue("currentViewing", "");

               if(data.currentViewing === "dbClick" && evt.data.id === data.id) rowNode.setDataValue("currentViewing", "multiClick");
               else if(data.currentViewing === "dbClick" || data.currentViewing === "multiClick" && evt.data.id !== data.id) rowNode.setDataValue("currentViewing", "dbClick");
               else if(evt.data.id === data.id) rowNode.setDataValue("currentViewing", "click");
            });
         } else if(!evt.node.selected && selectedRows.length === 0) {
            if (this._selectedTechTab === 0) {
               store.dispatch({ type: RelatedWorklistActionType.CLEAR_RELATED_ROW });
            } else if (this._selectedTechTab === 1) {
               store.dispatch({ type: RelatedTechlistActionType.CLEAR_RELATED_ROW });
            }
         }
      };

      this.gridOptions.getRowNodeId = (data) => {
         return data.id;
      };

      this.gridOptions.getContextMenuItems = (params) => {

         if(!params.node) return;

         this.dispatchEvent(new CustomEvent("getFilmboxWindow"));

         const {studyId, old} = this.filmboxHash;
         const rows = params.api.getSelectedRows();

         if(rows.length > 1) {
            // 다중 선택시 sort
            rows.sort((a, b) => a.no - b.no);

            // #16192 우클릭으로 선택되어있지 않는 row를 선택했을시 선택값을 초기화 한다.
            const row = rows.find( row => row.id === params.node.data.id);
            if (!row) {
               params.node.setSelected(true, true);
            }
         } else {
            // #16041 마우스 우클릭으로 row가 선택되게끔 추가
            params.node.setSelected(true, true);
         }

         if (this.customContextMenuState !== undefined) {
            store.dispatch({ type: CommonActionType.SHOW_CONTEXT_MENU, payload: CustomContextMenuType.SYSTEM });
         }

         let result = [
            {
               name: this.t("label.newReplaceTab"),
               disabled: this.isDisabledForReplaceContextMenu(rows),
               action: () => {
                  this.getUserStyle().then((s) => {
                     if (s && s.filmbox && s.filmbox.expand) {
                        const {expand} = s.filmbox;
                        this.filmboxExpand = expand;
                     }

                     const related = {rel1: params.node.id, rel2: old, type: "replace", group: "new", expand: this.filmboxExpand};
                     if(related.rel1 === related.rel2) related.rel2 = undefined;

                     store.dispatch({ type: FilmboxActionType.OPEN_FILMBOX, payload: { related } });
                     store.dispatch({ type: FilmboxActionType.CLOSE_FILMBOX });
                  });

                  if (this._selectedTechTab === 0) {
                     // #15727 Related를 판독하려면 Open On New로 열었을때만 가능하다.
                     const reportRow = {
                        detail: params.node.data,
                        rows: [params.node.data],
                        oldRow: this.reportRow?.detail,
                        oldRows: this.reportRow?.rows
                     };
                     store.dispatch({ type: ReportActionType.SELECT_REPORT_ROW, payload: reportRow });
                     // #17974 [HPACS > 전수령 원장님] Related List row 선택 하였을 때 Clinic Info (임상정보) 는 업데이트 되지 않고 New의 Clinic Info 가 유지되게끔 변경 되면 좋겠다.
                     store.dispatch({ type: ReportActionType.SELECT_CLINICALINFO_ROW, payload: this.selectedRelatedRow.id });
                  }
               }
            },
            {
               name: this.t("label.newAddTab"),
               action: () => {
                  this.getUserStyle().then((s) => {
                     if (s && s.filmbox && s.filmbox.expand) {
                        const {expand} = s.filmbox;
                        this.filmboxExpand = expand;
                     }

                     const related = {rel1: params.node.id, rel2: old, type: "tab", group: "new", expand: this.filmboxExpand};
                     if (related.rel1 === related.rel2) related.rel2 = undefined;

                     // 다중 선택시 선택된 모든 검사 추가
                     if (rows.length > 1) {
                        const ids = [];
                        rows.forEach(r => ids.push(r.id));
                        related.rel1 = ids.toString();

                        // #18066 [HPACS > 탭뷰 사용성 개선] 멀티로 new tab을 오픈 시 첫번째 검사가 focus되도록 변경 필요
                        this.setFirstSelectedRows(rows[0].id);
                     }

                     store.dispatch({ type: FilmboxActionType.OPEN_FILMBOX, payload: { related } });
                     store.dispatch({ type: FilmboxActionType.CLOSE_FILMBOX });
                  });

                  if (this._selectedTechTab === 0) {
                     // #15727 Related를 판독하려면 Open On New로 열었을때만 가능하다.
                     const reportRow = {
                        detail: params.node.data,
                        rows: [params.node.data],
                        oldRow: this.reportRow?.detail,
                        oldRows: this.reportRow?.rows
                     };
                     store.dispatch({ type: ReportActionType.SELECT_REPORT_ROW, payload: reportRow });
                     // #17974 [HPACS > 전수령 원장님] Related List row 선택 하였을 때 Clinic Info (임상정보) 는 업데이트 되지 않고 New의 Clinic Info 가 유지되게끔 변경 되면 좋겠다.
                     store.dispatch({ type: ReportActionType.SELECT_CLINICALINFO_ROW, payload: this.selectedRelatedRow.id });
                  }
               }
            },
            {
               name: this.t("label.relReplaceTab"),
               disabled: this.isDisabledForReplaceContextMenu(rows),
               action: () => {
                  this.getUserStyle().then((s) => {
                     if (s && s.filmbox && s.filmbox.expand) {
                        const {expand} = s.filmbox;
                        this.filmboxExpand = expand;
                     }

                     const related = {rel1: studyId, rel2: params.node.id, type: "replace", group: "old", expand: this.filmboxExpand};
                     if (related.rel1 === related.rel2) related.rel1 = undefined;

                     // #15727 Related를 판독하려면 Open On New로 열었을때만 가능하다.
                     store.dispatch({ type: FilmboxActionType.OPEN_FILMBOX, payload: { related } });
                     store.dispatch({ type: FilmboxActionType.CLOSE_FILMBOX });
                  });
               }
            },
            {
               name: this.t("label.relAddTab"),
               action: () => {
                  this.getUserStyle().then((s) => {
                     if (s && s.filmbox && s.filmbox.expand) {
                        const {expand} = s.filmbox;
                        this.filmboxExpand = expand;
                     }

                     let relId = params.node.id;
                     const related = {rel1: studyId, rel2: relId, type: "tab", group: "old", expand: this.filmboxExpand};
                     if (related.rel1 === related.rel2) related.rel1 = undefined;

                     // 다중 선택시 선택된 모든 검사 추가
                     if (rows.length > 1) {
                        relId = rows[0].id;
                        const ids = [];
                        rows.forEach(r => ids.push(r.id));
                        related.rel2 = ids.toString();

                        // #18066 [HPACS > 탭뷰 사용성 개선] 멀티로 new tab을 오픈 시 첫번째 검사가 focus되도록 변경 필요
                        this.setFirstSelectedRows(rows[0].id);
                     }

                     // #15727 Related를 판독하려면 Open On New로 열었을때만 가능하다.
                     store.dispatch({ type: FilmboxActionType.OPEN_FILMBOX, payload: { related } });
                     store.dispatch({ type: FilmboxActionType.CLOSE_FILMBOX });
                  });
               }
            }
         ];

         // #18136 [HPACS > M18]Related list의 Clinical Info 보기
         if(this._selectedTechTab === 0) {
            const menu = [
               "separator",
               {
                  name: this.t("label.viewClinicalInfo"),
                  action: () => {
                     store.dispatch({ type: ReportActionType.SELECT_CLINICALINFO_ROW, payload: this.selectedRelatedRow.id });
                  }
               },
            ];

            result = result.concat(menu);
         }

         return result;
      };

      this.gridOptions.onColumnMoved = () => {
         this.gridOptions.onDragStopped = () => {
            const columns = this.gridOptions.columnApi.columnController.gridColumns;
            const headerArr = columns.reduce((acc, {colDef}) => {
               acc.push(colDef);
               return acc;
            },[]);
            this.updateGridHeader(headerArr);
         };
      };

      this.gridOptions.onColumnResized = (v) => {
         if (v.finished) {
            const columns = this.gridOptions.columnApi.getAllColumns();
            const headerArr = columns.reduce((acc, {colDef, actualWidth}) => {
               const newColDef = colDef;
               newColDef.width = actualWidth;
               acc.push(newColDef);
               return acc;
            },[]);
            this.updateGridHeader(headerArr);
         };
      };

      this.gridOptions.onColumnVisible = () => {
         const columns = this.gridOptions.columnApi.getAllColumns();
         const headerArr = columns.reduce((acc, {colDef, visible}) => {
            const isHide = visible ? Boolean(false) : Boolean(true);
            const newColDef = colDef;
            newColDef.hide = isHide;
            acc.push(newColDef);
            return acc;
         },[]);

         this.updateGridHeader(headerArr);
      };

      this.gridOptions.onRowDoubleClicked = (e) => {

         if (!e.node) return;

         this.getUserStyle().then((s) => {
            if (s && s.filmbox && s.filmbox.expand) {
               const {expand} = s.filmbox;
               this.filmboxExpand = expand;
            }

            // #18030 [HPACS > Dev2] 필름박스 리팩토링 : Related exam/ New Exam 나오지 않는 이슈
            const related = {rel2: e.node.data.id, type: "click", group: "old", expand: this.filmboxExpand};

            // #15727 Related를 판독하려면 Open On New로 열었을때만 가능하다.
            store.dispatch({ type: FilmboxActionType.OPEN_FILMBOX, payload: { related } });
            store.dispatch({ type: FilmboxActionType.CLOSE_FILMBOX });
         });
      };

      this.gridOptions.onCellKeyDown = (param) => {
         const { code, ctrlKey, metaKey } = param.event;
         const { userAgent } = window.navigator;
         const [ isWindow, isMac ] = [ /Windows/.test(userAgent), /Mac OS/.test(userAgent)];

         // #16447 [HWP-UT-W-001] Worklist 단축키 관련
         if(isWindow && ctrlKey || isMac && metaKey) {
            const centerViewPort = this.gridApi.gridPanel.eCenterViewport;

            if(code === "ArrowLeft") centerViewPort.scrollLeft = 0;
            if(code === "ArrowRight") centerViewPort.scrollLeft = centerViewPort.scrollWidth;
         }
      };

      document.addEventListener("click", () => {
         if (this.customContextMenuState !== undefined) store.dispatch({ type: CommonActionType.HIDE_CONTEXT_MENU });
      });

      window.addEventListener("message", (e) => {
         // console.log("newfilm", e.data);
         switch((e.data||{}).event) {
         case "FILMBOX_CLOSED": {
            // #18074 [HPACS > 김성현원장님] Related 영상 list에 표시하는 부분 보완
            // filmbox가 닫혀 있을 때는 Viewing 아이콘을 실시간으로 빼준다
            this.clearViewing();
            break;
         }
         case "CHANGE_FILMBOX_TAB": {
            this.clearViewing();
            this.gridOptions.api.forEachNode((rowNode) => {
               const {data} = rowNode;
               e.data.relIds.forEach((id) => {
                  if(id === data.id) rowNode.setDataValue("currentViewing", "dbClick");
               });
            });
            // related report list에 related id정보를 보낸다
            this.dispatchEvent(new CustomEvent("relatedDblClickEvent", {bubbles: true, composed: true, detail: e.data.relIds}));
            break;
         }
         default:
         }
      });
   }

   onSelectRow(row) {
      const selectedRow = row?.detail;
      if (!selectedRow) {
         this.initOldFilm();
         return;
      }

      this.getOldFilmWorklist(selectedRow).then((result) => {
         if (this.selectedRow?.detail?.id !== selectedRow.id) {
            console.warn(`initOldFilm canceled. [selectedRow: ${this.selectedRow?.detail?.id}/${this.selectedRow?.detail?.patientName}, settingRow: ${selectedRow.id}/${selectedRow.patientName}`);
            return;
         }
         this.initOldFilm(selectedRow, result);
      }, (err) => {
         console.error(err);
      });
   }

   clearViewing() {
      this.gridOptions.api.forEachNode(rowNode => rowNode.setDataValue("currentViewing", ""));
   }

   setFirstSelectedRows(id) {
      this.clearSelectedRow();
      this.gridOptions.api.getRowNode(id).setSelected(true);
   }

   clearSelectedRow() {
      this.gridOptions.api.deselectAll();
   }

   emCellRenderer(params) {
      if(params.value === "normal") {
         return "<div class='emStatus'>N</div>";
      }
      if(params.value === "em") {
         return "<div class='emStatus em'>E</div>";
      }
      if(params.value === "cvr") {
         return "<div class='emStatus cvr'><iron-icon icon='healthhub:cvr'></iron-icon></div>";
      }
   }

   rsCellRenderer(params) {
      const user = JSON.parse(localStorage.user);
      if (params.value === "3A") {
         return "A";
      }
      if (params.value === "1W") {
         return "W";
      }
      if (params.value === "2T" && (user.id === params.data.readingDoctorId)) {
         return "H";
      }
      return "O";
   }

   createColumnDefs() {
      const columns = [
         {headerName: this.t("label.gridHeader.name.no"),            field: "no",                  width: 33,  sortable: false,  headerTooltip: this.t("label.gridHeader.tooltip.no"),                 cellStyle: {"text-align": "center"}},
         {headerName: this.t("label.gridHeader.name.viewing"),       field: "currentViewing",      headerTooltip: this.t("label.gridHeader.tooltip.viewing"),             suppressMenu: true, filter: false, sortable: true, resizable: false, width: 55, cellStyle: {"text-align": "center"}, cellRenderer: this.cvCellRenderer},
         {headerName: this.t("label.gridHeader.name.count"),         field: "imageCount",          width: 45,  sortable: false,  headerTooltip: this.t("label.gridHeader.tooltip.count"),       cellStyle: {"text-align": "center"}},
         {headerName: this.t("label.gridHeader.name.em"),            field: "isEmergency",         width: 50,                    headerTooltip: this.t("label.gridHeader.tooltip.em"), cellRenderer: params => this.emCellRenderer(params),
            cellClassRules: {"emergency": params => params.value === "true", "normal": params => params.value === "false"}},
         {headerName: this.t("label.gridHeader.name.rs"),            field: "readingStatus",       width: 50,                    headerTooltip: this.t("label.gridHeader.tooltip.rs"), cellStyle: {"text-align": "center"},   cellRenderer: params => this.rsCellRenderer(params)},
         {headerName: this.t("label.gridHeader.name.modality"),      field: "modality",            width: 60, headerTooltip: this.t("label.gridHeader.tooltip.modality"),  filter: "agSetColumnFilter", filterParams: {applyButton: true, clearButton: true, values: this._filters.modality}},
         {headerName: this.t("label.gridHeader.name.bodyPart"),      field: "bodypart",            width: 80, headerTooltip: this.t("label.gridHeader.tooltip.bodyPart"),  filter: "agSetColumnFilter", filterParams: {applyButton: true, clearButton: true, values: this._filters.bodypart}},
         {headerName: this.t("label.gridHeader.name.studyDesc"),     field: "studyDescription",    width: 200, headerTooltip: this.t("label.gridHeader.tooltip.studyDesc"), filter: "agTextColumnFilter",
            filterParams: {applyButton: true, textFormatter: r => this.textFormatter(r), caseSensitive:true},
            floatingFilterComponentParams: {suppressFilterButton: true}
         },
         {headerName: this.t("label.gridHeader.name.studyDate"),     field: "studyDtime",          width: 140, headerTooltip: this.t("label.gridHeader.tooltip.studyDate")},
         {headerName: this.t("label.gridHeader.name.repDoc"),   field: "readingDoctor",       width: 110, filter: "agTextColumnFilter",
            filterParams: {applyButton: true, clearButton: true, textFormatter: r => this.textFormatter(r), caseSensitive:true},
            floatingFilterComponentParams: {suppressFilterButton: true},
            headerTooltip: this.t("label.gridHeader.tooltip.repDoc"), tooltipField: "readingDoctor"
         },
         {headerName: this.t("label.gridHeader.name.confirm"),       field: "confirm",             width: 140, headerTooltip: this.t("label.gridHeader.tooltip.confirm")},
         {headerName: this.t("label.gridHeader.name.reqHosp"),     field: "requestHospital",     width: 150, headerTooltip: this.t("label.gridHeader.tooltip.reqHosp"), filter: "agSetColumnFilter",
            filterParams: {applyButton: true, clearButton: true, values: this._filters.requestHospital}
         },
         {headerName: this.t("label.gridHeader.name.reqDate"),         field: "requestDtime",        width: 140, headerTooltip: this.t("label.gridHeader.tooltip.reqDate")},
         {headerName: this.t("label.gridHeader.name.acqHosp"),     field: "checkupHospital",     width: 150, headerTooltip: this.t("label.gridHeader.tooltip.acqHosp"), filter: "agSetColumnFilter",
            filterParams: {applyButton: true, clearButton: true, values: this._filters.checkupHospital}
         },
         {headerName: this.t("label.gridHeader.name.reqDept"),          field: "checkupDepartment",   width: 80, headerTooltip: this.t("label.gridHeader.tooltip.reqDept"),  filter: "agSetColumnFilter",
            filterParams: {applyButton: true, clearButton: true, values: this._filters.checkupDepartment}
         },
         {headerName: this.t("label.gridHeader.name.reqDoc"),       field: "checkupDoctor",       width: 80, headerTooltip: this.t("label.gridHeader.tooltip.reqDoc"),  filter: "agTextColumnFilter",
            filterParams: {applyButton: true, textFormatter: r => this.textFormatter(r), caseSensitive:true},
            floatingFilterComponentParams: {suppressFilterButton: true}
         },
         {headerName: this.t("label.gridHeader.name.tech"),          field: "checkupTechnician",   width: 80, headerTooltip: this.t("label.gridHeader.tooltip.tech"),  filter: "agTextColumnFilter",
            filterParams: {applyButton: true, textFormatter: r => this.textFormatter(r), caseSensitive:true},
            floatingFilterComponentParams: {suppressFilterButton: true}
         },
      ];

      return GridUtils.changeFilterParams(columns);
   }

   oldGridHeaderCreate() {
      this.getFilters()
         .then((result) => {
            this._filters = result;
            this.getUserStyle()
               .then((result) => {
                  this.tabType = result.filmbox.itemConfig.tabConfig.tabType || "replaceTab";

                  if(result.tabIndex) {
                     this._selectedTechTab = result.tabIndex.worklistNewFilm || 0;
                  }

                  if (result.grid && result.grid.oldFilm && result.grid.oldFilm.length > 0) {
                     const {oldFilm} = result.grid;
                     // const columnDefs = this.changeFilterParams(this.cellRenderer(oldFilm));
                     const columnDefs = GridUtils.mergeFilterParams(oldFilm, this.createColumnDefs());
                     this.gridApi.setColumnDefs(columnDefs);
                  } else {
                     // const columnDefs = this.cellRenderer(this.createColumnDefs());
                     const columnDefs = this.createColumnDefs();
                     this.gridOptions.api.setColumnDefs(columnDefs);

                     this.updateGridHeader(this.createColumnDefs());
                  }
               })
               .catch((err) => {
                  console.info(err);
                  // const columnDefs = this.cellRenderer(this.createColumnDefs());
                  const columnDefs = this.createColumnDefs();
                  this.gridOptions.api.setColumnDefs(columnDefs);
               });
         })
         .catch((err) => {
            console.info(err);
            // const columnDefs = this.cellRenderer(this.createColumnDefs());
            const columnDefs = this.createColumnDefs();
            this.gridOptions.api.setColumnDefs(columnDefs);
         });
   }

   cellRenderer(headerArr) {
      return headerArr.reduce((acc, it) => {

         const {field, filterParams, floatingFilterComponentParams} = it;
         const header = it;
         if(field === "isEmergency")      header.cellRenderer = v => this.emCellRenderer(v);
         if(field === "readingStatus")    header.cellRenderer = v => this.rsCellRenderer(v);
         if (field === "currentViewing")  header.cellRenderer = v => this.cvCellRenderer(v);

         if(filterParams) {
            header.filterParams.textFormatter = this.gridTextFormmater()[0].textFormatter;
         }

         if (!floatingFilterComponentParams) {
            const filterKey = Object.keys(this._filters);
            const isFilter = filterKey.includes(field);

            if(isFilter && filterParams) {
               filterParams.values = this._filters[field];
               header.filterParams = filterParams;
            }
         }
         acc.push(header);
         return acc;
      }, []);
   }

   initOldFilm(selectedRow = {}, relatedRows = []) {
      const rows = Object.assign([], relatedRows);

      this.rowNodes = [];

      if (rows.length > 0) {
         // old data가 있지만 row 선택을 하지 않았을때
         // if (this.selectedRelatedRow && (relatedRows[0].patientID !== this.selectedRelatedRow?.patientID)) {
         //    if (this._selectedTechTab === 0) {
         //       store.dispatch({ type: RelatedWorklistActionType.CLEAR_RELATED_ROW });
         //    } else if (this._selectedTechTab === 1) {
         //       store.dispatch({ type: RelatedTechlistActionType.CLEAR_RELATED_ROW });
         //    }
         // }

         // UTC 시간 추가(Asia/Seoul +9)
         for (let i = 0; i < relatedRows.length; i++) {
            // requestDtime <- createDtime
            if (relatedRows[i].requestDtime) {
               // let localTime = moment(result[i].requestDtime).add(this._utcOffset, "h").toDate();
               // localTime = moment(localTime).format("YYYY-MM-DD HH:mm:ss");
               // rows[i].requestDtime = localTime;
               rows[i].requestDtime = CommonUtils.convertTimestampToDate(relatedRows[i].requestDtime);
            }

            // confirm <- opinionApprovedDtime(판독일시)
            if (relatedRows[i].confirm) {
               // let localConfirmTime = moment(result[i].confirm).add(this._utcOffset, "h").toDate();
               // localConfirmTime = moment(localConfirmTime).format("YYYY-MM-DD HH:mm:ss");
               // rows[i].confirm = localConfirmTime;
               rows[i].confirm = CommonUtils.convertTimestampToDate(relatedRows[i].confirm);
            }
         }
      } else { // old data가 없을때
         // if (this._selectedTechTab === 0) {
         //    store.dispatch({ type: RelatedWorklistActionType.CLEAR_RELATED_ROW });
         // } else if (this._selectedTechTab === 1) {
         //    store.dispatch({ type: RelatedTechlistActionType.CLEAR_RELATED_ROW });
         // }
      }
      const rows_all = this.initNo(rows);
      const rows_modality = this.initNo(rows.filter(it => this.isNotBlank(it.modality) && it.modality === selectedRow.modality));
      const rows_bodypart = this.initNo(rows.filter(it => this.isNotBlank(it.bodypart) && it.bodypart === selectedRow.bodypart));
      const rows_modality_bodypart = this.initNo(rows.filter(it => (this.isNotBlank(it.modality) && it.modality === selectedRow.modality) && (this.isNotBlank(it.bodypart) && it.bodypart === selectedRow.bodypart)));
      const rows_studyDesc = this.initNo(rows.filter(it => this.isNotBlank(it.studyDescription) && it.studyDescription === selectedRow.studyDescription));

      const rowsSet = {};
      rowsSet.all = rows_all;
      rowsSet.modality = rows_modality;
      rowsSet.bodypart = rows_bodypart;
      rowsSet.modality_bodypart = rows_modality_bodypart;
      rowsSet.studyDesc = rows_studyDesc;

      const param = {};
      param.detail = rowsSet;

      this.dispatchEvent(new CustomEvent("initOldFilmEvent", param));

      // #18800 [HPACS > M19] 필름박스 tab에 띄워져 있는 related 표시
      // related worklist 변경시 filmbox에 이벤트 전달
      window.postMessage({event: "CHANGE_RELATED"}, document.location.href);
   }

   isNotBlank(value) {
      if(value === null) return false;
      if (typeof value === "undefined") return false;
      if (typeof value === "string" && value === "") return false;
      if (typeof value === "string" && value === "-") return false;
      return true;
   }

   initNo(rows) {
      return rows.reduce((acc, it, index) => {
         const row = JSON.parse(JSON.stringify(it));
         row.no = index + 1;
         acc.push(row);

         return acc;
      },[]);
   }

   gridTextFormmater() {
      return [{
         textFormatter: (s) => {
            if (s === null) return null;
            return this.textFormatterCore(s);
         }
      }];
   };

   textFormatter(s) {
      if (s === null) return null;
      return this.textFormatterCore(s);
   }

   textFormatterCore(s) {
      let r = s.toLowerCase();
      r = r.replace(new RegExp("[àáâãäå]", "g"), "a");
      r = r.replace(new RegExp("æ", "g"), "ae");
      r = r.replace(new RegExp("ç", "g"), "c");
      r = r.replace(new RegExp("[èéêë]", "g"), "e");
      r = r.replace(new RegExp("[ìíîï]", "g"), "i");
      r = r.replace(new RegExp("ñ", "g"), "n");
      r = r.replace(new RegExp("[òóôõøö]", "g"), "o");
      r = r.replace(new RegExp("œ", "g"), "oe");
      r = r.replace(new RegExp("[ùúûü]", "g"), "u");
      r = r.replace(new RegExp("[ýÿ]", "g"), "y");
      return r;
   }

   getOldFilmWorklist(selectedRow) {
      return new Promise((resolve, reject) => {
         const param = { request : selectedRow };

         fetch("/api/exchange/worklist/old", {
            method: "POST",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
         }).then((response) => {
            if (response.ok) {
               response.json().then((rows) => {
                  resolve(rows);
               });
            } else {
               reject(new Error("worklist loading error."));
            }
         });
      });
   }

   completedPrefetchMark(result) {
      if (Array.isArray(result)) {
         // eslint-disable-next-line no-param-reassign
         result = result.reduce((a, b) => {
            if (a.indexOf(b) < 0) a.push(b);
            return a;
         }, []);

         // eslint-disable-next-line no-restricted-syntax
         for (const requestObjectId of result) {
            this.gridOptions.api.forEachNode((rowNode) => {
               if (rowNode.data.id === requestObjectId) {
                  this.rowNodes.push(rowNode);
               }
            });
         }
      } else {
         this.gridOptions.api.forEachNode((rowNode) => {
            if (rowNode.data.id === result) {
               this.rowNodes.push(rowNode);
            }
         });
      }

      this.gridOptions.getRowClass = (params) => {
         // eslint-disable-next-line no-restricted-syntax
         for (const i in this.rowNodes) {
            if (params.node === this.rowNodes[i]) {
               return "prefetch";
            }
         }
         return null;
      };

      this.gridOptions.api.redrawRows({rowNodes: this.rowNodes});
   }

   batchCompletedPrefetchMark(result) {
      // Array 중복 제거
      // eslint-disable-next-line no-param-reassign
      result = result.reduce((a, b) => {
         if (a.indexOf(b) < 0) a.push(b);
         return a;
      }, []);

      // eslint-disable-next-line no-restricted-syntax
      for (const studyObjectId of result) {
         this.gridOptions.api.forEachNode((rowNode) => {
            if (rowNode.data.studyId === studyObjectId) {
               this.rowNodes.push(rowNode);
            }
         });
      }

      this.gridOptions.getRowClass = (params) => {
         // eslint-disable-next-line no-restricted-syntax
         for (const i in this.rowNodes) {
            if (params.node === this.rowNodes[i]) {
               return "prefetch";
            }
         }
         return null;
      };

      this.gridOptions.api.redrawRows({rowNodes: this.rowNodes});
   }

   updateGridHeader(headerArr) {
      const type = "oldFilm";
      fetch(`/api/user/option/gridheader/${type}`, {
         method: "PATCH",
         headers: {
            "Authorization": localStorage.getItem("jwt"),
            "Content-Type": "application/json"
         },
         body: JSON.stringify(headerArr)
      }).then((response) => {
         if (!response.ok) {
            console.debug(new Error(`${response.status} ${response.statusText}`));
         }
      });
   }

   getUserStyle() {
      return new Promise((resolve, reject) => {
         fetch(`/api/user/option/style`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")
            }
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((rows) => {
                  resolve(rows);
               });
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }

   clearOldfilm() {
      this.gridOptions.api.setRowData(null);
   }

   setRowData(rows) {
      this.gridOptions.api.setRowData(rows);
   }

   navigateToNextCell(params) {
      let previousCell = params.previousCellPosition;
      const suggestedNextCell = params.nextCellPosition;

      const KEY_UP = 38;
      const KEY_DOWN = 40;
      const KEY_LEFT = 37;
      const KEY_RIGHT = 39;

      switch (params.key) {
      case KEY_DOWN:
         previousCell = params.previousCellPosition;
         this.gridOptions.api.forEachNode((node) => {
            node.setSelected(false);
            if (previousCell.rowIndex + 1 === node.rowIndex) {
               node.setSelected(true);
            }
         });
         return suggestedNextCell;
      case KEY_UP:
         previousCell = params.previousCellPosition;
         this.gridOptions.api.forEachNode((node) => {
            node.setSelected(false);
            if (previousCell.rowIndex - 1 === node.rowIndex) {
               node.setSelected(true);
            }
         });
         return suggestedNextCell;
      case KEY_LEFT:
      case KEY_RIGHT:
         return suggestedNextCell;
      default :
         throw new Error("this will never happen, navigation is always one of the 4 keys above");
      }
   }

   utcCheck() {
      return new Promise((resolve) => {
         const now = new Date();
         const _zone = moment.tz.guess();
         const timeOffset = moment(now.getTime()).tz(_zone);
         resolve(timeOffset._offset / 60);
      });
   }

   getFilters() {
      return new Promise((resolve, reject) => {
         fetch("/api/exchange/worklist/filters", {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")
            }
         }).then((response) => {
            if (response.ok) {
               response.json().then((httpResponse) => {
                  resolve(httpResponse);
               });
            } else {
               reject(new Error(`Error : ${response.status}`));
            }
         });
      });
   }

   approveOpinionisResult(result) {

      this.gridOptions.api.forEachNode((rowNode) => {
         const {data} = rowNode;
         const selectedRow = result.find(row => row.id === data.id);
         if(selectedRow) {
            data.readingStatus = selectedRow.readingStatus;
            data.studyStatus = selectedRow.studyStatus;
            data.readingDoctor = selectedRow.opinionUpdateUser;
            data.readingDoctorId = selectedRow.opinionUpdateUserId;
            data.teleStatus = selectedRow.teleStatus;
            data.verify = null;
            if(selectedRow.opinionUpdateDtime) data.confirm = CommonUtils.convertTimestampToDate(selectedRow.opinionUpdateDtime);
            if (selectedRow.verifyInfo && selectedRow.verifyInfo.length > 0) {
               const verify = selectedRow.verifyInfo.find(v => (v.isActive));
               if(verify) data.verify = verify.username;
            }
            this.gridOptions.api.redrawRows({row: data});
         }
      });
   }

   /**
    * ContextMenu 활성화 여부, Replace
    * @return {boolean}
    */
   isDisabledForReplaceContextMenu(rows) {
      if(rows.length > 1) return true;
      return false;
   }

   setFilterSortClear() {
      const colDefs = this.gridOptions.api.getColumnDefs();
      colDefs.forEach((col) => {
         if(col.sort) {
            col.sort = null;
         }
      });

      this.gridOptions.api.setColumnDefs(colDefs);
      this.gridOptions.api.refreshHeader();
   }

   cvCellRenderer(params) {
      if (params.value === "dbClick") {
         return `<iron-icon class="caseLockIcon" icon='vaadin:eye'></iron-icon>`;
      }

      if(params.value === "click") {
         return `<iron-icon class="relClickIcon" icon='vaadin:circle'></iron-icon>`;
      }

      if (params.value === "multiClick") {
         return `<iron-icon class="multiClickIcon" icon='vaadin:eye'></iron-icon>`;
      }
   }

   changeReportRow(row, old) {
      if (this._selectedTechTab !== 0) return;
      if (!this.selectedRelatedRow || !row.detail) return;

      if (this.selectedRelatedRow.id === row.detail.id) { // related row를 판독하려는 경우
         if (row.detail.id === old?.detail?.id) {
            const changeReadingStatus = row.detail.readingStatus !== old.detail.readingStatus;
            if (changeReadingStatus) {
               this.approveOpinionisResult([row.detail]);
            }
         }
      }
   }

   redrawRelatedExam(redrawId) {
      if (!redrawId) return;

      this.gridOptions.api.forEachNode((rowNode) => {
         const { data } = rowNode||{};
         if (redrawId === data?.id) {
            this.getSelectedRowsCaseInfo([redrawId]).then((results) => {
               const [ result ] = results||[];
               if (result) {
                  // 재조회하는 데이터의 return type이 worklistItem으로 같더라도 oldfilm query 자체가 가공한 데이터(aggregation projection)이기 때문에 같은 쿼리로 가져오지 않으면 일부 데이터가 다른 문제가 있음
                  // 그래서 모든 키에 대한 redraw는 일단 주석처리 하고 판독 관련 데이터만 redraw 하도록 처리
                  this.approveOpinionisResult(results);
                  // const keys = Object.keys(data);
                  // keys.forEach(key => {
                  //    if (result[key]) {
                  //       if (key === "requestDtime" || key === "confirm") data[key] = CommonUtils.convertTimestampToDate(result[key]);
                  //       else data[key] = result[key];
                  //    }
                  //    this.gridOptions.api.redrawRows({row: data});
                  // })
               }
            });
         }
      });
   }

   getSelectedRowsCaseInfo(ids = []) {
      if (ids.length === 0) return;
      return new Promise((resolve) => {
         fetch(`/api/case/list?ids=${ids.join(",")}`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json"
            },
         }).then((response) => {
            if (response.ok) {
               response.json().then((httpResponse) => {
                  resolve(httpResponse);
               });
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }
}

window.customElements.define(GridOldfilm.is, GridOldfilm);
