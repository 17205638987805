/* eslint-disable no-unused-vars */
import {html, PolymerElement} from "@polymer/polymer/polymer-element";

import "@polymer/paper-tabs/paper-tabs";
import "@polymer/paper-tabs/paper-tab";
import "@polymer/iron-pages/iron-pages";
import "@polymer/paper-icon-button/paper-icon-button";
import "@vaadin/vaadin-icons/vaadin-icons";
import "@vaadin/vaadin-split-layout";

import "@hpacs/hh-image-report";

// child component
// import "./hh-image-report";
import "./hh-thumbnail";
import "./hh-clinical-info";
import "./hh-report";
import "./hh-report-oldfilm";
import "./hh-reading-template";
import "./hh-ai-report";

import CommonUtils from "../../public/resource/js/utils/common";
import mixinCommons from "../common-mixin";
import store from "../redux/store";
import { QueryKey } from "../redux/reducers/query.ts";
import {CommonActionType, DialogActionType, DialogType} from "../redux/reducers/common";
import {FilmboxActionType} from "../redux/reducers/filmbox";
import i18n from "../utils/i18n-utils";

/**
 * `hh-report-window`
 *
 *
 * @customElement
 * @polymer
 */
class HhReportWindow extends mixinCommons(PolymerElement) {
   static get is() {
      return "hh-report-window";
   }

   static get template() {
      return html`
         <style>
         :host {
            display: block;
            width: 470px;
            height: 100%;
            background-color: #2d333e;
         }

         .container {
            display: flex;
            flex: 1 1;
            flex-direction: column;
            width: 100%;
            height: 100%;
         }

         paper-tabs {
            --paper-tabs-selection-bar: {
               border-bottom: 3px solid #0087cb;
               color: #2baab1;
            };
            --paper-tabs-content: {
               font-weight: bold;
               color: #aaaaaa;
               font-size: 13px;
            };
            background-color: #252934;
            box-shadow: 0px 3px 9px -2px #000000;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            height: 30px;
         }

         paper-tabs paper-tab.iron-selected {
            color: #2baab1;
         }

         paper-tabs paper-tab[disabled] {
            color: #424242;
         }

         iron-pages {
            width: 100%;
            height: calc(100% - 30px);
            background-color: rgba(0, 0, 0, 0.10);
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
         }

         .container-header {
            display: flex;
            flex-direction: row;
            /*justify-content: space-between;*/
            align-items: center;
            padding-left: 10px;
            padding-right: 10px;
            background-color: #252934;
            height: 30px;
            font-weight: bold;
            font-size: 12px;
            box-shadow: 0px 3px 9px -2px #000000;
         }

         .report-setting-title {
            color: grey;
            width: 90px;
            text-align: center;
            white-space: nowrap;
         }

         .report-setting-patientInfo {
            color: #aaa;
            width: calc(100% - 90px);
            text-align: center;
         }

         paper-icon-button {
            width: 25px;
            height: 100%;
            padding: 5px;
            color: #aaa;
         }

         paper-icon-button[active] {
            color: #d9534f;
         }

         paper-icon-button[disabled] {
            color: #595959;
         }

         paper-icon-button:hover {
            /*color: #0087cb;*/
         }

         .class-oldFilmReportSelected{
            height: 25px;
            width: 100%;
            font-size: 13px;
            color: #aaaaaa;
            background-color: #282e38;
            padding: 1px 0px 1px 0px;
            display: flex;
            flex-direction: row;
         }

         .checkBox-style{
            font-size: 13px;
            color: #aaaaaa;
            --paper-checkbox-checked-color: #0087cb;
            --paper-checkbox-label-color: #aaaaaa;
            --paper-checkbox-unchecked-color: #4c5667;
            padding: 3px;
         }

         .iconSortBtn{
            margin: 0px 1px 0px 1px;
            font-size: 13px;
            color: #aaaaaa;
         }

         /*#patientInfoTabNonExpand{*/
         /*   display: none;*/
         /*}*/

         input{
            color: #aaa;
            height: 12px;
            font-weight: bold;
            font-size: 12px;
            background-color: #252934;
            border: 0px;
            align-items: center;
            text-align: -webkit-center;
         }
         .div1 {
            width: calc(100% - 10px);
            height: calc(100% - 10px);
         }
         .container-body {
            height:calc(100% - 30px);
            width: 100%;
         }
         .body-top {
            height: 300px;
            width: 100%;
            min-height: 210px;

            /* #17120 [HPACS] Radiology탭 thumbnail tooltip 안나오는 현상 */
            overflow: visible;
            position: relative;
            z-index: 1;
         }
         .contents-top {
            height: 100%;
            width: 100%;
         }
         .contents-top > div{
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
         }

         .contents-top > div > div {
            height: calc(100% - 10px);
            width: calc(100% - 10px);
         }

         .body-bottom {
            height: calc(100% - 300px);
            width: 100%;
            min-height: 237px;
            /*display: flex;*/
            /*justify-content: center;*/
            /*align-items: center; */
            /*background-color:#252934;*/
         }

         .div5 {
            width: calc(100% - 10px);
            height: calc(100% - 10px);
         }

         .container-body-split {
            height: 100%;
            width: 100%;
         }
         .div4 {
            width: calc(100% - 10px);
            height: calc(100% - 10px);
         }

         .body-bottom-split {
            width: 100%;
            height: 100%;
         }
         .body-top-split {
            width: 100%;
            height: 100%;
         }

         .body-bottom-left-child {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
         }

         .body-bottom-right {
            width: 100%;
            min-width: 395px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
         }

         .body-top-left {
            height: 100%;
            width: 100%;
            min-width: 250px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow-y: hidden
         }

         .body-top-right-first-child {
            height: 100%;
            width: 200px;
            min-width: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
         }

         .body-top-right-second-child {
            height: 100%;
            width: calc(100% - 200px);
            min-width: 250px;
            display: flex;
            justify-content: center;
            align-items: center;
         }

         .body-top-right-contents {
            width: 100%;
            height: calc(100% - 30px);
            display: flex;
            justify-content: center;
            align-items: center;
            background-color:rgba(0, 0, 0, 0.10);
         }

         .ai-report-div {
            overflow-y: auto;
         }

         .ai-report-div::-webkit-scrollbar  {
            width: 10px;
            height: 100%;
            background: #303030;
          }

          .ai-report-div::-webkit-scrollbar-thumb  {
            background: #6C6969;
            border-radius: 5px;
          }

          .popup {
             --iron-icon-height: 20px;
             --iron-icon-width: 20px;
          }
         </style>

         <div class="container">
            <div class="container-header">
               <div class="report-setting-title">
                  {{t("label.reportWindow")}}
               </div>
               <div class="report-setting-patientInfo">
                  <template is="dom-if" if="{{patient}}">
                     {{patient.id}}&nbsp{{patient.name}}&nbsp{{patient.sex}}&nbsp{{patient.age}}
                  </template>
               </div>
            </div>
            <div class="container-body" id="windowReport">
               <vaadin-split-layout id="containerBodySplit" class="container-body-split" orientation="vertical" theme="minimal">
                  <div id="splitBodyTop" class="body-top">

                     <vaadin-split-layout id="bodyTopSplit" class="body-top-split" theme="minimal">

                        <div id="bodyTopLeft" class="body-top-left">
                           <div id="div1" class="div1">
                              <paper-tabs id="tabIndex" selected="{{patientInfoTab}}" noink>
                                 <paper-tab>{{t("label.thumbnail")}}</paper-tab>
                                 <template id="aiReportTab" is="dom-if" if="{{isUseAi}}">
                                    <paper-tab>{{t("label.aiReport")}}</paper-tab>
                                 </template>
                                 <paper-tab>{{t("label.imageReport")}}</paper-tab>
                                 <template id="tabIndexTemplate" is="dom-if" if="{{!isExpand}}">
                                    <paper-tab id="clinicalInfoTab">{{t("label.clinicalInfo")}}</paper-tab>
                                    <paper-tab id="readingTemplateTab">{{t("label.readingTemplate")}}</paper-tab>
                                 </template>
                              </paper-tabs>
                              <iron-pages id="reportWindowPage" selected="{{patientInfoTab}}">
                                 <div class="contents-top">
                                    <div>
                                       <div>
                                          <hh-thumbnail id="hhThumbnail" log="{{log}}"></hh-thumbnail>
                                       </div>
                                    </div>
                                 </div>
                                 <template id="aiTabContents" is="dom-if" if="{{isUseAi}}">
                                    <div class="contents-top" >
                                       <div class="ai-report-div" id="divhhAiReport">
                                          <div>
                                             <hh-ai-report id="hhAiReport"></hh-ai-report>
                                          </div>
                                       </div>
                                    </div>
                                 </template>
                                 <div class="contents-top">
                                    <div id="divhhImageReport">
                                       <div>
                                          <hh-image-report id="hhImageReport" report-url="/api/facility/imagereport" image-url="{{imageUrl}}" style="height: 100%; width: 100%;"></hh-image-report>
                                       </div>
                                    </div>
                                 </div>

<!--                                 <template is="dom-if" if="{{!isExpand}}">-->
                                    <div class="contents-top">
                                       <div>
                                          <div>
                                             <hh-clinical-info id="hhClinicalInfo" selected-row="[[clinicalInfoRowId]]"></hh-clinical-info>

                                          </div>
                                       </div>
                                    </div>
                                    <div class="contents-top">
                                       <div>
                                          <div>
                                             <hh-reading-template id="hhReadingTemplate"></hh-reading-template>
                                          </div>
                                       </div>
                                    </div>
<!--                                 </template>-->

                              </iron-pages>
                           </div>
                        </div>

                        <div id="splitBodyTopRight">
                           <template id="bodyTopRightTemplate" is="dom-if" if="{{isExpand}}">
                              <vaadin-split-layout id="bodyTopRightSplit" style="width: 100%; height: 100%" theme="minimal">
                                 <div id="splitBodyTopRightFirst" class="body-top-right-first-child">
                                    <div id="div2" style="height: calc(100% - 10px); width: calc(100% - 10px);">
                                       <div style="height: 30px; display:flex; align-items: center; padding-left: 10px;background-color: #252934;font-weight: bold;font-size: 12px;box-shadow: 0px 3px 9px -2px #000000; color: #aaaaaa; font-size: 13px;">
                                          {{t("label.clinicalInfo")}}
                                       </div>
                                       <div class="body-top-right-contents">
                                          <div style="background-color: rgba(0,0,0,0.15); height: calc(100% - 10px); width: calc(100% - 10px); border-bottom-right-radius: 3px; border-bottom-left-radius: 3px;">
                                             <hh-clinical-info id="hhClinicalInfoExpend"  selected-row="[[clinicalInfoRowId]]"></hh-clinical-info>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div id="bodyTopRightSecondChild" class="body-top-right-second-child">
                                    <div id="div3" style="height: calc(100% - 10px); width: calc(100% - 10px);">
                                       <div style="height: 30px; display:flex; align-items: center; padding-left: 10px;background-color: #252934;font-weight: bold;font-size: 12px;box-shadow: 0px 3px 9px -2px #000000; color: #aaaaaa; font-size: 13px;">
                                          {{t("label.readingTemplate")}}
                                       </div>
                                       <div class="body-top-right-contents">
                                          <div style="background-color: rgba(0,0,0,0.15); height: calc(100% - 10px); width: calc(100% - 10px); border-bottom-right-radius: 3px; border-bottom-left-radius: 3px;">
                                             <hh-reading-template id="hhReadingTemplateExpand"></hh-reading-template>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </vaadin-split-layout>
                           </template>
                        </div>

                     </vaadin-split-layout>

                  </div>

                  <div id="bodyBottom" class="body-bottom">
                     <vaadin-split-layout id="bodyBottomSplit" class="body-bottom-split" theme="minimal">
                        <div id="splitBodyBottomLeft">
                           <template id="bodyBottomLeftTemplate" is="dom-if" if="[[isCheckReportOrExpand(isExpand, popupReport)]]">
                                 <div class="body-bottom-left-child">
                                    <div id="div4" class="div4">
                                       <div style="height: 30px; display:flex; align-items: center; padding-left: 10px;background-color: #252934;font-weight: bold;font-size: 12px;box-shadow: 0px 3px 9px -2px #000000; color: gray;">
                                          {{t("label.relatedReportList")}}
                                       </div>
                                       <div id="oldfilmList" style="background-color: rgba(0,0,0,0.15); height: calc(100% - 30px); border-bottom-right-radius: 3px; border-bottom-left-radius: 3px;">
                                          <hh-report-oldFilm id="allHhReportOldFilm"></hh-report-oldFilm>
                                       </div>
                                    </div>
                                 </div>
                           </template>
                        </div>
                        <div id="bodyBottomRight" class="body-bottom-right">
                           <div class="div5">
                              <div class="container-header">
                                 <div class="report-setting-title" style="width: 50px">
                                    {{t("label.report")}}
                                 </div>
                                 <div class="report-setting-patientInfo" style="width: calc(100% - 50px);">
                                    {{patientInfo}}
                                 </div>
                                 <div class="report-setting-title" style="float: right; width: 25px;">
<!--                                    <iron-icon class="popup" icon="vaadin:external-link"></iron-icon>-->
                                    <paper-icon-button id="report_btn_popup" class="popup" icon="vaadin:external-link" title={{t("label.openReportWindow")}} on-click="openReportWindow"></paper-icon-button>
                                 </div>
                              </div>
                              <div style="width: 100%; height: calc(100% - 30px);">
                                 <div style="height: 100%; width: 100%;">
                                    <hh-report id="report" log="[hh-report-window]"></hh-report>
                                 </div>
                              </div>

<!--                              <paper-tabs selected=0 noink>-->
<!--                                 <paper-tab>Report</paper-tab>-->
<!--                              </paper-tabs>-->
<!--                              <iron-pages selected=0>-->
<!--                                 <div style="height: 100%; width: 100%;">-->
<!--                                    <hh-report id="report"></hh-report>-->
<!--                                 </div>-->
<!--                              </iron-pages>-->
                           </div>
                        </div>
                     </vaadin-split-layout>

                  </div>

               </vaadin-split-layout>
            </div>
         </div>
      `;
   }

   static get properties() {
      return {
         category: {
            type: Number
         },
         thumbnailRowId: {
            type: String,
            observer: "onSelectThumbnail"
         },
         clinicalInfoRowId: {
            type: String,
            observer: "onSelectClinicalInfo"
         },
         isExpand: {
            type: Boolean,
            value: false,
            observer: "handleExpand"
         },
         isUseAi: {
            type: Boolean,
            value: false
         },
         parentWidth: {
            type: Number,
            value: -1
         },
         patientInfoTab: {
            type: Number,
            value: 0
         },
         g_SelectedRow: {
            type: Object,
            observer: "onSelectRow"
         },
         g_nonExpandIndex: {
            type: Number,
            value: 0
         },
         g_expandIndex: {
            type: Number,
            value: 0
         },
         g_readingTemplateOpinion: { // readingTemplate doubleClick opinion
            type: Object,
            value: {},
            observer: "onReadingTemplateOpinion"
         },
         g_readingTemplateCheckbox: { // readingTemplate sensitive checkbox
            type: Array,
            value: [],
            observer: "onReadingTemplateSensitive"
         },
         isExpandEventListener: {
            type: Object,
            value: {
               bottomLeft: false,
               topRight: false
            }
         },
         splitSize: {
            type: Object,
            value: {
               "splitNewFilmOldFilm": "839",
               "splitFilmOpinion": "470",
               "splitClinicalReport": "140",
               "splitBodyTop": "300",
               "splitBodyTopRight": "450",
               "splitBodyTopRightFirst": "200",
               "splitBodyBottomLeft": "305",
               "splitFindings": null,
               "splitConclusion": null
            }
         },
         imageUrl: {
            type: String
         },
         // g_oldfilmList: {
         //    type: Array,
         //    value: [],
         //    observer: "onGetRelatedWorklistSuccess"
         // },
         patientInfo: {
            type: String,
            value: ""
         },
         popupReport: {
            type: Boolean,
            value: false,
            observer: "_changePopupReport"
         },
         _url: {
            type: String
         },
         isPopupPin: { // #17744 report popup pin 여부
            type: Boolean,
            value: true
         },
         popupReportName: {
            type: String
         },
         log: {
            type: String,
            value: "hh-report-window"
         },
         readingTemplateList: {
            type: Array,
            value: () => {
               return [];
            },
            // observer: "onChangeReadingTemplate"
         },
         _reportRow: { // open on new report
            type: Object,
            observer: "_selectedReportRowChanged"
         },
         userLayout: {
            type: Object,
            observer: "changeUserLayout"
         }
      };
   }

   static get observers() {
      return [
         "onChangeReadingTemplate(readingTemplateList)"
      ];
   }

   constructor() {
      super();

      // eslint-disable-next-line no-undef
      this.imageUrl = __CDN_URL__;
   }

   ready() {
      super.ready();

      store.subscribe(() => {
         this.category = store.getState().common.category;
         this.userLayout = store.getState().common.userConfig?.layout;
         this.popupReport = store.getState().common.popupReport?.open;
         this.g_SelectedRow = store.getState().worklist.row?.detail;
         this.thumbnailRowId = store.getState().report.thumbnailRow;
         this.clinicalInfoRowId = store.getState().report.clinicalInfoRow;
         this._reportRow = store.getState().report.reportRow;
         this.g_readingTemplateCheckbox = store.getState().report.readingTemplateSensitive;
         this.g_readingTemplateOpinion = store.getState().report.readingTemplateOpinion;
         const readingTemplateResult = store.getState().query[QueryKey.GET_READING_TEMPLATE];
         if(readingTemplateResult?.result) {
            this.readingTemplateList = readingTemplateResult?.result;
         }
      });

      this._url = document.location.href;

      // #17120 [HPACS] Radiology탭 thumbnail tooltip 안나오는 현상
      const style = ":host { overflow: visible !important; }";
      const styleElem = document.createElement("style");
      styleElem.appendChild(document.createTextNode(style));
      this.$.bodyTopSplit.shadowRoot.append(styleElem);

      this.getUserStyle().then((result) => {
         if (!result.layout) {
            this.updateSplitLayout(this.splitSize).then(() => this.getUserStyle());
         }
      }).catch((err) => {
         console.info(err);
      });

      this.$.tabIndexTemplate.addEventListener("dom-change", () => {
         if(this.isExpand) {
            this.patientInfoTab = this.g_expandIndex;
         } else {
            this.patientInfoTab = this.g_nonExpandIndex;
         }
      });

      this.$.bodyBottomLeftTemplate.addEventListener("dom-change", () => {
         if(this.isExpand) {
            if(!this.isExpandEventListener.bottomLeft) {
               this.isExpandEventListener.bottomLeft = true;
            }
         }
      });

      this.$.bodyTopRightTemplate.addEventListener("dom-change", () => {
         // row가 선택되지 않았을 경우 readingTemplate의 select checkbox 초기화
         // this.gridOptions.api.setSuppressRowDrag(드래그 아이콘 on/off)가 실행되지 않기 때문
         if(!this.g_SelectedRow) this.g_readingTemplateCheckbox = [];

         if(this.isExpand) {
            if(this.clinicalInfoRowId) this.shadowRoot.querySelector("#hhClinicalInfoExpend").fetchClinicalInfo(this.clinicalInfoRowId);

            if(!this.isExpandEventListener.topRight) {

               this.shadowRoot.querySelector("#bodyTopRightSplit").addEventListener("splitter-dragend", (e) => {
                  const splitBodyTopRightFirst = e.target.querySelector("#splitBodyTopRightFirst");
                  this.updateSplitLayout({"splitBodyTopRightFirst" : splitBodyTopRightFirst.offsetWidth}).then(() => this.getUserStyle());
               });

               this.isExpandEventListener.topRight = true;
            }

            // readingTemplate checkbox 세팅
            this.shadowRoot.querySelector("#hhReadingTemplateExpand").setSensitiveCheckedExpand(this.g_readingTemplateCheckbox);

            this.shadowRoot.querySelector("#splitBodyBottomLeft").style.flex = `1 1 ${this.splitSize.splitBodyBottomLeft}px`;
            this.shadowRoot.querySelector("#splitBodyTopRight").style.flex = `1 1 ${this.splitSize.splitBodyTopRight}px`;
            this.shadowRoot.querySelector("#splitBodyTopRightFirst").style.width = `${this.splitSize.splitBodyTopRightFirst}px`;
            this.shadowRoot.querySelector("#bodyTopRightSecondChild").style.width = `calc(100% - ${this.splitSize.splitBodyTopRightFirst}px)`;
         } else {
            this.$.hhReadingTemplate.setSensitiveCheckedExpand(this.g_readingTemplateCheckbox);
         }
      });

      this.$.containerBodySplit.addEventListener("iron-resize", () => {
         // NOTE: 2020/09/01 By Jacob - split 과 report 영역의 높이를 계산하여 report의 scroll 유무를 판단함.
         this.$.report.changeFixedHeight(this.isReportScroll());

         this.resize();
      });

      this.$.bodyTopSplit.addEventListener("iron-resize", () => {
         this.resize();
      });

      this.$.containerBodySplit.addEventListener("splitter-dragend", (e) => {
         const splitBodyTop = e.target.querySelector("#splitBodyTop").offsetHeight;
         const {splitFindings, splitConclusion} = this.$.report.getReportHeight();
         this.updateSplitLayout({
            splitBodyTop,
            "isReportScroll": this.isReportScroll(),
            splitFindings,
            splitConclusion
         }).then(() => this.getUserStyle());
      });

      this.$.bodyBottomSplit.addEventListener("iron-resize", (e) => {
         this.adjustTextareaHeights();
      });

      this.$.bodyBottomSplit.addEventListener("splitter-dragend", (e) => {
         const splitBodyBottomLeft = e.target.querySelector("#splitBodyBottomLeft");
         this.updateSplitLayout({"splitBodyBottomLeft" : splitBodyBottomLeft.offsetWidth}).then(() => this.getUserStyle());
      });

      this.$.bodyTopSplit.addEventListener("splitter-dragend", (e) => {
         const splitBodyTopRight = e.target.querySelector("#splitBodyTopRight");
         this.updateSplitLayout({"splitBodyTopRight" : splitBodyTopRight.offsetWidth}).then(() => this.getUserStyle());
      });

      this.addEventListener("iron-resize", () => {
         this._onIronResize();
      });

      this.$.reportWindowPage.addEventListener("iron-select", (e) => {
         if (e.detail.item.className === "reading-content iron-selected") {
            this.$.hhImageReport.onLoadBody();
            this.$.hhThumbnail.tabStartBody();
         }
      });

      this.$.hhThumbnail.addEventListener("toastEvent", (e) => {
         this.dispatchEvent(new CustomEvent("toastEvent", e));
      });

      // this.$.report.addEventListener("printOpenEvent", () => {
      //    this.getImage();
      // });

      this.$.report.addEventListener("reportSplitChangedEvent", (e) => {
         const {splitFindings, splitConclusion} = this.$.report.getReportHeight();
         this.updateSplitLayout({
            splitFindings,
            splitConclusion
         }).then(() => this.getUserStyle());
      });

      this.$.hhImageReport.addEventListener("setImageReportEvent", (e) => {
         this.dispatchEvent(new CustomEvent("setImageReportEvent", {bubbles: true, composed: true, detail: e.detail}));
      });

      this.$.hhImageReport.addEventListener("setStructImageEvent", (e) => {
         this.$.report.setStructImage(e.detail); // db에 저장될 imageReport
      });

      // clinicalInfo, thumbnail, aiReport...tab
      this.$.tabIndex.addEventListener("click",(e) => {
         // const param = {};
         // param.detail = e.currentTarget.selected;
         this.dispatchEvent(new CustomEvent("hhReportWindowTabIndexChangeEvent", {bubbles: true, composed: true, detail: e.currentTarget.selected}));

         this.resize();
      });

      this.checkUseAI();

      window.addEventListener("message", (e) => {

         switch ((e.data || {}).event) {
         case "POPUP_REPORT_PRINT": {
            this.getStructImage();
            this.getImage();
            break;
         }
         default:
            break;
         }
      });

      window.addEventListener("reportPrintEvent", () => {
         this.getStructImage();
         this.getImage();
      });

      const links = [
         {id:"$report_btn_popup", value: this.$.report_btn_popup},
      ]; window.__element_refers__ = window.__element_refers__ ? [...window.__element_refers__, ...links] : links;

      window.addEventListener("beforeunload", () => {
         window.postMessage({close: true}, document.location.href);
         window.postMessage({event: "READING_OFF"}, document.location.href);
         store.dispatch({ type: FilmboxActionType.CLOSE_FILMBOX });
      });

      // 브라우저 새로고침, 뒤로가기, 닫기
      window.onbeforeunload = (e) => {
         // 판독문 검사
         const status = this.checkClear();
         if(status && !status.isConfirm) {
            const evt = e || window.event;
            // For IE and Firefox
            // if(evt) evt.returnvalue = i18next.t("msg.leavingPage");
            if(evt) evt.returnvalue = this.t("msg.leavingPage");
            // For Safari
            return this.t("msg.leavingPage");
         }
      };

      // 로그아웃
      window.addEventListener("LOGOUT_EVENT", () => {
         if(!this.popupReport) {
            // 판독문 검사
            const status = this.checkClear();
            if(!status.isConfirm) {
               const message = {
                  title: i18n("label.logout"),
                  contents: i18n("msg.confirmLogout", { returnObjects: true }),
                  ok: i18n("button.logout"),
                  cancel: i18n("button.cancel"),
                  onOk: () => {
                     this.dispatchEvent(new CustomEvent("LOGOUT_OK", {bubbles: true, composed: true}));
                  },
               };
               store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, actionType: DialogActionType.LOGOUT, message, open: true } });
            } else {
               this.dispatchEvent(new CustomEvent("LOGOUT_DIALOG", {bubbles: true, composed: true}));
            }
         } else {
            window.postMessage({event: "POPUP_CHECK_LOGOUT"}, document.location.href);
            const reportPopup = window.report.getReport();
            // #17812 [HPACS] Report popup pin 버튼 비활성시 워크리스트에 포커스가 가있는 경우 팝업이 뒤로 숨지않음
            // 1. macro가 아닐때, 2. popupPin = true 일때
            if(reportPopup) window.open("","reportWindow");
         }
      });
   } // ready

   // todo : #21699 Expand가 아닐때 Resize 아이콘 나오는 현상 React 변환때 삭제 필요
   handleExpand(expand) {
      if (expand) {
         this.shadowRoot.querySelector("#bodyBottomSplit").shadowRoot.querySelector("#splitter").style.cursor = "ew-resize";
      } else {
         this.shadowRoot.querySelector("#bodyBottomSplit").shadowRoot.querySelector("#splitter").style.cursor = "auto";
      }
   }

   onSelectRow(row) {
      if(!row) this.clearReportWindow();

      if (this.category === 0 && row) {
         const {id} = row;
         this.setPatientInfo(row);
         // this.setReportPatientInfo(row);
         this.setClinicalInfo(id);

         // store.dispatch({ type: CommonActionType.CLEAR_POPUP_REPORT, payload: {open: this.popupReport, editOpinion: {}} });
      }
   }

   onSelectThumbnail(id) {
      if (this.category === 0 && id) {
         this.setThumbnail(id);
         this.setAiReportById(id);
         this.getStructImageReportById(id);
         if(this.popupReport) this.openReportWindow();
      }
   }

   onSelectClinicalInfo(id) {
      if (this.category === 0 && id) {
         this.setClinicalInfo(id);
      }
   }

   _selectedReportRowChanged(row = {}, old) {
      if(this.category === 0 && row.detail) {
         if (row.detail.id === old?.detail?.id) return; // 이미 설정된 정보라면 return
         this.setReportPatientInfo(row.detail);
      }
   }

   checkUseAI() {
      const userInfo = JSON.parse(localStorage.getItem("user"));

      if (!userInfo.activeAi) {
         this.isUseAi = false;
      } else {
         this.isUseAi = true;
      }
   }

   getPurchaseAiList() {
      return new Promise((resolve, reject) => {
         fetch(`/api/market/list`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")
            }
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((httpResponse) => {
                  resolve(httpResponse);
               });
            }
            else {
               reject(new Error(`Error : ${response.status}`));
            }
         });
      });
   }

   setAiReportById(id) {
      if (this.isUseAi) {
         const aiReportResults = [];

         return new Promise((resolve, reject) => {
            fetch(`/api/market/report/result/${id}`, {
               method: "GET",
               headers: {
                  "Authorization": localStorage.getItem("jwt")
               }
            }).then((response) => {
               if (response.ok && response.status === 200) {
                  response.json().then((httpResponse) => {
                     for(let i = 0; i < httpResponse.length; i++) {
                        const tempObject = {
                           conclusion: "",
                           finding: "",
                           recommendation: ""
                        };
                        if (httpResponse[i].report) {
                           tempObject.conclusion = httpResponse[i].report.conclusion;
                           tempObject.finding = httpResponse[i].report.finding;
                           tempObject.recommendation = httpResponse[i].report.recommendation;
                           tempObject.aiName = httpResponse[i].aiName;

                           aiReportResults.push(tempObject);
                        }
                     }

                     if (aiReportResults.length >= 1) {
                        this.patientInfoTab = 1;
                     }

                     this.shadowRoot.querySelector("#hhAiReport").aiReportResults = aiReportResults;
                  });
               }
               else {
                  reject(new Error(`Error : ${response.status}`));
               }
            });
         });
      }
   }

   isCheckReportOrExpand(isExpand, popupReport) {
      return isExpand || popupReport;
   }

   isReportScroll() {
      const reportMinHeight = this.$.report.getReportMinHeight() + 30 + 10;  // report minHeight + title(30) + padding(10)
      const bodyBottomHeight = this.$.bodyBottom.offsetHeight;
      if(reportMinHeight > bodyBottomHeight) return true;
      if(reportMinHeight <= bodyBottomHeight) return false;
   }

   resize() {
      if(this.category === 0 && this.g_SelectedRow && !CommonUtils.isEmptyObject(this.g_SelectedRow)) {
         this.$.hhThumbnail.resizeCanvas();
         this.$.hhThumbnail.redrawCellThumbnail();

         this.$.hhImageReport.onResizeWindow();
      }
   }

   updateSplitLayout(layout) {
      return new Promise((resolve, reject) => {
         fetch(`/api/user/option/style/layout`, {
            method: "PATCH",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json"
            },
            body: JSON.stringify(layout)
         }).then((response) => {
            if (!response.ok) {
               console.debug(new Error(`${response.status} ${response.statusText}`));
               reject(new Error(`${response.status} ${response.statusText}`));
            } else {
               resolve(true);
            }
         });
      });
   }

   getUserStyle() {
      return new Promise((resolve, reject) => {
         fetch(`/api/user/option/style`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")
            }
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((rows) => {
                  store.dispatch({ type: CommonActionType.SET_USER_CONFIG, payload: rows });
                  resolve(rows);
               });
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }

   _onIronResize() {
      this.parentWidth = this.parentNode.offsetWidth;
   }

   _isEqualTo(source, target) {
      return source === target;
   }

   setTabIndex(tabIndex, expand){
      if(expand === 0){
         this.g_expandIndex = tabIndex.hhReportWindowExpand;
         // this.patientInfoTab = tabIndex.hhReportWindowExpand;
      }else if(expand === 1) {
         this.g_nonExpandIndex = tabIndex.hhReportWindowNonExpand;
         // this.patientInfoTab = tabIndex.hhReportWindowNonExpand;
      }else if(expand === 3){
         this.g_expandIndex = tabIndex;
      }else if(expand ===4){
         this.g_nonExpandIndex = tabIndex;
      }
   }

   setThumbnail(id) {
      this.$.hhThumbnail.fetchThumbnail(id);
   }

   getStructImageReportById(id){
      this.getStructImageById(id).then( (structImage) => {
         // console.trace("getStructImageReportById", id, structImage);
         this.$.hhImageReport.setStructImage(structImage);
      });
   }

   clearImageReport(){
      this.$.hhImageReport.clearCanvas();
   }

   clearThumbnail() {
      this.$.hhThumbnail.clearThumbnail();
   }

   clearClinicalInfo() {
      if(this.isExpand) this.shadowRoot.querySelector("#hhClinicalInfoExpend").clearClinicalInfo();
      this.$.hhClinicalInfo.clearClinicalInfo();
   }

   /**
    * function setReportPatientInfo
    * Report Title New or Related 환자 정보 viewing
    *
    * Create by minjeong on 2020/11/17 11:34 오전
    * @param {object} row
    * @return {string} patientInfo
    * */
   setReportPatientInfo(row = {}) {
      this.patientInfo = "";
      if(row === null) return "";

      const {patientID, patientName, studyDescription, studyDtime} = row;
      this.patientInfo = `${patientID || `N/A`}, ${patientName || `N/A`}, ${studyDescription || `N/A`}, ${studyDtime || `N/A`}`;

      return this.patientInfo;
   }

   // ////////////////////////////
   // ////  CLINICAL INFO   //////
   // ////////////////////////////

   getClinicalInfo(ccInfo) {
      if(!ccInfo && !this.thumbnailRowId) {
         this.openToast({ msg: "Please Checked ClinicalInfo",  isErr: true});
      }
      else {
         this.setClinicalInfoById(this.thumbnailRowId, ccInfo);
      }
   }

   // ////////////////////////////
   // //  READING TEMPLATE  ////
   // ////////////////////////////

   // ////////////////////
   // hh-report method //
   // ////////////////////

   checkClear(){
      return this.$.report.checkClear();
   }

   // 입력된 판독문에 추가 판독문 입력하기
   setCopyOpinion(opinion) {
      if(this.popupReport) {
         if(this.isPopupPin) {
            const reportPopup = window.report.getReport();
            // #17812 [HPACS] Report popup pin 버튼 비활성시 워크리스트에 포커스가 가있는 경우 팝업이 뒤로 숨지않음
            // 1. macro가 아닐때, 2. popupPin = true 일때
            if(!opinion.isMacro && reportPopup) window.open("","reportWindow");
         }

         window.postMessage({event: "POPUP_SET_COPY_OPINION", opinion}, document.location.href);
      } else {
         const reportPopup = window.report.getReport();
         const hhReport = !reportPopup ? this.$.report : reportPopup;

         if(hhReport.isActiveApproveContent() && hhReport.isActiveApproveContentTextArea()) {
            // 판독입력할 때 공백 또는 개행 문자를 덧붙여 달라는 요청을 구현할 때,
            // FLeader에서 매크로를 통해 'enter' 한번 -- 공백(" ") 추가, 'enter" 두번 -- 개행문자를 덧붙이는 기능이 있으나
            // FLeader 쪽에선 판독문을 넣을 때의 상태를 알 수 없기때문에 이 함수에서 결정해야한다.(#13982)
            const tryCatch = (f) => { try { return f(); } catch(e) { return ""; }; };
            const prepend = tryCatch( () => (typeof(opinion.prepend) === "string") ? opinion.prepend : "" );
            // 전체 내용이 아니라 문단에 따라 prepend를 붙일지 말지를 결정해야한다
            const safePrepending = (value) => {
               // 앞선 내용이 아무것도 없다면 어떤 요청이든 공백/개행은 붙이지 않는다
               if(String(value).length === 0) {
                  return "";
               }

               // 앞선 내용의 마지막 글자가 개행이면 공백은 붙이지 않는다.
               if(/\n$/.test(value)) {
                  return (prepend === " ") ? "" : prepend;
               }

               // 개행이 아니면 요청한대로 붙인다
               return prepend;
            };

            // WARN: findings vs. .finding
            if(opinion.finding) hhReport.findings += safePrepending(hhReport.findings) + opinion.finding;
            if(opinion.conclusion) hhReport.conclusion += safePrepending(hhReport.conclusion) + opinion.conclusion;
            if(opinion.recommendation) hhReport.recommendation += safePrepending(hhReport.recommendation) + opinion.recommendation;
            this.adjustTextareaHeights();
         }
         window.fulfilled();
      }
   }

   adjustTextareaHeights() {
      this.$.report.adjustTextareaHeights();
   }

   getImage() {
      return this.$.hhImageReport.getImageReport();
   }

   getStructImage() {
      return this.$.hhImageReport.getStructImage();
   }

   enableAddendumList() {
      this.$.report.enableAddendumList();
   }

   disableAddendumList() {
      this.$.report.disableAddendumList();
   }

   disabledAddendumAccordionList() {
      this.$.report.disabledAddendumAccordionList();
   }

   ableAddendumAccordionList(){
      this.$.report.ableAddendumAccordionList();
   }

   clearSelectedRowToReadingTemplate(){
      if(this.isExpand) {
         const el = this.shadowRoot.querySelector("#hhReadingTemplateExpand");
         if(el) el.clearSelectedRow();
      }
      this.$.hhReadingTemplate.clearSelectedRow();
   }

   setPatientInfo(row = {}) {

      this.patient = null;    // 기존 patinet 초기화
      if(row === null) return;

      const {patientID, patientName, patientSex, patientAge} = row;
      const patient = {};
      patient.id = this.nvl(patientID, "N/A");
      patient.name = this.nvl(patientName, "N/A");
      patient.sex = this.nvl(patientSex, "N/A");
      patient.age = this.nvl(patientAge, "N/A");
      this.patient = patient;
   }

   clearPatientInfo() {
      this.patient = null;
      this.patientInfo = null;
   }

   clearReportBtn() {
      this.$.report.initReportButton();
   }

   nvl(str, defaultStr) {
      let reStr = str;
      if(CommonUtils.isEmptyValue(reStr)) reStr = defaultStr;

      return reStr;
   }

   getImageReport(){
      this.getImage();
   }

   setExpand(){
      // console.log("setExpand: ", this.isExpand);
      this.isExpand = true;

      const {splitBodyTop, splitBodyTopRight, splitBodyBottomLeft, splitFindings, splitConclusion} = this.splitSize;

      if(splitFindings && splitConclusion) {
         this.$.report.rfTopHeight = `${splitFindings}px`;
         this.$.report.rfBottomHeight = `calc(100% - ${splitFindings}px)`;
         this.$.report.rsTopHeight = `${splitConclusion}px`;
         this.$.report.rsBottomHeight = `calc(100% - ${splitConclusion}px)`;
      }

      this.$.splitBodyTop.style.height = `${splitBodyTop}px`;
      this.adjustTextareaHeights();
      this.$.bodyBottom.style.height = `calc(100% - ${splitBodyTop}px)`;

      this.$.bodyTopLeft.style.width = `calc(100% - ${splitBodyTopRight}px)`; // 500 변수화.
      this.$.splitBodyTopRight.style.width = `${splitBodyTopRight}px`;
      this.$.splitBodyTopRight.style.minWidth = "450px";

      if(!this.popupReport) {
         this.$.bodyBottomRight.style.display = "flex";
         this.$.splitBodyBottomLeft.style.display = "block";
         this.$.bodyBottomRight.style.width = `calc(100% - ${splitBodyBottomLeft}px)`;
         this.$.splitBodyBottomLeft.style.width = `${splitBodyBottomLeft}px`;
         this.$.splitBodyBottomLeft.style.minWidth = "305px"; // 315 변수화.
         this.$.report.style.display = "block";
      } else {
         this.$.bodyBottomRight.style.display = "none";
         this.$.splitBodyBottomLeft.style.display = "block";
         this.$.splitBodyBottomLeft.style.width = "100%";
         this.$.report.style.display = "none";
      }

      this.$.hhImageReport.onLoadBody();
   }

   setNonExpand(){
      this.isExpand = false;

      const {splitBodyTop, splitFindings, splitConclusion} = this.splitSize;

      if(splitFindings && splitConclusion) {
         this.$.report.rfTopHeight = `${splitFindings}px`;
         this.$.report.rfBottomHeight = `calc(100% - ${splitFindings}px)`;
         this.$.report.rsTopHeight = `${splitConclusion}px`;
         this.$.report.rsBottomHeight = `calc(100% - ${splitConclusion}px)`;
      }

      this.$.splitBodyTop.style.height = `${splitBodyTop}px`;
      this.adjustTextareaHeights();
      this.$.bodyBottom.style.height = `calc(100% - ${splitBodyTop}px)`;

      this.$.bodyTopLeft.style.width = "100%";
      this.$.splitBodyTopRight.style.flex = "0";
      this.$.splitBodyTopRight.style.minWidth = null;

      if(!this.popupReport) {
         this.$.splitBodyBottomLeft.style.display = "none";
         this.$.bodyBottomRight.style.display = "flex";
         this.$.bodyBottomRight.style.width = "100%";
         this.$.splitBodyBottomLeft.style.minWidth = null;
         this.$.report.style.display = "block";
      } else {
         this.$.bodyBottomRight.style.display = "none";
         this.$.splitBodyBottomLeft.style.display = "block";
         this.$.splitBodyBottomLeft.style.width = "100%";
         this.$.bodyBottomRight.style.minWidth = null;
         this.$.report.style.display = "none";
      }
      this.$.hhImageReport.onLoadBody();

      this.patientInfoTab = this.g_nonExpandIndex;
   }

   openToast(detail) {
      this.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail}));
   }

   getStructImageById(id) {
      return new Promise((resolve, reject) => {
         fetch(`/api/exchange/worklist/struct/imageReport/${id}`, {
            method: "GET",
         }).then( (response) => {
            if (response.ok) {
               response.json().then( (httpResponse) => {
                  resolve(httpResponse);
               });
            } else {
               reject(new Error(`Error : ${response.status}`));
            }
         });
      });
   }

   setClinicalInfoById(id, ccInfo) {
      fetch(`/api/exchange/worklist/clinicalInfo/${id}`, {
         method: "PATCH",
         headers: {
            "Content-Type": "application/json"
         },
         body: JSON.stringify({requestDesc: ccInfo})
      }).then( (response) => {
         if(response.status === 204) {
            console.log("Please Checked ClinicalInfo");
         }
         else if (response.ok && response.status === 200) {
            console.log("ClinicalInfo Save Success");
         }
      });
   }

   openReportWindow() {
      // console.log("openReportWindow: ", this.popupReport);
      // open 시에만 연동되어야 함
      const opened = !!window.report.get();
      if (!opened) { // 새로 열린 경우만 동기화
         const status = this.checkClear();
         // popup 상태값 연결
         if(!status.isConfirm) store.dispatch({ type: CommonActionType.OPEN_POPUP_REPORT, payload: {open: true, editOpinion: status.editOpinion} });
         else store.dispatch({ type: CommonActionType.OPEN_POPUP_REPORT, payload: {open: true, editOpinion: {}} });
      }

      const url = this.thumbnailRowId ? `/report#${this.thumbnailRowId}` : "/report";

      // #17744 isPopupPin : true >> reportPopup이 최상단
      // #17744 isPopupPin : false >> worklist가 최상단 (명시적으로 enter를 누를 경우에만 판독창이 상단으로 오게끔 변경)
      if(this.popupReport && !this.isPopupPin && window.report.get()) {
         const {origin} = window.location;
         window.report.get().location.href = `${origin}${url}`;
      } else {
         this.popupReportName = window.open(url, "reportWindow", "resizable=yes,toolbar=no,status=0,location=no,menubar=no,scrollbars=0,dependent=yes");
      }
   }

   _changePopupReport(popup) {
      // console.log("_changePopupReport: ", this.popupReport, ", isExpand: ", this.isExpand);
      if(!popup && this.popupReportName) this.popupReportName.close();

      if (popup) {
         this.$.bodyBottomRight.style.display = "none";
         this.$.splitBodyBottomLeft.style.display = "block";
         this.$.splitBodyBottomLeft.style.width = "100%";
      } else if(this.isExpand) {
         this.setExpand();
      } else {
         this.setNonExpand();
      }
   }

   // #17757 [HPACS] 리포트팝업창 새로고침 이후 ROW 이동하게 되면 환자 정보 업데이트 되지 않음
   setEditOpinion(opinion) {
      this.$.report.setEditOpinion(opinion);
   }

   setClinicalInfo(id) {
      if(this.isExpand) this.shadowRoot.querySelector("#hhClinicalInfoExpend").fetchClinicalInfo(id);
      this.$.hhClinicalInfo.fetchClinicalInfo(id);
   }

   clearReportWindow() {
      this.clearClinicalInfo();
      this.clearThumbnail();
      this.clearImageReport();
      this.clearSelectedRowToReadingTemplate();
      this.clearPatientInfo();
      this.clearReportBtn();
   }

   onChangeReadingTemplate(l) {
      if(!CommonUtils.isEmptyArr(l)) {
         const el = this.shadowRoot.querySelector("#hhReadingTemplateExpand");
         if(el) el.getReadingTemplateList({readingTemplateList: this.readingTemplateList});
         this.$.hhReadingTemplate.getReadingTemplateList({readingTemplateList: this.readingTemplateList});
      }
   }

   onReadingTemplateOpinion(r = {}) {
      if(this.g_SelectedRow && Object.keys(this.g_SelectedRow).length > 0 && Object.keys(r).length > 0) this.setCopyOpinion(r);
   }

   onReadingTemplateSensitive(c = []) {
      if(this.isExpand) {
         // readingTemplate checkbox 세팅
         this.shadowRoot.querySelector("#hhReadingTemplateExpand").setSensitiveCheckedExpand(this.g_readingTemplateCheckbox);
      } else {
         this.$.hhReadingTemplate.setSensitiveCheckedExpand(this.g_readingTemplateCheckbox);
      }
   }

   changeUserLayout(layout) {
      if (layout) {
         const {splitBodyTop, splitBodyTopRight, splitBodyTopRightFirst, splitBodyBottomLeft, splitFindings, splitConclusion, isPopupPin} = layout;

         if (splitBodyTop) this.splitSize.splitBodyTop = splitBodyTop;
         if (splitBodyTopRight) this.splitSize.splitBodyTopRight = splitBodyTopRight;
         if (splitBodyTopRightFirst) this.splitSize.splitBodyTopRightFirst = splitBodyTopRightFirst;
         if (splitBodyBottomLeft) this.splitSize.splitBodyBottomLeft = splitBodyBottomLeft;

         // NOTE: 2020/09/01 By Jacob - report split height 적용
         if (splitFindings) this.splitSize.splitFindings = splitFindings;
         if (splitConclusion) this.splitSize.splitConclusion = splitConclusion;

         if (isPopupPin !== undefined) this.isPopupPin = isPopupPin;

         if (this.isExpand) {
            this.setExpand();
         } else {
            this.setNonExpand();
         }
      }
   }
}

window.customElements.define(HhReportWindow.is, HhReportWindow);

