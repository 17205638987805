/* eslint-disable no-param-reassign,no-prototype-builtins,no-unused-vars */
import {html, PolymerElement} from "@polymer/polymer/polymer-element";

import "ag-grid-polymer";
import "./components/healthhub-confirm-dialog";

import moment from "moment-timezone";
import GridUtils from "./utils/grid-utils";
import CommonUtils from "../public/resource/js/utils/common";
import DateFilterUtils from "../public/resource/js/utils/date-filter-utils";

import {CustomDateComponent} from "../public/resource/js/custom-date-component";
import {CustomDateFloatingComponent} from "../public/resource/js/custom-date-floating-component";
import mixinCommons from "./common-mixin";
import store from "./redux/store";
import {RelatedTechlistActionType} from "./redux/reducers/related-techlist";
import TechnicianUtils from "./utils/technician-utils";
import {CommonActionType, CustomContextMenuType, DialogActionType, DialogType} from "./redux/reducers/common";
import {TechlistActionType} from "./redux/reducers/techlist";
import i18n from "./utils/i18n-utils";

class GridOrder extends mixinCommons(PolymerElement) {
   static get template() {
      return html`
         <link rel="stylesheet" href="/vendor/ag-grid-enterprise/dist/styles/ag-grid.min.css">
         <link rel="stylesheet" href="/vendor/ag-grid-enterprise/dist/styles/ag-theme-balham-dark.min.css">
         <link rel="stylesheet" href="/resource/style/ag-grid-hpacs.css">
         <style>
            :host {
               display: block;
               height: 100%;
               width: 100%;
            }

            .class-container {
               height: 100%;
               width: 100%;
            }

            .contextMenuIcon {
               --iron-icon-width: 16px;
               --iron-icon-height: 16px;
            }

            #gridOrderList .ag-cell-data-changed {
               background-color: rgba(0, 139, 177, 0.5) !important;
            }

            .ag-set-filter-list {
               height: 85px !important;
            }

            ::-webkit-scrollbar {
               width: 15px;
               height: 15px;
            }

            ::-webkit-scrollbar-thumb {
               background-color: #6b6b6b;
               border-radius: 10px;
               background-clip: content-box;
               border: 3px solid rgba(255,255,255,0);
            }

            paper-input {
               --paper-input-container-input-color: #aaaaaa;
               --paper-input-container-focus-color: #0087cb;
               --paper-input-container-label: {
                  text-align: left;
                  font-size: 13px;
               };
            }

            .relative-date-filter-input {
               text-align: center;
            }

            paper-dropdown-menu, paper-listbox {
               --paper-input-container-focus-color: #0087cb;
               --paper-input-container-label: {
                  text-align: center;
                  color: #aaaaaa;
                  font-size: 13px;
               };
               --paper-input-container-input: {
                  text-align: center;
                  color: #aaaaaa;
                  font-size: 13px;
               };
               color: #aaaaaa;
               background: var(--ag-background-color,#2d3436);
            }

            paper-item {
               --paper-item-min-height: 30px;
               --paper-item-focused: {
                  color: #0087cb;
               };
               font-size: 13px;
            }

            .relative-date-filter-body {
                display: flex;
                justify-content: space-evenly;
                align-items: flex-end;
                width: 100%;
            }
         </style>

         <div class="class-container">
            <ag-grid-polymer
                  id="gridOrderList"
                  class="ag-theme-balham-dark"
                  gridOptions="{{gridOptions}}"
                  columnDefs="{{columnDefs}}"
            ></ag-grid-polymer>
         </div>
         <hh-confirm-dialog id="hhOrderlistConfirmDialog"></hh-confirm-dialog>
      `;
   }

   static get properties() {
      return {
         // _selectedStudyRow: {
         //    type: Object,
         //    observer: "onSelectTechRow"
         // },
         category: {
            type: Number,
            observer: "onSelectCategory"
         },
         gridApi: {
            type: Object,
            value: {}
         },
         gridOptions: {
            type: Object,
            value: {}
         },
         _utcOffset: {
            type: Number
         },
         _selectedRows: {
            type: Array,
            value: []
         },
         _selectedTechlist: {
            type: Object,
            // value: [],
            observer: "onSelectTechlist"
         },
         _altKey: {
            type: Boolean,
            value: false
         },
         // orderCnt: {
         //    type: Number,
         //    value: 0
         // },
         columnDefs: {
            type: Array,
            value: []
         },
         isDisabledMatch: {
            type: Boolean,
            value: true
         },
         isDisabledUnMatch: {
            type: Boolean,
            value: true
         },
         matchMsgCode: {
            type: String
         },
         _filters: {
            type: Object,
            value: {}
         },
         createdRelativeDateFilter: {
            type: Object,
            value: {
               scheduledDtime: false,
               requestDtime: false
            }
         },
         appliedDateFilters: {
            type: Object,
            value: {}
         },
         redrawOrderIds: {
            type: Array,
            observer: "changeRedrawOrderIds"
         },
         refreshOrder: {
            type: Boolean,
            value: false,
            observer: "changeRefreshOrder"
         },
         componentType: {
            type: String
         },
         customContextMenuState: {
            type: Number
         }
      };
   }

   constructor() {
      super();

      this._utcOffset = this.utcCheck();

      this.gridOptions = {
         defaultColDef: {
            suppressMenu: true,
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filterParams: {
               newRowsAction: "keep"
            },
            suppressKeyboardEvent: params => GridUtils.disableRowDeselectionBySpace(params),
         },
         rowModelType: "serverSide",
         serverSideStoreType: "partial",
         animateRows: true,
         components: {
            agDateInput: CustomDateComponent,
            customDateFloatingFilter: CustomDateFloatingComponent
         },
         // floatingFilter: true,
         rowSelection: "single",
         sideBar: {
            toolPanels: [
               {
                  id: "columns",
                  labelDefault: this.t("label.showHideColumns"),
                  labelKey: "columns",
                  iconKey: "columns",
                  toolPanel: "agColumnsToolPanel",
                  toolPanelParams: {
                     suppressRowGroups: true,
                     suppressValues: true,
                     suppressPivots: true,
                     suppressPivotMode: true,
                     suppressSideButtons: true,
                     suppressColumnFilter: true,
                     suppressColumnSelectAll: true,
                     suppressColumnExpandAll: true,
                  }
               }
            ]
         },
         postProcessPopup: (params) => {
            if (params.type !== "columnMenu") return;

            const columnId = params.column.getId();
            const {ePopup} = params;

            if(columnId === "scheduledDtime" || columnId === "requestDtime") {
               let count = 0;
               const interval = setInterval(() => {
                  if(!this.createdRelativeDateFilter[columnId]) {
                     const filterBody = ePopup.querySelector(".ag-filter-body");
                     const filterButton = ePopup.querySelector(".ag-filter-apply-panel");
                     if (filterBody && filterButton) {
                        clearInterval(interval);

                        DateFilterUtils.renderRelativeDateFilter(ePopup, () => {
                           const floatingFilter = this.$.gridOrderList.querySelector(".ag-popup"); // 팝업이 한번 닫힌 후 ePopup 에는 하위 element 값이 없어서 다시 불러줌
                           const filterModel = this.getFilterModelIncludeDateFilter();
                           DateFilterUtils.applyRelativeDateFilter(floatingFilter, columnId, filterModel, (filterModel) => {
                              this.setFilter(filterModel);
                           });
                        }, () => {
                           const floatingFilter = this.$.gridOrderList.querySelector(".ag-popup"); // 팝업이 한번 닫힌 후 ePopup 에는 하위 element 값이 없어서 다시 불러줌
                           const filterModel = this.getFilterModelIncludeDateFilter();
                           DateFilterUtils.clearRelativeDateFilter(floatingFilter, columnId, filterModel, (filterModel) => {
                              this.setFilter(filterModel);
                           });
                        }, () => {
                           const filterInstance = this.gridApi.getFilterInstance(columnId);
                           DateFilterUtils.setDateFilterComponent(ePopup, filterInstance, this.appliedDateFilters[columnId]);
                        });
                        this.createdRelativeDateFilter[columnId] = true;
                     }
                  } else {
                     const customFilter = ePopup.querySelector(".relative-date-filter-body");
                     const customButton = ePopup.querySelector(".relative-date-filter-button");
                     const applyButton = ePopup.querySelector(".ag-filter-apply-panel-button");
                     const relativeDateFilterInput = ePopup.querySelector(".relative-date-filter-input");
                     const relativeDateFilterList = ePopup.querySelector(".relative-date-filter-list");
                     if(customFilter && customButton && applyButton && relativeDateFilterInput && relativeDateFilterList) {
                        clearInterval(interval);

                        const filterInstance = this.gridApi.getFilterInstance(columnId);
                        DateFilterUtils.setDateFilterComponent(ePopup, filterInstance, this.appliedDateFilters[columnId]);
                     }
                  }
                  if(this.createdRelativeDateFilter[columnId] || count > 100) {
                     clearInterval(interval);
                  }
                  count++;
               }, 100);
            }
         },
         overlayNoRowsTemplate: `<span style='font-size: 13px'>${this.t("label.noRecordsFound")}</span>`,
         cacheBlockSize: 50,
         tooltipShowDelay: 0,
         navigateToNextCell: params => this.navigateToNextCell(params),
         // onGridReady: params => this.onGridReady(params),
         onRowClicked: e => this.onRowClicked(e),
         onRowSelected: evt => this.onRowSelected(evt),
         onRowDoubleClicked: e => this.onRowDoubleClicked(e),
         getContextMenuItems: params => this.getContextMenuItems(params),
         onFilterModified: (e) => {
            const columnId = e.column.getId();
            if(columnId === "scheduledDtime" || columnId === "requestDtime") {
               const floatingFilter = this.$.gridOrderList.querySelector(".ag-popup");
               const filterInstance = this.gridApi.getFilterInstance(e.column.colId);
               DateFilterUtils.setDateFilterComponent(floatingFilter, filterInstance);
            }
         },
         onFilterChanged: (v) => {
            const filterModel = this.gridApi.getFilterModel();

            // global applied date filter 정리
            Object.entries(this.appliedDateFilters).forEach(([columnId, {type: globalType, dateFrom: globalDateFrom}]) => {
               // - global date filter 에는 있는데 현재 적용된 filter model 에 없거나
               // - 적용된 filter model 에 있는데 global filter 와 타입이 맞지 않거나
               // - global filter 에 today 인데 적용된 filter 와 date 가 다른 경우
               if(!filterModel[columnId]
                  || (filterModel[columnId] && filterModel[columnId].type !== globalType)
                  || (filterModel[columnId] && filterModel[columnId].type === "equals" && !moment(filterModel[columnId].dateFrom).isSame(moment(globalDateFrom)))) {
                  delete this.appliedDateFilters[columnId];
               }
            });
         }
      };

      this.gridOptions.onColumnMoved = () => {
         this.gridOptions.onDragStopped = () => {
            const tempArr = this.gridOptions.columnApi.columnController.gridColumns;

            const headerArr = tempArr.reduce((acc, {colDef}) => {
               acc.push(colDef);
               return acc;
            },[]);

            this.updateGridHeader(headerArr);
         };
      };


      this.gridOptions.onColumnResized = (v) => {
         if(v.finished) {
            const tempArr = this.gridOptions.columnApi.columnController.gridColumns;

            const headerArr = tempArr.reduce((acc, {colDef, actualWidth}) => {
               const newColDef = colDef;
               newColDef.width = actualWidth;
               acc.push(newColDef);
               return acc;
            },[]);

            this.updateGridHeader(headerArr);
         };
      };

      this.gridOptions.onColumnVisible = () => {
         const tempArr = this.gridOptions.columnApi.columnController.gridColumns;

         const headerArr = tempArr.reduce((acc, {colDef, visible}) => {
            const isHide = visible ? Boolean(false) : Boolean(true);
            const newColDef = colDef;
            newColDef.hide = isHide;
            acc.push(newColDef);
            return acc;
         },[]);

         this.updateGridHeader(headerArr);
      };

      this.gridOptions.onGridReady = (params) => {
         this.gridApi = params.api;
         // this.setDefaultFilterModel();
         params.api.setServerSideDatasource(this.enterpriseDatasource());
      };

      this.gridOptions.onCellKeyDown = (param) => {
         const { code, ctrlKey, metaKey } = param.event;
         const { userAgent } = window.navigator;
         const [ isWindow, isMac ] = [ /Windows/.test(userAgent), /Mac OS/.test(userAgent)];

         // #16447 [HWP-UT-W-001] Worklist 단축키 관련
         if(isWindow && ctrlKey || isMac && metaKey) {
            const centerViewPort = this.gridApi.gridPanel.eCenterViewport;

            if(code === "ArrowLeft") centerViewPort.scrollLeft = 0;
            if(code === "ArrowRight") centerViewPort.scrollLeft = centerViewPort.scrollWidth;
         }
      };
   };

   ready() {
      super.ready();

      store.subscribe(() => {
         if (this.isPopup()) return;
         this.category = store.getState().common.category;
         this._selectedTechlist = store.getState().techlist.row;
         this.redrawOrderIds = store.getState().relatedTechlist.redrawOrderIds;
         this.refreshOrder = store.getState().relatedTechlist.refreshOrder;
         this.customContextMenuState = store.getState().common.customContextMenu;
      });

      document.addEventListener("click", () => {
         if (this.customContextMenuState !== undefined) store.dispatch({ type: CommonActionType.HIDE_CONTEXT_MENU });
      });

      // this.$.gridOrderList.addEventListener("click", (e) => {
      //    if(e.altKey){
      //       this._altKey = true;
      //       // return false;
      //    }
      // });

      // /**
      //  * grid-study -> grid-order
      //  * grid-study의 선택 row가 초기화 괴면 grid-order에서 갖고 있는 study row도 초기화 시켜준다.
      //  */
      // document.addEventListener("clearStudyRowsInOrder", () => {
      //    this._selectedTechlist = {};
      // });
   }

   onSelectTechlist(selected) {
      if (this.category !== 1) return;

      this.getOrderMatchStatusCode(); // context match status 재설정

      if (!selected) return;
      const { detail: row, rows, altKey } = selected;

      if (!altKey) {
         // tech row 선택시 matching 된 order 선택
         if (row?.isMatch === true) {
            this.selectedRowToMatched(row);
         } else {
            this.clearSelectedRow(); // this.diabledSelectedRows();
         }
      }
   }

   onSelectCategory(category) {
      this.purgeEnterpriseCache();
      this.clearSelectedRow();
   }

   // #16581 updateGridHeader로 통합
   // updateGridHeader(headerArr) {
   //    this.getUserStyle().then((result) => {
   //       this.updateGridOrderFilm(result.id, headerArr);
   //    }).catch((err) => {
   //       console.info(err);
   //       this.gridApi.setColumnDefs(headerArr);
   //    });
   // }

   updateGridHeader(headerArr) {
      const type = "orderFilm";
      fetch(`/api/user/option/gridheader/${type}`, {
         method: "PATCH",
         headers: {
            "Authorization": localStorage.getItem("jwt"),
            "Content-Type": "application/json"
         },
         body: JSON.stringify(headerArr)
      }).then((response) => {
         if (!response.ok) {
            console.debug(new Error(`${response.status} ${response.statusText}`));
         }
      });
   }

   getUserStyle() {
      return new Promise((resolve, reject) => {
         fetch(`/api/user/option/style`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")
            }
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((rows) => {
                  resolve(rows);
               });
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }

   // #16581 updateGridHeader로 통합
   // updateGridOrderFilm(id, orderFilmArr) {
   //    fetch(`/api/user/option/orderFilm/${id}`, {
   //       method: "PATCH",
   //       headers: {
   //          "Authorization": localStorage.getItem("jwt"),
   //          "Content-Type": "application/json"
   //       },
   //       body: JSON.stringify(orderFilmArr)
   //    }).then((response) => {
   //       if (!response.ok) {
   //          console.debug(new Error(`${response.status} ${response.statusText}`));
   //       }
   //    });
   // }

   // applyFilter() {
   //    this.getOrderFilters().then((result) => {
   //       this.columnDefs.forEach((c) => {
   //          const v = result[c.field];
   //          // eslint-disable-next-line no-param-reassign
   //          if(v) c.filterParams.values = v;
   //       });
   //    });
   // }

   enterpriseDatasource() {
      let index = 0;
      return {
         getRows: (v) => {
            const params = v;
            const {filterModel} = params.request;

            if (index === 0) {
               this.getOrderFilters().then((result) => {
                  this._filters = result;

                  this.getUserStyle().then((result) => {
                     if (result.grid && result.grid.orderFilm && result.grid.orderFilm.length > 0) {
                        const {orderFilm} = result.grid;
                        // const columnDefs = this.changeFilterParams(this.cellRenderer(orderFilm));
                        const columnDefs = GridUtils.mergeFilterParams(orderFilm, this.createColumnDefs());
                        this.columnDefs = columnDefs;

                        params.api.setColumnDefs(columnDefs);
                     } else {
                        // const columnDefs = this.cellRenderer(this.createColumnDefs());
                        const columnDefs = this.createColumnDefs();
                        params.api.setColumnDefs(columnDefs);

                        this.columnDefs = columnDefs;
                        this.updateGridHeader(this.createColumnDefs());
                     }
                     this.setDefaultFilterModel();
                  }).catch((err) => {
                     console.info(err);
                     // const columnDefs = this.cellRenderer(this.createColumnDefs());
                     const columnDefs = this.createColumnDefs();
                     this.columnDefs = columnDefs;
                     params.api.setColumnDefs(columnDefs);
                     this.setDefaultFilterModel();
                  });
               });
            }


            params.request.filterModel = this.conversionFilter(filterModel);
            this.getOrderlist(params, index).then((result) => {
               if (index === 1) {
                  // this.applyFilter();
                  // this.setDefaultFilterModel();
               }
               params.success({
                  rowData: result.rows||[],
                  rowCount: result.lastRow
               });
               if ((result.rows||[]).length === 0) {
                  params.api.showNoRowsOverlay();
               } else {
                  params.api.hideOverlay();
               }
            }).catch((err) => {
               console.error(err);
               params.fail();
            });
            index++;
         }
      };
   }

   onRowClicked(e) {
      // console.log("-> [order] onRowClicked", e.event.altKey)

      this._altKey = e.event.altKey;
      // alt + click 인경우 study의 선택값을 초기화 하지 않는다.
      // if (!this._altKey) {
      //    this.dispatchEvent(new CustomEvent("studyCountClearEvent"));
      // }

      // #16192 같은 row 클릭시 이벤트 재호출
      /* this._selectedRows = e.api.getSelectedRows();
      if (e.node.selected && !this._altKey) {

         // const param = {};
         // param.detail = e.data;
         // this.dispatchEvent(new CustomEvent("onSelectionOrderChangedEvent", param));
         // store.dispatch({ type: RelatedTechlistActionType.SELECT_ORDER, payload: { row: e.data, rows: this._selectedRows } });

         // const param = {};
         // param.detail = this._selectedRows;
         // this.dispatchEvent(new CustomEvent("selectedOrderRowEvent", param));
      } */

      // if (Object.keys(this._selectedTechlist).length > 0 && this._altKey) {
      //    this.dispatchEvent(new CustomEvent("selectedOrderRowEvent", {detail: this._selectedRows}));
      // }
   };

   onRowSelected(evt) {
      // console.log("-> [order] onRowSelected", evt.node.selected)
      this._selectedRows = evt.api.getSelectedRows();
      if (evt.node.selected) {
         // evt.data -> evt.node.selected = true: selected row, evt.node.selected = false: deselected row
         // evt.api.getSelectedRows() -> evt.node.selected = true, false: selected rows
         if (!this.isPopup()) store.dispatch({ type: RelatedTechlistActionType.SELECT_ORDER, payload: { row: evt.data, rows: this._selectedRows, altKey: this._altKey } });
      } else {
         // eslint-disable-next-line no-lonely-if
         if (!this.isPopup() && this._selectedRows.length === 0) store.dispatch({ type: RelatedTechlistActionType.CLEAR_ORDER });
      }
      /* if (evt.node.selected && !this._altKey) {

         // #16116 order탭일 경우에는 Thumbnail 초기화를 할 필요가 없어서 주석처리함
         // this.dispatchEvent(new CustomEvent("clearTechTabWindowEvent"));

         // const param = {};
         // param.detail = evt.data;
         // this.dispatchEvent(new CustomEvent("onSelectionOrderChangedEvent", param));
         // store.dispatch({ type: RelatedTechlistActionType.SELECT_ORDER, payload: { row: evt.data, rows: this._selectedRows } });

         // const param = {};
         // param.detail = this._selectedRows;
         // this.dispatchEvent(new CustomEvent("selectedOrderRowEvent", param));
      } */

      // if (Object.keys(this._selectedTechlist).length > 0 && this._altKey) {
      //    this.dispatchEvent(new CustomEvent("selectedOrderRowEvent", {detail: this._selectedRows}));
      // }

      this._altKey = false;
   };

   onRowDoubleClicked(e) {}

   setRightSelectedRows(row) {
      // console.log("-> [order] setRightSelectedRows")
      // #16041 마우스 우클릭으로 row가 선택되게끔 추가
      row.node.setSelected(true, true);

      // #16192
      // onRowSelected 이벤트 보다 먼저 타기 때문에 우클릭으로 row 선택시 this._selectedRows 값 세팅
      // store.dispatch -> onRowSelected 에서 처리
      this._selectedRows = [ row.node.data ];
   }

   getContextMenuItems(params) {

      if(!params.node) return;

      // #16192
      // 우클릭으로 row 선택시 onRowSelected 이벤트만 타고 onRowClicked 이벤트는 타지 않는다.
      // onRowSelected에서는 보조key를 잡을 수 없어 ( alt, ctrl, shift ) 멀티선택 불가

      // if ((this._selectedRows??[]).length > 0) {
      //    // #16192 우클릭으로 다른 row를 선택했을시 선택값을 초기화 한다.
      //    const {id} = this._selectedRows[0];
      //    if (id !== params.node.data.id) this.dispatchEvent(new CustomEvent("studyCountClearEvent"));
      // }
      // - 아래 setRightSelectedRows 이벤트에서 select 이벤트롤 태우기 때문에 studyCountClearEvent 은 필요없음 (2022.04.25)
      // - setRightSelectRow -> onRowSelected -> store.dispatch(selected order) -> grid-study -> onSelectOrder -> select matched study or clear selected rows

      this.setRightSelectedRows(params);

      // Match, Unmatch Contextmenu
      this.getOrderMatchStatusCode();
      const result = [
         {
            name: this.t("label.match"),
            disabled: this.isDisabledMatch,
            action: () => {
               this.match();
            }
         },
         {
            name: this.t("label.unmatch"),
            disabled: this.isDisabledUnMatch,
            action: () => {
               this.unMatch();
            }
         },
         "separator",
         {
            name: this.t("label.newExam"),
            action: () => {
               this.newExam();
            }
         },
         "separator",
         "csvExport"
      ];

      if (this.customContextMenuState !== undefined) {
         store.dispatch({ type: CommonActionType.SHOW_CONTEXT_MENU, payload: CustomContextMenuType.SYSTEM });
      }

      return result;
   };

   dateFilterSearch(amount, unit) {
      const scheduledDtime = DateFilterUtils.getDateFilterModel(amount, unit);
      this.setFilter({"scheduledDtime": scheduledDtime});
   }

   setDefaultFilterModel() {
      this.dateFilterSearch(3, "days");
   }

   purgeEnterpriseCache() {
      this.gridOptions.api.refreshServerSideStore({ route: [], purge: true });
   }

   dateComparator(filterLocalDateAtMidnight, cellValue) {
      const dateAsString = cellValue;
      if (dateAsString == null) return 0;
      const dateParts = dateAsString.split("/");
      const day = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[0]);
      const cellDate = new Date(day, month, year);
      if (cellDate < filterLocalDateAtMidnight) return -1;
      if (cellDate > filterLocalDateAtMidnight) return 1;
      return 0;
   }




   psCellRenderer(params) {
      if (params.value === "F") return "F";
      if (params.value === "M") return "M";
      return "O";
   }

   mtCellRenderer(params) {
      if (params.value === true) return "<div class='check'>M</div>";
      return "<div class='normal'>N</div>";
   }

   createColumnDefs() {
      const columns = [
         {headerName: this.t("label.gridHeader.name.no"),            field: "no",                  width: 33, headerTooltip: this.t("label.gridHeader.tooltip.no"),  sortable: false, cellStyle: {"text-align": "center"} },
         {headerName: this.t("label.gridHeader.name.accessionNo"),  field: "accessionNumber",     width: 80, filter: "agTextColumnFilter", headerTooltip: this.t("label.gridHeader.tooltip.accessionNo"),
            filterParams: {filterOptions: ["contains", "notContains"], caseSensitive: true, suppressAndOrCondition: true, applyButton: true},
            floatingFilterComponentParams: {suppressFilterButton: true}
         },
         {headerName: this.t("label.gridHeader.name.order"),         field: "studyDescription",    width: 200, filter: "agTextColumnFilter", headerTooltip: this.t("label.gridHeader.tooltip.order"),
            filterParams: {filterOptions: ["contains", "notContains"], caseSensitive: true, suppressAndOrCondition: true, applyButton: true},
            floatingFilterComponentParams: {suppressFilterButton: true}
         },
         {headerName: this.t("label.gridHeader.name.orderStatus"),  field: "orderStatus",         width: 80, filter: "agSetColumnFilter", headerTooltip: this.t("label.gridHeader.tooltip.orderStatus"),
            filterParams: {applyButton: true, clearButton: true, values: this._filters.orderStatus}
         },
         {headerName: this.t("label.gridHeader.name.matched"),       field: "isMatch",             width: 80,  filter: "agSetColumnFilter", headerTooltip: this.t("label.gridHeader.tooltip.matched"),
            filterParams: {applyButton: true, clearButton: true, values: this._filters.isMatch},
            cellClassRules: {"(M) Matched)": params => params.value === "true", "(NM) Not Matched": params => params.value === "false"},
            cellRenderer: params => this.mtCellRenderer(params)
         },
         {headerName: this.t("label.gridHeader.name.id"),            field: "patientID",           width: 80, headerTooltip: this.t("label.gridHeader.tooltip.id"),  filter: "agTextColumnFilter",
            filterParams: { applyButton: true, caseSensitive:true },
            floatingFilterComponentParams: { suppressFilterButton: true }
         },
         {headerName: this.t("label.gridHeader.name.name"),          field: "patientName",         width: 85, headerTooltip: this.t("label.gridHeader.tooltip.name"),  filter: "agTextColumnFilter",
            filterParams: {filterOptions:["contains", "notContains"], caseSensitive:true, suppressAndOrCondition:true, applyButton: true},
            floatingFilterComponentParams: { suppressFilterButton: true }
         },
         {headerName: this.t("label.gridHeader.name.age"),           field: "patientAge",          width: 45, headerTooltip: this.t("label.gridHeader.tooltip.age"),  sortable: false},
         {headerName: this.t("label.gridHeader.name.birthDate"),     field: "patientBirthDate",    width: 80, headerTooltip: this.t("label.gridHeader.tooltip.birthDate"),  filter: "agTextColumnFilter",
            filterParams: { applyButton: true, caseSensitive:true },
            floatingFilterComponentParams: { suppressFilterButton: true }
         },
         {headerName: this.t("label.gridHeader.name.sex"),           field: "patientSex",          width: 45, headerTooltip: this.t("label.gridHeader.tooltip.sex"),  filter: "agSetColumnFilter",
            filterParams: { applyButton: true, clearButton: true, values: this._filters.patientSex},
            cellRenderer: params => this.psCellRenderer(params)
         },
         {headerName: this.t("label.gridHeader.name.modality"),      field: "modality",            width: 60, headerTooltip: this.t("label.gridHeader.tooltip.modality"),  filter: "agSetColumnFilter", filterParams: {applyButton: true, clearButton: true, values: this._filters.modality}},
         {headerName: this.t("label.gridHeader.name.bodyPart"),      field: "bodyPartExamined",            width: 80, headerTooltip: this.t("label.gridHeader.tooltip.bodyPart"),  filter: "agSetColumnFilter", filterParams: {applyButton: true, clearButton: true, values: this._filters.bodypart}},
         {headerName: this.t("label.gridHeader.name.reqDept"),          field: "institutionalDepartmentName",               width: 120, headerTooltip: this.t("label.gridHeader.tooltip.reqDept"), filter: "agTextColumnFilter",
            filterParams: {filterOptions: ["contains", "notContains"], caseSensitive: true, suppressAndOrCondition: true, applyButton: true},
            floatingFilterComponentParams: {suppressFilterButton: true}
         },
         {headerName: this.t("label.gridHeader.name.room"),          field: "examRoom",            width: 100, headerTooltip: this.t("label.gridHeader.tooltip.room"), sortable: false} ,
         {headerName: this.t("label.gridHeader.name.scheduledDate"), field: "scheduledDtime",      width: 150, headerTooltip: this.t("label.gridHeader.tooltip.scheduledDate"), filter: "agDateColumnFilter", floatingFilterComponent: "customDateFloatingFilter",
            filterParams: {applyButton: true, clearButton: true, filterOptions: ["equals", "lessThanOrEqual", "greaterThanOrEqual", "inRange", DateFilterUtils.relativeDateFilterOption], suppressAndOrCondition: true, browserDatePicker: true, comparator: this.dateComparator}
         },
         {headerName: this.t("label.gridHeader.name.orderedDate"),   field: "requestDtime",        width: 150, headerTooltip: this.t("label.gridHeader.tooltip.orderedDate"), filter: "agDateColumnFilter", floatingFilterComponent: "customDateFloatingFilter",
            filterParams: {applyButton: true, clearButton: true, filterOptions: ["equals", "lessThanOrEqual", "greaterThanOrEqual", "inRange", DateFilterUtils.relativeDateFilterOption], suppressAndOrCondition: true, browserDatePicker: true, comparator: this.dateComparator}
         },
         {headerName: this.t("label.gridHeader.name.acqHosp"),         field: "institutionName",   width: 180, headerTooltip: this.t("label.gridHeader.tooltip.acqHosp"), filter: "agTextColumnFilter",
            filterParams: {filterOptions: ["contains", "notContains"], caseSensitive: true, suppressAndOrCondition: true, applyButton: true},
            floatingFilterComponentParams: {suppressFilterButton: true}
         },
         {headerName: this.t("label.gridHeader.name.reqDoc"),    field: "requestPhysician",    width: 140, headerTooltip: this.t("label.gridHeader.tooltip.reqDoc"), filter: "agSetColumnFilter",
            filterParams: {applyButton: true, clearButton: true, values: this._filters.requestPhysician}}
      ];

      return GridUtils.changeFilterParams(columns);
   }

   serverSideDatasource(params) {
      const model = params.api.getFilterModel();
      // eslint-disable-next-line no-param-reassign
      params.request.filterModel = this.conversionFilter(model);
      this.getOrderlist(params).then((result) => {
         params.success({
            rowData: result.rows||[],
            rowCount: result.lastRow
         });
         if ((result.rows||[]).length === 0) {
            params.api.showNoRowsOverlay();
         }
      }).catch((err) => {
         console.error(err);
         params.fail();
      });
   }

   getOrderlist(params) {
      return new Promise((resolve, reject) => {
         // Object.entries(params.request.filterModel)
         //    .map(m => m[1])
         //    .filter(m => m.filterType === "date")
         //    .map((m) => {
         //       if(m.dateFrom) Object.assign(m, {dateFrom: this.formatDate(m.dateFrom, "YYYY-MM-DD")});
         //       if(m.dateTo) Object.assign(m, {dateTo: this.formatDate(m.dateTo, "YYYY-MM-DD")});
         //       return m;
         //    });

         fetch(`/api/tech/orderlist`, {
            method: "POST",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json"
            },
            body: JSON.stringify(params.request)
         }).then((response) => {
            if (response.ok) {
               response.json().then((httpResponse) => {
                  if (httpResponse.rows.length > 0) {
                     for (let i = 0; i < httpResponse.rows.length; i++) {
                        let localTime = moment(httpResponse.rows[i].requestDtime).add(this._utcOffset, "h").toDate();
                        localTime = moment(localTime).format("YYYY-MM-DD HH:mm:ss");
                        // eslint-disable-next-line no-param-reassign
                        httpResponse.rows[i].requestDtime = localTime;

                        let localScheduledDtime = "";
                        if (httpResponse.rows[i].scheduledDtime) {
                           localScheduledDtime = moment(httpResponse.rows[i].scheduledDtime).add(this._utcOffset, "h").toDate();
                           localScheduledDtime = moment(localScheduledDtime).format("YYYY-MM-DD HH:mm:ss");
                           // eslint-disable-next-line no-param-reassign
                           httpResponse.rows[i].scheduledDtime = localScheduledDtime;
                        }
                     }
                     const _startRow = params.request.startRow;
                     let cnt = _startRow;
                     // eslint-disable-next-line no-restricted-syntax
                     for (const row of httpResponse.rows) {
                        cnt++;
                        row.no = cnt;
                     }
                  }
                  resolve(httpResponse);
               });
            } else {
               reject(new Error("Orderlist loading error."));
            }
         });
      });
   }

   getFilter() {
      return this.gridOptions.api.getFilterModel();
   }

   setFilter(filterModel) {
      // set global date filters
      Object.entries(filterModel).forEach(([key, value]) => {
         const {filterType, type, amount, unit, isRelative} = value;
         if(filterType === "date" && type === "inRelativeRange") { // relative range
            filterModel[key].dateFrom = DateFilterUtils.getRelativeDate(amount, unit);
            filterModel[key].dateTo = DateFilterUtils.getToday();
            this.appliedDateFilters[key] = value;
         } else if(filterType === "date" && type === "equals" && isRelative) { // today
            filterModel[key].dateFrom = DateFilterUtils.getToday();
            filterModel[key].dateTo = DateFilterUtils.getToday();
            this.appliedDateFilters[key] = value;
         }
      });

      // ag-grid-enterprise.min.noStyle.js:8 Uncaught TypeError: Cannot read property 'length' of undefined 오류 수정(2021. 3. 11 - dave.oh)
      try {
         this.gridOptions.api.setFilterModel(filterModel);
      } catch(e) {}
   }

   reloadFilter() {
      const filterModel = this.getFilterModelIncludeDateFilter();
      this.setFilter(filterModel);
      // this.gridOptions.api.deselectAll();
   }

   // getSelectedStudyRows(studies) {
   //    this._selectedTechlist = studies;
   // }

   // getSelectedOrderRows(){
   //    return this._selectedRows;
   // }

   clearSelectedRow() {
      // this.orderCnt = 0;
      // this._selectedTechlist = [];
      // store.dispatch({ type: TechlistActionType.CLEAR_ROW_SELECTION });
      this.gridOptions.api.deselectAll();
   }

   utcCheck() {
      const now = new Date();
      const _zone = moment.tz.guess();
      const timeOffset = moment(now.getTime()).tz(_zone);
      return (timeOffset._offset / 60);
   }

   getOrderFilters() {
      return new Promise((resolve, reject) => {
         fetch(`/api/tech/orderlist/filters`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")
            }
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json()
                  .then((result) => {
                     resolve(result);
                  });
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }

   conversionFilter(filterModel) {
      let newObj = {};
      newObj = JSON.parse(JSON.stringify(filterModel));

      if (newObj.scheduledDtime) {
         newObj.scheduledDtime.dateFrom = moment(newObj.scheduledDtime.dateFrom).unix() * 1000;
         if(newObj.scheduledDtime.dateTo === "null null") {
            newObj.scheduledDtime.dateTo = null;
         } else {
            newObj.scheduledDtime.dateTo = moment(newObj.scheduledDtime.dateTo).unix() * 1000;
         }
      }

      if (newObj.requestDtime) {
         newObj.requestDtime.dateFrom = moment(newObj.requestDtime.dateFrom).unix() * 1000;
         if(newObj.requestDtime.dateTo === "null null") {
            newObj.requestDtime.dateTo = null;
         } else {
            newObj.requestDtime.dateTo = moment(newObj.requestDtime.dateTo).unix() * 1000;
         }
      }

      if (newObj.isMatch) {
         if (newObj.isMatch.values.find(value => value === "(M) Matched")) newObj.isMatch.values[0] = Boolean(true);
         if (newObj.isMatch.values.find(value => value === "(NM) Not Matched")) newObj.isMatch.values[0] = Boolean(false);
      }

      return newObj;
   }

   changeRefreshOrder(refresh = false) {
      if (!refresh) return;
      this.reloadFilter();
      if (!this.isPopup()) store.dispatch({ type: RelatedTechlistActionType.REFRESH_ORDER, payload: false });
   }

   changeRedrawOrderIds(orderIds) {
      if (!orderIds) return;
      // const [ orderRow ] = this.gridOptions.api.getSelectedRows();
      orderIds.forEach(id => this.redrawOrderById(id));
   }

   /** order grid redraw */
   redrawOrderById(orderId) {
      if (CommonUtils.isEmptyValue(orderId)) return;

      fetch(`/api/tech/order/${orderId}`, {
         method: "GET",
         headers: {
            "Authorization": localStorage.getItem("jwt"),
            "Content-Type": "application/json"
         }
      }).then((response) => {
         if (response.ok) {
            response.json().then((order) => {
               this.gridOptions.api.forEachNode((rowNode) => {
                  const {data} = rowNode;
                  if (data.id === order.id) {
                     data.isMatch = order.isMatch;
                     data.studies = order.studies;
                     this.gridOptions.api.redrawRows({row: data});
                  }
               });
            });
         }
      });
   }

   navigateToNextCell(params) {
      let previousCell = params.previousCellPosition;
      const suggestedNextCell = params.nextCellPosition;

      const KEY_UP = 38;
      const KEY_DOWN = 40;
      const KEY_LEFT = 37;
      const KEY_RIGHT = 39;

      switch (params.key) {
      case KEY_DOWN:
         previousCell = params.previousCellPosition;
         this.gridOptions.api.forEachNode((node) => {
            node.setSelected(false);
            if (previousCell.rowIndex + 1 === node.rowIndex) {
               node.setSelected(true);
            }
         });
         return suggestedNextCell;
      case KEY_UP:
         previousCell = params.previousCellPosition;
         this.gridOptions.api.forEachNode((node) => {
            node.setSelected(false);
            if (previousCell.rowIndex - 1 === node.rowIndex) {
               node.setSelected(true);
            }
         });
         return suggestedNextCell;
      case KEY_LEFT:
      case KEY_RIGHT:
         return suggestedNextCell;
      default :
         throw new Error("this will never happen, navigation is always one of the 4 keys above");
      }
   }

   // diabledSelectedRows() {
   //    this.gridOptions.api.forEachNode((node) => {
   //       node.setSelected(false);
   //    });
   // }

   // orderCountClear(){
   //    // this.orderCnt = 0;
   //    // this._selectedRows = [];
   //    // store.dispatch({ type: RelatedTechlistActionType.CLEAR_ORDER });
   //    this.gridOptions.api.deselectAll();
   //
   //    // grid-study에서 갖고있는 select order row의 값을 초기화
   //    // this.dispatchEvent(new CustomEvent("clearOrderRowsInStudy", {bubbles: true, composed: true}));
   // }

   selectedRowToMatched(params) {
      const selectedStudyRow = params;
      // study 선택시 matching 된 order 를 찾아 선택
      // 이미 matching 된 order 가 선택되어 있다면 return
      if (this.gridOptions.api.getSelectedRows().map(r => r.id).includes(selectedStudyRow.studies)) return;

      let lastSelectedRowIndex;
      this.gridOptions.api.forEachNode((node) => {
         if (node.isSelected()) node.setSelected(false);
         if (node.data !== undefined && node.data.isMatch){
            if(node.data.studies.includes(selectedStudyRow.id)) {
               node.setSelected(true);
               lastSelectedRowIndex = node.rowIndex;
            }
         }
      });
      if (lastSelectedRowIndex) this.gridApi.ensureIndexVisible(lastSelectedRowIndex);
      // if (this.orderCnt === 0 ) {
      //    this.orderCnt++;
      // }
   }

   toastMessage(msg,isErr){
      const message = {};
      const params = {};
      const detail = {};
      message.msg = msg;
      message.isErr = isErr;
      detail.message = message;
      params.detail = detail;
      this.dispatchEvent(new CustomEvent("openToastEvent", params));
   }

   formatDate(d, f = "YYYYMMDD") {
      if(!d || (d === "Invalid date") || (d === "null null")) return null;
      return moment(d, "YYYY-MM-DD HH:mm:ss").format(f);
   }

   clearFilter() {
      this.dateFilterSearch(2, "months");
   }

   /**
    * #14374
    * Contextmenu의 Match, Unmatch 활성화 및 비활성화 상태 리턴
    * return   N  -> Match, Unmatch가 비활성화 상태
    *          U  -> Unmacth만 활성화 상태
    *          M  -> Match만 활성화 상태, 1:n 매칭칭
    *          MS -> Match만 활성화 상태, 같은 Match group에 Unmatch된 Study를 추가할때
    *          MD -> Match만 활성화 상태, 그외의 Order, Study를 Match할때
    */
   getOrderMatchStatusCode() {
      const rows = this._selectedTechlist?.rows??[];
      this.matchMsgCode = "N";
      if (this.isIncludedVerified(rows)) this.matchMsgCode = "W";

      // Order의 row가 하나도 선택되지 않은 상태에서는 Match/Unmatch가 비활성화 된다.
      if ((this._selectedRows??[]).length === 0) {
         // console.log("---->1");
         this.isDisabledMatch = true;
         this.isDisabledUnMatch = true;
         this.matchMsgCode += ",N";
         return;
      }

      // 선택한 Study가 같은 group일때 Unmatch만 활성화
      if (Object.keys(rows).length > 1 && this.isSameStudyMatchGroup()) {
         // console.log("---->10");
         this.isDisabledMatch = true;
         this.isDisabledUnMatch = false;
         this.matchMsgCode += ",U";
         return;
      }

      // Match할 대상 study가 선택이 안되었을때
      if (Object.keys(rows).length === 0) {
         if (!CommonUtils.isEmptyValue(this._selectedRows[0].isMatch) && this._selectedRows[0].isMatch) {
            // console.log("---->2");
            this.isDisabledMatch = true;
            this.isDisabledUnMatch = false;
            this.matchMsgCode += ",U";
            return;
         }
         // console.log("---->3");
         this.isDisabledMatch = true;
         this.isDisabledUnMatch = true;
         this.matchMsgCode += ",N";
         return;
      }

      // 같은 Match group이 산택됐을때 또는 Order에서는 Match를 선택했지만 Study에서는 찾을수 없을때
      if (this.isSameMatchGroup(false)) {
         // console.log("---->4");
         this.isDisabledMatch = true;
         this.isDisabledUnMatch = false;
         this.matchMsgCode += ",U";
         return;
      }

      // Match만 활성화 상태
      this.isDisabledMatch = false;
      this.isDisabledUnMatch = true;

      // 같은 Match group에 Unmatch된 study를 추가할때
      // eslint-disable-next-line no-lonely-if
      // if (this.isSameMatchGroup(true)) {
      //    console.log("---->5");
      //    this.matchMsgCode += ",MS";
      //    return;
      // }

      // 사용자에게 보여줄 메시지 분기
      // 1:N의 일반적인 Match 메시지
      if (Object.keys(rows).length > 0 && this.isAllUnmatch(rows) &&
            Object.keys(this._selectedRows).length > 0 && !this._selectedRows[0].isMatch) {
         // console.log("---->6");
         this.matchMsgCode += ",M";
         return;
      }

      // 그외
      // console.log("---->7");
      this.matchMsgCode += ",MD";
   }

   /**
    * Study, Order에서 선택되어 있는 row의 Match가 같은 그룹의 Match인지 체크
    * @param chk Boolean
    * @return boolean
    */
   isSameMatchGroup(chk) {
      let isSame = true;
      if ((this._selectedRows??[][0]) && this._selectedRows[0].isMatch) {
         // const orderMatchId = this._selectedRows[0].studies[0];
         const orderMatchId = this._selectedRows[0].id;
         // eslint-disable-next-line no-restricted-syntax
         const rows = this._selectedTechlist?.rows??[];
         rows.forEach((study) => {
            if (isSame && orderMatchId !== study.studies) isSame = false;
         });
         // for (const study of rows) {
         //    // if (study.isMatch) {
         //    if (isSame && orderMatchId !== study.studies) isSame = false;
         //    // } else if (!chk) isSame = false;
         // }
      } else {
         isSame = false;
      }
      return isSame;
   }

   /**
    * 한개 이상의 study를 선택했을때 선택한 study가 같은 그룹인지 체크
    * @return {boolean}
    */
   isSameStudyMatchGroup() {
      let isSame = true;
      let baseMatchId = "";
      let orderId = "";
      if (Object.keys(this._selectedRows??[]).length === 1) {
         orderId = this._selectedRows[0].id;
      }

      const rows = this._selectedTechlist?.rows??[];
      if (CommonUtils.isEmptyObject(rows)) isSame = false;
      // eslint-disable-next-line no-restricted-syntax
      for (const study of rows) {
         if (!CommonUtils.isEmptyValue(study.isMatch) && study.isMatch) {
            if (CommonUtils.isEmptyValue(baseMatchId)) {
               baseMatchId = study.studies;
            } else if (baseMatchId !== study.studies) {
               isSame = false;
            } else if (baseMatchId !== orderId) isSame = false;
         } else {
            // eslint-disable-next-line no-return-assign
            return isSame = false;
         }
      }

      return isSame;
   }

   /**
    * 선택된 row가 모두 Unmatch상태인지 체크
    * @param rows
    * @return Boolean
    */
   isAllUnmatch(rows) {
      let isUnmatch = true;
      if (CommonUtils.isEmptyObject(rows)) return false;
      // eslint-disable-next-line no-restricted-syntax
      for (const row of rows) {
         if (row.isMatch) isUnmatch = false;
      }
      return isUnmatch;
   }

   /**
    * Verified 된 Study를 포함하고 있는지 체크
    * @param rows
    * @return {boolean}
    */
   isIncludedVerified(rows) {
      let isVerified = false;
      if (CommonUtils.isEmptyObject(rows)) return false;
      // eslint-disable-next-line no-restricted-syntax
      for (const row of rows) {
         if (!CommonUtils.isEmptyValue(row.studyStatus) && row.studyStatus === "verified") {
            // eslint-disable-next-line no-return-assign
            return isVerified = true;
         }
      }
      return isVerified;
   }

   /**
    * grid-study -> grid-order : Set match message code
    * @param msgCode
    */
   setMatchMsgCode(msgCode) {
      this.matchMsgCode = msgCode;
   }

   /**
    * Match, Unmatch할때 사용자에게 보여줄 메시지를 생성한다.
    * @return {[]|*[]}
    */
   makeMatchUnmatchMessage() {
      if (CommonUtils.isEmptyValue(this.matchMsgCode)) return [];

      const msg = [];
      const msgCode = this.matchMsgCode.split(",");
      // Verified 포함
      if (msgCode.length === 2 && msgCode[0] === "W") {
         // Verified된 Study가 포함되어 있습니다.
         msg.push(this.t("msg.verify.include"));
      }

      const code = msgCode[1];
      if (code === "M") {
         // Match 하시겠습니까?
         msg.push(this.t("msg.match.m"));
      } else if (code === "U") {
         // Unmatch 하시겠습니까?
         msg.push(this.t("msg.match.u"));
      } else if (code === "MS") {
         // 기존에 Match된 케이스가 있습니다.
         msg.push(this.t("msg.match.ms.0"));
         // 기존 Match된 케이스를 풀고 선택한 케이스를 Match하시겠습니까?
         msg.push(this.t("msg.match.ms.1"));
      } else if (code === "MD") {
         // 기존에 이미 매칭이 존재합니다.
         msg.push(this.t("msg.match.md.0"));
         // 그래도 매칭을 진행하시겠습니까?
         msg.push(this.t("msg.match.md.1"));
      } else {
         msg.push(this.t("msg.match.err"));
      }

      return msg;
   }

   /**
    * 적용된 DateFilter 정보를 반영한 Grid FilterModel
    * @returns {{[p: string]: any} | void}
    */
   getFilterModelIncludeDateFilter() {
      const filterModel = this.gridApi.getFilterModel();
      Object.keys(this.appliedDateFilters).forEach((key) => {
         if(filterModel[key])
            filterModel[key] = this.appliedDateFilters[key];
      });
      return filterModel;
   }

   match() {
      // const params = {
      //    detail: {
      //       selectedRows: this._selectedTechlist?.rows,
      //       matchMsgCode: this.matchMsgCode
      //    }
      // };
      // this.dispatchEvent(new CustomEvent("techtabMatchEvent", params));
      const studyRows = this._selectedTechlist?.rows;
      const [ orderRow ] = this._selectedRows;
      const match = () => {
         TechnicianUtils.match(studyRows, orderRow).then((result) => {
            if (result) {
               this.redrawOrderById(orderRow?.id);
               // #20261 기존 matched study인 경우 unmatch 되기 때문에 redraw 처리
               studyRows?.filter(study => study.studies).forEach(study => this.redrawOrderById(study.studies));
               if (!this.isPopup()) store.dispatch({ type: TechlistActionType.REDRAW_SELECTED_ROWS, payload: true });

               document.dispatchEvent(new CustomEvent("toastEvent", { bubbles: true, composed: true, detail: {msg: this.t("msg.match.success"), isErr: false} }));
            } else {
               document.dispatchEvent(new CustomEvent("toastEvent", { bubbles: true, composed: true, detail: {msg: this.t("msg.match.fail"), isErr: true} }));
            }
         });
      };
      const message = {
         contents: TechnicianUtils.makeMatchUnMatchMessage({matchMsgCode: this.matchMsgCode}),
         title: i18n("label.match"),
         ok: i18n("button.yes"),
         cancel: i18n("button.no"),
         onOk: match,
      };
      if (!this.isPopup()) store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, actionType: DialogActionType.MATCH, message, open: true } });
   }

   unMatch() {
      const studyRows = this._selectedTechlist?.rows;
      const [ orderRow ] = this._selectedRows;
      const unMatch = () => {
         TechnicianUtils.unMatch(studyRows, orderRow).then((result) => {
            if (result) {
               this.redrawOrderById(orderRow?.id);
               if (!this.isPopup()) store.dispatch({ type: TechlistActionType.REDRAW_SELECTED_ROWS, payload: true });
               document.dispatchEvent(new CustomEvent("toastEvent", { bubbles: true, composed: true, detail: {msg: this.t("msg.unmatch.success"), isErr: false} }));
            } else {
               document.dispatchEvent(new CustomEvent("toastEvent", { bubbles: true, composed: true, detail: {msg: this.t("msg.unmatch.fail"), isErr: true} }));
            }
         });
      };
      const message = {
         contents: TechnicianUtils.makeMatchUnMatchMessage({matchMsgCode: this.matchMsgCode}),
         title: i18n("label.unmatch"),
         ok: i18n("button.yes"),
         cancel: i18n("button.no"),
         onOk: unMatch,
      };
      if (!this.isPopup()) store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, actionType: DialogActionType.UN_MATCH, message, open: true } });
   }

   newExam() {
      const [ orderRow ] = this._selectedRows;
      const message = {
         detail: orderRow
      };
      store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.NEW_EXAM_DIALOG, actionType: DialogActionType.NEW_EXAM, message, open: true } });
   }

   isPopup() {
      return this.componentType === "popup";
   }
}
window.customElements.define("grid-order", GridOrder);
