import React, { useEffect, useState } from "react";

import "@polymer/paper-dialog";

import "../../worklist-content";
import "../../components/hh-report-window";
import "../../components/hh-tech-window";

import "../../../public/style/reflex.css";

import $ from "cash-dom";
import * as Sentry from "@sentry/browser";
import { useDispatch, useSelector } from "react-redux";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import { useEventListener } from "usehooks-ts";
import { CommonActionType } from "../../redux/reducers/common";
import { RootState } from "../../redux/reducers";
import WorklistNavbar from "../main/WorklistNavbar";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import { Content } from "../content/Content";

export default function Worklist() {
   const userConfig = useSelector((state: RootState) => state.common.userConfig);
   const _category = useSelector((state: RootState) => state.common.category);
   const g_selectedRow = useSelector((state: RootState) => state.worklist.row?.detail);
   const g_selectedTechRow = useSelector((state: RootState) => state.techlist.row?.detail);
   const _divideStandard: number = 700;
   let signText: string;
   const filmboxName: string = "popup";

   const dispatch = useDispatch();

   const [divReportWindow, setDivReportWindow] = useState<any>({ width: "100%", height: "100%" });
   const [hhReportWindow, setHhReportWindow] = useState<any>({ width: "100%", height: "100%" });
   const [hhTechWindow, setHhTechWindow] = useState<any>({ width: "100%", height: "100%", display: "none" });
   const [divWorklistContent, setDivWorklistContent] = useState<any>({ width: "100%", height: "100%" });

   useDidMountEffect(() => {
      onSelectCategory(_category);
   }, [_category, userConfig]);

   useEffect(() => {
      // set split layout
      getUserStyle().then((result) => {
         if (result) {
            const userStyle = result;

            if (!userStyle.tabIndex) userStyle.tabIndex = getDefaultTabIndex();
            else userStyle.tabIndex = { ...getDefaultTabIndex(), ...userStyle.tabIndex };

            updateWorkListTabIndex(userStyle.id, userStyle.tabIndex);

            if (!userStyle.layout) userStyle.layout = getDefaultLayout();
            else userStyle.layout = { ...getDefaultLayout(), ...userStyle.layout };

            updateWorkListStyle(userStyle.layout);

            $("#hhReportWindow")[0].setTabIndex(userStyle.tabIndex.hhReportWindowExpand, 3);
            $("#hhReportWindow")[0].setTabIndex(userStyle.tabIndex.hhReportWindowNonExpand, 4);
            dispatch({ type: CommonActionType.SET_USER_CONFIG, payload: userStyle });
            setStyleFilmOpinion(userStyle);
         }
      }).catch((err) => {
         console.info(err);
         dispatch({ type: CommonActionType.SET_USER_CONFIG, payload: { ...userConfig, layout: getDefaultLayout(), tabIndex: getDefaultTabIndex() } });
      });

      // $("#worklistConfirm")[0].addEventListener("confirmCancel", (e) => {
      //    if(popupReport) window.open("", "reportWindow");
      // });
   }, []);

   useEventListener("hhReportWindowTabIndexChangeEvent", (e) => {
      const path = (e.composedPath && e.composedPath()) || e.path;
      if (path[1].offsetWidth > _divideStandard) {
         userConfig.tabIndex.hhReportWindowExpand = e.detail;
         $("#hhReportWindow")[0].setTabIndex(e.detail, 3);
      } else {
         userConfig.tabIndex.hhReportWindowNonExpand = e.detail;
         $("#hhReportWindow")[0].setTabIndex(e.detail, 4);
      }
      // @ts-ignore
      updateWorkListTabIndex(userConfig.id, userConfig.tabIndex);
      dispatch({ type: CommonActionType.SET_USER_CONFIG, payload: { ...userConfig, tabIndex: { ...userConfig.tabIndex } } });
   });

   // WorklistNavbar Event
   useEventListener("navbarHotkeyOpinionPaste", (e) => {
      $("#hhReportWindow")[0].setCopyOpinion(e.detail);
   });

   window.addEventListener("message", (event) => {
      if (event.data.type === "updateKeyImage") {
         $("#hhReportWindow")[0].setThumbnail(event.data.id);
      }
      if (event.data.filmboxFocus) {
         window.open("", filmboxName);
      }
   });

   window.addEventListener("beforeunload", () => {
      // #17034 토큰이 만료된 후 웹페이지를 닫았을 경우 viewing 정보 update
      const nowDate = new Date().getTime();
      const expTime = JSON.parse(localStorage.getItem("jwtExpTime")!);
      if ((!expTime) || (expTime && nowDate >= expTime)) updateFilmboxOFFStatusByObjectIdUser(g_selectedRow?.id);
   });

   function onSelectCategory(newValue: any) {
      switch (newValue) {
      case 0:
         setDivReportWindow({ ...divReportWindow, minWidth: 395, maxWidth: null, flex: parseFloat(userConfig.layout.splitFilmOpinion) / window.innerWidth });
         setHhReportWindow({  ...hhReportWindow, display: "block"  });
         setHhTechWindow({ ...hhTechWindow, display: "none"  });
         setDivWorklistContent({  ...divWorklistContent, flex: (parseFloat(userConfig.layout.splitFilmOpinion) + 8) / window.innerWidth });
         changehhReportWindowSize();
         break;
      case 1:
         setDivReportWindow({  ...divReportWindow, minWidth: 395, maxWidth: null, flex: parseFloat(userConfig.layout.splitFilmOpinionTech) / window.innerWidth });
         setHhReportWindow({  ...hhReportWindow, display: "none"  });
         setHhTechWindow({ ...hhTechWindow, display: "block"  });
         setDivWorklistContent({ ...divWorklistContent, flex: parseFloat(userConfig.layout.splitFilmOpinionTech) / window.innerWidth });
         break;
      default:
      }
   }

   function getDefaultTabIndex(): any {
      const tabIndex = {
         worklistNewFilm: 0,
         hhReportWindowExpand: 0,
         hhReportWindowNonExpand: 0,
         worklistRelated: 0,
         techlistBottom: 0,
         techlistRelated: 0,
      };
      return tabIndex;
   }

   function getDefaultLayout(): any {
      const layout = {
         splitNewFilmOldFilm: "839",
         splitFilmOpinion: "395",
         splitClinicalReport: "140",
         splitBodyTop: "300",
         splitBodyTopRight: "500",
         splitBodyTopRightFirst: "200",
         splitBodyBottomLeft: "330",
         isIndividualScroll: false,
         isReportScroll: false,
         splitFindings: null,
         splitConclusion: null,
         isPopupPin: true,
         splitFilmOpinionTech: "395",
         splitBodyTopTech: "300",
      };
      return layout;
   }

   function setStyleFilmOpinion(result: any) {
      if (result.tabIndex.worklistNewFilm === 0) {
         const readingWidth = parseFloat(result.layout.splitFilmOpinion);
         setDivReportWindow({ ...divReportWindow, width: `${readingWidth}px` });
         setDivWorklistContent({ ...divReportWindow, width: `calc(100% - ${readingWidth + 8}px)` });

         changehhReportWindowSize(readingWidth);
         if (readingWidth > _divideStandard) {
            $("#hhReportWindow")[0].setTabIndex(result.tabIndex, 0);
         } else {
            $("#hhReportWindow")[0].setTabIndex(result.tabIndex, 1);
         }
      } else if (result.tabIndex.worklistNewFilm === 1) {
         const readingWidthTech = parseFloat(result.layout.splitFilmOpinionTech);
         setDivReportWindow({  ...divReportWindow, width: `${readingWidthTech}px`  });
         setDivWorklistContent({  ...divReportWindow, width: `calc(100% - ${readingWidthTech + 8}px)`  });

         // changehhTechWindowSize();
      }
   }

   function updateSplitFilmOpinion(hhReportWindowWidth: any) {
      if (userConfig.layout.splitFilmOpinion && userConfig.layout.splitFilmOpinion !== hhReportWindowWidth) {
         dispatch({ type: CommonActionType.SET_USER_CONFIG, payload: { ...userConfig, layout: { ...userConfig.layout, splitFilmOpinion: hhReportWindowWidth } } });
         updateWorkListStyle({ splitFilmOpinion: hhReportWindowWidth });
      }
   }

   function updateSplitFilmOpinionTech(hhReportWindowWidth: any) {
      if (userConfig.layout.splitFilmOpinionTech && userConfig.layout.splitFilmOpinionTech !== hhReportWindowWidth) {
         dispatch({ type: CommonActionType.SET_USER_CONFIG, payload: { ...userConfig, layout: { ...userConfig.layout, splitFilmOpinionTech: hhReportWindowWidth } } });
         updateWorkListStyle({ splitFilmOpinionTech: hhReportWindowWidth });
      }
   }

   function changehhReportWindowSize(readingWidth?: any) {
      const width = readingWidth || userConfig.layout.splitFilmOpinion;
      if (width >= _divideStandard) {
         $("#hhReportWindow")[0].setExpand();
      } else {
         $("#hhReportWindow")[0].setNonExpand();
      }
   }

   function changehhTechWindowSize() {
      $("#hhTechWindow")[0].setSize();
      $("#content")[0].setTechWidth();
   }

   function getUserStyle() {
      return new Promise<any>((resolve, reject) => {
         fetch(`/api/user/option/style`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")!,
            },
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((rows) => {
                  resolve(rows);
               });
            } else {
               reject(new Error(`status: ${response.status}, message: ${response.statusText}`));
            }
         });
      });
   }

   function updateWorkListStyle(layout: any) {
      fetch(`/api/user/option/style/layout`, {
         method: "PATCH",
         headers: {
            "Authorization": localStorage.getItem("jwt")!,
            "Content-Type": "application/json",
         },
         body: JSON.stringify(layout),
      }).then((response) => {
         if (!response.ok) {
            console.debug(new Error(`${response.status} ${response.statusText}`));
         }
      });
   }

   function updateWorkListTabIndex(id : string, tabIndex: any) {
      const param = { request : tabIndex };
      fetch(`/api/user/option/tabIndex/${id}`, {
         method: "PATCH",
         headers: {
            "Authorization": localStorage.getItem("jwt")!,
            "Content-Type": "application/json",
         },
         body: JSON.stringify(param.request),
      }).then((response) => {
         if (!response.ok) {
            console.log("Response Layout Failed");
         }
      });
   }

   function updateFilmboxOFFStatusByObjectIdUser(requestObjectId: string) {
      const user = JSON.parse(localStorage.getItem("user")!);
      if (!user || !requestObjectId) return;
      return new Promise((resolve) => {
         fetch(`/api/exchange/worklist/filmboxStatus/close/${requestObjectId}/user?userId=${user.id}`, {
            method: "PATCH",
            headers: {
               "Content-Type": "application/json",
            },
         }).then((response) => {
            if (!response.ok) {
               console.error(`Message : `, response.status);
            }
         });
      });
   }

   function onResizeStop(el: any) {
      // hhReportWindow, hhTechWindow offsetWidth의 경우 resize했을때 state에 들어가기 때문에 조건에 여부 확인 필요
      if (_category === 0) {
         if (hhReportWindow.offsetWidth && userConfig.layout) {
            updateSplitFilmOpinion(parseFloat(hhReportWindow.offsetWidth));
         }
      } else if (_category === 1) {
         if (hhTechWindow.offsetWidth && userConfig.layout) {
            updateSplitFilmOpinionTech(parseFloat(hhTechWindow.offsetWidth));
         }
      }
   }

   function onResize(el: any) {
      if (_category === 0) {
         changehhReportWindowSize(el.domElement.offsetWidth);
         if (el.domElement.offsetWidth > _divideStandard) {
            $("#hhReportWindow")[0].setTabIndex(userConfig.tabIndex, 0);
         } else {
            $("#hhReportWindow")[0].setTabIndex(userConfig.tabIndex, 1);
         }
         setHhReportWindow({ ...hhReportWindow, offsetWidth: el.domElement.offsetWidth });
      } else {
         setHhTechWindow({ ...hhTechWindow, offsetWidth: el.domElement.offsetWidth });
      }
   }

   // 호출하는 이벤트가 없음 (2022.08.26 확인)
   // addEventListener("getListSelectHotkey", () => {
   //    dispatchEvent(new CustomEvent("getListSelectHotkey"));
   //    $.navbar.getListSelectHotkey();
   // });

   // // PREFETCH 삭제 EVENT
   // 호출하는 이벤트가 없음 (2022.08.26 확인)
   // $.navbar.addEventListener("deletedRawCaseIDEvent", (e) => {
   //    prefetcStatushMark(e.detail);
   // });

   // $("#hhReportWindow")[0].addEventListener("updateExpandEvent", (e) => {
   //    updateExpandForReportWindow(e.detail).then(() => {
   //       // console.log(result);
   //    });
   // });

   // 사용하지 않은 듯한 소스 (2022.11.04)
   // function hideOverlay() {
   //    $("#content")[0].hideOverlay();
   // }

   // 호출하는 이벤트가 없음 (2022.08.26 확인)
   // function updateExpandForReportWindow(param) {
   //    return new Promise((resolve, reject) => {
   //       const { id } = JSON.parse(localStorage.getItem("user")!);
   //       fetch(`/api/user/${id}/config/worklist`, {
   //          method: "PATCH",
   //          headers: {
   //             "Authorization": localStorage.getItem("jwt")!,
   //             "Content-Type": "application/json",
   //          },
   //          body: JSON.stringify(param),
   //       }).then((response) => {
   //          if (!response.ok) {
   //             reject(new Error(`${response.status} ${response.statusText}`));
   //          }
   //       });
   //    });
   // }

   // 호출하는 이벤트가 없음 (2022.08.26 확인)
   // function changeCacheUsage() {
   //    dispatchEvent(new CustomEvent("changeCacheUsageEvent", { bubbles: true, composed: true }));
   // }

   // function onvertHashCode(str) {
   //    return Array.from(str).reduce((s, c) => Math.imul(31, s) + c.charCodeAt(0) | 0, 0);
   // }

   // filmboxInit() {
   //    $("#settingDialogContainer")[0].filmboxInit();
   // }

   return (
      <>
         <ReflexContainer orientation="horizontal" style={{ color: "white" }}>
            <ReflexElement className="header" flex={0} style={{ overflow: "unset", height: "unset" }}>
               <WorklistNavbar/>
            </ReflexElement>
            <ReflexElement flex={1}>
               <ReflexContainer orientation="vertical">
                  <ReflexElement minSize={330}>
                     <ReflexContainer orientation="horizontal">
                        <worklist-content id="content" style={{ overflow: "hidden" }}></worklist-content>
                        {/* <Content/> */}
                     </ReflexContainer>
                  </ReflexElement>
                  <ReflexSplitter/>
                  <ReflexElement onResize={onResize} onStopResize={onResizeStop} flex={divReportWindow.flex} minSize={divReportWindow.minWidth}>
                     <ReflexContainer orientation="horizontal" className="divReportWindow">
                        <hh-report-window id="hhReportWindow" style={hhReportWindow}></hh-report-window>
                        <hh-tech-window id="hhTechWindow" style={hhTechWindow}></hh-tech-window>
                     </ReflexContainer>
                  </ReflexElement>
               </ReflexContainer>
            </ReflexElement>
         </ReflexContainer>
         <boneage-report id="boneageReport"/>
      </>
   );
}
