/* eslint-disable no-bitwise,no-param-reassign,object-shorthand,no-return-assign,no-unused-vars,no-undef,prefer-object-spread,prefer-destructuring,consistent-return,no-lonely-if */
import {html, PolymerElement} from "@polymer/polymer/polymer-element";

import "@polymer/polymer/lib/elements/dom-repeat";

import "@polymer/paper-icon-button/paper-icon-button";
import "@polymer/paper-dialog/paper-dialog";
import "@polymer/paper-checkbox/paper-checkbox";
import "@polymer/paper-button/paper-button";
import "@polymer/paper-input/paper-input";

import "@polymer/iron-autogrow-textarea/iron-autogrow-textarea";
import "@polymer/iron-icon";
import "@polymer/iron-icons";

import "@vaadin/vaadin-split-layout/vaadin-split-layout";
import "@vaadin/vaadin-icons/vaadin-icons";

import "@hpacs/healthhub-icons/healthhub-icons";

import "./hh-report-confirm-dialog";
import moment from "moment-timezone";
import CryptoJS from "crypto-js";

import * as Sentry from "@sentry/browser";
// eslint-disable-next-line import/no-extraneous-dependencies
import {IronResizableBehavior} from "@polymer/iron-resizable-behavior/iron-resizable-behavior";
import {mixinBehaviors} from "@polymer/polymer/lib/legacy/class";
import mixinCommons from "../common-mixin";
import store from "../redux/store";
import {CommonActionType, DialogActionType, DialogType, CustomContextMenuType} from "../redux/reducers/common";
import CommonUtils from "../../public/resource/js/utils/common";
import {ReportActionType} from "../redux/reducers/report";
import {RelatedWorklistActionType} from "../redux/reducers/related-worklist";
import {RedrawRowsType, WorklistActionType} from "../redux/reducers/worklist";
import ReportUtils from "../utils/report-utils";
import i18n from "../utils/i18n-utils";

class HHReport extends mixinBehaviors([IronResizableBehavior], mixinCommons(PolymerElement)) {
   static get is() {
      return "hh-report";
   }

   static get template() {
      return html`
         <style>
            :host {
               display: block;
               height: 100%;
               width: 100%;
               position: relative;
            }

            .container {
               display: flex;
               flex-direction: column;
               height: 100%;
               width: 100%;
            }

            .item {
               width: 100%;
            }

            .textarea-container {
               background-color: rgba(0, 0, 0, 0.30);
               /*display: flex;*/
               padding: 5px;
               height: calc(100% - 40px);
            }

            .textarea-container:hover {
               cursor: text;
            }

            iron-autogrow-textarea {
               --iron-autogrow-textarea : {
                  padding: 0px;
               }
               width: 100%;
               color: #ccc;
               padding: 0px;
               border: 0px;
               resize: none;
               outline: none !important;
               font-size: 15px;
               line-height: 1.5em;
               font-family: NotoSansCJK, NotoSansCJKLight;
            }

            textarea {
               height: calc(100% - 23px);
               width: calc(100% - 20px);
               background-color: rgba(0, 0, 0, 0); /* 투명 (opacity: 0) */
               color: #ccc;
               padding: 0px;
               border: 0px;
               resize: none;
               outline: none !important;
               font-size: 15px;
               line-height: 1.5em;
               font-family: NotoSansCJK, NotoSansCJKLight;
            }

            .readOnly {
               color: #999;
            }

            textarea::placeholder {
               color: #444;
            }

            paper-icon-button {
               --paper-icon-button: {
                  color: #aaaaaa;
               };
               --paper-icon-button-hover: {
                  color: #0087cb;
               };
               --paper-icon-button-disabled: {
                  color: #4C5667;
               };
            }

            .icon-container {
               margin: 0px 0;
               padding: 0 0px;
               text-align: center;
               display: inline-block;
               cursor: default;
               vertical-align: top;
               box-sizing: border-box;
               overflow: hidden;
            }

            .icon-container span {
               font-family: NotoSansCJK, NotoSansCJKLight;
               display: block;
               color: #aaaaaa;
               transition: all 600ms;
            }

            .icon-container .label-top {
               font-size: 12px;
               margin: 1px;
               line-height: 1;
               overflow: hidden;
               text-overflow: ellipsis;
               white-space: nowrap;
            }

            .icon-container .label-bottom {
               font-size: 12px;
            }

            .label-reader {
               font-family: NotoSansCJK, NotoSansCJKLight;
               font-size: 14px;
               color: #aaaaaa;
               font-weight: bold;
            }

            .item-container {
               height: 100%;
            }

            paper-input {
               --paper-input-container-input-color: #fff;
               --paper-input-container-focus-color: #0087cb;
               --paper-input-container-label: {
                  font-size: 14px;
               };
               width: 150px;
            }


            .content-counter {
               display: flex;
               align-items: center;
               min-width: 360px;
               height: 33px;
            }

            iron-icon, div[suffix] {
               color: hsl(0, 0%, 50%);
               margin-right: 12px;
               width: 14px;
               height: 14px;
            }

            .ag-row-selected {
               background-color: #596072 !important;
               color: #fff !important;
            }

            #scpUsersModal {
               width: 200px;
               border-radius: 5px;
               margin: 0;
               border: 2px solid;
               border-color: #0087cb;
               color: #0087cb;
               top: 264px;
            }

            paper-listbox {
               padding: 0px;
               margin: 0px;
            }

            paper-item {
               font-size: 14px;
               cursor: pointer;
            }

            paper-item:hover {
               font-weight: bold;
               background-color: #0087cb;
               color: #fff;
            }

            .accordion {
               background-color: #252934;
               box-shadow: 0px 3px 9px -2px #000000;
               font-size: 14px;
               color: #aaaaaa;
               cursor: pointer;
               margin: 5px 0 5px 0;
               padding: 0 10px 0 10px;
               height: 30px;
               width: 100%;
               border: none;
               text-align: left;
               outline: none;
               transition: 0.4s;
               font-family: NotoSansCJK, NotoSansCJKLight;
            }

            .active, .accordion:hover {
               background-color: #0087cb;
               color: #f0f0f0;
               font-weight: bold;
            }

            .accordion:after {
               content: '\\002B';
               color: #f0f0f0;
               font-weight: bold;
               float: right;
               margin-left: 5px;
            }

            .active:after {
               content: "\\2212";
            }

            .panel {
               /*margin: 1px 5px 5px 5px;*/
               /*padding: 0 5px;*/
               color: #aaaaaa;
               display: none;
               /* background-color: #38528929; */
               box-shadow: 0px 3px 9px -2px #000000;
               overflow-x: hidden;
               transition: max-height 0.2s ease-out;
            }
            .class-newAddendum{
               min-height: 32px;
               width: calc(100% - 20px);
               /*margin: 1px 5px 5px 5px;*/
               /*padding: 0 10px;*/
               color: #aaaaaa;
               display: none;
               background-color: rgba(0, 0, 0, 0.10);
               overflow-x: hidden;
               overflow-y: auto;
               transition: max-height 0.2s ease-out;
            }

            .approve-panel {
               /*margin: 1px 5px 5px 5px;*/
               /*padding: 5px;*/
               color: #aaaaaa;
               display: none;
               /*background-color: #111c3329;*/
               /*box-shadow: 0px 3px 9px -2px #000000;*/
               overflow: hidden;
               transition: max-height 0.2s ease-out;
               padding: 0 5px 5px 5px;
            }

            .class-report-approve-title {
               height: 30px;
               display:flex;
               align-items: center;
               padding-left: 10px;
               background-color: #252934;
               font-weight: bold;
               font-size: 12px;
               box-shadow: 0px 3px 9px -2px #000000;
               color: white;
               font-size: 13px;
               margin: 5px 0 5px 0;
            }

            .class-report-approve-title-content {
               width: 100%;
               font-size: 13px;
               font-weight: bold;
               background-color: rgba(0, 0, 0, 0.10);
               color: #aaa;
               border: 0px;
            }

            .class-report-approve-content {
               height: 100%;
            }

            .report-approve-content {
               height: 100%;
               width: 100%;
               background-color: #111c3329;
            }

            .class-addendum-report-content {
               height: calc(100% - 130px);
               width: 100%;
               overflow-y: auto;
               overflow-x: hidden;
            }

            ::-webkit-scrollbar {
               width: 15px;
               height: 15px;
            }

            ::-webkit-scrollbar-thumb {
               background-color: #6b6b6b;
               border-radius: 10px;
               background-clip: content-box;
               border: 3px solid rgba(255,255,255,0);
            }

            /* context menu */
            .ag-theme-balham-dark .ag-popup-child:not(.ag-tooltip-custom) {
               box-shadow: 5px 5px 10px rgba(0,0,0,.3);
            }
            .ag-theme-balham-dark .ag-menu {
               border: 1px solid;
               border-color: #424242;
               background: #2d3436;
               border-radius: 2px;
               box-shadow: none;
               padding: 4px;
               padding: 0;
            }

            .ag-theme-balham-dark [class^=ag-],
            .ag-theme-balham-dark [class^=ag-]:after,
            .ag-theme-balham-dark [class^=ag-]:before,
            .ag-theme-balham-dark [class^=ag-]:focus {
               box-sizing: border-box;
               outline: none;
            }

            .ag-ltr {
               direction: ltr;
            }

            .ag-menu {
               max-height: 100%;
               overflow-y: auto;
               position: absolute;
               /* left: 100px; */
               /* top: 100px; */
               -webkit-user-select: none;
               -ms-user-select: none;
               user-select: none;
            }

            .ag-popup-child {
               z-index: 3;
            }

            .ag-theme-balham-dark {
               -webkit-font-smoothing: antialiased;
               color: #f5f5f5;
               font-size: 12px;
               line-height: normal;
            }

            .ag-unselectable {
               -webkit-user-select: none;
               -ms-user-select: none;
               user-select: none;
            }

            .ag-root-wrapper {
               cursor: default;
               position: relative;
               display: flex;
               flex-direction: column;
               overflow: hidden;
            }
            .ag-theme-balham-dark .ag-menu-list {
               cursor: default;
               width: 100%;
               padding-top: 4px;
               padding-bottom: 4px;
            }

            .ag-menu-list {
               display: table;
            }

            .ag-ltr {
               direction: ltr;
            }

            .ag-menu {
               max-height: 100%;
               overflow-y: auto;
               position: absolute;
               -webkit-user-select: none;
               -ms-user-select: none;
               user-select: none;
            }

            .ag-menu-option,
            .ag-menu-separator {
               display: table-row;
            }

            .ag-menu-option {
               cursor: pointer;
            }

            .ag-menu-option:not(.ag-menu-option-disabled):hover {
               background: #3d4749;
            }

            .ag-theme-balham-dark .ag-menu-option-icon {
               /* padding-left: 8px; */
               /* padding-right: 4px; */
               /* min-width: 24px; */
               text-align: center;
               color: #f5f5f5;
            }

            iron-icon {
               --iron-icon-fill-color: #f5f5f5;
            }

            .ag-theme-balham-dark .ag-menu-option-part {
               padding-top: 6px;
               padding-bottom: 6px;
            }

            .ag-menu-option-part,
            .ag-menu-separator-cell {
               display: table-cell;
               vertical-align: middle;
            }

            .ag-theme-balham-dark .ag-menu-list {
               cursor: default;
               width: 100%;
               padding-top: 4px;
               padding-bottom: 4px;
            }

            .ag-theme-balham-dark .ag-menu-option-disabled {
               opacity: .5;
               cursor: default;
            }

            .ag-menu-option-part, .ag-menu-separator-cell {
               display: table-cell;
               vertical-align: middle;
            }

            .ag-theme-balham-dark .ag-menu-separator {
               height: 8px;
            }

            .ag-theme-balham-dark .ag-menu-separator-cell:after {
               content: "";
               display: block;
               border-top: 1px solid;
               border-top-color: #424242;
               border-top-color: var(--ag-border-color,#424242);
            }

            #contextMenu {
               position: absolute;
            }

            .hideContextMenu {
               display: none;
            }

            .opinion-title {
               height: 30px;
               display:flex;
               align-items: center;
               /*margin:5px 0 5px 0;*/
               padding-left: 10px;
               background-color: #252934;
               font-weight: bold;
               box-shadow: 0px 3px 9px -2px #000000;
               color: #aaaaaa;
               font-size: 13px;
            }

            .opinion-content {
               white-space: pre-wrap;
               /*margin: 0 0 15px 5px;*/
               /* background-color: #2b3445; */
               background-color: rgba(0, 0, 0, 0.10);
               color: #ccc;
               min-height: 24px;
               padding: 5px;
               word-break: break-all;
            }

            .addendum-content {
               white-space: pre-wrap;
               /*margin: 0 0 15px 5px;*/
               /* background-color: #2b3445; */
               background-color: rgba(0, 0, 0, 0.10);
               color: #ccc;
               /*min-height: 50px;*/
               padding: 5px;
            }

            button.accordion.active,
            button.accordion:hover {
               background-color: #252934;
            }

            .report-container {
               display: flex;
               flex-direction: column;
               padding-top: 5px;
            }

            .input-resizer {
               box-sizing: border-box;
               resize: none;
               overflow: hidden;
               width: 100%;
               /*height: 100%;*/
               height: 22px;
            }
         </style>

         <div class="container">
            <div class="item" style="height: 65px; display: flex; justify-content: space-evenly; align-items: center">
               <div class="icon-container" id="approveDiv">
                 <paper-icon-button id="approve" icon="vaadin:check-square-o" on-click="handleClick" disabled></paper-icon-button>
                 <!--<span class="label-top" id="approveTitle">Approve/Attest</span>-->
                 <span class="label-top" >{{t("button.approve")}}</span>
               </div>
               <div class="icon-container" id="addendumDiv" style="display: none">
                 <paper-icon-button id="addendum" icon="vaadin:check-square" on-click="handleClick"></paper-icon-button>
                 <span class="label-top">{{t("button.addendum")}}</span>
               </div>
               <div class="icon-container">
                 <paper-icon-button id="save" icon="vaadin:download" on-click="handleClick" disabled></paper-icon-button>
                 <span class="label-top">{{t("button.save")}}</span>
               </div>
               <div class="icon-container">
                 <paper-icon-button id="edit" icon="vaadin:edit" on-click="handleClick" disabled></paper-icon-button>
                 <span class="label-top">{{t("button.edit")}}</span>
               </div>
               <div class="icon-container">
                 <paper-icon-button id="preliminary" icon="vaadin:external-link" on-click="handleClick" disabled></paper-icon-button>
                 <span class="label-top">
                   <template is="dom-if" if="{{isExpand}}">{{t("button.preliminary.expand")}}</template>
                   <template is="dom-if" if="{{!isExpand}}">{{t("button.preliminary.restore")}}</template>
                 </span>
               </div>
               <div class="icon-container">
                 <paper-icon-button id="dictate" icon="vaadin:microphone" on-click="handleClick" disabled></paper-icon-button>
                 <span class="label-top">{{t("button.dictate")}}</span>
               </div>
               <div class="icon-container">
                 <paper-icon-button id="transcribe" icon="vaadin:keyboard-o" on-click="handleClick" disabled></paper-icon-button>
                 <span class="label-top">
                   <template is="dom-if" if="{{isExpand}}">{{t("button.transcribe.expand")}}</template>
                   <template is="dom-if" if="{{!isExpand}}">{{t("button.transcribe.restore")}}</template>
                 </span>
               </div>
               <!--<div class="icon-container">
                 <paper-icon-button id="consultTo" icon="vaadin:comment-ellipsis-o" on-click="handleClick" disabled></paper-icon-button>
                 <span class="label-top">Consult to</span>
               </div>-->
               <div class="icon-container">
                 <paper-icon-button id="except" icon="vaadin:ban" on-click="handleClick" disabled></paper-icon-button>
                 <span class="label-top">
                   <template is="dom-if" if="{{isExpand}}">{{t("button.except.expand")}}</template>
                   <template is="dom-if" if="{{!isExpand}}">{{t("button.except.restore")}}</template>
                 </span>
               </div>
               <div class="icon-container">
                 <paper-icon-button id="markCVR" icon="vaadin:warning" on-click="handleClick" disabled="{{isDisabledMarkCVR}}"></paper-icon-button>
                 <span class="label-top">
                   <template is="dom-if" if="{{isExpand}}">{{t("button.cvr.expand")}}</template>
                   <template is="dom-if" if="{{!isExpand}}">{{t("button.cvr.restore")}}</template>
                 </span>
               </div>
               <div class="icon-container">
                 <paper-icon-button id="print" icon="vaadin:print" on-click="handleClick" disabled></paper-icon-button>
                 <span class="label-top">
                   <template is="dom-if" if="{{isExpand}}">{{t("button.print.expand")}}</template>
                   <template is="dom-if" if="{{!isExpand}}">{{t("button.print.restore")}}</template>
                 </span>
               </div>
<!--               <div class="icon-container">-->
<!--                  <paper-icon-button icon="vaadin:cog" on-tap="openDialog" disabled></paper-icon-button>-->
<!--               </div>-->
            </div>
            <div id="reportContents" class="class-addendum-report-content">
               <div class="class-report-approve-content" id="approveContent">
                  <div id="reportContainer" class="report-container" style="height: calc(100% - 5px)">
                     <vaadin-split-layout id="reportFirstSplit" orientation="vertical" theme="minimal" style="height: 100%;">
                        <div id="reportFristTopWrapper" style$="height: {{rfTopHeight}}; min-height: {{rfTopMinHeight}}">
                           <div style="height: 100%;">
                              <div class="opinion-title">{{t("label.findings")}}</div>
                              <div id="findingContainer" class="textarea-container context-menus" on-click="textContainerClick">
                                 <textarea id="taFinding" value="{{findings::input}}" rows="1" placeholder={{t("label.inputFindings")}} tabindex=1 class="report-area input-resizer"></textarea>
                              </div>
                           </div>
                        </div>
                        <div id="reportFristBottomWrapper" style$="height: {{rfBottomHeight}}; min-height: {{rfBottomMinHeight}}">
                           <vaadin-split-layout id="reportSecondSplit" orientation="vertical" theme="minimal" style="height: 100%;">
                              <div id="reportSecondTopWrapper" style$="height: {{rsTopHeight}}; min-height: {{rsTopMinHeight}}">
                                 <div style="height: 100%;">
                                    <div class="opinion-title">
                                       {{t("label.conclusion")}}
                                    </div>
                                    <div id="conclusionContainer" class="textarea-container context-menus" on-click="textContainerClick">
                                       <textarea id="taConclusion" value="{{conclusion::input}}" rows="1" placeholder={{t("label.inputConclusion")}} tabindex=2 class="report-area input-resizer"></textarea>
                                    </div>
                                 </div>
                              </div>
                              <div id="reportSecondBottomWrapper" style$="height: {{rsBottomHeight}}; min-height: {{rsBottomMinHeight}}">
                                 <div style="height: 100%;">
                                    <div class="opinion-title">
                                       {{t("label.recommendation")}}
                                    </div>
                                    <div id="recommendationContainer" class="textarea-container context-menus" on-click="textContainerClick">
                                       <textarea id="taRecommendation" value="{{recommendation::input}}" rows="1" placeholder={{t("label.inputRecommendation")}} tabindex=3 class="report-area input-resizer"></textarea>
                                    </div>
                                 </div>
                              </div>
                           </vaadin-split-layout>
                        </div>
                     </vaadin-split-layout>
                  </div>
               </div>

               <!--Start of test accodian-->
               <div class ="class-addendumContent" id="addendumContent" style="display: none">
                  <button class="accordion" id="approveInfo" active></button>
                  <div class="approve-panel" id="approvePanel">
                     <div class="report-approve-content">
<!--                        <vaadin-split-layout orientation="vertical" style="height: 100%; width: 100%;" theme="minimal">-->
                        <div style="height: 100%; width: 100%;">
<!--                           <div style="height: 33%; min-height: 72px; overflow: hidden">-->
                           <div>
                              <div style="height: 30px; display:flex; align-items: center; padding-left: 10px;background-color: #252934;font-weight: bold;font-size: 12px;box-shadow: 0px 3px 9px -2px #000000; color: #aaaaaa; font-size: 13px;">{{t("label.findings")}}</div>
                              <!-- <iron-autogrow-textarea  value="{{findings}}" readonly class="context-menus"></iron-autogrow-textarea> -->
                              <div class="context-menus opinion-content">{{findings}}</div>
                           </div>
<!--                           <vaadin-split-layout orientation="vertical" style="height: 67%; min-height: 140px" theme="minimal">-->
<!--                           <div style="height: 67%; ">-->
                           <div>
                              <div style="height: 30px; display:flex; align-items: center; padding-left: 10px;background-color: #252934;font-weight: bold;font-size: 12px;box-shadow: 0px 3px 9px -2px #000000; color: #aaaaaa; font-size: 13px;">{{t("label.conclusion")}}</div>
                              <!-- <iron-autogrow-textarea value="{{conclusion}}" readonly class="context-menus"></iron-autogrow-textarea> -->
                              <div class="context-menus opinion-content">{{conclusion}}</div>
                           </div>
                           <div>
                              <div style="height: 30px; display:flex; align-items: center; padding-left: 10px;background-color: #252934;font-weight: bold;font-size: 12px;box-shadow: 0px 3px 9px -2px #000000; color: #aaaaaa; font-size: 13px;">{{t("label.recommendation")}}</div>
                              <!-- <iron-autogrow-textarea value="{{recommendation}}" readonly class="context-menus"></iron-autogrow-textarea> -->
                              <div class="context-menus opinion-content">{{recommendation}}</div>
                           </div>
<!--                           </div>-->
<!--                           </vaadin-split-layout>-->
<!--                        </vaadin-split-layout>-->
                        </div>
                     </div>
                  </div>

                  <!--addendum list-->
                  <div id="divAddendumList">
                     <template is="dom-repeat" items="[[addendumList]]" id="addendumRepeat" on-dom-change="attachContextMenu">
                        <button class="accordion active">[[item.title]]</button>
                        <div class="panel" style="display: block;">
                           <!-- <p>[[item.addendum]]</p> -->
                           <!-- <iron-autogrow-textarea value="[[item.addendum]]" style="background-color: #2b3445" readonly class="context-menus"></iron-autogrow-textarea> -->
                           <div class="context-menus addendum-content">[[item.addendum]]</div>
                        </div>
                     </template>
                  </div>

                  <button id="newAddendum" class="accordion">New Addendum</button>
                  <textarea id="newAddendumText" value="{{newAddendum}}" rows="1" class="class-newAddendum report-area context-menus input-resizer" style="padding: 5px" placeholder={{t("label.inputAddendum")}}></textarea>
               </div>
               <!--End of test accodian-->
            </div>

            <div class="item" id="items" style="height: 65px; display: flex; flex-direction: column;">
               <div  id="itemsWrap" style="height: 65px; display: flex; flex-direction: column; align-items: flex-end;">
                  <div class="item-container" style="width: calc(100% - 340px); display:flex; align-items: center; padding-left: 10px;">
                  <!-- <span class="label-reader">Radiologist<br>{{radiologist}}</span> -->
                  </div>
                  <div class="item-container" style="width: 340px; display: flex; justify-content: space-evenly;">
                     <div class="icon-container">
                        <paper-icon-button id="copyButton" icon="vaadin:copy-o" on-click="copyWithButton"></paper-icon-button>
                        <span class="label-bottom">{{t("button.copy")}}</span>
                     </div>
                     <div class="icon-container">
                        <paper-icon-button id="pasteButton" icon="vaadin:paste" on-click="pasteWithButton"></paper-icon-button>
                        <span class="label-bottom">{{t("button.paste")}}</span>
                     </div>
                     <div class="icon-container">
                        <paper-icon-button id="resetToUnread" icon="vaadin:refresh" on-click="handleClick" disabled></paper-icon-button>
                        <span class="label-bottom">{{t("button.resetToUnread")}}</span>
                     </div>
                     <div class="icon-container">
                        <paper-icon-button id="clear" icon="vaadin:trash" on-click="handleClear"></paper-icon-button>
                        <span class="label-bottom">{{t("button.clear")}}</span>
                     </div>
                     <div class="icon-container">
                        <paper-icon-button id="prev" icon="healthhub:display-prev" on-click="handleClick"></paper-icon-button>
                        <span class="label-bottom">{{t("button.prev")}}</span>
                     </div>
                     <div class="icon-container">
                        <paper-icon-button id="next" icon="healthhub:display-next" on-click="handleClick"></paper-icon-button>
                        <span class="label-bottom">{{t("button.next")}}</span>
                     </div>
                  </div>
                  <div class="content-counter" id="contentCount">
                     <worklist-counter id="worklistCount"></worklist-counter>
                  </div>
               </div>

            </div>
            <div id="contextMenu" class="contextMenu hideContextMenu">
               <div class="ag-theme-balham-dark ag-popup">
                  <div class="ag-menu ag-ltr ag-popup-child ag-keyboard-focus" style="min-width: 125px;">
                     <div class="ag-menu-list" tabindex="-1">
                        <div class="ag-menu-option do-cut" tabindex="-1" on-click="handleCut">
                           <span class="ag-menu-option-icon ag-menu-option-part"><iron-icon icon="icons:content-cut"></iron-icon></span>
                           <span class="ag-menu-option-text ag-menu-option-part">{{t("button.contextMenu.cut")}}</span>
                           <span class="ag-menu-option-shortcut ag-menu-option-part"></span>
                           <span class="ag-menu-option-popup-pointer ag-menu-option-part">&nbsp;</span>
                        </div>
                        <div class="ag-menu-option do-copy" tabindex="-1" on-click="handleCopy">
                           <span class="ag-menu-option-icon ag-menu-option-part"><iron-icon icon="icons:content-copy"></iron-icon></span>
                           <span class="ag-menu-option-text ag-menu-option-part">{{t("button.contextMenu.copy")}}</span>
                           <span class="ag-menu-option-shortcut ag-menu-option-part"></span>
                           <span class="ag-menu-option-popup-pointer ag-menu-option-part">&nbsp;</span>
                        </div>
                        <div class="ag-menu-option do-paste" tabindex="-1" on-click="handlePaste">
                           <span class="ag-menu-option-icon ag-menu-option-part"><iron-icon icon="icons:content-paste"></iron-icon></span>
                           <span class="ag-menu-option-text ag-menu-option-part">{{t("button.contextMenu.paste")}}</span>
                           <span class="ag-menu-option-shortcut ag-menu-option-part"></span>
                           <span class="ag-menu-option-popup-pointer ag-menu-option-part">&nbsp;</span>
                        </div>
                        <div class="ag-menu-option do-delete" tabindex="-1" on-click="handleDelete">
                           <span class="ag-menu-option-icon ag-menu-option-part"><iron-icon icon="icons:delete"></iron-icon></span>
                           <span class="ag-menu-option-text ag-menu-option-part">{{t("button.contextMenu.delete")}}</span>
                           <span class="ag-menu-option-shortcut ag-menu-option-part"></span>
                           <span class="ag-menu-option-popup-pointer ag-menu-option-part">&nbsp;</span>
                        </div>
                        <div class="ag-menu-option do-select-all" tabindex="-1" on-click="handleSelectAll">
                           <span class="ag-menu-option-icon ag-menu-option-part"><iron-icon icon="icons:select-all"></iron-icon></span>
                           <span class="ag-menu-option-text ag-menu-option-part">{{t("button.contextMenu.selectAll")}}</span>
                           <span class="ag-menu-option-shortcut ag-menu-option-part"></span>
                           <span class="ag-menu-option-popup-pointer ag-menu-option-part">&nbsp;</span>
                        </div>
                        <div class="ag-menu-separator">
                           <span class="ag-menu-separator-cell"></span>
                           <span class="ag-menu-separator-cell"></span>
                           <span class="ag-menu-separator-cell"></span>
                           <span class="ag-menu-separator-cell"></span>
                        </div>
                        <div class="ag-menu-option do-copy-report" tabindex="-1" on-click="handleCopyReport">
                           <span class="ag-menu-option-icon ag-menu-option-part"><iron-icon icon="icons:description"></iron-icon></span>
                           <span class="ag-menu-option-text ag-menu-option-part">{{t("button.contextMenu.copyReport")}}</span>
                           <span class="ag-menu-option-shortcut ag-menu-option-part"></span>
                           <span class="ag-menu-option-popup-pointer ag-menu-option-part">&nbsp;</span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      `;
   }


   connectedCallback() {
      super.connectedCallback();
      this.addEventListener("iron-resize", this.onIronResize.bind(this));
   }

   onIronResize() {
      const lng = localStorage.getItem("i18nextLng");
      let maxSize = 480;

      if(lng === "es") maxSize = 510;

      if (this.offsetWidth > 0 && this.offsetWidth < maxSize) this.isExpand = false;
      else this.isExpand = true;
   }

   static get properties() {
      return {
         isExpand: {
            type: Boolean,
            value: true
         },
         radiologist: {
            type: String,
            value: ""
         },
         findings: {
            type: String,
            value: ""
         },
         conclusion: {
            type: String,
            value: ""
         },
         recommendation: {
            type: String,
            value: ""
         },
         newAddendum:{
            type:String,
            value: ""
         },
         // approveText: {
         //    type: String,
         //    value: "Approve"
         // },
         imageReport: {
            type: Object,
            value: {}
         },
         addendumList : {
            type: Array,
            value: []
         },
         contextWidth: {
            type: Number,
            value: 125
         },
         contextHeight: {
            type: Number,
            value: 200
         },
         isAddendum: {
            type: Boolean,
            value: false,
         },
         elemEditor: {
            type: Object
         },
         opinion : {
            type: Object,
            value: {}
         },
         clipboard: {
            type: Object
         },
         supportClipboard: {
            type: Boolean,
            value: false
         },
         isDisabledMarkCVR: {
            type: Boolean,
            value: true
         },
         fHeight: {
            type: Number
         },
         rfTopHeight: {
            type: String,
            value: "33.3%"
         },
         rfBottomHeight: {
            type: String,
            value: "66.7%"
         },
         rfTopMinHeight: {
            type: String,
            value: "33.3%"
         },
         rfBottomMinHeight: {
            type: String,
            value: "66.7%" // #17778 [HPACS > Report popup] 팝업된 판독창 크기가 작을 경우 판독문에 스크롤이 생기지 않음
         },
         rsTopHeight: {
            type: String,
            value: "50%"
         },
         copiedFindings: {
            type: String,
            value: ""
         },
         copiedConclusion: {
            type: String,
            value: ""
         },
         copiedRecommendation: {
            type: String,
            value: ""
         },
         rsBottomHeight: {
            type: String,
            value: "50%"
         },
         rsTopMinHeight: {
            type: String,
            value: "50%" // #17778 [HPACS > Report popup] 팝업된 판독창 크기가 작을 경우 판독문에 스크롤이 생기지 않음
         },
         rsBottomMinHeight: {
            type: String,
            value: "50%" // #17778 [HPACS > Report popup] 팝업된 판독창 크기가 작을 경우 판독문에 스크롤이 생기지 않음
         },
         g_objId: {
            type: String
         },
         isIndividualScroll: {
            type: Boolean,
            value: false,
            observer: "changeIndividualScroll"
         },
         _selectedRow: {
            type: Object,
            value: {},
            observer: "_selectedRowChanged"
         },
         customContextMenuState: {
            type: Number,
            observer: "changeCustomContextMenuState"
         },
         customUniqueKey: {
            type: String,
            readonly: true,
            computed: "computeCustomUniqueKey()"
         },
         isMismatchContinue: {
            type: Boolean,
            value: false
         },
         _reportRow: {
            type: Object,
            observer: "_selectedReportRowChanged"
         },
         isPopupPin: {
            type: Boolean,
            value: true
         },
         log: { // 리팩토링 테스트를 위한 로그
            type: String,
            value: "[hh-report]"
         },
         certAtLogin: {
            type: Boolean,
            value: false
         },
         popupReport: {
            type: Object,
            // value: {},
            observer: "_changePopupReport"
         },
         popupEditOpinion: {
            type: Object
         },
         copyAndPasteReport: { // related report copy and paste
            type: Object,
            observer: "onCopyAndPasteReport"
         },
         userDN: {
            type: String
         },
         isPopup: {
            boolean: false,
         },
         userConfig: {
            type: Object,
         }
      };
   }

   computeCustomUniqueKey() {
      return `${CustomContextMenuType.REPORT}_${Math.random()}`;
   }

   ready() {
      super.ready();

      store.subscribe(() => {
         const { userConfig, popupReport } = store.getState().common;
         this.userConfig = userConfig;
         this.popupReport = popupReport;
         if (userConfig?.layout?.isIndividualScroll !== undefined) this.isIndividualScroll = userConfig.layout.isIndividualScroll;
         if (userConfig?.layout?.isPopupPin !== undefined) this.isPopupPin = userConfig.layout.isPopupPin;
         if (userConfig?.certAtLogin !== undefined) this.certAtLogin = userConfig.certAtLogin;
         this._selectedRow = store.getState().worklist.row;
         this.customContextMenuState = store.getState().common.customContextMenu;
         this._reportRow = store.getState().report.reportRow;
         this.copyAndPasteReport = store.getState().report.copyAndPasteReport;
         this.userDN = store.getState().report.userDn;
      });

      // #14291 -- TEXTAREA에서 포커스가 나갈 때 alt+key 조합으로 발생한 문자를 제거
      const TEXTAREAs   = [this.$.taFinding, this.$.taConclusion, this.$.taRecommendation, this.$.newAddendumText];
      const self        = this;

      const watermark   = () => self.$.reportContents.clientHeight;
      let   focused_ta  = null; // onfocus() 핸들러에서 설정됨

      const scrollHeights = {
         taFinding() {
            const el           = self.$.taFinding;
            const has_focus    = (focused_ta === el);
            let   height       = el.scrollHeight;
            const is_overflow  = (height + 40) > watermark(); // '40' ==> "Findings" label height + padding 10

            if(has_focus) {
               height = is_overflow ? (height + 40) - watermark() : 0;
            } else {
               height += 40;
            }
            return height;
         },
         taConclusion() {
            const el           = self.$.taConclusion;
            const has_focus    = (focused_ta === el);
            let   height       = el.scrollHeight;
            const is_overflow  = (height + 40) > watermark(); // '40' ==> "Conclusion" label height + padding 10

            if(has_focus) {
               height = is_overflow ? (scrollHeights.taFinding() + height + 40) - watermark() : scrollHeights.taFinding();
            } else {
               height += 40;
            }
            return height;

         },
         taRecommendation() {
            const el           = self.$.taRecommendation;
            const has_focus    = (focused_ta === el);
            let   height       = el.scrollHeight;
            const is_overflow  = (height + 40) > watermark(); // '40' ==> "Recommendation" label height + padding 10

            if(has_focus) {
               const priors_height = scrollHeights.taFinding() + scrollHeights.taConclusion();
               height = is_overflow ? (priors_height + height + 40) - watermark() :scrollHeights.taFinding() + priors_height;
            } else {
               height += 40;
            }
            return height;
         }

      };

      TEXTAREAs.forEach((textarea) => {
         textarea.addEventListener("change", () => {
            textarea.value = this.normalizeOpinionValues(textarea.value);
         });
         textarea.onfocus= () => {focused_ta = textarea; queueMicrotask(()=>window.ElementEventRepeater(textarea,1));};
         textarea.onblur= () => {focused_ta = null;      queueMicrotask(()=>window.ElementEventRepeater(textarea,0));};

         // automatic resize TEXTAREA height;
         ["cut", "paste", "drop", "input", "set", "clear"].forEach((ev_type) => {
            // if(textarea.id === "newAddendumText") return;
            textarea.addEventListener(ev_type, () => {
               this.findings = this.findings || "";
               this.conclusion = this.conclusion || "";
               this.recommendation = this.recommendation || "";

               // 1. approve가 가능한 생태, 2. 글자를 2개이상 입력했을때
               const isReading = this.isActiveApproveContent() && this.isActiveApproveContentTextArea() && textarea.textLength > 1;
               if(textarea.id !== "newAddendumText" && ev_type === "input" && isReading) {
                  window.postMessage({event: "READING_ON"}, document.location.href);
               }

               const resize = () => {
                  // textarea.style.height = "auto";
                  // textarea.style.height = `${textarea.scrollHeight}px`;

                  if(textarea.id === "newAddendumText") {
                     textarea.style.height = "auto";
                     textarea.style.height = `${textarea.scrollHeight}px`;

                     if((this.$.addendumContent.offsetHeight > this.$.reportContents.offsetHeight) && this.isAddendum) {
                        this.$.reportContents.scrollTop = (this.$.addendumContent.offsetHeight - this.$.divAddendumList.offsetHeight) - 70;
                        this.isAddendum = false;
                     }
                  } else {
                     // #16460 전체 스크롤
                     if(!this.isIndividualScroll) {
                        textarea.style.height = "auto";
                        textarea.style.height = `${textarea.scrollHeight}px`;

                        // reportFristTopWrapper min-height 적용
                        if(textarea.id === "taFinding") {
                           this.$.reportFristTopWrapper.style.minHeight = `${textarea.offsetHeight + 40}px`;
                        }

                        // reportSecondTopWrapper min-height 값 계산
                        if(textarea.id === "taConclusion") {
                           this.$.reportSecondTopWrapper.style.minHeight = `${textarea.offsetHeight + 40}px`;
                        }

                        // reportSecondBottomWrapper min-height 값 계산
                        if(textarea.id === "taRecommendation") {
                           this.$.reportSecondBottomWrapper.style.minHeight = `${textarea.offsetHeight + 40}px`;
                        }

                        // reportFristBottomWrapper min-height 값 계산
                        if(textarea.id === "taConclusion" || textarea.id === "taRecommendation") {
                           this.$.reportFristBottomWrapper.style.minHeight = `${this.$.taConclusion.offsetHeight + this.$.taRecommendation.offsetHeight + 80}px`;
                        }
                     } else {
                        textarea.style.height = "100%";
                        textarea.style.overflow = "hidden auto";
                     }

                     // NOTE: 2020/09/01 By Jacob - split 과 report 영역의 높이를 계산하여 report의 scroll 유무를 판단함.
                     const isFixedHeight = this.checkFixedHeight();
                     this.changeFixedHeight(isFixedHeight);

                     // self.$.reportContents.scrollTop = scrollHeights[textarea .id]() + 5; // 사용자 입력에 따라 scrolling 높이 조정

                     // #16460 전체 스크롤
                     if(!this.isIndividualScroll && ev_type === "set") self.$.reportContents.scrollTop = 0;
                  }
               };
               requestAnimationFrame(resize);
            }, false);
         });

         // alt+key 조합으로 키가 "on_editing" 상태에서 alt+key 형태의 전역단축키에 설정되어 있다면 해당 확장문자를 표시하지 않게한다
         // WARN: 1) 4개의 Dead키는 막지 못하기 때문에 "onChange" 핸들러에서 4개의 Dead 문자가 발견되면 삭제해야한다
         // WARN: 2) 문자만 표시하지 않게 할뿐 FLeader가 바인딩한 window 쪽으로 이벤트 전파를 막지는 않는다
         ["keypress", "keyup"].forEach((ev_type) => {
            textarea.addEventListener(ev_type, (event) => {
               return window.is_global_shortcut_key(event) ?  (event.preventDefault(), false) : true;
            });
         });
      });

      this.$.reportFirstSplit.addEventListener("iron-resize",(e) => {
         // NOTE: 2020/09/01 By Jacob - split의 min-height, flex 값 계산 후 적용.
         // NOTE: 2020/08/28 By Jacob - 대상 : reportFristTopWrapper, reportFristBottomWrapper

         // reportFristTopWrapper min-height 적용
         let taFindingHeight = this.$.taFinding.offsetHeight;
         // #16460 전체 스크롤
         if(!this.isIndividualScroll && (!taFindingHeight || taFindingHeight === 0)) taFindingHeight = 22;
         if(!this.isIndividualScroll) this.rfTopMinHeight = `${taFindingHeight + 40}px`;
         // #16460 멀티 스크롤
         if(this.isIndividualScroll && (!taFindingHeight || taFindingHeight <= 22)) taFindingHeight = 22;

         // reportFristBottomWrapper min-height 값 계산
         let taConclusionHeight = this.$.taConclusion.offsetHeight;
         // #16460 전체 스크롤
         if(!this.isIndividualScroll && (!taConclusionHeight || taConclusionHeight === 0)) taConclusionHeight = 22;
         // #16460 멀티 스크롤
         if(this.isIndividualScroll && (!taConclusionHeight || taConclusionHeight <= 22)) taConclusionHeight = 22;
         let taRecommendationHeight = this.$.taRecommendation.offsetHeight;
         // #16460 전체 스크롤
         if(!this.isIndividualScroll && (!taRecommendationHeight || taRecommendationHeight === 0)) taRecommendationHeight = 22;
         if(!this.isIndividualScroll) this.rfBottomMinHeight = `${taConclusionHeight + taRecommendationHeight + 80}px`;
         // #16460 멀티 스크롤
         if(this.isIndividualScroll && (!taRecommendationHeight || taRecommendationHeight <= 22)) taRecommendationHeight = 22;

         // NOTE: 2020/09/01 By Jacob - split flex 값 계산 - minHeight 값에 도달하면 flex값 변경을 막는다.
         const rfSplitHeight = this.$.reportFirstSplit.offsetHeight;
         const rfTopFlexHeight = this.getFlexHeight(this.$.reportFristTopWrapper);
         const rfBottomFlexHeight = this.getFlexHeight(this.$.reportFristBottomWrapper);

         if (taFindingHeight + 40 > rfTopFlexHeight) {
            this.$.reportFristTopWrapper.style.flex = `1 1 ${taFindingHeight + 40}px`;
            this.$.reportFristBottomWrapper.style.flex = `1 1 ${rfSplitHeight - (taFindingHeight + 40)}px`;
         }

         if ((taConclusionHeight + taRecommendationHeight + 80) > rfBottomFlexHeight) {
            this.$.reportFristBottomWrapper.style.flex = `1 1 ${taConclusionHeight + taRecommendationHeight + 80}px`;
            this.$.reportFristTopWrapper.style.flex = `1 1 ${rfSplitHeight - (taConclusionHeight + taRecommendationHeight + 80)}px`;
         }
      });

      this.$.reportSecondSplit.addEventListener("iron-resize",(e) => {
         // NOTE: 2020/08/31 By Jacob - split의 min-height, flex 값 계산 후 적용.
         // NOTE: 2020/08/31 By Jacob - 대상 : reportSecondTopWrapper, reportSecondBottomWrapper

         // reportSecondTopWrapper min-height 적용
         let taConclusionHeight = this.$.taConclusion.offsetHeight;
         // #16460 전체 스크롤
         if(!this.isIndividualScroll && (!taConclusionHeight || taConclusionHeight === 0)) taConclusionHeight = 22;
         if(!this.isIndividualScroll) this.rsTopMinHeight = `${taConclusionHeight + 40}px`;
         // #16460 멀티 스크롤
         if(this.isIndividualScroll && (!taConclusionHeight || taConclusionHeight <= 22)) taConclusionHeight = 22;

         // reportSecondBottomWrapper min-height 값 계산
         let taRecommendationHeight = this.$.taRecommendation.offsetHeight;
         // #16460 전체 스크롤
         if(!this.isIndividualScroll && (!taRecommendationHeight || taRecommendationHeight === 0)) taRecommendationHeight = 22;
         if(!this.isIndividualScroll) this.rsBottomMinHeight = `${taRecommendationHeight + 40}px`;
         // #16460 멀티 스크롤
         if(this.isIndividualScroll && (!taRecommendationHeight || taRecommendationHeight <= 22)) taRecommendationHeight = 22;

         // NOTE: 2020/09/01 By Jacob - split flex 값 계산 - minHeight 값에 도달하면 flex값 변경을 막는다.
         const rsSplitHeight = this.$.reportSecondSplit.offsetHeight;
         const rsTopFlexHeight = this.getFlexHeight(this.$.reportSecondTopWrapper);

         if (taConclusionHeight + 40 > rsTopFlexHeight) {
            this.$.reportSecondTopWrapper.style.flex = `1 1 ${taConclusionHeight + 40}px`;
            this.$.reportSecondBottomWrapper.style.flex = `1 1 ${rsSplitHeight - (taConclusionHeight + 40)}px`;
         }
      });

      this.$.reportFirstSplit.addEventListener("splitter-dragend",() => {
         if (this.checkFixedHeight()) {
            this.$.reportFristTopWrapper.style.flex = `1 1 ${this.rfTopHeight}`;
            this.$.reportFristBottomWrapper.style.flex = `1 1 ${this.rfBottomHeight}`;
         } else {
            this.dispatchEvent(new CustomEvent("reportSplitChangedEvent", {detail: this.getReportHeight()}));
         }
      });

      this.$.reportSecondSplit.addEventListener("splitter-dragend",() => {
         if (this.checkFixedHeight()) {
            this.$.reportSecondTopWrapper.style.flex = `1 1 ${this.rsTopHeight}`;
            this.$.reportSecondBottomWrapper.style.flex = `1 1 ${this.rsBottomHeight}`;
         } else {
            this.dispatchEvent(new CustomEvent("reportSplitChangedEvent", {detail: this.getReportHeight()}));
         }
      });

      window.document.addEventListener("focusOnReportArea",() => {
         // 필름박스에서 ENTER를 입력하면 워크리스트 윈도우로 포거스가 이동한 후에 TEXTAREA로 포커스를 옮겨야 한다
         const self = this;
         queueMicrotask(()=> self.$.taFinding.focus());
      });

      // ai report 결과 값을 일반 report에 붙여 넣어주는 이벤트 입니다.
      window.addEventListener("copyAndPasteAiReport", (event) => {

         this.setAiReport(event);
      });

      window.document.addEventListener("focusOutFromReportArea",() => {
         const self = this;
         queueMicrotask(()=> self.$.taFinding.blur());
      });

      this.$.addendumContent.addEventListener("click", (e) => {
         const path = (e.composedPath && e.composedPath()) || e.path;
         if(path[0].nodeName === "BUTTON"){
            if(e.target.nextElementSibling.id === "newAddendumText"){
               // console.log(e.target.nextElementSibling.value.length);
               if(e.target.nextElementSibling.value === "                    "){
                  e.target.nextElementSibling.value = "";
               }
               // e.target.nextElementSibling.focus();
            }
            e.target.classList.toggle("active");
            if(e.target.nextElementSibling){
               const panel = e.target.nextElementSibling;
               if (panel.style.display === "block") {
                  panel.style.display = "none";
               } else {
                  panel.style.display = "block";
                  // window.scrollTo();
                  // this.animate(e.target);
                  window.scroll({
                     top: 100,
                     left: 100,
                     behavior: "smooth"
                  });
               }
            }
         }

         // mismatch 창이 바로 닫히는 현상으로 인해 focus를 강제로 넣어줌
         if(path[0].nodeName === "TEXTAREA") this.$.newAddendumText.focus();
      });

      this.supportClipboard = navigator.clipboard;

      this.$.contextMenu.style.width  = `${this.contextWidth}px`;
      this.$.contextMenu.style.height = `${this.contextHeight}px`;

      this.shadowRoot.querySelectorAll(".context-menus").forEach(el => this.setContextMenu(el));
      document.addEventListener("click", () => {
         // if(!this.$.contextMenu.classList.contains("hideContextMenu")) {
         //    this.$.contextMenu.classList.add("hideContextMenu");
         //    window.postMessage({event: "closeOtherContextMenu"}, document.location.href);
         //    window.postMessage({event: "closeReportContextMenu"}, document.location.href);
         // }
         // console.log("[report] document click", (this.customContextMenuState))
         if (this.customContextMenuState !== undefined) store.dispatch({ type: CommonActionType.HIDE_CONTEXT_MENU });
      });

      // FLeader: 단축키로 Report 영역 버튼을 호출하기 위해 미리 요소를 등록 (FLeader의 초기화보다 빨라서 윈도우에 남긴다)
      // id 이름에 붙은 $ 표시는 this.$ 에서 가져왔음을 의미할 뿐 특별한 이유는 없다.
      // TEXTAREA 요소의 id 값이 바뀌거나 polymer element로 대체될 경우 아래 값들을 적절히 변경시켜야 한다.
      const links = [
         {id:"$report", value: this},
         {id:"$report_btn_approve", value: this.$.approve},
         {id:"$report_btn_addendum", value: this.$.addendum},
         {id:"$report_btn_save", value: this.$.save},
         {id:"$report_btn_preliminary", value: this.$.preliminary},
         {id:"$report_btn_dictate", value: this.$.dictate},
         {id:"$report_btn_transcribe", value: this.$.transcribe},
         {id:"$report_btn_edit", value: this.$.edit},
         {id:"$report_btn_except", value: this.$.except},
         {id:"$report_btn_markCVR", value: this.$.markCVR},
         {id:"$report_btn_clear", value: this.$.clear},
         {id:"$report_btn_pasteJustPrevious", value: this.$.pasteJustPrevious},
         {id:"$report_btn_resetToUnread", value: this.$.resetToUnread},
         {id:"$report_copy_btn", value: this.$.copyButton},
         {id:"$report_paste_btn", value: this.$.pasteButton},
         {id:"$taFinding", value: this.$.taFinding},
         {id:"$taConclusion", value: this.$.taConclusion},
         {id:"$taRecommendation", value: this.$.taRecommendation}
      ]; window.__element_refers__ = window.__element_refers__ ? [...window.__element_refers__, ...links] : links;

      // #16460 초기 scroll 설정
      this.getStyleObj().then((s) => {
         // 각 컴포넌트 ready 순서 문제로 중복되더라도 필요한 컴포넌트에서 초기화 셋팅 필요할 것 같음 (2022.05.09)
         if (s) {
            const { layout, certAtLogin } = s;
            // console.log("---> [hh-report] ready style", layout.isIndividualScroll);
            // store.dispatch({ type: CommonActionType.SET_USER_CONFIG, payload: { layout, certAtLogin } });
            store.dispatch({ type: CommonActionType.SET_USER_CONFIG, payload: { ...this.userConfig, layout: {...this.userConfig.layout, ...layout}, certAtLogin } });
         } else {
            this.changeScrollHeight(this.isIndividualScroll);
         }
         // if(s && s.layout && s.layout.isIndividualScroll) this.isIndividualScroll = s.layout.isIndividualScroll;

         // this.changeScrollHeight(this.isIndividualScroll);
      }).catch(err => console.error(err));

      // window.addEventListener("REPORT_SCROLL", (e) => {
      //    this.handleScrollReport(e.detail.isIndividualScroll);
      // });

      window.addEventListener("message", (event) => {
         switch((event.data||{}).event) {
         case "FILMBOX_OPENED_STUDY" : {
            const filmboxId = event.data.studyId;
            const worklistId = event.data.row.id;
            const params = event.data;

            if(filmboxId !== worklistId && !this.isMismatchContinue) {
               this.onMismatchDialog(params);
            } else {
               this.reporting(params);
            }
            break;
         }
         default:
         }
      });

      this.$.contentCount.style.display = "none";
      this.initReportButton();
   } // ready

   onMismatchDialog(params) {
      const message = {
         contents: i18n("msg.mismatchCase", { returnObjects: true }),
         title: i18n("label.mismatchCase"),
         ok: i18n("button.continueToRead"),
         cancel: i18n("button.cancel"),
         onOk: () => {
            this.reporting(params);
         },
         onCancel: () => {
            // cancel시 textarea에 focus가 이동되면서 mismatch를 체크하기 때문에 continue와 동일하게 동작함
            const newRow = this._reportRow?.detail;
            const newRows = this._reportRow?.rows;
            this.setReport(newRow, newRows);
         },
      };
      store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, actionType: DialogActionType.MISMATCH, message, open: true } });
   }

   reporting(params = {}) {
      if(params) {
         // 판독문 수정할 때 마다 confirm창이 뜨면 안되므로 플래그 추가
         this.isMismatchContinue = true;

         const {row, rows, opinion} = params;
         const newRow = row;
         const newRows = rows;

         switch(params.callType) {
         case "addendum":
            if(opinion && opinion.addendum) this.addendumOpinionData(newRow.id, opinion, newRow);
            break;
         case "approve":
            this.approveOpinionData(newRow, newRows, opinion);
            break;
         case "save":
            this.saveOpinionData(false, newRow, newRows);
            break;
         case "transcribe":
            this.transcribeOpinionData(newRow, newRows);
            break;
         default:
            break;
         }
      }
   }

   // NOTE: 2020/09/01 리포트 영역의 높이를 변경한다. 스크롤과 연관됨.
   changeFixedHeight(isFixedHeight = false) {
      // #16460 스크롤 설정별 ui 변경
      if(!this.isIndividualScroll && isFixedHeight) {
         const rFirstSplitMinHeight = parseFloat(this.$.reportFristTopWrapper.style.minHeight.split("p")[0])
            + parseFloat(this.$.reportFristBottomWrapper.style.minHeight.split("p")[0]);
         this.$.reportContainer.style.height = null;
         this.$.reportFirstSplit.style.height = `${rFirstSplitMinHeight}px`;
      } else {
         this.$.reportContainer.style.height = "calc(100% - 5px)";   // 5px(padding)
         this.$.reportFirstSplit.style.height = "100%";
      }
   }

   checkFixedHeight() {
      const reportContents = this.$.reportContents.getBoundingClientRect().height - 5;  // '5' ==> padding 5
      const rFirstSplitMinHeight = parseFloat(this.$.reportFristTopWrapper.style.minHeight.split("p")[0])
         + parseFloat(this.$.reportFristBottomWrapper.style.minHeight.split("p")[0]);
      return !this.isIndividualScroll && reportContents <= rFirstSplitMinHeight;
   }

   onCopyAndPasteReport(r = {}) {
      if (r && Object.keys(r).length > 0) {
         this.handleRelatedReportCopy(r.detail, this._reportRow?.detail?.readingStatus);
      }
   }

   handleRelatedReportCopy(detail, readingStatus) {
      if (this.$.taFinding.getAttribute("readonly") || this.$.taConclusion.getAttribute("readonly") || this.$.taRecommendation.getAttribute("readonly")) {
         window.rejected();
         return;
      }

      const { finding, conclusion, recommendation } = detail;

      const existingFindigs = this.findings;
      const existingConclusion = this.conclusion;
      const existingRecommendation = this.recommendation;

      if (finding) {
         if (existingFindigs) {
            this.findings = `${existingFindigs}\n${finding}\n`;
         } else  {
            this.findings = `${finding}\n`;
         }
      }

      if (conclusion) {
         if (existingConclusion) {
            this.conclusion = `${existingConclusion}\n${conclusion}\n`;
         } else  {
            this.conclusion = `${conclusion}\n`;
         }
      }

      if (recommendation) {
         if (existingRecommendation) {
            this.recommendation = `${existingRecommendation}\n${recommendation}\n`;
         } else  {
            this.recommendation = `${recommendation}\n`;
         }
      }

      this.$.taFinding.dispatchEvent(new CustomEvent("paste"));
      this.$.taConclusion.dispatchEvent(new CustomEvent("paste"));
      this.$.taRecommendation.dispatchEvent(new CustomEvent("paste"));

      window.fulfilled();
   }

   // #14291
   normalizeOpinionValues(value) {

      if(value === null) return value = "";

      const deadkey_pattern = /[´ˆ˜¨]/g;
      return value.replace(deadkey_pattern, "");
   }

   adjustTextareaHeights( type = "input") {
      const TEXTAREAs = [this.$.taFinding, this.$.taConclusion, this.$.taRecommendation, this.$.newAddendumText];
      TEXTAREAs.forEach((ta) => {
         ta.dispatchEvent(new CustomEvent(type));
      });
   }

   getFlexHeight(element) {
      const {flex} = element.style;

      if(flex && flex !== "") {
         return parseFloat(flex.split(" ")[2].split("p")[0]);
      }
   }

   getReportHeight() {
      return {"splitFindings": this.$.reportFristTopWrapper.getBoundingClientRect().height.toFixed(2), "splitConclusion": this.$.reportSecondTopWrapper.getBoundingClientRect().height.toFixed(2)};
   }

   getReportMinHeight() {
      const minHeight = this.$.taFinding.offsetHeight + this.$.taConclusion.offsetHeight + this.$.taRecommendation.offsetHeight + 120;
      return minHeight + 65 + 65 + 5; // each textarea + header(65) + footer(65) + padding(5)
   }

   /*
   TODO : line number 734에서 사용
   */
   // animate(elem) {
   //    let left = 0;
   //    function frame() {
   //       left++;  // update parameters
   //       // eslint-disable-next-line no-param-reassign
   //       elem.style.left = `${left}px`; // show frame
   //       if (left === 100)  // check finish condition
   //       // eslint-disable-next-line no-use-before-define
   //          clearInterval(id);
   //    }
   //    // eslint-disable-next-line no-var
   //    var id = setInterval(frame, 10); // draw every 10ms
   // }

   enabledReportArea(isEnabled) {
      return (isEnabled ? this.readWriteReportArea() : this.readOnlyReportArea());
   }

   readWriteReportArea() {
      // this.$.taFinding.focus();
      this.$.taFinding.classList.remove("readOnly");
      this.$.taConclusion.classList.remove("readOnly");
      this.$.taRecommendation.classList.remove("readOnly");

      this.$.taFinding.removeAttribute("readonly");
      this.$.taConclusion.removeAttribute("readonly");
      this.$.taRecommendation.removeAttribute("readonly");
   }

   readOnlyReportArea() {
      this.$.taFinding.classList.add("readOnly");
      this.$.taConclusion.classList.add("readOnly");
      this.$.taRecommendation.classList.add("readOnly");

      this.$.taFinding.setAttribute("readonly", true);
      this.$.taConclusion.setAttribute("readonly", true);
      this.$.taRecommendation.setAttribute("readonly", true);
   }

   handleClick(e) {
      const {id} = e.target;

      if (id === "addendum") {
         this.isAddendum = true;
      }

      if (id === "addendum" && this.$.reportContents.scrollTop < this.$.addendumContent.offsetHeight - this.$.reportContents.offsetHeight - 100) {
         this.$.reportContents.scrollTop = this.$.addendumContent.offsetHeight - this.$.reportContents.offsetHeight + 5;
         return;
      }
      this.onClickReportBtn(id);
   }

   onClickReportBtn(id) {
      if(id === "save" || id === "approve" || id === "addendum" || id === "transcribe") {
         if(this.popupReport.open && window.opener) window.opener.postMessage({event: "POPUP_REPORT_PRINT"}, document.location.href);
         else this.dispatchEvent(new CustomEvent("reportPrintEvent", {bubbles: true, composed: true}));
      }

      const { detail: newRow, rows: newRows } = this._reportRow;
      switch(id) {
      case "saveRadiologist":
         break;
      case "approve":
         Object.assign(newRow, {editOpinion: this.getOpinion()});
         this.approveOpinion(newRow, newRows);
         this.setOpinion(Object.assign({}, this.getOpinion(), {study: newRow}));
         break;
      case "addendum":
         this.addendumOpinion(newRow.id);
         break;
      case "save":
         Object.assign(newRow, {editOpinion: this.getOpinion()});
         this.saveOpinion(false, newRow, newRows);
         this.setOpinion(Object.assign({}, this.getOpinion(), {study: newRow}));
         break;
      case "prev":
         if(this.popupReport.open && window.opener && window.opener.report && window.opener.report.get()) window.opener.postMessage({event: "POPUP_DISPLAY_PREV"}, document.location.href);
         else this.dispatchEvent(new CustomEvent("prevEvent", {bubbles: true, composed: true}));
         break;
      case "next":
         if(this.popupReport.open && window.opener && window.opener.report && window.opener.report.get()) window.opener.postMessage({event: "POPUP_DISPLAY_NEXT"}, document.location.href);
         else this.dispatchEvent(new CustomEvent("nextEvent", {bubbles: true, composed: true}));
         break;
      case "print":
         if(this.popupReport.open && window.opener && window.opener.report && window.opener.report.get()) window.opener.postMessage({event: "POPUP_PRINT"}, document.location.href);
         else this.dispatchEvent(new CustomEvent("printEvent", {bubbles: true, composed: true}));
         // ReportUtils.openPopupPrintReport(this._selectedRow?.rows); //TODO: image report 확인 필요 (2022.09.14)
         break;
      case "markCVR":
         this.requestMarkCVR();
         break;
      case "edit":
         this.editButtonIsActive(newRow, true);
         break;
      case "transcribe":
         Object.assign(newRow, {editOpinion: this.getOpinion()});
         this.transcribeOpinion(newRow, newRows);
         this.setOpinion(Object.assign({}, this.getOpinion(), {study: newRow}));
         break;
      default:
      }
      // }
   }

   handleClear() {
      if(this.$.approveContent.style.display !== "none") {
         this.findings = "";
         this.conclusion = "";
         this.recommendation = "";
      }
      this.$.newAddendumText.value = "";
      this.adjustTextareaHeights("clear");
   }

   checkClear() {
      this.adjustTextareaHeights("clear");
      return this.reportConfim(this.opinion, this.getOpinion());
   }

   checkHashCode(n1, n2) {
      return n1 === n2;
   }

   convertHashCode(str) {
      return Array.from(str)
         .reduce((s, c) => Math.imul(31, s) + c.charCodeAt(0) | 0, 0);
   }

   reportConfim(opinion, editOpinion) {

      // 1W일 경우 opinion = null or finding, conclusion, recommendation null 체크
      if(!opinion.finding) opinion.finding = "";
      if(!opinion.conclusion) opinion.conclusion = "";
      if(!opinion.recommendation) opinion.recommendation = "";
      if(!opinion.addendum) opinion.addendum = "";

      if(!editOpinion.finding) editOpinion.finding = "";
      if(!editOpinion.conclusion) editOpinion.conclusion = "";
      if(!editOpinion.recommendation) editOpinion.recommendation = "";

      // console.log("opinion >>> ", opinion.finding, opinion.conclusion, opinion.recommendation);
      // console.log("editOpinion >>> ", editOpinion);

      // 저장되있는 판독문과 다른지 확인
      const isFinding = this.checkHashCode(this.convertHashCode(opinion.finding.replace(/\r/g, "")), this.convertHashCode(editOpinion.finding.replace(/\r/g, "")));
      const isConclusion = this.checkHashCode(this.convertHashCode(opinion.conclusion.replace(/\r/g, "")), this.convertHashCode(editOpinion.conclusion.replace(/\r/g, "")));
      const isRecommendation = this.checkHashCode(this.convertHashCode(opinion.recommendation.replace(/\r/g, "")), this.convertHashCode(editOpinion.recommendation.replace(/\r/g, "")));
      const isAddendum = this.checkHashCode(this.convertHashCode(opinion.addendum.replace(/\r/g, "")), this.convertHashCode(editOpinion.addendum.replace(/\r/g, "")));

      // console.log(isFinding, isConclusion, isRecommendation, isAddendum);

      if(!isFinding || !isConclusion || !isRecommendation || !isAddendum) return {isConfirm:false, editOpinion:editOpinion};
      return {isConfirm:true, editOpinion:editOpinion};
   }

   textContainerClick(e) {
      if(e.target.nodeName === "DIV") e.target.querySelector("textarea").focus();
   }

   initTextArea() {
      this.findings = "";
      this.conclusion = "";
      this.recommendation = "";
      this.newAddendum = "";
   }

   getOpinion() {
      const opinion = {};
      opinion.finding = this.normalizeOpinionValues(this.findings);
      opinion.conclusion = this.normalizeOpinionValues(this.conclusion);
      opinion.recommendation = this.normalizeOpinionValues(this.recommendation);
      opinion.addendum = this.normalizeOpinionValues(this.$.newAddendumText.value);
      opinion.imageReport = this.imageReport;
      return opinion;
   }

   enabledClearBtn(){
      this.$.clear.disabled = false;
   }

   disabledClearBtn(){
      this.$.clear.disabled = true;
   }

   enabledApproveBtn(isEnabled) {
      this.$.approve.disabled = !isEnabled;
   }

   enabledEditBtn(isEnabled) {
      this.$.edit.disabled = !isEnabled;
   }

   enabledTranscribeBtn(isEnabled) {
      this.$.transcribe.disabled = !isEnabled;
   }

   invisibleApproveDiv() {
      this.$.approveDiv.style.display = "none";
   }

   visibleApproveDiv() {
      this.$.approveDiv.style.display = "block";
   }

   invisibleAddendumDiv() {
      this.$.addendumDiv.style.display = "none";
      this.$.addendum.disabled = true;
   }

   visibleAddendumDiv(enable = true) {
      this.$.addendumDiv.style.display = "block";
      this.$.addendum.disabled = !enable;
   }

   // changeApproveText(text) {
   // this.approveText = text;
   // }

   enabledSaveBtn(isEnabled) {
      this.$.save.disabled = !isEnabled;
   }

   setScpUsers(users) {
      this.scpUsers = users;
   }

   openScpUsersModal() {
      this.$.scpUsersModal.positionTarget = this.$.approve;
      this.$.scpUsersModal.open();
   }

   closeScpUsersModal() {
      this.$.scpUsersModal.close();
   }

   enabledPrintBtn(){
      this.$.print.disabled = false;
   }

   disabledPrintBtn(){
      this.$.print.disabled = true;
   }

   disabledAddendumAccordionList(){
      this.$.divAddendumList.style.display = "none";
   }

   ableAddendumAccordionList(){
      this.$.divAddendumList.style.display = "block";
   }

   setOpinion(opinion){
      // console.log("setOpinion: ", opinion.study);
      this.opinion = opinion;
      this.$.newAddendumText.value = "";
      this.findings = opinion.finding;
      this.conclusion = opinion.conclusion;
      this.recommendation = opinion.recommendation;

      if (opinion.approverLicenseNo) {
         this.$.approveInfo.innerHTML = `${opinion.approver || `N/A`} (${opinion.approverLicenseNo}) Approved at ${opinion.approveDtime || `N/A`}`;
      } else {
         this.$.approveInfo.innerHTML = `${opinion.approver || `N/A`} Approved at ${opinion.approveDtime || `N/A`}`;
      }
      if(opinion.addendumList){
         for(let i=0; i<opinion.addendumList.length; i++){
            if (opinion.addendumList[i].approverLicenseNo) {
               opinion.addendumList[i].title = `${opinion.addendumList[i].addemdumer || `N/A`} (${opinion.addendumList[i].approverLicenseNo}) Addendum at ${ opinion.addendumList[i].addendumDtime || `N/A`}`;
            } else {
               opinion.addendumList[i].title = `${opinion.addendumList[i].addemdumer || `N/A`} Addendum at ${ opinion.addendumList[i].addendumDtime || `N/A`}`;
            }
         }
         this.addendumList = opinion.addendumList;
      }
      this.adjustTextareaHeights("set");
   }

   enableAddendumList(){
      this.$.approveContent.style.display ="none";
      this.$.addendumContent.style.display = "block";
      if(!this.$.approveInfo.classList.value.includes("active")){
         const targetElement = this.$.approveInfo;
         targetElement.classList.toggle("active");
         if(targetElement.nextElementSibling){
            targetElement.nextElementSibling.style.display = "block";
         }
      }
      this.$.pasteButton.disabled = true;
   }

   disableAddendumList(){
      this.$.addendumContent.style.display ="none";
      this.$.approveContent.style.display = "block";
      this.$.pasteButton.disabled = false;
   }

   isActiveApproveContent() {
      return (this.$.approveContent.style.display === "block") ? Boolean(true) : Boolean(false);
   }

   isActiveApproveContentTextArea() {
      return !this.$.approveContent.querySelector("textarea").hasAttribute("readonly") ? Boolean(true) : Boolean(false);
   }

   /**
    * function setStructImage
    * image Report 구조체 저장
    *
    * Create by mjkim on 2019-07-17 오전 9:53
    * */
   setStructImage(imageReport) {
      if(imageReport) this.imageReport = JSON.parse(imageReport);
   }


   attachContextMenu() {
      // this.$.divAddendumList.querySelectorAll("iron-autogrow-textarea").forEach(el => this.setContextMenu(el));
      this.$.divAddendumList.querySelectorAll("div.context-menus").forEach(el => this.setContextMenu(el));
   }

   setContextMenu(el) {
      el.addEventListener("contextmenu", e => this.showContextMenu(e, el));
   }

   showContextMenu(e, target) {
      e.preventDefault();
      // if (this.otherContextMenuIsOpen) {
      //    window.postMessage({event: "onCloseNewfilmSearchInputContextMenu"},  document.location.href);
      //    window.postMessage({event: "onCloseClinicContextMenu"},  document.location.href);
      // }

      const menus = this.$.contextMenu;

      if(target.classList.contains("textarea-container")) {
         this.elemEditor = target.querySelector("textarea");
      } else {
         this.elemEditor = target;
      }
      const rect = this.getBoundingClientRect();
      const {x, y} = rect;
      const {pageX, pageY} = e;
      const cw = this.contextWidth;
      const ch = this.contextHeight;

      const l = pageX - x;
      const t = pageY - y;

      let left = `${l}px`;
      if((pageX + cw) >= window.innerWidth) {
         left = `calc(100% - ${cw + 5}px)`;
      }

      let top  = `${t}px`;
      if((pageY + ch) >= window.innerHeight) {
         top = `calc(100% - ${ch + 5}px)`;
      }

      menus.style.left = left;
      menus.style.top = top;

      if(menus.classList.contains("hideContextMenu")) {
         menus.classList.remove("hideContextMenu"); // SHOW
         store.dispatch({ type: CommonActionType.SHOW_CONTEXT_MENU, payload: this.customUniqueKey });
      }


      // window.postMessage({event: "openOtherContextMenu"},  document.location.href);
      // window.postMessage({event: "openReportContextMenu"},  document.location.href);

      this.disableContextMenuItem(this.elemEditor);
   }

   disableContextMenuItem(target) {
      if(!target) return;

      const menus = this.$.contextMenu;
      const list = [".do-cut", ".do-delete", ".do-paste"];

      if(!this.supportClipboard) {
         list.forEach(name => this.addDisableContextMenuItem(menus.querySelector(name)));
         this.addDisableContextMenuItem(menus.querySelector(".do-copy"));
      } else {
         switch(this.elemEditor.tagName) {
         case "TEXTAREA": {
            const start = target.selectionStart;
            const end   = target.selectionEnd;

            if(target.hasAttribute("readonly")) {
               list.forEach(name => this.addDisableContextMenuItem(menus.querySelector(name)));
            } else {
               list.forEach(name => this.removeDisableContextMenuItem(menus.querySelector(name)));
               if(start === end) {
                  this.addDisableContextMenuItem(menus.querySelector(".do-cut"));
                  this.addDisableContextMenuItem(menus.querySelector(".do-delete"));
               } else {
                  this.removeDisableContextMenuItem(menus.querySelector(".do-cut"));
                  this.removeDisableContextMenuItem(menus.querySelector(".do-delete"));
               }
            }

            if(start === end) {
               this.addDisableContextMenuItem(menus.querySelector(".do-copy"));
            } else {
               this.removeDisableContextMenuItem(menus.querySelector(".do-copy"));
            }
            break;
         }
         case "DIV": {
            const bol = this.isSelectText(this.elemEditor);
            this.addDisableContextMenuItem(menus.querySelector(".do-cut"));
            this.addDisableContextMenuItem(menus.querySelector(".do-delete"));
            this.addDisableContextMenuItem(menus.querySelector(".do-paste"));
            if(bol) {
               this.removeDisableContextMenuItem(menus.querySelector(".do-copy"));
            } else {
               this.addDisableContextMenuItem(menus.querySelector(".do-copy"));
            }
            break;
         }
         default:
         }

         if(Object.keys(this.opinion||{}).includes("approveDtime")) {
            this.removeDisableContextMenuItem(menus.querySelector(".do-copy-report"));
         } else {
            this.addDisableContextMenuItem(menus.querySelector(".do-copy-report"));
         }
      }
   }

   addDisableContextMenuItem(el) {
      if(!el) return;

      if(!el.classList.contains("ag-menu-option-disabled"))
         el.classList.add("ag-menu-option-disabled");
   }

   removeDisableContextMenuItem(el) {
      if(!el) return;

      if(el.classList.contains("ag-menu-option-disabled"))
         el.classList.remove("ag-menu-option-disabled");
   }

   handleCut() {
      if((this.$.contextMenu.querySelector(".do-cut").classList.contains("ag-menu-option-disabled"))) return;
      if(!this.supportClipboard) {
         this.openToast(this.t("msg.opinion.clipboard.fail"), true);
         return;
      }

      // console.log("onCut", this.elemEditor, this.elemEditor.tagName);
      if(this.elemEditor.tagName !== "TEXTAREA") return;

      const v = this.elemEditor.value;
      const start = this.elemEditor.selectionStart;
      const end   = this.elemEditor.selectionEnd;

      navigator.clipboard.writeText(v.substring(start, end)).then(() => {
         const newVal = v.substring(0, start) + v.substring(end);
         // this.elemEditor.value = newVal;
         this.setNewValue(newVal);

         this.openToast(this.t("msg.opinion.clipboard.success"), false);

         // setTimeout(() => this.elemEditor.focus(), 0);
         this.focusPosition(start);
      });
   }

   copyWithButton() {
      this.copiedFindings = this.findings;
      this.copiedConclusion = this.conclusion;
      this.copiedRecommendation = this.recommendation;

      this.openToast(this.t("msg.opinion.copy.report"), false);
   }

   pasteWithButton() {
      if (this.$.taFinding.getAttribute("readonly") || this.$.taConclusion.getAttribute("readonly") || this.$.taRecommendation.getAttribute("readonly")) return;
      if (!this.copiedFindings && !this.copiedConclusion && !this.copiedRecommendation) return;

      this.findings = [this.findings, this.copiedFindings].filter(Boolean).join("\n");
      this.conclusion = [this.conclusion, this.copiedConclusion].filter(Boolean).join("\n");
      this.recommendation = [this.recommendation, this.copiedRecommendation].filter(Boolean).join("\n");

      this.$.taFinding.dispatchEvent(new CustomEvent("paste"));
      this.$.taConclusion.dispatchEvent(new CustomEvent("paste"));
      this.$.taRecommendation.dispatchEvent(new CustomEvent("paste"));
   }

   handleCopy() {
      if(!this.elemEditor || (this.$.contextMenu.querySelector(".do-copy").classList.contains("ag-menu-option-disabled"))) return;
      if(!this.supportClipboard) {
         this.openToast(this.t("msg.opinion.clipboard.fail"), true);
         return;
      }

      // console.log("onCopy", this.elemEditor);
      switch(this.elemEditor.tagName) {
      case "TEXTAREA": {
         const v = this.elemEditor.value;
         const start = this.elemEditor.selectionStart;
         const end   = this.elemEditor.selectionEnd;

         navigator.clipboard.writeText(v.substring(start, end)).then(() => {
            this.openToast(this.t("msg.opinion.clipboard.success"), false);
         });
         break;
      }
      case "DIV": {
         const text = this.selectedText(this.elemEditor);
         if(text) {
            navigator.clipboard.writeText(text).then(() => {
               this.openToast(this.t("msg.opinion.clipboard.success"), false);
            });
         }
         break;
      }
      default:
      }
   }

   handlePaste() {
      if(!this.elemEditor || this.elemEditor.hasAttribute("readonly")
            || (this.$.contextMenu.querySelector(".do-paste").classList.contains("ag-menu-option-disabled"))) return;
      if(!this.supportClipboard) {
         this.openToast(this.t("msg.opinion.clipboard.fail"), true);
         return;
      }

      if(this.elemEditor.tagName !== "TEXTAREA") return;

      // console.log("onPaste", this.elemEditor);
      const v = this.elemEditor.value;
      const start = this.elemEditor.selectionStart;
      const end   = this.elemEditor.selectionEnd;

      navigator.clipboard.readText().then((text) => {
         const newVal = v.substring(0, start) + text + v.substring(end);

         this.setNewValue(newVal);
         // this.elemEditor.value = v.substring(0, start) + text + v.substring(end);

         // setTimeout(() => this.setCaretToPos(this.elemEditor.textarea||this.elemEditor, start), 0);
         this.focusPosition(start + text.length);
         this.adjustTextareaHeights();
      });
   }

   handleDelete() {
      if(!this.elemEditor || this.elemEditor.hasAttribute("readonly")
            || (this.$.contextMenu.querySelector(".do-delete").classList.contains("ag-menu-option-disabled"))) return;

      // console.log("onDelete", this.elemEditor);

      if(this.elemEditor.tagName !== "TEXTAREA") return;

      const start = this.elemEditor.selectionStart;
      const end   = this.elemEditor.selectionEnd;
      const v = this.elemEditor.value;
      const newVal = v.substring(0, start) + v.substring(end);
      // this.elemEditor.value = newVal;
      this.setNewValue(newVal);

      // setTimeout(() => this.setCaretToPos(this.elemEditor.textarea||this.elemEditor, start), 0);
      this.focusPosition(start);
   }

   handleSelectAll() {
      if(!this.elemEditor || (this.$.contextMenu.querySelector(".do-select-all").classList.contains("ag-menu-option-disabled"))) return;

      // console.log("onSelectAll", this.elemEditor, this.elemEditor.tagName);

      switch(this.elemEditor.tagName) {
      case "TEXTAREA": {
         const ta = this.elemEditor.textarea||this.elemEditor;
         /* ta.setSelectionRange(0, this.elemEditor.value.length); */
         this.setSelectionRange(ta, 0, this.elemEditor.value.length);
         ta.select();
         ta.focus();
         break;
      }
      case "DIV": {
         this.selectText(this.elemEditor);
         break;
      }
      default:
      }
   }

   handleCopyReport() {
      if(!Object.keys(this.opinion||{}).includes("approveDtime")
            || (this.$.contextMenu.querySelector(".do-select-all").classList.contains("ag-menu-option-disabled"))) return;

      this.getCopyReport();
   }

   getCopyReport() {

      if(!this.supportClipboard) {
         this.openToast(this.t("msg.opinion.clipboard.fail"), true);
         return;
      }

      // console.log(this.opinion);
      const op = this.opinion;
      const study = this.opinion.study||{};
      const lines = [];
      // lines.push([study.studyDescription, study.studyDtime].filter(f => f !== null).join(", "));
      // lines.push("");
      lines.push(`[${[study.patientName, study.patientID, study.patientBirthDate, study.patientSex, study.studyDtime, study.studyDescription].map(m => (m||"").trim()).join(" ")}]`);
      lines.push("");
      lines.push("[Finding]");
      lines.push(op.finding);
      lines.push("");
      lines.push("[Conclusion]");
      lines.push(op.conclusion);
      lines.push("");
      lines.push("[Recommendation]");
      lines.push(op.recommendation);
      lines.push("");

      if(op.approver && op.approveDtime) lines.push(["Approved by", op.approver, "at", op.approveDtime].map(m => m.trim()).join(" "));

      if(op.addendumList && op.addendumList.length > 0) {
         lines.push("");
         lines.push("[Addendum]");
         op.addendumList.forEach((ad) => {
            lines.push(`[${[ad.addemdumer, "at", ad.addendumDtime].map(m => m.trim()).join(" ")}]`);
            lines.push(ad.addendum);
            lines.push("");
         });
      }

      const report = lines.join("\r\n");
      navigator.clipboard.writeText(report).then(() => {
         this.openToast(this.t("msg.opinion.clipboard.success"), false);
      });
   }

   changeScrollHeight(isIndividualScroll = false) {
      // console.log(`-> ${this.log} changeScrollHeight`, isIndividualScroll)

      const reportArea = this.$.reportContainer.querySelectorAll("textarea.report-area");
      reportArea.forEach((el) => {
         if(!isIndividualScroll) {
            // reportFristTopWrapper min-height 적용
            if (el.id === "taFinding") this.$.reportFristTopWrapper.style.minHeight = `${el.offsetHeight + 40}px`;
            // reportSecondTopWrapper min-height 값 계산
            if (el.id === "taConclusion") this.$.reportSecondTopWrapper.style.minHeight = `${el.offsetHeight + 40}px`;
            // reportSecondBottomWrapper min-height 값 계산
            if (el.id === "taRecommendation") this.$.reportSecondBottomWrapper.style.minHeight = `${el.offsetHeight + 40}px`;
            // reportFristBottomWrapper min-height 값 계산
            if (el.id === "taConclusion" || el.id === "taRecommendation") this.$.reportFristBottomWrapper.style.minHeight = `${this.$.taConclusion.offsetHeight + this.$.taRecommendation.offsetHeight + 80}px`;
         } else {
            // reportFristTopWrapper min-height 적용
            if (el.id === "taFinding") this.$.reportFristTopWrapper.style.minHeight = `62px`; // 22 + 40
            // reportSecondTopWrapper min-height 값 계산
            if (el.id === "taConclusion") this.$.reportSecondTopWrapper.style.minHeight = `62px`; // 22 + 40
            // reportSecondBottomWrapper min-height 값 계산
            if (el.id === "taRecommendation") this.$.reportSecondBottomWrapper.style.minHeight = `62px`; // 22 + 40
            // reportFristBottomWrapper min-height 값 계산
            if (el.id === "taConclusion" || el.id === "taRecommendation") this.$.reportFristBottomWrapper.style.minHeight = `124px`; // (22 + 40) + (22 + 40)
         }
      });

      const isFixedHeight = this.checkFixedHeight();
      this.changeFixedHeight(isFixedHeight);

      // #16460 전체스크롤 초기화 처리
      if(isIndividualScroll) this.$.approveContent.style.overflow = "hidden";
      else this.$.approveContent.style.overflow = "auto";

      // #16460 [HPACS > 임태환 교수님] 판독창의 Findings, Conclusion, Recommendation 의 스크롤이 각각 있을 수 있게 처리 요청
      const container = this.$.reportContainer.querySelectorAll("div.textarea-container");
      container.forEach((el) => {
         el.style.overflow = "hidden";
      });
   }

   getStyleObj() {
      return new Promise((resolve, reject) => {
         fetch(`/api/user/option/style`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")
            }
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((rows) => {
                  resolve(rows);
               });
            } else {
               reject(new Error(`status: ${response.status}, message: ${response.statusText}`));
            }
         });
      });
   }

   // updateSplitLayout(layout) {
   //    fetch(`/api/user/option/style/layout`, {
   //       method: "PATCH",
   //       headers: {
   //          "Authorization": localStorage.getItem("jwt"),
   //          "Content-Type": "application/json"
   //       },
   //       body: JSON.stringify(layout)
   //    }).then((response) => {
   //       if (!response.ok) {
   //          console.debug(new Error(`${response.status} ${response.statusText}`));
   //       }
   //    });
   // }

   openToast(msg, isErr) {
      const detail = {msg, isErr};
      this.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail}));
      if (this.popupReport.open && window.opener) {
         window.opener.postMessage({event: "POPUP_TOAST_EVENT", detail }, document.location.href);
      }
   }

   setSelectionRange(input, selectionStart, selectionEnd) {
      if (input.setSelectionRange) {
         input.focus({ preventScroll: true });
         input.setSelectionRange(selectionStart, selectionEnd);
      }
      else if (input.createTextRange) {
         const range = input.createTextRange();
         range.collapse(true);
         range.moveEnd("character", selectionEnd);
         range.moveStart("character", selectionStart);
         range.select();
      }
   }

   focusPosition(pos, isNotAutoScroll) {
      queueMicrotask(() => this.setSelectionRange(this.elemEditor.textarea||this.elemEditor, pos, pos));
      // this.setSelectionRange(input, pos, pos);
   }

   setNewValue(val) {
      const target = this.elemEditor.getAttribute("id");
      switch(target) {
      case "taFinding": {
         this.findings = val;
         break;
      }
      case "taConclusion": {
         this.conclusion = val;
         break;
      }
      case "taRecommendation": {
         this.recommendation = val;
         break;
      }
      default:
         this.elemEditor.value = val;
      }
   }

   selectText(el) {
      if (window.getSelection && document.createRange) { // Browser compatibility
         const sel = window.getSelection();
         if(sel.toString() === "") { // no text selection
            window.setTimeout(() => {
               const range = document.createRange(); // range object
               range.selectNodeContents(el); // sets Range
               sel.removeAllRanges(); // remove all ranges from selection
               sel.addRange(range);// add Range to a Selection.
            }, 0);
         }
      } else if (document.selection) { // older ie
         const sel = document.selection.createRange();
         if(sel.text === "") { // no text selection
            const range = document.body.createTextRange(); // Creates TextRange object
            range.moveToElementText(el); // sets Range
            range.select(); // make selection.
         }
      }
   }

   isSelectText() {
      if (window.getSelection && document.createRange) {
         const sel = window.getSelection();
         return (sel.toString() !== "");
      }

      if (document.selection) {
         const sel = document.selection.createRange();
         return (sel.text !== "");
      }
      return false;
   }

   selectedText() {
      if (window.getSelection && document.createRange) { // Browser compatibility
         const sel = window.getSelection();
         return sel.toString();
      }

      if (document.selection) { // older ie
         const sel = document.selection.createRange();
         return sel.text;
      }

      return null;
   }

   // getCaseId(id) {
   //    // console.log("getCaseId: ", id);
   //    this.g_objId = id;
   // }

   setAiReport(event) {
      if (this.$.approveContent.style.display !== "none") {

         if(event.data) {
            event.detail = event.data.detail;
         }

         switch (event.detail.type) {
         case "finding":
            if (this.findings.length >= 1) {
               this.findings = `${this.findings} \n [AI result] \n ${event.detail.text}`;
            } else {
               this.findings = `[AI result] \n ${event.detail.text}`;
            }

            this.$.taFinding.dispatchEvent(new CustomEvent("paste"));
            break;
         case "conclusion":
            if (this.conclusion.length >= 1) {
               this.conclusion = `${this.conclusion} \n [AI result] \n ${event.detail.text}`;
            } else {
               this.conclusion = `[AI result] \n ${event.detail.text}`;
            }

            this.$.taConclusion.dispatchEvent(new CustomEvent("paste"));
            break;
         case "recommendation":
            if (this.recommendation.length >= 1) {
               this.recommendation = `${this.recommendation} \n [AI result] \n ${event.detail.text}`;
            } else {
               this.recommendation = `[AI result] \n ${event.detail.text}`;
            }

            this.$.taRecommendation.dispatchEvent(new CustomEvent("paste"));
            break;
         default:
            break;
         }
      } else {
         return null;
      }
   }

   // #17757 [HPACS] 리포트팝업창 새로고침 이후 ROW 이동하게 되면 환자 정보 업데이트 되지 않음
   ableNewAddendum(addendum) {
      // console.log(addendum);
      this.$.newAddendumText.style.display = "block";
      this.$.newAddendum.className = "accordion active";
      this.$.newAddendumText.value = addendum;
      this.$.newAddendumText.value = `${addendum}`;
   }

   // #17757 [HPACS] 리포트팝업창 새로고침 이후 ROW 이동하게 되면 환자 정보 업데이트 되지 않음
   setEditOpinion(opinion) {
      if(opinion.finding) this.findings = opinion.finding;
      if(opinion.conclusion) this.conclusion = opinion.conclusion;
      if(opinion.recommendation) this.recommendation = opinion.recommendation;
      this.adjustTextareaHeights();
   }
   //
   // getNewAddendumText() {
   //    if(this.$.newAddendumText.style.display === "block") {
   //       return this.$.newAddendumText.value;
   //    }return "";
   // }

   changeIndividualScroll(isIndividualScroll) {
      this.changeScrollHeight(isIndividualScroll);
   }

   _selectedRowChanged(row = {}) {
      // console.log("hhReport>>>_selectedRowChanged : ", row);

      this.isMismatchContinue = false;

      // if(row.detail) {
      //    const newRow = row?.detail;
      //    const newRows = row?.row;
      //    const oldRow = row?.oldRow;
      //    const checkClear = this.checkClear();
      //
      //    if(checkClear.isConfirm) {
      //       // 현재 선택된 판독문 출력
      //       this.setOpinion({finding: "", conclusion: "", recommendation: "", approverLicenseNo: "", study: newRow});
      //       this.setReport(newRow, newRows);
      //    } else {
      //       // 이전 선택한 판독문 추가
      //       Object.assign(oldRow || {}, {editOpinion: checkClear.editOpinion});
      //       const user = JSON.parse(localStorage.user);
      //       let saveStatus = "H";
      //       // 내가 아닌 다른사람이 임시저장한 판독문은 나한테 보이지 않음
      //       if (oldRow && (user.name !== oldRow.readingDoctor) && oldRow.readingStatus === "2T") saveStatus = "O";
      //
      //       // 경고메시지
      //       if(oldRow) this.onReportConfirmDialog({newRow, oldRow}, oldRow.readingStatus, saveStatus);
      //    }
      // } else {
      //    // 판독문 검사
      //    const newRow = this._selectedRow?.oldRow;
      //    const oldRow = this._selectedRow?.oldRow;
      //    const checkClear = this.checkClear();
      //
      //    if(checkClear.isConfirm) {
      //       this.setOpinion({finding:"", conclusion:"", recommendation:"", study: newRow});
      //    } else {
      //       // 이전 선택한 판독문 추가
      //       Object.assign(oldRow || {}, {editOpinion:checkClear.editOpinion});
      //       const user = JSON.parse(localStorage.user);
      //       let saveStatus = "H";
      //       // 내가 아닌 다른사람이 임시저장한 판독문은 나한테 보이지 않음
      //       if (oldRow && (user.name !== oldRow.readingDoctor) && oldRow.readingStatus === "2T") saveStatus = "O";
      //
      //       // 경고메시지
      //       this.onReportConfirmDialog({newRow, oldRow}, oldRow.readingStatus, saveStatus);
      //    }
      // }
   }

   _selectedReportRowChanged(row = {}, old) {
      if(row.detail) {
         if (row.detail.id === old?.detail?.id) return; // 이미 설정된 정보라면 return

         const { detail: newRow, rows: newRows, oldRow } = row;
         const referenceRow = newRows.filter(r => r.processingStatus === "reference");
         if (!referenceRow.length) {
            const checkClear = this.checkClear();

            if(checkClear.isConfirm) { // 판독문이 변경되지 않았으면
               // 현재 선택된 판독문 출력
               this.setOpinion({finding: "", conclusion: "", recommendation: "", approverLicenseNo: "", study: newRow});
               this.setReport(newRow, newRows);
            } else {
               // 이전 선택한 판독문 추가
               Object.assign(oldRow || {}, {editOpinion: checkClear.editOpinion});
               const user = JSON.parse(localStorage.user);
               let saveStatus = "H";
               // 내가 아닌 다른사람이 임시저장한 판독문은 나한테 보이지 않음
               if (oldRow && (user.name !== oldRow.readingDoctor) && oldRow.readingStatus === "2T") saveStatus = "O";

               // 경고메시지
               if(oldRow) this.onReportConfirmDialog({newRow, oldRow}, oldRow.readingStatus, saveStatus);
            }

            this.isMismatchContinue = false;
         } else {
            this.isDisabledMarkCVR = true; // mark cvr Btn disable
            this.enabledEditBtn(false); // edit disable
            this.enabledReportArea(false); // report area enable
            this.enabledClearBtn(); // clear enable
            this.disabledPrintBtn(); // print disable
            this.enabledSaveBtn(false); // save Btn disable
            this.enabledApproveBtn(false); // approve Btn disable
            this.disabledClearBtn(); // clear Btn disable
            this.disableAddendumList();
            this.visibleAddendumDiv(false);
         }
         // this.getSelectedRowsCaseInfo([row.detail.id]).then((r) => {
         // }).catch((e) => {
         //    console.error(e);
         // });
      } else {
         this.initReportButton();

         if (row.oldRow) {
            // 판독문 검사
            const newRow = row.oldRow;
            const oldRow = row.oldRow;
            const checkClear = this.checkClear();

            if (checkClear.isConfirm) { // 판독문이 변경되지 않았으면
               this.setOpinion({finding: "", conclusion: "", recommendation: "", study: newRow});
            } else {
               // 이전 선택한 판독문 추가
               Object.assign(oldRow || {}, {editOpinion: checkClear.editOpinion});
               const user = JSON.parse(localStorage.user);
               let saveStatus = "H";
               // 내가 아닌 다른사람이 임시저장한 판독문은 나한테 보이지 않음
               if (oldRow && (user.name !== oldRow.readingDoctor) && oldRow.readingStatus === "2T") saveStatus = "O";

               // 경고메시지
               this.onReportConfirmDialog({newRow, oldRow}, oldRow.readingStatus, saveStatus);
            }
         }
      }
   }

   onReportConfirmDialog(row, readingStatus, saveStatus) {
      const message = {
         params: { row },
         readingStatus,
         saveStatus,
         callback: (result, params) => {
            this.reportConfirm(params).then(() => {});
         }
      };
      if ((this.isPopup && this.popupReport.open) || (!this.isPopup && !this.popupReport.open)) {
         store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.REPORT_CONFIRM_DIALOG, actionType: DialogActionType.REPORT_NOT_SAVED, message, open: true } });
      }
   }

   async reportConfirm(params = {}) {
      if(params.row) {
         const {newRow, oldRow} = params?.row;
         const oldRows = [oldRow];
         const {editOpinion} = oldRow;

         switch(params.btn) {
         case "approve":
            if(editOpinion && editOpinion.addendum) await this.addendumOpinion(oldRow.id, editOpinion, oldRow);
            else await this.approveOpinion(oldRow, oldRows, editOpinion);
            break;
         case "save":
            await this.saveOpinion(false, oldRow, oldRows);
            break;
         default:
            window.postMessage({event: "READING_OFF"}, document.location.href);

            break;
         }
         await this.setNewReport(params, newRow);
      }

   }

   setNewReport(params = {}, row = {}) {
      this.setOpinion({finding:"", conclusion:"", recommendation:"", study: row});
      this.setReport(row, [row]);
      window.isConfirmboxOpened = false;
   }

   setReport(selectedRow = {}, selectedRows = [], editOpinion) {
      const user = JSON.parse(localStorage.user);
      if (selectedRows.length === 0) selectedRows = [selectedRow];
      if (this.popupEditOpinion && !CommonUtils.isEmptyObject(this.popupEditOpinion)) {
         editOpinion = { ...this.popupEditOpinion };
         this.popupEditOpinion = undefined;
      }

      // report btn init
      this.isDisabledMarkCVR = true; // mark cvr disable
      this.enabledEditBtn(false); // edit disable
      this.enabledReportArea(true); // report area enable
      this.enabledClearBtn(); // clear enable
      this.disabledPrintBtn(); // print disable
      this.enabledSaveBtn(false); // save Btn disable
      this.enabledApproveBtn(false); // approve Btn disable
      this.enabledTranscribeBtn(false);

      if(selectedRow) {
         // 판독문이 존재할 경우(RS : 2T, 3A)
         switch (selectedRow.readingStatus) {
         case "3A":
            if (this.canMarkCVR(selectedRows)) this.isDisabledMarkCVR = false;
            if (this.canPrint(selectedRows)) this.enabledPrintBtn();

            // console.log("approve");
            this.getOpinionByWeever(selectedRow.id).then((opinionResult) => {
               // 현재 선택된 판독문 추가
               Object.assign(selectedRow, {
                  finding: opinionResult.finding,
                  conclusion: opinionResult.conclusion,
                  recommendation: opinionResult.recommendation
               });
               // this._rows.new = selectedRow;

               this.getAllAddendumByWeever(selectedRow.id).then((addendumList) => {
                  const elipseTime = CommonUtils.elapsedTimeCheck(opinionResult.approveTime); // min

                  // NOTE 재판독 가능
                  if(opinionResult.isApproveModify
                     && ((elipseTime <= (opinionResult.approveModifyHours * 60)) || opinionResult.approveModifyHours === 0)
                     && addendumList == null
                     && ((opinionResult.approveModifyRoles === "User" && user.id === opinionResult.userId) || (opinionResult.approveModifyRoles === "Group" && opinionResult.isContainsMembers)) ) {

                     // 해당 케이스에 addendum이 입력되지 않았고, 72시간(3일, 4320분) 이내 본인이 판독문을 수정 하였을때.
                     // console.log("approve correct");
                     this.disableAddendumList();
                     this.invisibleAddendumDiv();
                     this.visibleApproveDiv();
                     // this.$.hhReportWindow.changeApproveText("Approve");

                     // this.$.hhReportWindow.enabledSaveBtn(false);
                     this.enabledApproveBtn(false);
                     this.enabledReportArea(false);
                     this.disabledClearBtn();
                     if (selectedRows.length === 1) // this.canEdit(selectedRows)
                        this.enabledEditBtn(true);

                     const opinion = {};
                     opinion.finding = opinionResult.finding;
                     opinion.conclusion = opinionResult.conclusion;
                     opinion.recommendation = opinionResult.recommendation;
                     opinion.approverLicenseNo = opinionResult.approverLicenseNo;

                     this.setOpinion(Object.assign(opinion, {study: selectedRow}));

                     // #17757 [HPACS] 리포트팝업창 새로고침 이후 ROW 이동하게 되면 환자 정보 업데이트 되지 않음
                     if (editOpinion && !CommonUtils.isEmptyObject(editOpinion) && !CommonUtils.isEmptyObject(selectedRow)) {
                        this.setEditOpinion(editOpinion);
                        this.editButtonIsActive(selectedRow, true);
                     }

                     // 팝업에서 edit, addendum 중이었을 경우해당 작업 이어서 하도록
                     // if (data.constructor !== Object || Object.keys(data).length !== 0) {
                     //    if (data.edit.editButtonIsActive) {
                     //       const {findings, conclusion, recommendation, editButtonIsActive} = data.edit;
                     //       this.$.hhReportWindow.setReport(findings, conclusion, recommendation);
                     //       this.editButtonIsActive(data.edit.editButtonIsActive);
                     //    }
                     //    if (data.writingAddendum.addendum) {
                     //       this.$.hhReportWindow.ableNewAddendum(data.writingAddendum.addendum);
                     //    }
                     // }
                  } else { // NOTE 재판독 불가능
                     // 판독문이 확정 되었거나, 72시간(3일, 4320분) 이내에 다른 사람이 판독 하였을때.
                     // approve (addendum 이 없을때), addendum
                     // 72시간(3일, 4320분) 이후 에는 누가 판독 했던 addendum
                     // console.log("approve addendum")
                     // this.$.hhReportWindow.enabledSaveBtn(false);

                     this.enableAddendumReport(opinionResult, addendumList, selectedRows, selectedRow, editOpinion);

                     // 팝업에서 edit, addendum 중이었을 경우해당 작업 이어서 하도록
                     // if (data.constructor !== Object || Object.keys(data).length !== 0) {
                     //    if (data.edit.editButtonIsActive) {
                     //       const {findings, conclusion, recommendation, editButtonIsActive} = data.edit;
                     //       this.$.hhReportWindow.setReport(findings, conclusion, recommendation);
                     //       this.editButtonIsActive(data.edit.editButtonIsActive);
                     //    }
                     //    if (data.writingAddendum.addendum) {
                     //       this.$.hhReportWindow.ableNewAddendum(data.writingAddendum.addendum);
                     //    }
                     // }
                  }
               });
            });
            break;
         case "2T":
            // 선택한 케이스가 판독이 되지 않았을때
            // console.log("save");
            this.disableAddendumList();
            this.invisibleAddendumDiv();
            this.visibleApproveDiv();
            // this.$.hhReportWindow.enabledApproveBtn(false);
            // this.$.hhReportWindow.enabledSaveBtn(false);
            // this.$.hhReportWindow.changeApproveText("Approve");

            // 판독문을 확정하기 전에 판독문을 임시 저장하였을때,
            // 저장된 임시 판독문이 내가 저장한게 아닐때.
            // 판독문이 임시 저장 되어 있지만 내가 작성한게 아니라면 확인 불가.
            this.getOpinionByWeever(selectedRow.id).then((result) => {

               Object.assign(selectedRow, {
                  finding: result.finding,
                  conclusion: result.conclusion,
                  recommendation: result.recommendation
               });
               // this._rows.new = selectedRow;

               // console.log(result);
               if (result.userId === user.id) {
                  // console.log("save");
                  // 저장된 임시 판독문을 내가  다시 확인할때
                  // save (Hold)
                  const opinion = {};
                  opinion.finding = result.finding;
                  opinion.conclusion = result.conclusion;
                  opinion.recommendation = result.recommendation;
                  opinion.approverLicenseNo = result.approverLicenseNo;

                  this.setOpinion(Object.assign(opinion, {study: selectedRow}));

                  // 팝업에서 edit, addendum 중이었을 경우해당 작업 이어서 하도록
                  // if (data.constructor !== Object || Object.keys(data).length !== 0) {
                  //    if (data.edit.editButtonIsActive) {
                  //       const {findings, conclusion, recommendation, editButtonIsActive} = data.edit;
                  //       this.$.hhReportWindow.setReport(findings, conclusion, recommendation);
                  //       this.editButtonIsActive(data.edit.editButtonIsActive);
                  //    }
                  //    if (data.writingAddendum.addendum) {
                  //       this.$.hhReportWindow.ableNewAddendum(data.writingAddendum.addendum);
                  //    }
                  // }

                  if (this.canApprove(selectedRows)) this.enabledApproveBtn(true);
                  if (this.canSave(selectedRows)) this.enabledSaveBtn(true);
               } else {
                  // Save (Open)
                  this.enabledApproveBtn(false);
                  if (this.canSave(selectedRows)) this.enabledSaveBtn(true);
               }

               // #17757 [HPACS] 리포트팝업창 새로고침 이후 ROW 이동하게 되면 환자 정보 업데이트 되지 않음
               if (editOpinion && !CommonUtils.isEmptyObject(editOpinion) && !CommonUtils.isEmptyObject(selectedRow)) {
                  this.setEditOpinion(editOpinion);
               }
            });
            break;
         case "2DT":
            this.disableAddendumList();
            this.invisibleAddendumDiv();
            this.visibleApproveDiv();
            // this.$.hhReportWindow.changeApproveText("Approve");

            this.getOpinionByWeever(selectedRow.id).then((result) => {
               // transcribe 열람 가능 조건
               if( (result.transcribeReadRoles === "User" && result.userId === user.id)
                  || (result.transcribeReadRoles === "Group" && result.isContainsMembers)
                  || (result.transcribeReadRoles === "All" && (result.userId === user.id || result.isContainsMembers || result.isContainsFacilityMembers)) ) {

                  Object.assign(selectedRow, {
                     finding: result.finding,
                     conclusion: result.conclusion,
                     recommendation: result.recommendation
                  });

                  const opinion = {};
                  opinion.finding = result.finding;
                  opinion.conclusion = result.conclusion;
                  opinion.recommendation = result.recommendation;
                  opinion.approverLicenseNo = result.approverLicenseNo;

                  this.setOpinion(Object.assign(opinion, {study: selectedRow}));

                  if( (result.transcribeModifyRoles === "User" && result.userId === user.id)
                     || (result.transcribeModifyRoles === "Group" && result.isContainsMembers) ) {
                     if (this.canApprove(selectedRows)) this.enabledApproveBtn(true);
                     if (this.canTranscribe(selectedRows)) this.enabledTranscribeBtn(true);
                  }
               } else { // transcribe 열람 불가능.
                  this.enabledApproveBtn(false);
                  if (this.canTranscribe(selectedRows)) this.enabledTranscribeBtn(true);
               }

               // #17757 [HPACS] 리포트팝업창 새로고침 이후 ROW 이동하게 되면 환자 정보 업데이트 되지 않음
               if (editOpinion && !CommonUtils.isEmptyObject(editOpinion) && !CommonUtils.isEmptyObject(selectedRow)) {
                  this.setEditOpinion(editOpinion);
               }
            });
            break;
         default:
            // 선택한 케이스가 판독이 되지 않았을때
            // console.log("wait");
            this.disableAddendumList();
            if (this.canApprove(selectedRows)) this.enabledApproveBtn(true);
            if (this.canSave(selectedRows)) this.enabledSaveBtn(true);
            if (this.canTranscribe(selectedRows)) this.enabledTranscribeBtn(true);
            this.invisibleAddendumDiv();
            this.visibleApproveDiv();
            // this.$.hhReportWindow.changeApproveText("Approve");

            // this._rows.new = selectedRow;
            this.setOpinion({
               finding: "",
               conclusion: "",
               recommendation: "",
               approverLicenseNo: "",
               study: selectedRow
            });

            // #17757 [HPACS] 리포트팝업창 새로고침 이후 ROW 이동하게 되면 환자 정보 업데이트 되지 않음
            if (editOpinion && !CommonUtils.isEmptyObject(editOpinion) && !CommonUtils.isEmptyObject(selectedRow)) {
               this.setEditOpinion(editOpinion);
            }
            break;
         }
      }
   }

   editButtonIsActive(row, isActive) {
      if(row.readingStatus !== "1W") {
         if (isActive) {
            this.enabledReportArea(true);
            this.enabledApproveBtn(true);
            this.enabledEditBtn(false);
            this.enabledClearBtn();
         } else {
            this.enabledReportArea(false);
            this.enabledApproveBtn(false);
            this.enabledEditBtn(true);
            this.enabledClearBtn();
         }
      }
   }

   canApprove(rows = []) {
      return rows.filter(row => row.readingStatus === "3A").length === 0; // W(1W)이거나, H,O(2T)인 경우만 APPROVE 가능, (예외, edit button click)
   }

   canSave(rows = []) {
      return rows.every(row => row.readingStatus !== "3A" && row.readingStatus !== "2DT");
   }

   canTranscribe(rows = []) {
      return rows.every(row => row.readingStatus !== "3A" && row.readingStatus !== "2T");
   }

   canEdit(rows = []) {
      // TODO: 3일 조건도 추가해야 함
      // return rows.filter(row => row.readingStatus !== "3A").length === 0; // A(3A)인 경우만 EDIT 가능
   }

   canPrint(rows = []) {
      return rows.filter(row => row.readingStatus !== "3A").length === 0; // A(3A)인 경우만 PRINT 가능
   }

   canMarkCVR(rows = []) {
      return rows.filter(row => row.readingStatus === "3A").length === 1; // A(3A)가 한개인 경우만 MARK CVR 가능
   }

   enableAddendumReport(opinionResult = {}, addendumList = [], selectedRows = [], selectedRow = {}, editOpinion) {
      this.invisibleApproveDiv();
      this.enableAddendumList();
      let enableAddendumBtn = true;
      if (selectedRows.length > 1) {
         enableAddendumBtn = false;
         this.disabledClearBtn();
      }
      this.visibleAddendumDiv(enableAddendumBtn);

      const opinion = {};
      opinion.finding = opinionResult.finding;
      opinion.conclusion = opinionResult.conclusion;
      opinion.recommendation = opinionResult.recommendation;
      opinion.approverLicenseNo = opinionResult.approverLicenseNo;

      if (opinionResult.approver) {
         opinion.approver = opinionResult.approver;

         if (opinionResult.approveTime) {
            const approveDtime = new Date(parseInt(opinionResult.approveTime, 10));
            opinion.approveDtime = CommonUtils.getMilisecToDate(approveDtime);
         }
      } else {
         if (opinionResult.owner) {
            opinion.approver = opinionResult.owner;
         } else {
            opinion.approver = "";
         }

         if (opinionResult.approveTime) {
            const approveDtime = new Date(parseInt(opinionResult.approveTime, 10));
            opinion.approveDtime = CommonUtils.getMilisecToDate(approveDtime);
         }
      }
      if (addendumList && addendumList.length > 0) {
         // addendum 이 한개 이상
         // console.log("approve addendom list");
         this.enabledApproveBtn(false);
         this.ableAddendumAccordionList();
         const tempList = [];
         for (let i = 0; i < addendumList.length; i++) {
            const addendum = {};
            if (addendumList[i].finding) {
               if (addendumList[i].finding) {
                  addendum.addendum = addendumList[i].finding;
               }

               if (addendumList[i].conclusion) {
                  addendum.addendum += ` ${addendumList[i].conclusion}`;
               }

               if (addendumList[i].recommendation) {
                  addendum.addendum += ` ${addendumList[i].recommendation}`;
               }
            } else if (addendumList[i].conclusion) {
               addendum.addendum = addendumList[i].conclusion;
            }

            // console.log("addendum list descending")
            const addendumDate = new Date(parseInt(addendumList[i].approveTime, 10));
            addendum.addendumDtime = CommonUtils.getMilisecToDate(addendumDate);
            addendum.addemdumer = addendumList[i].approver;
            addendum.approverLicenseNo = addendumList[i].approverLicenseNo;
            tempList.push(addendum);
         }
         opinion.addendumList = tempList;
      } else {
         this.disabledAddendumAccordionList();
      }

      this.setOpinion(Object.assign(opinion, {study: selectedRow}));

      // #17757 [HPACS] 리포트팝업창 새로고침 이후 ROW 이동하게 되면 환자 정보 업데이트 되지 않음
      if (editOpinion && !CommonUtils.isEmptyObject(editOpinion) && editOpinion.addendum && !CommonUtils.isEmptyObject(selectedRow)) {
         this.ableNewAddendum(editOpinion.addendum);
      }
   }

   getAllAddendumByWeever(id) {
      return new Promise((resolve, reject) => {
         fetch(`/api/opinion/addendums/${id}`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")
            }
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((httpResponse) => {
                  resolve(httpResponse);
               });
            }
            else if(response.status === 204){
               resolve(null);
            }else{
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }

   getOpinionByWeever(id) {
      return new Promise((resolve) => {
         fetch(`/api/opinion/weever/${id}`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")
            }
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((httpResponse) => {
                  resolve(httpResponse);
               });
            } else {
               resolve("failed");
            }
         });
      });
   }

   getCurrentTime(ids) {
      return new Promise((resolve, reject) => {
         fetch(`/api/opinion/cert/currentTime?ids=${ids}`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json"
            }
         }).then((response) => {
            if(response.status !== 200) {
               throw new Error(`[${response.status}] ${response.statusText}`);
            } else {
               response.json().then((httpResponse) => {
                  return resolve(httpResponse);
               });
            }
         }).catch((e) => {
            this.openToast(e.message, true);
            reject(e);
         });
      });
   }

   makeCertJson(row, time, opinion) {
      const user = JSON.parse(localStorage.getItem("user"));
      const regex = /[^0-9]/g;
      const studyDtime = row.studyDtime.replace(regex, "");
      return {
         id: row.id,
         hospital: {
            id: row.referralFacilityId,
            name: row.requestHospital
         },
         patient: {
            id: row.patientID,
            name: row.patientName,
            dobr: row.patientBirthDate,
            sex: row.patientSex
         },
         exam: {
            name: row.studyDescription,
            date: studyDtime
         },
         opinion: {
            finding: opinion.finding,
            conclusion: opinion.addendum ? opinion.addendum : opinion.conclusion,
            recommendation: opinion.recommendation,
            userId: user.id,
            approver: user.name,
            approveDtime: time
         }
      };
   }

   isRequiredSign() {
      return JSON.parse(localStorage.getItem(__CERT_STORAGE__)) === true;
   }

   doSign(hash, caseId) {
      return new Promise((resolve, reject) => {
         // if(JSON.parse(localStorage.getItem(__CERT_STORAGE__)) !== true) {
         if(!this.isRequiredSign()) {
            resolve({sign: false});
         } else {
            // const userDN = Cookies.get("userDN");
            // const userDN = sessionStorage.getItem(__CERT_USER_DN__);
            unisign.SignDataNonEnveloped(hash, this.userDN, "", (resultObj) => {
               if(!resultObj || resultObj.resultCode !== 0) {
                  const {resultCode, resultMessage} = resultObj;
                  let isErr = true;
                  switch(resultCode) {
                  case 999: {
                     isErr = false;
                     break;
                  }
                  default: {
                     isErr = true;
                  }
                  }
                  const detail = {msg: `[${resultCode }] ${resultMessage}`, isErr, code: resultCode};
                  reject(detail);
               } else {
                  // certAtLogin(로그인 후 한번만 인증)인 경우만 sessionStorage 등록
                  if (this.certAtLogin && (!this.userDN || this.userDN === ""))
                     // sessionStorage.setItem(__CERT_USER_DN__, resultObj.certAttrs.subjectName);
                     store.dispatch({ type: ReportActionType.SET_USERDN, payload: resultObj.certAttrs.subjectName });
                  // Cookies.set("userDN", resultObj.certAttrs.subjectName);

                  resolve({sign: true, signedData: resultObj.signedData, caseId});
               }
            });
         }
      });
   }

   isEmptyAddendum(opinion) {
      return (opinion.addendum == null || opinion.addendum === "") ? Boolean(true) : Boolean(false);
   }

   createAddendumOption(addendum = "cosigned", imageReport) {
      return {
         finding: "",
         conclusion: addendum,
         recommendation: "",
         imageReport
      };
   }

   validateOpinion(opinion) {
      if (!opinion || (opinion.finding === "" && opinion.conclusion === "" && opinion.recommendation === "")) {
         this.openToast(this.t("msg.opinion.validate.empty"), true);
         return false;
      }

      if(!this.isApprover() && !this.isRefer()){
         this.openToast(this.t("msg.opinion.validate.permission"), true);
         return false;
      }
      return true;
   }

   isApprover() {
      const user = JSON.parse(localStorage.user);
      // if (user.isScp && user.licenseNo != null) {
      if (user.licenseNo != null) {
         return true;
      }
      return false;
   }

   isRefer() {
      const user = JSON.parse(localStorage.user);
      if (user.isRefer) return true;
      return false;
   }

   isApprove(row) {
      return new Promise((resolve, reject) => {
         const user = JSON.parse(localStorage.user);
         if (row[0].readingStatus === "3A") {
            resolve(false);
            // this.checkHumanCase(row[0].id).then((isHumanApprover) => {
            //    if(!isHumanApprover){
            //       resolve(true);
            //    } else if(row[0].readingDoctorId === user.id){
            //       resolve(true);
            //    } else {
            //       resolve(false);
            //    }
            // }
            // , (err) => {
            //    console.log(err);
            //    reject(new Error(false));
            // }
            // ).catch((err) => {
            //    console.log(err);
            //    reject(new Error(false));
            // });
         } else {
            resolve(true);
         }
      });
   }

   isSave(row) {
      return new Promise((resolve) => {
         const user = JSON.parse(localStorage.user);
         if (row[0].readingStatus === "2T") {
            if(user.id === row[0].readingDoctorId) {
               resolve(true);
            } else {
               resolve(false);
            }
         }
         else if(row.readingStatus === "3A") {
            resolve(false);
         } else {
            resolve(true);
         }
      });
   }

   getSelectedRowsCaseInfo(ids) {
      return new Promise((resolve) => {
         fetch(`/api/case/list?ids=${ids.join(",")}`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")
            }
         }).then((response) => {
            if (response.ok) {
               response.json().then((httpResponse) => {
                  resolve(httpResponse);
               });
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   };

   addendumOpinion(id = this._selectedRow.detail.id, opinion = this.getOpinion(), row = this._selectedRow.detail) {
      if(!this.popupReport.open) {
         if(window.filmbox && window.filmbox.get()) {
            window.postMessage({event: "REPORTING", callType: "addendum", id, opinion, row}, document.location.href);
         } else if(window.filmbox && !window.filmbox.get()) {
            // filmbox off
            this.addendumOpinionData(id, opinion, row);
         }
      } else {
         if(window.opener.filmbox && window.opener.filmbox.get()) {
            window.opener.postMessage({event: "REPORTING", callType: "addendum", id, opinion, row}, document.location.href);
         } else if(window.opener.filmbox && !window.opener.filmbox.get()) {
            this.addendumOpinionData(id, opinion, row);
         }
      }
   }

   addendumOpinionData(id = this._selectedRow.detail.id, opinion = this.getOpinion(), row = this._selectedRow.detail) {
      this.getAllAddendumByWeever(id).then((addendumList) => {
         const user = JSON.parse(localStorage.user);

         if(!opinion) opinion = this.getOpinion();

         const isEmptyAddendum = this.isEmptyAddendum(opinion);
         // const opinion = this.$.hhReportWindow.getOpinion();
         if(!addendumList) {
            this.getOpinionByWeever(id).then((opinionResult) => {
               if(user.id === opinionResult.userId) {
                  if(isEmptyAddendum) {
                     window.rejected();
                     this.openToast(this.t("msg.opinion.validate.empty"), true);
                  } else {
                     const addendumOption = this.createAddendumOption(opinion.addendum, opinion.imageReport);
                     if (this.validateOpinion(addendumOption)) this.updateAddendumOpinion(id, addendumOption, row);
                  }
               } else {
                  let addendumOption = {};
                  if(isEmptyAddendum) {
                     addendumOption = this.createAddendumOption("cosigned", opinion.imageReport);
                  }else{
                     addendumOption = this.createAddendumOption(opinion.addendum, opinion.imageReport);
                  }
                  if (this.validateOpinion(addendumOption)) this.updateAddendumOpinion(id, addendumOption, row);
               }
            });
         } else {
            const addendum = addendumList[0];
            if(user.id === addendum.userId) {
               if(isEmptyAddendum) {
                  window.rejected();
                  this.openToast(this.t("msg.opinion.validate.empty"), true);
               } else {
                  const addendumOption = this.createAddendumOption(opinion.addendum, opinion.imageReport);
                  if (this.validateOpinion(addendumOption)) this.updateAddendumOpinion(id, addendumOption, row);
               }
            } else {
               let addendumOption = {};
               if(isEmptyAddendum) {
                  addendumOption = this.createAddendumOption("cosigned", opinion.imageReport);
               }else{
                  addendumOption = this.createAddendumOption(opinion.addendum, opinion.imageReport);
               }
               if (this.validateOpinion(addendumOption)) this.updateAddendumOpinion(id, addendumOption, row);
            }
         }
      });
   }

   /**
    * PDF에 인증을 추가한다.
    * @param id
    * @param opinion
    * @param row
    */
   async updateAddendumOpinion(id, opinion, row = this._selectedRow.detail) {
      if(this.popupReport.open) this.changeTopPopupOrWorklist();

      if(this.isRequiredSign()) {
         try {
            const time = await this.getCurrentTime([id]);
            const hashData = this.makeCertJson(row, time, opinion);
            if (id !== (row||{}).id) throw new Error(`${this.t("msg.digitalSign.fail")} id: ${id}, row.id: ${(row||{}).id}`);

            const hash = CryptoJS.SHA256(JSON.stringify(hashData)).toString();

            const signEl = document.getElementById("ESignWindow");
            if(signEl && window.dialog_opened) window.dialog_opened({dialog:signEl, is_modal:true});

            this.doSign(hash, hashData.id).then((signed) => {
               if(window.dialog_closed) window.dialog_closed();

               if (signed.sign) {
                  opinion.approveTime = time;
                  opinion.certText = signed.signedData;
                  opinion.caseId = signed.caseId;
                  opinion.hashText = JSON.stringify(hashData);
               }

               this.execUpdateAddendumOpinion(id, opinion);
            }).catch((err) => {
               console.error("doSign error!", err);

               this.showAddendumSingCanceledDialog(id, opinion);

               const message = err.msg ? err.msg : this.t("msg.digitalSign.fail");
               this.openToast(message, true); // err.isErr); // warning 없으므로 일단 전부 오류처리
               // this.execUpdateAddendumOpinion(id, opinion);
            });
         } catch (err) {
            console.error("sign error!", err);

            this.showAddendumSingCanceledDialog(id, opinion);

            const message = err.message ? err.message : this.t("msg.digitalSign.fail");
            this.openToast(message, true);
         }
      } else {

         this.execUpdateAddendumOpinion(id, opinion).then((result) => {
            const { caseId, seq } = result;
            // this.makePDF(caseId, seq).then(() => {});
         });
      }
      /*
      // select event 발생 후 실행해야함
      // this.openBlocker("판독문 등록 중 입니다...");
      fetch(`/api/opinion/addendum/${id}`, {
         method: "PATCH",
         headers: {
            "Authorization": localStorage.getItem("jwt"),
            "Content-Type": "application/json"
         },
         body: JSON.stringify(opinion)
      }).then((response) => {
         this.closeBlocker();

         if (response.ok) {
            response.json().then((json) => {
               const {addendum, sign, hash, seq} = json;
               if(addendum === true) {
                  // if(sign === true) {
                  //    this.doSign(hash).then((result) => {
                  //       if(!result.sign) {
                  //          this.$.hhReportWindow.ableAddendumAccordionList();
                  //          this.setReport(this.g_selectedRow);
                  //       } else {
                  //          this.signedOpinion(id, seq, hash, result.signData)
                  //             .then(() => {
                  //                this.$.hhReportWindow.ableAddendumAccordionList();
                  //                this.setReport(this.g_selectedRow);
                  //             });
                  //       }
                  //    }).catch((detail) => {
                  //       this.openToast(detail.msg, true);
                  //    });
                  // } else {
                  this.$.hhReportWindow.ableAddendumAccordionList();
                  // TODO addendum
                  this.setReport(this.g_selectedRow, this.g_selectedRows);
                  this.$.hhReportWindow.enabledMarkCVRBtn(true);
                  window.fulfilled();
                  // }
               } else {
                  window.failed();
                  this.openToast(i18next.t("msg.opinion.fail.response"), true);
               }
            });

         }
         else {
            window.failed();
            this.openToast(i18next.t("msg.opinion.fail.response"), true);
         }
      });
      */
   }

   showAddendumSingCanceledDialog(id, opinion) {
      const message = {
         title: i18n("label.addendum"),
         contents: [i18n("msg.digitalSign.dialog.addendumLabel.0"), i18n("msg.digitalSign.dialog.addendumLabel.1")],
         ok: i18n("button.addendum"),
         cancel: i18n("button.cancel"),
         onOk: (result) => {
            if (result?.checked) {
               localStorage.setItem(__CERT_STORAGE__, JSON.stringify(false));
            }
            // addendum
            this.execUpdateAddendumOpinion(id, opinion);

            if(window.dialog_closed) window.dialog_closed();
         },
         // @ts-ignore
         onCancel: () => window.rejected(),
         checkbox: { label: i18n("msg.digitalSign.dialog.checkboxLabel") },
      };
      store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, actionType: DialogActionType.SIGN_CANCEL, message, open: true } });
   }

   execUpdateAddendumOpinion(id, opinion) {
      return new Promise((resolve, reject) => {
         fetch(`/api/opinion/addendum/${id}`, {
            method: "PATCH",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json"
            },
            body: JSON.stringify(opinion)
         }).then((response) => {
            if (response.ok) {
               response.json().then((json) => {
                  const {addendum} = json;
                  if(addendum === true) {
                     this.ableAddendumAccordionList();
                     // TODO addendum
                     this.setReport(this._reportRow.detail, this._reportRow.rows);
                     this.isDisabledMarkCVR = false;
                     // #16299 판독 완료 후 related reports 다시 조회
                     // ui 바뀔때 추가
                     // this.getRelatedReports(this._objId);
                     window.fulfilled();

                     this.openToast(this.t("msg.opinion.success.addendum"), false);

                     resolve(json);
                  } else {
                     window.failed();
                     this.openToast(this.t("msg.opinion.fail.response"), true);
                     reject(new Error(this.t("msg.opinion.fail.response")));
                  }
               });
            } else if(response.status === 400) {
               response.text().then((t) => {
                  window.failed();

                  if(t.includes("Bad Request")) {
                     const {message} = JSON.parse(t);
                     this.openToast(message, true);
                     console.error(message);
                  } else {
                     this.openToast(`${t}`, true);
                     console.error(`${t}`);
                  }
               });
            } else {
               window.failed();
               this.openToast(this.t("msg.opinion.fail.response"), true);
               reject(new Error(this.t("msg.opinion.fail.response")));
            }
         }).catch((e) => {
            this.openToast(e.message, true);
            window.failed();
            console.error(e);
            reject(e);
         });
      });
   }

   // TODO : SCU CONSULT TO 주석처리(20190321 이성현 상무님 요청)
   // 1. Approve 후 Save 활성화 유지가 맞는지?
   // 2. Addemdom 정확한 정의
   // 3. 단축키로 Approve 할 때, 판독이 가능한사람인지 확인
   approveOpinion(row = this._selectedRow.detail, rows = this._selectedRow.rows, opinion = this.getOpinion()) {
      if(!this.popupReport.open) {
         if(window.filmbox && window.filmbox.get()) {
            window.postMessage({event: "REPORTING", callType: "approve", row, rows, opinion}, document.location.href);
         } else if(window.filmbox && !window.filmbox.get()) {
            // filmbox off
            this.approveOpinionData(row, rows, opinion);
         }
      } else {
         if(window.opener.filmbox && window.opener.filmbox.get()) {
            window.opener.postMessage({event: "REPORTING", callType: "approve", row, rows, opinion}, document.location.href);
         } else if(window.opener.filmbox && !window.opener.filmbox.get()) {
            // filmbox off
            this.approveOpinionData(row, rows, opinion);
         }
      }
   }

   approveOpinionData(row = this._selectedRow.detail, rows = this._selectedRow.rows, opinion = null) {
      if(!opinion) opinion = this.getOpinion();

      if (this.validateOpinion(opinion)) {
         if(rows.length === 0)
            rows = [row];
         this.getSelectedRowsCaseInfo(rows.map(row => row.id)).then((result) => {
            if (result) {
               // check temp or edit rows
               const tempRows = result.filter(row => row.processingStatus === "temp" || row.edit === true);
               if(tempRows.length > 0) {
                  window.rejected();
                  this.openToast(this.t("msg.opinion.fail.tempCase.save"), true);
                  return;
               }
               const requestedRows = result.filter(row => row.teleStatus === "sending" || row.teleStatus === "sent" || row.teleStatus === "inReading");
               const unverifiedRows = result.filter(row => row.studyStatus !== "verified");
               if(requestedRows.length > 0) {
                  const message = {
                     title: i18n("label.confirmOpinion.alreadyReq.approve"),
                     contents: [i18n("msg.opinion.confirm.alreadyReq")],
                     ok: i18n("button.accept"),
                     cancel: i18n("button.cancel"),
                     onOk: () => {
                        this.updateApproveOpinion(opinion, rows).then(() => {});
                     },
                  };
                  store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, actionType: DialogActionType.REPORT_ALREADY_REQUEST, message, open: true } });
               }
               else if(unverifiedRows.length > 0) {
                  const message = {
                     title: i18n("label.confirmOpinion.notVerify.approve"),
                     contents: i18n("msg.opinion.confirm.notVerify.approve", { returnObjects: true }),
                     ok: i18n("button.accept"),
                     cancel: i18n("button.cancel"),
                     onOk: () => {
                        this.updateApproveOpinion(opinion, rows).then(() => {});
                     },
                  };
                  store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, actionType: DialogActionType.REPORT_NOT_VERIFY, message, open: true } });
               }
               else {
                  this.updateApproveOpinion(opinion, rows).then(() => {});
               }
            } else {
               console.log("getSelectedRowsCaseInfo.result", result);
               window.failed();
            }
         }).catch((e) => {
            console.log("getSelectedRowsCaseInfo", e);
            window.failed();
         });
      } else {
         window.rejected();
      }
   }

   async updateApproveOpinion(opinion, rows) {
      if(!rows) return;

      if(this.popupReport.open) this.changeTopPopupOrWorklist();

      const ids = rows.map(row => row.id);
      if (this.isRequiredSign()) {
         const time = await this.getCurrentTime(ids);
         const list = rows.map(row => this.makeCertJson(row, time, opinion));

         const [hashData] = list.slice(0, 1);
         const hash = CryptoJS.SHA256(JSON.stringify(hashData)).toString();

         const signEl = document.getElementById("ESignWindow");
         if(signEl && window.dialog_opened) window.dialog_opened({dialog:signEl, is_modal:true});

         this.doSign(hash, hashData.id).then((signed) => {
            if(window.dialog_closed) window.dialog_closed();

            const ops = [];
            const op = {...opinion};
            if (signed.sign) {
               op.approveTime = time;
               op.certText = signed.signedData;
               op.caseId = signed.caseId;
               op.hashText = JSON.stringify(hashData);
            }
            ops.push(op);

            Promise.all((list.slice(1)||[]).map((data) => {
               const hash = CryptoJS.SHA256(JSON.stringify(data)).toString();
               return this.doSign(hash, data.id);
            })).then((signedList) => {
               signedList.forEach((signed) => {
                  const op = {...opinion};
                  if (signed.sign) {
                     op.approveTime = time;
                     op.certText = signed.signedData;
                     op.caseId = signed.caseId;
                     op.hashText = JSON.stringify(hashData);
                  }
                  ops.push(op);
               });

               this.execUpdateApproveOpinionCert(ops);
            }).catch((err) => {
               console.error("doSign error!", err);

               this.showApproveSingCanceledDialog(ids, opinion);

               const message = err.msg ? err.msg : this.t("msg.digitalSign.fail");
               this.openToast(message, true); // err.isErr); // warning 없으므로 일단 전부 오류처리
               // this.execUpdateApproveOpinion(ids, opinion);
            });

         }).catch((err) => {
            console.error("doSign error!", err);

            this.showApproveSingCanceledDialog(ids, opinion);

            const message = err.msg ? err.msg : this.t("msg.digitalSign.fail");
            this.openToast(message, true); // err.isErr); // warning 없으므로 일단 전부 오류처리
            // this.execUpdateApproveOpinion(ids, opinion);
         });
      } else {
         this.execUpdateApproveOpinion(ids, opinion);
      }
   }

   execUpdateApproveOpinionCert(opinions) {
      const ids = opinions.map(o => o.caseId);
      return this.execUpdateApproveOpinions(ids, opinions, "/api/exchange/worklist/opinionApprove/cert");
   }

   execUpdateApproveOpinion(ids, opinion) {
      const params = {ids, opinion};
      return this.execUpdateApproveOpinions(ids, params);
   }

   execUpdateApproveOpinions(ids, params, url = "/api/exchange/worklist/opinionApprove") {
      return new Promise((resolve, reject) => {
         // fetch(`/api/exchange/worklist/opinionApprove?ids=${ids.join(",")}`, {
         fetch(url, {
            method: "PATCH",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json"
            },
            // body: JSON.stringify(opinion)
            body: JSON.stringify(params)
         }).then((response) => {
            if (response.ok) {
               response.json().then((httpResponse) => {
                  const {approve, list} = httpResponse;
                  if(approve === true) {
                     this.approveDone({list, ids});

                     // #18040 [HPACS] MACRO (Reading Template + approve + next filmbox) 사용시 다음 row Report창의 Edit 버튼이 활성화 되는 오류
                     // window.fulfilled();
                     window.postMessage({event: "READING_OFF"}, document.location.href);
                     // if (this.popupReport.open && window.opener) window.opener.postMessage({ event: "POPUP_CHANGE_WORKLIST_COUNT" }, document.location.href);

                     resolve(httpResponse);
                  } else {
                     this.openToast(this.t("msg.opinion.fail.response"), true);
                     window.failed();
                     reject();
                  }
               });
            } else if(response.status === 400) {
               response.text().then((t) => {
                  window.failed();

                  if(t.includes("Bad Request")) {
                     const {message} = JSON.parse(t);
                     this.openToast(message, true);
                     console.error(message);
                  } else {
                     this.openToast(`${t}`, true);
                     console.error(`${t}`);
                  }
               });
            } else {
               this.openToast(this.t("msg.opinion.fail.response"), true);
               window.failed();
               reject();
            }
         }).catch((e) => {
            this.openToast(e.message, true);
            window.failed();
            console.error(e);
            reject();
         });
      });
   }

   approveDone(data) {
      this.approveOpinionisResult(data);

      const {ids} = data;
      // redraw worklist rows
      store.dispatch({ type: WorklistActionType.REDRAW_ROWS, payload: { ids, type: RedrawRowsType.REPORTED } });
      store.dispatch({ type: RelatedWorklistActionType.REDRAW_RELATED_EXAM, payload: ids[0] });

      if (this.checkCurrentReportRow(ids)) { // #15135 관련 처리
         this.getOpinionByWeever(this._reportRow.detail.id).then((opinionResult) => {
            if (!this.checkCurrentReportRow(ids)) return;

            if(opinionResult.isApproveModify) {
               this.isDisabledMarkCVR = false;
               this.enabledEditBtn(true);
            } else {
               this.enableAddendumReport(opinionResult, null, this._reportRow.rows, this._reportRow.detail);
            }
            this.isDisabledMarkCVR = false;

            this.enabledSaveBtn(false);
            this.enabledReportArea(false);
            this.enabledApproveBtn(false);
            this.enabledTranscribeBtn(false);
            this.enabledPrintBtn();
         }).finally(() => {
            this.updateReportRowData(ids); // rs 상태값 동기화
         });
      }
   }

   // 판독 대기중 일때 판독문 승인 처리시 결과
   approveOpinionisResult(result) {
      let msg;
      let isErr;
      if (result) {
         msg = this.t("msg.opinion.success.approve");
         isErr = false;
      } else {
         msg = this.t("msg.opinion.fail.response");
         isErr = true;
      }
      this.openToast(msg, isErr);
   }

   showApproveSingCanceledDialog(ids, opinion) {
      const message = {
         title: i18n("label.approve"),
         contents: [i18n("msg.digitalSign.dialog.approveLabel.0"), i18n("msg.digitalSign.dialog.approveLabel.1")],
         ok: i18n("button.approve"),
         cancel: i18n("button.cancel"),
         onOk: (result) => {
            if (result?.checked) {
               localStorage.setItem(__CERT_STORAGE__, JSON.stringify(false));
            }
            // approve
            this.execUpdateApproveOpinion(ids, opinion);

            if(window.dialog_closed) window.dialog_closed();
         },
         // @ts-ignore
         onCancel: () => window.rejected(),
         checkbox: { label: i18n("msg.digitalSign.dialog.checkboxLabel") },
      };
      store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, actionType: DialogActionType.SIGN_CANCEL, message, open: true } });
   }

   // TODO macro 실행시 FLeader에서 호출함
   saveOpinion(isShortcut, row, rows = []) {
      if(!this.popupReport.open) {
         if(window.filmbox && window.filmbox.get()) {
            window.postMessage({event: "REPORTING", callType: "save", isShortcut, row, rows}, document.location.href);
         } else if(window.filmbox && !window.filmbox.get()) {
            // filmbox off
            this.saveOpinionData(isShortcut, row, rows);
         }
      } else {
         if(window.opener.filmbox && window.opener.filmbox.get()) {
            window.opener.postMessage({event: "REPORTING", callType: "save", isShortcut, row, rows}, document.location.href);
         } else if(window.opener.filmbox && !window.opener.filmbox.get()) {
            this.saveOpinionData(isShortcut, row, rows);
         }
      }
   }

   saveOpinionData(isShortcut, row, rows = []) {
      const opinion = row.editOpinion;
      // const opinion = this.$.hhReportWindow.getOpinion();
      const user = JSON.parse(localStorage.user);
      // const {id} = this.g_selectedRow;
      if (this.validateOpinion(opinion)) {
         if(rows.length === 0)
            rows = [row];
         this.getSelectedRowsCaseInfo(rows.map(row => row.id)).then((result) => {
            if(result) {
               // check approve rows
               const approveRow = result.find(row => row.readingStatus === "3A");
               if(approveRow) {
                  window.rejected();
                  this.openToast(this.t("msg.opinion.fail.approveCase"), true);
                  return;
               }

               const trnascribeRow = result.find(row => row.readingStatus === "2DT");
               if(trnascribeRow) {
                  window.rejected();
                  this.openToast(this.t("msg.opinion.fail.transcribeCase"), true);
                  return;
               }
               // check temp or edit rows
               const tempRows = result.filter(row => row.processingStatus === "temp" || row.edit === true);
               if(tempRows.length > 0) {
                  window.rejected();
                  this.openToast(this.t("msg.opinion.fail.tempCase.save"), true);
                  return;
               }
               const openedRows = result.filter(row => row.readingStatus === "2T" && row.opinionUpdateUserId !== user.id);
               const requestedRows = result.filter(row => row.teleStatus === "sending" || row.teleStatus === "sent" || row.teleStatus === "inReading");
               // const unverifiedRows = result.filter(row => row.studyStatus !== "verified");
               const saveVerfiedRows = result.filter(row => (row.studyStatus !== "verified" && !row.isSaveVerified));
               if(isShortcut) {
                  if(openedRows.length > 0) {
                     const msgArr = this.t("msg.opinion.confirm.already.save", { returnObjects: true });
                     let msg = `${msgArr[0]} ${openedRows[0].opinionUpdateUser}`;
                     if(result.length > 1) msg = msgArr[1];
                     const message = {
                        title: i18n("label.saveOpinion"),
                        contents: [
                           msg,
                           msgArr[2]
                        ],
                        ok: i18n("button.accept"),
                        cancel: i18n("button.cancel"),
                        onOk: () => {
                           this.updateSaveOpinion(opinion, rows);
                        },
                     };
                     store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, actionType: DialogActionType.REPORT_SAVE_OPINION, message, open: true } });
                  } else {
                     this.updateSaveOpinion(opinion, rows);
                  }
               }
               else if(requestedRows.length > 0) {
                  const message = {
                     title: i18n("label.confirmOpinion.alreadyReq.save"),
                     contents: [i18n("msg.opinion.confirm.alreadyReq")],
                     ok: i18n("button.accept"),
                     cancel: i18n("button.cancel"),
                     onOk: () => {
                        this.updateSaveOpinion(opinion, rows);
                     },
                  };
                  store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, actionType: DialogActionType.REPORT_ALREADY_REQUEST, message, open: true } });
               }
               else if(saveVerfiedRows.length > 0) {
                  const message = {
                     title: i18n("label.confirmOpinion.notVerify.save"),
                     contents: i18n("msg.opinion.confirm.notVerify.save", { returnObjects: true }),
                     ok: i18n("button.accept"),
                     cancel: i18n("button.cancel"),
                     onOk: () => {
                        this.updateSaveOpinion(opinion, rows);
                     },
                  };
                  store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, actionType: DialogActionType.REPORT_NOT_VERIFY, message, open: true } });
               }
               else if(openedRows.length > 0) {
                  const msgArr = this.t("msg.opinion.confirm.already.save", { returnObjects: true });
                  let msg = `${msgArr[0]} ${openedRows[0].opinionUpdateUser}`;
                  if(result.length > 1) msg = msgArr[1];
                  const message = {
                     title: i18n("label.saveOpinion"),
                     contents: [
                        msg,
                        msgArr[2]
                     ],
                     ok: i18n("button.accept"),
                     cancel: i18n("button.cancel"),
                     onOk: () => {
                        this.updateSaveOpinion(opinion, rows);
                     },
                  };
                  store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, actionType: DialogActionType.REPORT_SAVE_OPINION, message, open: true } });
               }
               else {
                  this.updateSaveOpinion(opinion, rows);
               }
            } else {
               console.log("getSelectedRowsCaseInfo.result", result);
               window.failed();
            }
         }).catch((e) => {
            console.log("getSelectedRowsCaseInfo", e);
            window.failed();
         });
      } else {
         window.rejected();
      }
   }

   updateSaveOpinion(opinion, rows) {
      // select event 발생 후 실행해야함
      if(!rows) return;

      if(this.popupReport.open) this.changeTopPopupOrWorklist();

      const ids = rows.map(row => row.id);
      fetch(`/api/exchange/worklist/opinionSave?ids=${ids.join(",")}`, {
         method: "PATCH",
         headers: {
            "Authorization": localStorage.getItem("jwt"),
            "Content-Type": "application/json"
         },
         body: JSON.stringify(opinion)
      }).then((response) => {
         if (response.ok) {
            response.json().then((httpResponse) => {
               this.saveDone({ids});

               // window.fulfilled();
               window.postMessage({event: "READING_OFF"}, document.location.href);
               // if (this.popupReport.open && window.opener) window.opener.postMessage({ event: "POPUP_CHANGE_WORKLIST_COUNT" }, document.location.href);

            });
         }
         else {
            window.failed();
            this.openToast(this.t("msg.opinion.fail.response"), true);
         }
      }).catch((e) => {
         console.log(e);
         this.openToast("Exception Occur while update Saving Opinion", true);
         window.failed();
      });
   }

   saveDone(data) {
      this.saveOpinionisResult(data);

      const { ids } = data;
      // redraw worklist rows
      store.dispatch({ type: WorklistActionType.REDRAW_ROWS, payload: { ids, type: RedrawRowsType.REPORTED } });
      store.dispatch({ type: RelatedWorklistActionType.REDRAW_RELATED_EXAM, payload: ids[0] });

      if (this.checkCurrentReportRow(ids)) { // #15135 관련 처리
         this.enabledApproveBtn(true);
         this.enabledTranscribeBtn(false);
         // this.$.hhReportWindow.changeApproveText("Approve");

         this.updateReportRowData(ids); // rs 상태값 동기화
      }
   }

   saveOpinionisResult(result) {
      let msg;
      let isErr;
      if (result) {
         msg = this.t("msg.opinion.success.save");
         isErr = false;
      } else {
         msg = this.t("msg.opinion.fail.response");
         isErr = true;
      }
      this.openToast(msg, isErr);
   }

   transcribeOpinion(row = {}, rows = []) {
      if(!this.popupReport.open) {
         if(window.filmbox && window.filmbox.get()) {
            window.postMessage({event: "REPORTING", callType: "transcribe", row, rows}, document.location.href);
         } else if(window.filmbox && !window.filmbox.get()) {
            this.transcribeOpinionData(row, rows);
         }
      } else {
         if(window.opener.filmbox && window.opener.filmbox.get()) {
            window.opener.postMessage({event: "REPORTING", callType: "transcribe", row, rows}, document.location.href);
         } else if(window.opener.filmbox && !window.opener.filmbox.get()) {
            this.transcribeOpinionData(row, rows);
         }
      }
   }

   transcribeOpinionData(row = {}, rows = []) {
      const {editOpinion: opinion} = row;
      const user = JSON.parse(localStorage.user);
      if (this.validateOpinion(opinion)) {
         if(rows.length === 0) rows = [row];

         Promise.all(rows.map(row => this.getOpinionByWeever(row.id)))
            .then((v) => {
               this.getSelectedRowsCaseInfo(rows.map(row => row.id)).then((result) => {
                  if(result) {
                     const approveRow = result.find(row => row.readingStatus === "3A");
                     if(approveRow) {
                        window.rejected();
                        this.openToast(this.t("msg.opinion.fail.approveCase"), true);
                        return;
                     }

                     const saveRow = result.find(row => row.readingStatus === "2T");
                     if(saveRow) {
                        window.rejected();
                        this.openToast(this.t("msg.opinion.fail.saveCase"), true);
                        return;
                     }

                     const tempRows = result.filter(row => row.processingStatus === "temp" || row.edit === true);
                     if(tempRows.length > 0) {
                        window.rejected();
                        this.openToast(this.t("msg.opinion.fail.tempCase.transcribe"), true);
                        return;
                     }

                     if(result.every(row => row.readingStatus === "2DT")) {
                        result = v.map((m) => {
                           const row = result.find(f => m.caseId === f.id);
                           row.transcribeReadRoles = m.transcribeReadRoles;
                           row.isContainsMembers = m.isContainsMembers;
                           row.isContainsFacilityMembers = m.isContainsFacilityMembers;
                           row.userId = m.userId;
                           return row;
                        });
                     }

                     const openedRows = result.filter((row) => {
                        const isTranscribeReadRoles = (row.transcribeReadRoles === "User" && row.userId === user.id)
                           || (row.transcribeReadRoles === "Group" && row.isContainsMembers)
                           || (row.transcribeReadRoles === "All" && (row.userId === user.id || row.isContainsMembers || row.isContainsFacilityMembers));
                        return row.readingStatus === "2DT" && !isTranscribeReadRoles;
                     });
                     const requestedRows = result.filter(row => row.teleStatus === "sending" || row.teleStatus === "sent" || row.teleStatus === "inReading");
                     const unverifiedRows = result.filter(row => row.studyStatus !== "verified");

                     if(openedRows.length > 0) {
                        const msgArr = this.t("msg.opinion.confirm.already.transcribe", { returnObjects: true });
                        let msg = `${msgArr[0]} ${openedRows[0].opinionUpdateUser}`;
                        if(result.length > 1) msg = msgArr[1];

                        const message = {
                           title: i18n("label.transcribeOpinion"),
                           contents: [
                              msg,
                              msgArr[2]
                           ],
                           ok: i18n("button.accept"),
                           cancel: i18n("button.cancel"),
                           onOk: () => {
                              this.updateTranscribeOpinion(opinion, rows);
                           },
                        };
                        store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, actionType: DialogActionType.REPORT_TRANSCRIBE_OPINION, message, open: true } });
                     }
                     else if(requestedRows.length > 0) {
                        const message = {
                           title: i18n("label.confirmOpinion.alreadyReq.transcribe"),
                           contents: [i18n("msg.opinion.confirm.alreadyReq")],
                           ok: i18n("button.accept"),
                           cancel: i18n("button.cancel"),
                           onOk: () => {
                              this.updateTranscribeOpinion(opinion, rows);
                           },
                        };
                        store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, actionType: DialogActionType.REPORT_ALREADY_REQUEST, message, open: true } });
                     }
                     else if(unverifiedRows.length > 0) {
                        const message = {
                           title: i18n("label.confirmOpinion.notVerify.transcribe"),
                           contents: i18n("msg.opinion.confirm.notVerify.transcribe", { returnObjects: true }),
                           ok: i18n("button.accept"),
                           cancel: i18n("button.cancel"),
                           onOk: () => {
                              this.updateTranscribeOpinion(opinion, rows);
                           },
                        };
                        store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, actionType: DialogActionType.REPORT_NOT_VERIFY, message, open: true } });
                     } else {
                        this.updateTranscribeOpinion(opinion, rows);
                     }
                  } else {
                     console.log("getSelectedRowsCaseInfo.result", result);
                     window.failed();
                  }
               }).catch((e) => {
                  console.log("getSelectedRowsCaseInfo", e);
                  window.failed();
               });
            });
      } else {
         window.rejected();
      }
   }

   updateTranscribeOpinion(opinion, rows) {
      // select event 발생 후 실행해야함
      if(!rows) return;

      if(this.popupReport.open) this.changeTopPopupOrWorklist();

      const ids = rows.map(row => row.id);
      fetch(`/api/exchange/worklist/opinionTranscribe?ids=${ids.join(",")}`, {
         method: "PATCH",
         headers: {
            "Authorization": localStorage.getItem("jwt"),
            "Content-Type": "application/json"
         },
         body: JSON.stringify(opinion)
      }).then((response) => {
         if (response.ok) {
            response.json().then((httpResponse) => {
               this.transcribeDone({ids});

               // window.fulfilled();
               window.postMessage({event: "READING_OFF"}, document.location.href);
               // if (this.popupReport.open && window.opener) window.opener.postMessage({ event: "POPUP_CHANGE_WORKLIST_COUNT" }, document.location.href);

            });
         }
         else {
            window.failed();
            this.openToast(this.t("msg.opinion.fail.response"), true);
         }
      }).catch((e) => {
         console.log(e);
         this.openToast("Exception Occur while update Transcribing Opinion", true);
         window.failed();
      });
   }

   transcribeDone(data) {
      this.transcribeOpinionisResult(data);

      const { ids } = data;
      // redraw worklist rows
      store.dispatch({ type: WorklistActionType.REDRAW_ROWS, payload: { ids, type: RedrawRowsType.REPORTED } });
      store.dispatch({ type: RelatedWorklistActionType.REDRAW_RELATED_EXAM, payload: ids[0] });

      if (this.checkCurrentReportRow(ids)) { // #15135 관련 처리
         this.enabledApproveBtn(true);
         this.enabledSaveBtn(false);
         // this.$.hhReportWindow.changeApproveText("Approve");

         this.updateReportRowData(ids); // rs 상태값 동기화
      }
   }

   transcribeOpinionisResult(result) {
      let msg;
      let isErr;
      if (result) {
         msg = this.t("msg.opinion.success.transcribe");
         isErr = false;
      } else {
         msg = this.t("msg.opinion.fail.response");
         isErr = true;
      }
      this.openToast(msg, isErr);
   }

   requestMarkCVR(id = this._selectedRow.detail.id) {
      fetch(`/api/case/${id}/cvr`, {
         method: "PATCH",
         headers: {
            "Authorization": localStorage.getItem("jwt")
         }
      }).then((response) => {
         if (response.ok && response.status === 200) {
            response.json().then((httpResponse) => {
               const {isResult} = httpResponse;
               if(isResult) {
                  this.openToast(this.t("msg.cvr.success"), false);
                  if(this.popupReport.open && window.opener && window.opener.report && window.opener.report.get()) window.opener.postMessage({event: "POPUP_MARK_CVR", id}, document.location.href);
                  else this.dispatchEvent(new CustomEvent("markCVREvent", {bubbles: true, composed: true, detail: id}));
               }
               else this.openToast(this.t("msg.cvr.fail"), true);
            });
         } else {
            this.openToast(this.t("msg.cvr.fail"), true);
         }
      }).catch(() => {
         this.openToast(this.t("msg.cvr.fail"), true);
      });
   }

   changeCustomContextMenuState(customContextMenuState) {
      if (this.customUniqueKey !== customContextMenuState) {
         if (!this.$.contextMenu.classList.contains("hideContextMenu"))
            this.$.contextMenu.classList.add("hideContextMenu"); // HIDE
      }
   }

   initReportButton() {
      this.disableAddendumList();
      this.invisibleAddendumDiv();
      this.visibleApproveDiv();
      this.enabledReportArea(false); // report area enable
      this.disabledPrintBtn(); // print Btn disable
      this.isDisabledMarkCVR = true; // mark cvr Btn disable
      this.enabledEditBtn(false); // edit Btn disable
      this.enabledSaveBtn(false); // save Btn disable
      this.enabledApproveBtn(false); // approve Btn disable
      this.enabledTranscribeBtn(false);
      this.disabledClearBtn(); // clear Btn disable
   }

   _changePopupReport(popup = {}) {
      const {open, editOpinion} = popup;
      if (!open) {
         if (this.offsetWidth > 0 && this.offsetWidth < 480) this.isExpand = false;
         else this.isExpand = true;
      }

      // 17787 [HPACS] 기본 리포트영역과 리포트팝업영역의 판독문 작성중 동기화 기능
      // 판독문 작성 도중 popup open/close시 판독문 동기화
      if (this._reportRow?.detail) this.setReport(this._reportRow.detail, this._reportRow.rows, editOpinion);
      else this.popupEditOpinion = editOpinion;
   }

   changeTopPopupOrWorklist() {
      if(!this.isPopupPin) {
         // #17744 isPopupPin : false >> worklist가 최상단
         const filmboxPopup = typeof window.filmbox?.get === "function" ? window.filmbox.get() : window.opener.filmbox.get();
         if(filmboxPopup && filmboxPopup.document.hasFocus()) {
            // filmbox에 foucs가 있을때
            filmboxPopup.open("", "worklist");
         } else {
            // reportpopup에 focus가 있을때
            const reportPopup = typeof window.report?.get === "function" ? window.report.get() : window.opener.report.get();
            reportPopup.open("", "worklist");
         }
      }
   }

   updateReportRowData(ids = []) {
      const currentIds = this._reportRow?.rows?.map(r => r.id); // old row로 판독했을 경우 current row와 다를 수 있음, 그럼 reportRow를 dispatch 하지 말아야 함.
      if (!currentIds || currentIds.length === 0) return;

      if (JSON.stringify(ids.sort()) !== JSON.stringify(currentIds.sort())) return;

      // row 정보 변경
      this.getIdFiltersByRows(ids).then((result) => {
         if (!this.checkCurrentReportRow(ids)) return;

         const { oldRow, oldRows } = this._reportRow;
         const param = {
            detail: result?.[0],
            oldRow,
            rows: result,
            oldRows
         };
         store.dispatch({ type: ReportActionType.SELECT_REPORT_ROW, payload: { ...param } });
      }).finally(() => {
         // mismatch continue to read + Reading Template + approve + next filmbox 사용시 reportRow data가 늦게 바껴서 이전 report 정보를 출력함
         window.fulfilled();
      });

   }

   getIdFiltersByRows(ids) {
      return new Promise((resolve) => {
         return fetch(`/api/exchange/worklist/idfilter?ids=${ids}`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json"
            },
         }).then((response) => {
            if (response.ok) {
               response.json().then((httpResponse) => {
                  resolve(httpResponse);
               });
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }

   checkCurrentReportRow(ids = []) {
      return ids.includes(this._reportRow?.detail?.id);
   }

   checkOldReportRow(ids = []) {
      return ids.includes(this._reportRow?.oldRow?.id);
   }
}

window.customElements.define(HHReport.is, HHReport);
